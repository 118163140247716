export const infoRow = (text, withHr = true) => {
  return {
    columns: [
      {
        default: 12,
        xs: 12,
        className: 'align-self-center',
        field: {
          type: 'custom-html',
          html: `<h6 class="small text-muted"><b>${text}</b></h6>${withHr ? '<hr class="my-0"/>' : ''}`
        }
      }
    ]
  };
};

const loginFormRows = [
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'email',
          titleKey: 'email',
          title: 'Email',
          type: 'text',
          required: true
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'password',
          title: 'Password',
          type: 'text',
          variant: 'password',
          required: true
        }
      }
    ]
  }
];

export const loginForm = {
  forms: [
    {
      name: 'login',
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: 'Login',
        show: true,
        onSubmit: 'onLoginFormSubmit'
      },
      rows: loginFormRows
    }
  ]
};

export const getStartedForm = {
  forms: [
    {
      name: 'checkEmail',
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: 'Register',
        show: true,
        onSubmit: 'onCheckEmailFormSubmit'
      },
      rows: [
        {
          columns: [
            {
              default: 6,
              field: {
                id: 'firstName',
                title: 'First Name',
                type: 'text',
                required: true,
                onChangeWindow: 'updateUserId'
              }
            },
            {
              default: 6,
              field: {
                id: 'lastName',
                title: 'Last Name',
                type: 'text',
                required: true,
                onChangeWindow: 'updateUserId'
              }
            },
            {
              default: 12,
              field: {
                id: 'email',
                title: 'Email',
                titleKey: 'email_get_started',
                type: 'text',
                variant: 'email',
                required: true,
              }
            },
            {
              default: 12,
              field: {
                id: 'phoneNumber',
                title: 'Phone Number',
                type: 'text',
                required: false
              }
            },
           
  
          ]
        }
      ]
    }
  ]
};

export const emailForm = {
  forms: [
    {
      name: 'checkEmail',
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: 'Continue',
        show: true,
        onSubmit: 'onCheckEmailFormSubmit'
      },
      rows: [
        {
          columns: [
            {
              default: 12,
              field: {
                id: 'email',
                title: 'Email',
                type: 'text',
                variant: 'email',
                required: true
              }
            }
          ]
        }
      ]
    }
  ]
};

export const registerForm = {
  forms: [
    {
      name: 'register',
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: 'Register',
        show: true,
        onSubmit: 'onRegisterFormSubmit'
      },
      rows: [
        {
          columns: [
            {
              default: 12,
              field: {
                id: 'password',
                title: 'Password',
                type: 'text',
                variant: 'password',
                required: true
              }
            }
          ]
        },
        {
          columns: [
            {
              default: 12,
              field: {
                id: 'confirmPassword',
                title: 'Confirm Password',
                type: 'text',
                variant: 'password',
                required: true
              }
            }
          ]
        },
        {
          columns: [
            {
              default: 12,
              field: {
                id: 'otp',
                title: 'OTP',
                type: 'text',
                hint: 'Enter OTP here...',
                max: 6,
                required: true
              }
            }
          ]
        }
      ]
    }
  ]
};

export const changePasswordForm = {
  forms: [
    {
      name: 'changePassword',
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: 'Change',
        show: true,
        onSubmit: 'onChangePasswordFormSubmit'
      },
      rows: [
        {
          columns: [
            {
              default: 6,
              field: {
                id: 'oldPassword',
                title: 'Old Password',
                type: 'text',
                variant: 'password',
                required: true
              }
            },
            {
              default: 6,
              field: {
                id: 'newPassword',
                title: 'New Password',
                type: 'text',
                variant: 'password',
                required: true
              }
            }
          ]
        },
        {
          columns: [
            {
              default: 6,
              field: {
                id: 'confirmPassword',
                title: 'Confirm Password',
                type: 'text',
                variant: 'password',
                required: true
              }
            }
          ]
        }
      ]
    }
  ]
};

export const resetPasswordForm = {
  forms: [
    {
      name: 'resetPassword',
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: 'Reset Password',
        show: true,
        onSubmit: 'onResetPasswordFormSubmit'
      },

      rows: [
        {
          columns: [
            {
              default: 12,
              field: {
                id: 'otp',
                title: 'OTP',
                type: 'text',
                required: true
              }
            }
          ]
        },
        {
          columns: [
            {
              default: 12,
              field: {
                id: 'newPassword',
                title: 'New Password',
                type: 'text',
                variant: 'password',
                required: true
              }
            }
          ]
        },
        {
          columns: [
            {
              default: 12,
              field: {
                id: 'confirmPassword',
                title: 'Confirm Password',
                type: 'text',
                variant: 'password',
                required: true
              }
            }
          ]
        }
      ]
    }
  ]
};

export const forgetPaswordForm = {
  forms: [
    {
      name: 'forgotPassword',
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: 'Get reset email',
        show: true,
        onSubmit: 'onForgotPasswordFormSubmit'
      },
      rows: [
        {
          columns: [
            {
              default: 12,
              field: {
                id: 'forgetPasswordEmail',
                title: 'Email',
                type: 'text',
                variant: 'email',
                required: true
              }
            }
          ]
        }
      ]
    }
  ]
};

export const verifyEmailForm = {
  forms: [
    {
      name: 'verifyEmail',
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: 'Verify',
        show: true,
        onSubmit: 'onEmailVerifyFormSubmit'
      },
      rows: [
        {
          columns: [
            {
              default: 12,
              field: {
                id: 'otp',
                title: 'OTP',
                type: 'text',
                hint: 'Enter OTP here...',
                required: true
              }
            }
          ]
        }
      ]
    }
  ]
};
