import { makeApiRequests } from '../helpers/api';
import { ENDPOINTS } from '../helpers/constants';

export const apartmentServices = {
  getApartments: async (requestBody, signal) => {
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.GET_APPARTMENTS,
      requestBody: requestBody,
      signal: signal,
      method: 'POST'
    });

    return { response, error };
  },
  createApartment: async requestBody => {
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CREATE_APARTMENT,
      requestBody: requestBody,
      method: 'POST'
    });

    return { response, error };
  },
  updateApartment: async (id, requestBody) => {
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.APARTMENT_WITH_ID(id),
      requestBody: requestBody,
      method: 'PUT'
    });

    return { response, error };
  },
  deleteApartment: async id => {
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.APARTMENT_WITH_ID(id),
      method: 'DELETE'
    });

    return { response, error };
  },
  createDriveFolder: async requestBody => {
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.CREATE_DRIVE_FOLDER,
      requestBody: requestBody,
      method: 'POST'
    });

    return { response, error };
  }
};
