import React from 'react';
import { useParams } from 'react-router-dom';
import CachedResponse from '../common/CachedResponse';
import CustomerPortal from '../customer/CustomerPortal';
import Header from '../common/Header';
import { ENDPOINTS } from '../../helpers/constants';

const ExampleUser = () => {
  let { userId } = useParams();

  return (
    <>
      <Header />
      <CachedResponse
        listEndpoint={'comps'}
        requestBody={{ filter: { customer: false } }}
        responseModifier={response => response[0]}
        render={compTable => (
          <CustomerPortal
            adminCompTable={compTable}
            readOnly
            endPointToFetchCustomerFrom={ENDPOINTS.EXAMPLE_USER(userId)}
          />
        )}
      />
    </>
  );
};

export default ExampleUser;
