import React, { useContext } from 'react';
import { Pie } from 'react-chartjs-2';
import { commonChartConfig } from '../../../helpers/constants';
import { LocalizeContext } from 'react-locale-language';
import { useMemo } from 'react';
import { isRtl } from '../../../helpers/localization';

const PieChart = ({ chartData }) => {
  const { langCode } = useContext(LocalizeContext);

  const commonConfig = useMemo(() => commonChartConfig(isRtl(langCode)), [langCode]);

  return (
    <div className="chart-container">
      <Pie data={chartData} options={commonConfig} />{' '}
    </div>
  );
};

export default PieChart;
