import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../../../../helpers/api';
import { ENDPOINTS } from '../../../../../helpers/constants';
import RichTextEditor from '../../../../common/rich-text-editor/RichTextEditor';

const CustomerGeneralInfo = ({ customer, onCustomerUpdate, disabled, editable = true }) => {
  const [updatingCustomer, setUpdatingCustomer] = useState('');

  const onUpdateCustomerFormSubmit = async generalInformation => {
    setUpdatingCustomer(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_UPDATE,
      requestBody: { generalInformation: generalInformation, _id: customer._id }
    });

    setUpdatingCustomer(false);
    if (error) {
      toast.error(error);
      return;
    }

    toast.success('Info updated succesfully!');
    onCustomerUpdate(response);
  };

  return (
    <div className="d-flex flex-column h-100">
      <Card className="flex-grow-1">
        <Card.Body className="p-0 ">
          <RichTextEditor
            className="border-0"
            rows={10}
            richText={customer?.generalInformation || ''}
            disabled={updatingCustomer}
            editable={editable}
            onSubmit={onUpdateCustomerFormSubmit}
          />
        </Card.Body>
      </Card>
    </div>
  );
};

export default CustomerGeneralInfo;
