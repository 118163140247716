import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Container, FormCheck, FormControl, FormSelect, Table } from 'react-bootstrap';
import { ArrowsAngleContract, ArrowsAngleExpand, Check } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import { toast } from 'react-toastify';
import PercentageInput from '../../form-generator/components/PercentageInput';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import { isRtl } from '../../helpers/localization';
import { formatCurrency, formatPercentage } from '../admin/manage-users/customer/strategies/helper';
import { getAllOptionsFields, getAllRSUFields } from './constants';
import { getInitialValues, getRSUOptionsParsedValues } from './helpers';
import { getOptionsCalculations, getRsuCalculations } from './rsuOptionsHelpers';

const getRSUCalculatedValues = (allFields, sections, adminCompTable, rsuRawData) => {
  const parsedValues = getRSUOptionsParsedValues(allFields, sections, adminCompTable, rsuRawData);
  return getRsuCalculations(parsedValues);
};

const getOptionsCalculatedValues = (allFields, sections, adminCompTable, rsuRawData) => {
  const parsedValues = getRSUOptionsParsedValues(allFields, sections, adminCompTable, rsuRawData);
  return getOptionsCalculations(parsedValues);
};

const DataRow = ({
  label,
  dataObject,
  objectKey,
  isInput,
  sections,
  type,
  onValueChange,
  allFields,
  isRtlLanguage,
  collapsedSections,
  tabIndex,
  totalFieldsCount,
  readOnlyMode
}) => {
  return (
    <tr>
      <td className={`p-1 bg-white text-${isRtlLanguage ? 'end' : 'start'}`}>
        <b>
          {label}
          {type === 'percentage' && ' (%)'}
        </b>
      </td>
      {sections.map((section, sectionIndex) => (
        <td key={section.id} className={`p-1 text-${isRtlLanguage ? 'end' : 'start'}`}>
          {!collapsedSections.includes(section.id) && (
            <>
              {isInput ? (
                type === 'boolean' ? (
                  <FormCheck
                    tabIndex={sectionIndex * totalFieldsCount + tabIndex}
                    size="sm"
                    disabled={readOnlyMode}
                    checked={dataObject[`${section.id}${objectKey}`]}
                    onChange={e => onValueChange(`${section.id}${objectKey}`, type, e.target.checked)}
                  />
                ) : type === 'rate' ? (
                  <FormSelect
                    tabIndex={sectionIndex * totalFieldsCount + tabIndex}
                    size="sm"
                    disabled={readOnlyMode}
                    value={dataObject[`${section.id}${objectKey}`]}
                    onChange={e => onValueChange(`${section.id}${objectKey}`, type, e.target.value)}
                  >
                    {[
                      'Select...',
                      ...Array(10)
                        .fill(0)
                        .map((a, i) => i + 1)
                    ].map(o => (
                      <option key={o} value={o === 'Select...' ? '' : o}>
                        {o}
                      </option>
                    ))}
                  </FormSelect>
                ) : type === 'percentage' ? (
                  <PercentageInput
                    tabIndex={sectionIndex * totalFieldsCount + tabIndex}
                    className="m-0"
                    size="sm"
                    disabled={readOnlyMode}
                    value={dataObject[`${section.id}${objectKey}`]}
                    onChange={e => onValueChange(`${section.id}${objectKey}`, type, e.target.value)}
                  />
                ) : (
                  <FormControl
                    autoComplete="off"
                    tabIndex={sectionIndex * totalFieldsCount + tabIndex}
                    className="m-0"
                    as={type === 'text-area' ? 'textarea' : 'input'}
                    type={'text'}
                    disabled={readOnlyMode}
                    size="sm"
                    rows={3}
                    value={dataObject[`${section.id}${objectKey}`]}
                    onChange={e => onValueChange(`${section.id}${objectKey}`, type, e.target.value)}
                  />
                )
              ) : (
                <span className="px-2">
                  {type === 'percentage'
                    ? formatPercentage(
                        getPercentageValue(
                          allFields,
                          `${section.id}${objectKey}`,
                          dataObject[`${section.id}${objectKey}`]
                        )
                      )
                    : formatCurrency(dataObject[`${section.id}${objectKey}`])}
                </span>
              )}
            </>
          )}
        </td>
      ))}
    </tr>
  );
};

const getServerFields = (allFields, sections, values) => {
  return allFields
    .filter(f => f.isInput)
    .flatMap(f => sections.map(s => `${s.id}${f.objectKey}`))
    .reduce((acc, objectKey) => ({ ...acc, [objectKey]: values[objectKey] }), {});
};

const rsuSections = [
  {
    label: 'holding_postponing_tax',
    id: 'postponingTax'
  },
  {
    label: 'paying_tax_investing_somewhere',
    id: 'payingTax'
  }
];

const optionsSections = [
  {
    label: 'options_to_cash_after_two_years',
    id: 'after'
  },
  {
    label: 'options_to_cash_pre_two_years',
    id: 'pre'
  }
];

const Calculator = ({
  title,
  values,
  onValuesChange,
  showSaveButton,
  updatingCustomer,
  onSaveClick,
  onToggleCollapse,
  allFields,
  sections,
  tabIndexOffset = 0,
  onInputValueChange,
  readOnlyMode
}) => {
  const { translate, langCode } = useContext(LocalizeContext);
  const [collapsedSections, setCollapsedSections] = useState([]);
  const totalFieldsCount = useMemo(() => allFields.length, [allFields]);
  const isRtlLanguage = useMemo(() => isRtl(langCode), [langCode]);

  const onCollapseSectionClick = sectionId => {
    if (collapsedSections.includes(sectionId)) {
      collapsedSections.splice(collapsedSections.indexOf(sectionId), 1);
    } else {
      collapsedSections.push(sectionId);
    }
    onToggleCollapse(collapsedSections);
    setCollapsedSections([...collapsedSections]);
  };

  useEffect(() => {
    if (values?.collapsedSections?.length > 0) {
      setCollapsedSections([...values?.collapsedSections]);
    }
  }, [values]);
  return (
    <Card className="mb-4">
      <Card.Header>
        <div className="d-flex">
          <div className={`flex-grow-1 text-${isRtlLanguage ? 'end' : 'start'}`}>{translate(title)}</div>
          <div>
            {showSaveButton && !readOnlyMode && (
              <Button
                disabled={updatingCustomer}
                variant="outline-success"
                size="sm"
                className="px-1 py-0 mx-1"
                onClick={onSaveClick}
              >
                <Check size={10} />
                <span className="align-middle mx-1">{translate('save')}</span>
              </Button>
            )}
          </div>
        </div>
      </Card.Header>
      <Card.Body className="p-0 scrollbar-visible overflow-auto">
        <Table bordered hover className="mid">
          <thead>
            <tr className="bg-primary text-white">
              <th className="calc-column"></th>
              {sections.map(section => (
                <th
                  style={!collapsedSections.includes(section.id) ? {} : undefined}
                  key={section.id}
                  className={`p-1 text-end ${!collapsedSections.includes(section.id) ? 'calc-column' : ''}`}
                >
                  <div className="d-flex px-1 align-items-end">
                    {!collapsedSections.includes(section.id) && (
                      <div className={`flex-grow-1 mx-1 text-${isRtlLanguage ? 'end' : 'start'}`}>
                        {translate(section.label)}
                      </div>
                    )}
                    <div>
                      <Button
                        variant="danger"
                        size="sm"
                        className="px-1 py-0"
                        onClick={() => onCollapseSectionClick(section.id)}
                      >
                        {collapsedSections.includes(section.id) ? (
                          <ArrowsAngleExpand size={12} />
                        ) : (
                          <ArrowsAngleContract size={12} />
                        )}
                      </Button>
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {allFields.map((rowDetails, index) => (
              <DataRow
                {...rowDetails}
                sections={sections}
                allFields={allFields}
                dataObject={values}
                onValueChange={onInputValueChange}
                isRtlLanguage={isRtlLanguage}
                collapsedSections={collapsedSections}
                tabIndex={tabIndexOffset + index + 1}
                totalFieldsCount={totalFieldsCount}
                readOnlyMode={readOnlyMode}
              />
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const RSUOptions = ({ customer, onCustomerUpdate, fromCustomerPortal, adminCompTable, readOnlyMode }) => {
  const [rsuValues, setRSUValues] = useState({});
  const [optionsValues, setOptionsValues] = useState({});

  const [updatingCustomer, setUpdatingCustomer] = useState(false);
  const { translate, langCode } = useContext(LocalizeContext);

  const allRSUFields = useMemo(() => getAllRSUFields(translate), [langCode]);
  const allOptionsFields = useMemo(() => getAllOptionsFields(translate), [langCode]);

  useEffect(() => {
    const rsuObject =
      customer?.rsu ||
      rsuSections.reduce(
        (acc, sec) => ({ ...acc, ...getInitialValues(allRSUFields, adminCompTable, false, sec.id) }),
        {}
      );
    setRSUValues({ ...rsuObject, ...getRSUCalculatedValues(allRSUFields, rsuSections, adminCompTable, rsuObject) });
    const optionsObject =
      customer?.options ||
      optionsSections.reduce(
        (acc, sec) => ({ ...acc, ...getInitialValues(allOptionsFields, adminCompTable, false, sec.id) }),
        {}
      );
    setOptionsValues({
      ...optionsObject,
      ...getOptionsCalculatedValues(allOptionsFields, optionsSections, adminCompTable, optionsObject)
    });
  }, [customer]);

  const onSaveClick = async () => {
    toast.info('Saving data...');
    setUpdatingCustomer(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_UPDATE,
      requestBody: {
        rsu: {
          ...getServerFields(allRSUFields, rsuSections, rsuValues),
          collapsedSections: rsuValues?.collapsedSections || []
        },
        options: {
          ...getServerFields(allOptionsFields, optionsSections, optionsValues),
          collapsedSections: optionsValues?.collapsedSections || []
        },
        _id: customer['_id']
      }
    });

    setUpdatingCustomer(false);
    if (error) {
      toast.error(error);
      return;
    }

    toast.success('Updated successfully!');
    onCustomerUpdate(response);
  };

  const onValueChange = (objectKey, currentInputType, newValue, forRSU) => {
    const values = forRSU ? rsuValues : optionsValues;
    const sections = forRSU ? rsuSections : optionsSections;
    const allFields = forRSU ? allRSUFields : allOptionsFields;

    values[objectKey] = !currentInputType && newValue ? formatCurrency(newValue, true, false) : newValue;

    if (objectKey.startsWith(sections[0].id)) {
      const keyWithoutSection = objectKey.replace(sections[0].id, '');
      const field = allFields.find(f => f.objectKey === keyWithoutSection && f.linkedWithFirstSection);
      if (field) {
        values[`${sections[1].id}${keyWithoutSection}`] =
          !currentInputType && newValue ? formatCurrency(newValue, true, false) : newValue;
      }
    }

    if (forRSU) {
      setRSUValues({ ...values, ...getRSUCalculatedValues(allFields, sections, adminCompTable, values) });
    } else {
      setOptionsValues({ ...values, ...getOptionsCalculatedValues(allFields, sections, adminCompTable, values) });
    }
  };

  return (
    <>
      <Container fluid className="h-100 py-2 px-md-3 text-start">
        <Calculator
          title="rsu_calculator"
          values={rsuValues}
          showSaveButton={customer}
          updatingCustomer={updatingCustomer}
          onSaveClick={onSaveClick}
          onValuesChange={setRSUValues}
          adminCompTable={adminCompTable}
          allFields={allRSUFields}
          sections={rsuSections}
          onInputValueChange={(objectKey, currentInputType, newValue) =>
            onValueChange(objectKey, currentInputType, newValue, true)
          }
          onToggleCollapse={collapsedSections => setRSUValues({ ...rsuValues, collapsedSections })}
          readOnlyMode={readOnlyMode}
        />
        <Calculator
          title="options_calculator"
          values={optionsValues}
          showSaveButton={customer}
          updatingCustomer={updatingCustomer}
          onSaveClick={onSaveClick}
          onValuesChange={setOptionsValues}
          adminCompTable={adminCompTable}
          allFields={allOptionsFields}
          sections={optionsSections}
          tabIndexOffset={allRSUFields.length * 2}
          onInputValueChange={(objectKey, currentInputType, newValue) =>
            onValueChange(objectKey, currentInputType, newValue, false)
          }
          onToggleCollapse={collapsedSections => setOptionsValues({ ...optionsValues, collapsedSections })}
          readOnlyMode={readOnlyMode}
        />
      </Container>
    </>
  );
};

export default RSUOptions;
