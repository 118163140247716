import {
  apartmentTypeOptions,
  assetCategoriesOptions,
  months,
  primaryBankAccountName,
  stockTypeOptions,
  transferFrequencyOptions
} from '../../../../../helpers/constants';
import { infoRow } from '../../../../../helpers/forms';
import { findIfUserIsInterestedInCourseAndOfSpecifiedLevel } from '../../../../../helpers/global';
import { isPaperApartment } from './helper';

const noteCol = {
  default: 12,
  field: {
    id: 'notes',
    key: 'notes',
    type: 'text-area',
    row: 2
  }
};

const getMonthsOptions = ({ yearToMonths, selectedYear, returnAllMonths }) => {
  if (returnAllMonths) {
    return { options: months, optionValues: months.map((m, i) => i) };
  }

  return {
    options: yearToMonths?.[selectedYear]
      ? months.slice(yearToMonths?.[selectedYear].start, yearToMonths?.[selectedYear].end)
      : months,
    optionValues: yearToMonths?.[selectedYear]
      ? months.map((m, i) => i).slice(yearToMonths?.[selectedYear].start, yearToMonths?.[selectedYear].end)
      : months.map((m, i) => i)
  };
};

const getAssetCostsColumns = ({ apartmentType, assetCategory, isFixedIncome, assetEditMode, isPrimaryBankAccount }) => {
  const isPurchaseCostFieldsDisabled = isPrimaryBankAccount || isFixedIncome || !assetEditMode;
  const isSalesCostFieldsDisabled = isPrimaryBankAccount || isFixedIncome;
  const isApartment = assetCategory === 'Real Estate';
  const isPaperApartment = assetCategory === 'Real Estate' && apartmentType === 'Paper Apartment';

  let purchaseCostFields = [
    {
      default: 4,
      xs: 8,
      field: {
        titleKey: isApartment ? 'additional_costs_lawyer_apraiser' : 'purchase_cost',
        id: 'purchaseCost',
        title: 'Purchase Cost',
        onChangeWindow: 'onAssetPurchaseCostChange',
        key: 'purchaseCost',
        type: 'currency',
        required: false,
        disabled: isPurchaseCostFieldsDisabled
      }
    },
    {
      default: 2,
      xs: 4,
      field: {
        id: 'purchaseCostPercentage',
        title: 'In %',
        onChangeWindow: 'onAssetPurchasePercentageChange',
        key: 'purchaseCostPercentage',
        type: 'percentage',
        required: false,
        disabled: isPurchaseCostFieldsDisabled
      }
    }
  ];

  if (isApartment) {
    purchaseCostFields.unshift(
      {
        default: 4,
        xs: 8,
        field: {
          titleKey: 'realtor_cost',
          id: 'realtorCost',
          onChangeWindow: 'onAssetRealtorCostChange',
          key: 'realtorCost',
          type: 'currency',
          required: false,
          disabled: isPurchaseCostFieldsDisabled
        }
      },
      {
        default: 2,
        xs: 4,
        field: {
          id: 'realtorCostPercentage',
          title: 'In %',
          onChangeWindow: 'onAssetRealtorPercentageChange',
          key: 'realtorCostPercentage',
          type: 'percentage',
          required: false,
          disabled: isPurchaseCostFieldsDisabled
        }
      }
    );

    if (isPaperApartment) {
      purchaseCostFields.push(
        {
          default: 4,
          xs: 8,
          field: {
            titleKey: 'additional_costs_for_making_the_apartment_ready_for_rent_paper_apartment',
            id: 'makeReadyCost',
            onChangeWindow: 'onAssetMakeReadyCostsChange',
            key: 'makeReadyCost',
            type: 'currency',
            required: false
          }
        },
        {
          default: 2,
          xs: 4,
          field: {
            id: 'makeReadyPercentage',
            title: 'In %',
            onChangeWindow: 'onAssetMakeReadyPercentageChange',
            key: 'makeReadyPercentage',
            type: 'percentage',
            required: false
          }
        }
      );
    }
  }

  return [
    ...purchaseCostFields,
    {
      default: 4,
      xs: 8,
      field: {
        id: 'salesCost',
        title: 'Sales Cost',
        onChangeWindow: 'onAssetSalesCostChange',
        key: 'salesCost',
        type: 'currency',
        required: false,
        disabled: isSalesCostFieldsDisabled
      }
    },
    {
      default: 2,
      xs: 4,
      field: {
        id: 'salesCostPercentage',
        title: 'In %',
        onChangeWindow: 'onAssetSalesPercentageChange',
        key: 'salesCostPercentage',
        type: 'percentage',
        required: false,
        disabled: isSalesCostFieldsDisabled
      }
    }
  ];
};

const assetRows = (
  strategyYears = [],
  selectedBuyingYear,
  selectedSellingYear,
  yearToMonths,
  assetCategory,
  apartmentType,
  isPrimaryBankAccount,
  assetOptions = [],
  presetOptions = [],
  assetEditMode,
  user,
  showImport,
  fromAddForm,
  additionalFields = [],
  renderPaperApartmentPayments,
  renderTaxes,
  renderRSUGrants
) => {
  const isFixedIncome = assetCategory === 'Fixed Income';
  const isAnona = assetCategory === 'Anona';
  const isRSU = assetCategory === 'RSU';
  const cashAssetOptions = assetOptions.filter(a => a.category === 'Cash');

  const generalColumns = [
    {
      default: 6,
      xs: 12,
      field: {
        id: 'assetCategory',
        title: 'Category',
        key: 'category',
        options: assetCategoriesOptions,
        type: 'dropdown',
        onChange: 'onAssetCategoryChange',
        required: true,
        disabled: isPrimaryBankAccount,
        dependentElems: ['anonaMonthlyPayment', 'stockType', 'apartmentType'],
        showDependentOnArray: ['Anona', 'Stocks']
      }
    },
    {
      default: showImport ? 4 : 6,
      xs: 12,
      field: {
        id: 'preset',
        title: 'Preset',
        key: 'preset',
        options: ['None', ...presetOptions.map(a => a.name)],
        optionValues: ['', ...presetOptions.map(a => a.name)],
        type: 'dropdown',
        onChange: 'onPresetChange'
      }
    },
    {
      default: 2,
      xs: 12,
      hide: !showImport,
      field: {
        id: 'import',
        title: 'Import',
        key: 'import',
        type: 'button',
        onClick: 'onImportClick',
        disabled: !showImport
      }
    },
    {
      default: isRSU ? 12 : isAnona ? 4 : 6,
      xs: 12,
      field: {
        id: 'name',
        title: 'Name',
        key: 'name',
        type: 'text',
        required: true,
        disabled: isPrimaryBankAccount
      }
    },
    {
      default: isAnona ? 4 : 6,
      xs: 12,
      hide: isRSU,
      field: {
        id: 'value',
        title: 'Value',
        key: 'value',
        type: 'currency',
        disabled: isRSU,
        required: !isRSU,
        onChangeWindow: 'onAssetValueChange',
        onCurrencyConvertWindow: 'onAssetValueCurrencyConvert',
        withCurrencyConverter: true
      }
    },
    {
      default: 12,
      xs: 12,
      field: {
        id: 'apartmentType',
        title: 'Apartment Type',
        key: 'apartmentType',
        options: apartmentTypeOptions,
        type: 'dropdown',
        required: true,
        onChange: 'onAssetApartmentTypeChange'
      }
    },
    {
      default: 4,
      xs: 12,
      field: {
        id: 'anonaMonthlyPayment',
        title: 'Anona Monthly Payment',
        key: 'anonaMonthlyPayment',
        type: 'currency',
        required: true
      }
    },
    {
      default: 12,
      xs: 12,
      field: {
        id: 'stockType',
        title: 'Stock Type',
        key: 'stockType',
        options: stockTypeOptions,
        type: 'dropdown',
        required: true
      }
    },
    {
      default: 3,
      xs: 12,
      field: {
        id: 'returnAppreciation',
        title: 'Expected Return (Appreciation)',
        key: 'returnAppreciation',
        type: 'percentage',
        onChangeWindow: 'onAssetAppreciationChange',
        required: false,
        disabled: isFixedIncome
      }
    },
    {
      default: 3,
      xs: 8,
      field: {
        id: 'returnCashflow',
        title: 'Expected Return (Cash Flow)',
        key: 'returnCashflow',
        titleKey: 'return_cashflow_monthly',
        type: 'currency',
        onChangeWindow: 'onAssetCashflowChange',
        required: false,
        disabled: isPrimaryBankAccount || isFixedIncome
      }
    },
    {
      default: 2,
      xs: 4,
      field: {
        id: 'returnCashflowPercentage',
        title: 'In %',
        key: 'returnCashflowPercentage',
        onChangeWindow: 'onAssetCashflowPercentageChange',
        type: 'percentage',
        required: false,
        disabled: isPrimaryBankAccount || isFixedIncome
      }
    },
    {
      default: 2,
      xs: 4,
      field: {
        id: 'cashflowAppreciation',
        title: 'Cashflow Appreciation',
        key: 'cashflowAppreciation',
        type: 'percentage',
        disabled: isFixedIncome,
        required: false
      }
    },
    {
      default: 2,
      xs: 4,
      field: {
        id: 'taxOnCashflow',
        titleKey: 'tax_on_cashflow',
        key: 'taxOnCashflow',
        type: 'percentage',
        disabled: isFixedIncome,
        required: false
      }
    },
    {
      default: 3,
      xs: 6,
      field: {
        id: 'buyingYear',
        title: 'Buying Year',
        key: 'buyingYear',
        titleKey: isRSU ? 'start_year' : 'buying_year',
        type: 'dropdown',
        onChange: 'onAssetBuyYearChange',
        options: strategyYears,
        required: true,
        disabled: isPrimaryBankAccount
      }
    },
    {
      default: 3,
      xs: 6,
      field: {
        id: 'buyingMonth',
        title: 'Buying Month',
        key: 'buyingMonth',
        titleKey: isRSU ? 'start_month' : 'buying_month',
        type: 'dropdown',
        onChange: 'onAssetBuyMonthChange',
        required: true,
        disabled: isPrimaryBankAccount,
        ...getMonthsOptions({ yearToMonths, selectedYear: selectedBuyingYear })
      }
    },
    {
      default: 3,
      xs: 6,
      field: {
        id: 'sellingYear',
        title: 'Selling Year',
        onChange: 'onAssetSellYearChange',
        key: 'sellingYear',
        type: 'dropdown',
        options: ['Select Year', ...strategyYears],
        required: false,
        disabled: isPrimaryBankAccount
      }
    },
    {
      default: 3,
      xs: 6,
      field: {
        id: 'sellingMonth',
        title: 'Selling Month',
        key: 'sellingMonth',
        type: 'dropdown',
        onChange: 'onAssetSellMonthChange',
        disabled: isPrimaryBankAccount,
        ...getMonthsOptions({ yearToMonths, selectedYear: selectedSellingYear })
      }
    },
    ...additionalFields
  ];

  if (renderPaperApartmentPayments) {
    generalColumns.push({
      default: 12,
      xs: 12,
      field: {
        id: 'payments',
        title: 'Payments',
        key: 'payments',
        type: 'custom',
        render: renderPaperApartmentPayments
      }
    });
  }

  if (isRSU && renderRSUGrants) {
    generalColumns.push(
      {
        default: 6,
        xs: 6,
        field: {
          id: 'initialRSUStockValue',
          title: 'Initial Stock Value',
          key: 'initialRSUStockValue',
          type: 'currency',
          required: true,
          titleKey: 'initial_stock_value',
          onChangeWindow: 'onRSUInitialValueChange',
          withCurrencyConverter: true
        }
      },
      {
        default: 6,
        xs: 6,
        field: {
          id: 'rsuMarginalTax',
          title: 'Marginal Tax',
          key: 'rsuMarginalTax',
          type: 'percentage',
          required: false
        }
      },
      {
        default: 12,
        xs: 12,
        field: {
          id: 'rsuGrants',
          title: 'Grants',
          key: 'rsuGrants',
          type: 'custom',
          render: renderRSUGrants
        }
      }
    );
  }

  const rows = [
    {
      collapsable: true,
      defaultCollapsed: false,
      rowName: 'general',
      columns: generalColumns
    },
    {
      collapsable: true,
      defaultCollapsed: false,
      rowName: 'costs',
      columns: getAssetCostsColumns({
        apartmentType,
        assetCategory,
        isFixedIncome,
        assetEditMode,
        isPrimaryBankAccount
      })
    },
    ...(fromAddForm
      ? [
          {
            collapsable: true,
            defaultCollapsed: true,
            rowName: 'deposits',
            columns: [
              {
                default: 12,
                xs: 12,
                field: {
                  id: 'deposits',
                  type: 'table-input',
                  tableFields: [
                    {
                      id: 'source',
                      key: 'source',
                      type: 'dropdown',
                      title: 'from',
                      options: ['', ...assetOptions.map(o => o.name)],
                      optionValues: ['', ...assetOptions.map(o => o._id)],
                      value: assetOptions[0]._id,
                      type: 'dropdown'
                    },
                    {
                      id: 'frequency',
                      key: 'frequency',
                      type: 'dropdown',
                      title: 'Frequency',
                      options: transferFrequencyOptions.map(o => o.label),
                      optionValues: transferFrequencyOptions.map(o => o.value),
                      value: transferFrequencyOptions[0].value,
                      type: 'dropdown',
                      required: true
                    },
                    { title: 'Value', key: 'value', type: 'currency', required: true }
                  ]
                }
              }
            ]
          }
        ]
      : []),
    {
      collapsable: true,
      defaultCollapsed: true,
      rowName: 'partialSales',
      columns: [
        {
          default: 12,
          xs: 12,
          field: {
            id: 'partialSales',
            type: 'table-input',
            tableFields: [
              {
                id: 'afterTax',
                title: 'After Tax?',
                key: 'afterTax',
                type: 'switch',
                required: false
              },
              { title: 'Value', key: 'value', type: 'currency', required: true },
              {
                id: 'month',
                title: 'Month',
                key: 'month',
                type: 'dropdown',
                required: true,
                ...getMonthsOptions({ yearToMonths, returnAllMonths: true })
              },
              {
                id: 'year',
                title: 'Year',
                key: 'year',
                type: 'dropdown',
                options: [...strategyYears],
                required: true
              },
              { title: 'Tax', key: 'tax', titleKey: isRSU ? 'marginal_tax' : 'tax', type: 'percentage' }
            ]
          }
        }
      ]
    }
  ];

  if (renderTaxes) {
    rows.push({
      collapsable: true,
      defaultCollapsed: true,
      rowName: 'tax',
      columns: [
        {
          default: 12,
          xs: 12,
          field: {
            id: 'tax',
            key: 'tax',
            type: 'custom',
            render: renderTaxes
          }
        }
      ]
    });
  }

  rows.push(
    ...[
      {
        collapsable: true,
        defaultCollapsed: true,
        rowName: 'advanced',
        columns: [
          {
            default: 12,
            field: {
              id: 'editMode',
              type: 'block-select',
              title: 'Mode',
              options: ['Edit Mode', 'Setup Mode'],
              value: assetEditMode ? 'Edit Mode' : 'Setup Mode',
              required: true,
              multiple: false,
              disabled: isPrimaryBankAccount,
              onChange: 'onAssetEditModeChange'
            }
          },
          {
            xs: 12,
            default: 3,
            field: {
              id: 'linkedBankAccount',
              key: 'linkedBankAccount',
              type: 'dropdown',
              title: 'Linked Bank Account',
              options: ['Not linked', ...cashAssetOptions.map(a => a.name)],
              optionValues: ['not_linked', ...cashAssetOptions.map(a => a._id)],
              value: cashAssetOptions.find(a => a.name === primaryBankAccountName)?._id || '',
              type: 'dropdown',
              required: false,
              disabled: isPrimaryBankAccount
            }
          },
          ...(!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1])
            ? [
                {
                  default: 3,
                  xs: 12,
                  field: {
                    id: 'liquidationScore',
                    title: 'Liquidation Score',
                    key: 'liquidationScore',
                    type: 'dropdown',
                    options: [1, 2, 3],
                    required: true
                  }
                },
                {
                  default: 3,
                  xs: 12,
                  field: {
                    id: 'crashPercentage',
                    title: 'Crash Percentage',
                    key: 'crashPercentage',
                    type: 'percentage',
                    required: false
                  }
                },
                {
                  default: 3,
                  xs: 12,
                  field: {
                    id: 'canSellPartially',
                    title: '',
                    label: 'Can Sell Partially?',
                    showLabel: true,
                    key: 'canSellPartially',
                    type: 'checkbox',
                    required: false
                  }
                }
              ]
            : [])
        ]
      },
      {
        collapsable: true,
        defaultCollapsed: true,
        rowName: 'notes',
        columns: [noteCol]
      }
    ]
  );

  return rows;
};

/**
 * yearToMonths =  {
 *  2023: {
 *  start:3,
 *  end:11
 * },
 * 2033:{
 * start:0,
 * end:2
 * }
 * }
 */
export const addAssetForm = (
  strategyYears,
  selectedBuyingYear,
  selectedSellingYear,
  yearToMonths,
  assetCategory,
  apartmentType,
  assetOptions = [],
  presetOptions = [],
  assetEditMode,
  user,
  showImport,
  renderPaperApartmentPayments,
  renderTaxes,
  renderRSUGrants
) => {
  const additionalFields = getAdditionalAssetFields(assetCategory, apartmentType, user);

  return {
    forms: [
      {
        name: 'New Asset',
        markCompulsoryFields: true,
        hideFormName: true,
        compact: true,
        submit: {
          name: 'Add Asset',
          show: false,
          onSubmit: 'onAssetFormSubmit'
        },
        rows: assetRows(
          strategyYears,
          selectedBuyingYear,
          selectedSellingYear,
          yearToMonths,
          assetCategory,
          apartmentType,
          false,
          assetOptions,
          presetOptions,
          assetEditMode,
          user,
          showImport,
          true,
          additionalFields,
          renderPaperApartmentPayments,
          renderTaxes,
          renderRSUGrants
        )
      }
    ]
  };
};

export const updateAssetForm = (
  strategyYears,
  selectedBuyingYear,
  selectedSellingYear,
  yearToMonths,
  isPrimaryBankAccount,
  assetCategory,
  apartmentType,
  assetEditMode,
  user,
  assetOptions = [],
  presetOptions = [],
  renderPaperApartmentPayments,
  renderTaxes,
  renderRSUGrants
) => {
  const additionalFields = getAdditionalAssetFields(assetCategory, apartmentType, user);

  return {
    forms: [
      {
        name: 'Update Asset',
        markCompulsoryFields: true,
        hideFormName: true,
        compact: true,
        submit: {
          name: 'Update',
          show: false,
          onSubmit: 'onAssetFormSubmit'
        },
        rows: assetRows(
          strategyYears,
          selectedBuyingYear,
          selectedSellingYear,
          yearToMonths,
          assetCategory,
          apartmentType,
          isPrimaryBankAccount,
          assetOptions,
          presetOptions,
          assetEditMode,
          user,
          false,
          false,
          additionalFields,
          renderPaperApartmentPayments,
          renderTaxes,
          renderRSUGrants
        )
      }
    ]
  };
};

const getAdditionalAssetFields = (assetCategory, apartmentType, user) => {
  if (assetCategory === 'Fixed Income') return fixedIncomeRows();
  if (assetCategory === 'Real Estate') {
    switch (apartmentType) {
      case 'Paper Apartment':
        return paperApartmentRows(user);
      case 'Pinui Binui Apartment':
        return pinuiBinuiApartmentRows(user);
    }
  }

  return [];
};

const liabilityRows = (
  strategyYears,
  liabilityStartYear,
  liabilityEndYear,
  yearToMonths,
  linkedAsset,
  fromAssetCreateForm,
  assetOptions = [],
  cashAssetOptions = [],
  loanType,
  loanAutoCalculated,
  loanEditMode
) => [
  { columns: [noteCol] },
  ...(!fromAssetCreateForm
    ? [
        {
          columns: [
            {
              default: 12,
              xs: 12,
              field: {
                id: 'assetName',
                title: 'Link Asset',
                key: 'assetName',
                options: ['No Asset', ...assetOptions.map(a => `${a.name} ($${a.value})`)],
                optionValues: ['', ...assetOptions.map(a => a.name)],
                type: 'dropdown',
                required: false,
                onChange: 'onAssetChange'
              }
            }
          ]
        }
      ]
    : []),
  {
    columns: [
      {
        default: 6,
        field: {
          id: 'linkedBankAccount',
          key: 'linkedBankAccount',
          type: 'dropdown',
          title: 'Linked Bank Account',
          options: ['Not linked', ...cashAssetOptions.map(a => a.name)],
          optionValues: ['not_linked', ...cashAssetOptions.map(a => a._id)],
          value: cashAssetOptions.find(a => a.name === primaryBankAccountName)?._id || '',
          type: 'dropdown',
          required: false
        }
      },
      {
        default: 6,
        field: {
          id: 'name',
          title: 'Name',
          key: 'name',
          type: 'text',
          required: true
        }
      }
    ]
  },
  {
    columns: [
      {
        default: loanType === 'Margin' ? 12 : 6,
        xs: 12,
        field: {
          id: 'type',
          title: 'Type',
          key: 'type',
          options: ['Annuity', 'Interest only', 'Balloon', ...(linkedAsset ? ['Margin'] : [])],
          onChange: 'onLoanTypeChange',
          type: 'dropdown',
          required: true
        }
      },
      ...(loanType !== 'Margin'
        ? [
            {
              default: 6,
              xs: 12,
              field: {
                id: 'gracePeriod',
                title: 'Grace Period',
                key: 'gracePeriod',
                type: 'number'
              }
            }
          ]
        : [])
    ]
  },
  ...(loanType === 'Margin'
    ? [
        {
          columns: [
            {
              default: 6,
              xs: 12,
              field: {
                id: 'marginFloor',
                title: 'Margin Floor',
                key: 'marginFloor',
                onChangeWindow: 'onMarginFloorChange',
                type: 'percentage',
                required: true
              }
            },
            {
              default: 6,
              xs: 12,
              field: {
                id: 'marginCeiling',
                title: 'Margin Ceiling',
                key: 'marginCeiling',
                type: 'percentage',
                required: true
              }
            }
          ]
        }
      ]
    : []),

  {
    columns: [
      {
        default: linkedAsset ? 6 : 9,
        field: {
          id: 'value',
          title: 'Value',
          key: 'value',
          type: 'currency',
          onChangeWindow: 'onLiabilityValueChange',
          onCurrencyConvertWindow: 'onLiabilityValueCurrencyConvert',
          required: true,
          withCurrencyConverter: true
        }
      },
      ...(linkedAsset
        ? [
            {
              default: 3,
              field: {
                id: 'valueInPercentage',
                title: 'In %',
                key: 'valueInPercentage',
                onChangeWindow: 'onLiabilityValuePercentageChange',
                type: 'percentage',
                required: false
              }
            }
          ]
        : []),
      {
        default: 3,
        xs: 12,
        field: {
          id: 'monthlyPayment',
          title: 'Monthly Payment',
          key: 'monthlyPayment',
          onChangeWindow: 'onLoanMonthlyPaymentChange',
          type: 'currency',
          required: false
        }
      }
    ]
  },

  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'interest',
          title: 'Interest',
          key: 'interest',
          type: 'percentage',
          onChangeWindow: 'onLiabilityInterestChange',
          required: true
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'typeOfInterest',
          title: 'Interest Type',
          key: 'typeOfInterest',
          type: 'dropdown',
          options: ['Fixed', 'Variable', 'Mortgage Interest'],
          required: true
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 6,
        xs: 12,
        field: {
          id: 'timeToMaturity',
          title: 'Time To Maturity (Months)',
          key: 'timeToMaturity',
          onChangeWindow: 'onLoanTimeToMaturityChange',
          type: 'number',
          required: loanType !== 'Margin'
        }
      },
      {
        default: 3,
        xs: 6,
        field: {
          id: 'startYear',
          title: 'Start Year',
          key: 'startYear',
          //for paper apartment the end date can go beyong strategy years, so we use number input here
          [isPaperApartment(linkedAsset) ? 'onChangeWindow' : 'onChange']: 'onLiabilityStartYearChange',
          type: isPaperApartment(linkedAsset) ? 'number' : 'dropdown',
          value: linkedAsset && !isPaperApartment(linkedAsset) ? linkedAsset.buyingYear : undefined,
          options: strategyYears,
          required: true,
          disabled: isPaperApartment(linkedAsset)
        }
      },
      {
        default: 3,
        xs: 6,
        field: {
          id: 'startMonth',
          title: 'Starting Month',
          key: 'startMonth',
          onChange: 'onLiabilityStartMonthChange',
          type: 'dropdown',
          value: linkedAsset && !isPaperApartment(linkedAsset) ? linkedAsset.buyingMonth : undefined,
          required: true,
          disabled: isPaperApartment(linkedAsset),
          ...getMonthsOptions({
            yearToMonths,
            selectedYear: liabilityStartYear,
            returnAllMonths: isPaperApartment(linkedAsset)
          })
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 3,
        xs: 6,
        field: {
          id: 'endYear',
          title: 'End Year',
          key: 'endYear',
          onChange: 'onLiabilityEndYearChange',
          type: 'dropdown',
          disabled: loanAutoCalculated,
          value: linkedAsset ? linkedAsset.sellingYear : undefined,
          options: ['', ...strategyYears]
        }
      },
      {
        default: 3,
        xs: 6,
        field: {
          id: 'endMonth',
          title: 'End Month',
          key: 'endMonth',
          onChange: 'onLiabilityEndMonthChange',
          type: 'dropdown',
          disabled: loanAutoCalculated,
          ...getMonthsOptions({ yearToMonths, selectedYear: liabilityEndYear })
        }
      },
      {
        default: 6,
        xs: 12,
        field: {
          id: 'loanProvider',
          title: 'Loan Provider',
          key: 'loanProvider',
          type: 'text'
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 12,
        xs: 12,
        field: {
          id: 'endDateCalculatedAutomatically',
          title: '',
          label: 'End date calculated automatically?',
          showLabel: true,
          key: 'endDateCalculatedAutomatically',
          onChange: 'onEndDateCalculatedAutomaticallyChange',
          type: 'checkbox',
          required: false,
          defaultChecked: true
        }
      }
    ]
  },
  {
    collapsable: true,
    defaultCollapsed: true,
    rowName: 'advanced',
    columns: [
      {
        default: 12,
        field: {
          id: 'editMode',
          type: 'block-select',
          title: 'Mode',
          options: ['Edit Mode', 'Setup Mode'],
          value: loanEditMode ? 'Edit Mode' : 'Setup Mode',
          required: true,
          multiple: false,
          blockWidth: 4,
          onChange: 'onLiabilityEditModeChange'
        }
      }
    ]
  }
];

export const addLiabilityForm = (
  strategyYears,
  liabilityStartYear,
  liabilityEndYear,
  incompleteYearsOfStrategy,
  linkedAsset,
  fromAssetCreateForm,
  assetOptions = [],
  cashAssetOptions = [],
  loanType,
  loanAutoCalculated,
  loanEditMode
) => ({
  forms: [
    {
      name: 'New Liability',
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: 'Add',
        show: false,
        onSubmit: 'onLiabilityFormSubmit'
      },
      rows: liabilityRows(
        strategyYears,
        liabilityStartYear,
        liabilityEndYear,
        incompleteYearsOfStrategy,
        linkedAsset,
        fromAssetCreateForm,
        assetOptions,
        cashAssetOptions,
        loanType,
        loanAutoCalculated,
        loanEditMode
      )
    }
  ]
});

export const updateLiabilityForm = (
  strategyYears,
  liabilityStartYear,
  liabilityEndYear,
  incompleteYearsOfStrategy,
  linkedAsset,
  fromAssetCreateForm,
  assetOptions = [],
  cashAssetOptions = [],
  loanType,
  loanAutoCalculated,
  loanEditMode
) => ({
  forms: [
    {
      name: 'Update Liability',
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: 'Update',
        show: false,
        onSubmit: 'onLiabilityFormSubmit'
      },
      rows: liabilityRows(
        strategyYears,
        liabilityStartYear,
        liabilityEndYear,
        incompleteYearsOfStrategy,
        linkedAsset,
        fromAssetCreateForm,
        assetOptions,
        cashAssetOptions,
        loanType,
        loanAutoCalculated,
        loanEditMode
      )
    }
  ]
});

const strategyRows = translate => [
  {
    columns: [
      {
        default: 12,
        xs: 12,
        field: {
          id: 'name',
          title: 'Name',
          key: 'name',
          type: 'text',
          required: true
        }
      }
    ]
  },
  infoRow(translate ? translate('please_provide_a_primary_bank_account_for_this_strategy') : ''),
  {
    columns: [
      {
        default: 12,
        field: {
          id: 'value',
          title: 'Initial Account Balance',
          key: 'value',
          type: 'currency',
          onChangeWindow: 'onAccountBalanceChange',
          required: false
        }
      }
    ]
  },
  {
    columns: [
      {
        default: 4,
        xs: 12,
        field: {
          id: 'returnAppreciation',
          title: 'Expected Return (Appreciation)',
          key: 'returnAppreciation',
          type: 'percentage',
          required: false
        }
      },
      {
        default: 4,
        xs: 8,
        field: {
          id: 'returnCashflow',
          title: 'Expected Return (Cash Flow)',
          titleKey: 'return_cashflow_monthly',
          key: 'returnCashflow',
          type: 'currency',
          onChangeWindow: 'onReturnCashflowChange',
          required: false
        }
      },
      {
        default: 2,
        xs: 4,
        field: {
          id: 'returnCashflowPercentage',
          title: 'In %',
          key: 'returnCashflowPercentage',
          onChangeWindow: 'onReturnCashflowPercentageChange',
          type: 'percentage',
          required: false
        }
      },
      {
        default: 2,
        xs: 4,
        field: {
          id: 'cashflowAppreciation',
          title: 'Cashflow Appreciation',
          key: 'cashflowAppreciation',
          type: 'number',
          required: false
        }
      }
    ]
  }
];

export const addStrategyForm = translate => ({
  forms: [
    {
      name: 'New Strategy',
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: 'Add',
        show: true,
        onSubmit: 'onNewStrategyFormSubmit'
      },
      rows: strategyRows(translate)
    }
  ]
});

export const updateStrategyForm = (isClone, translate) => ({
  forms: [
    {
      name: 'Update Strategy',
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: isClone ? 'Clone' : 'Update',
        show: true,
        onSubmit: 'onUpdateStrategyFormSubmit'
      },
      rows: [strategyRows(translate)[0]]
    }
  ]
});

export const fixedIncomeRows = () => {
  return [
    {
      default: 6,
      xs: 12,
      field: {
        id: 'type',
        title: 'Type',
        key: 'type',
        options: ['Annuity', 'Interest only', 'Balloon'],
        type: 'dropdown',
        required: true
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'gracePeriod',
        title: 'Grace Period',
        key: 'gracePeriod',
        type: 'number'
      }
    },
    {
      default: 4,
      xs: 12,
      field: {
        id: 'interest',
        title: 'Interest',
        key: 'interest',
        type: 'number',
        required: true,
        onChangeWindow: 'onFixedIncomeInterestChange'
      }
    },
    {
      default: 4,
      xs: 12,
      field: {
        id: 'timeToMaturity',
        title: 'Time To Maturity (Months)',
        onChangeWindow: 'onFixedIncomeTimeToMaturityChange',
        key: 'timeToMaturity',
        type: 'number',
        required: true
      }
    },
    {
      default: 4,
      xs: 12,
      field: {
        id: 'monthlyPayment',
        title: 'Monthly Payment',
        key: 'monthlyPayment',
        onChangeWindow: 'onFixedIncomeMonthlyPaymentChange',
        type: 'currency',
        required: false
      }
    }
  ];
};

export const paperApartmentRows = user => {
  return [
    {
      default: 4,
      xs: 12,
      field: {
        id: 'yearlyMaterialCostIndexRise',
        title: 'Material Cost Index',
        key: 'yearlyMaterialCostIndexRise',
        type: 'percentage',
        disabled: findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1])
      }
    },
    {
      default: 4,
      xs: 12,
      field: {
        id: 'estimatedTimeForFinishingContruction',
        title: 'Time For Finishing Construction',
        titleKey: 'estimated_time_for_contruction_months',
        key: 'estimatedTimeForFinishingContruction',
        type: 'number',
        required: true,
        disabled: findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1])
      }
    },
    {
      default: 4,
      xs: 12,
      field: {
        id: 'valueOfApartmentToday',
        title: 'Value of Received Apartment Today',
        key: 'valueOfApartmentToday',
        type: 'currency',
        required: true,
        disabled: findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1])
      }
    },

    {
      default: 6,
      xs: 12,
      field: {
        id: 'downPayment',
        title: 'Down Payment',
        key: 'downPayment',
        type: 'percentage',
        disabled: findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1])
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'remainingPayment',
        title: 'Remaining Payment',
        key: 'remainingPayment',
        options: ['One Final Payment', 'Gradual Payment'],
        optionValues: ['oneFinalPayment', 'gradualPayment'],
        type: 'dropdown',
        required: true,
        disabled: findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1])
      }
    }
  ];
};

export const pinuiBinuiApartmentRows = user => {
  return [
    {
      default: 6,
      xs: 12,
      field: {
        id: 'estimatedTimeForFinishingContruction',
        title: 'Time For Finishing Construction',
        titleKey: 'estimated_time_for_contruction_months',
        key: 'estimatedTimeForFinishingContruction',
        type: 'number',
        required: true,
        disabled: findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1])
      }
    },
    {
      default: 6,
      xs: 12,
      field: {
        id: 'valueOfApartmentToday',
        title: 'Value of Received Apartment Today',
        key: 'valueOfApartmentToday',
        type: 'currency',
        required: true,
        disabled: findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1])
      }
    }
  ];
};
