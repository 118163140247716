import React, { useContext, useEffect, useMemo, useState } from 'react';

import { useFormik } from 'formik';
import { Button, Col, FormControl, Modal, Row } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import { eventActionTypeOptions, monthSmall, oneOffEventTypeOptions } from '../../../../../helpers/constants';
import OneOffEventSchema from '../../../../../schema/oneOffEventSchema';
import { formatCurrency } from './helper';

const AddEditOneOffEventModal = ({
  initialValues,
  strategyYears,
  handleOneOffEventSubmit,
  hideDialog,
  show,
  isPrimaryBankAccount,
  incompleteYearsOfStrategy
}) => {
  const [isSubmitting, setSubmitting] = useState(false);

  const { translate } = useContext(LocalizeContext);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: OneOffEventSchema,
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: async (values, formik) => {
      if (!validateContextually()) {
        setShowErrors(true);
        return;
      }
      setSubmitting(true);
      await handleOneOffEventSubmit({ ...values, month: values.month }, isEdit);
      formik.resetForm();
      setSubmitting(false);
      hideDialog();
    }
  });

  const validateContextually = () => {
    let isValid = true;
    if (formik.values.attribute?.buyingYear && formik.values.year < formik.values.attribute?.buyingYear) {
      formik.setFieldError('year', 'Year cannot be less than buying year');
      isValid = false;
    }

    if (formik.values.attribute?.year && formik.values.year < formik.values.attribute?.year) {
      formik.setFieldError('year', 'Year cannot be less than start year');
      isValid = false;
    }

    if (formik.values.attribute?.sellingYear && formik.values.year > formik.values.attribute?.sellingYear) {
      formik.setFieldError('year', 'Year cannot be greater than selling year');
      isValid = false;
    }
    if (formik.values.attribute?.endYear && formik.values.year > formik.values.attribute?.endYear) {
      formik.setFieldError('year', 'Year cannot be greater than end year');
      isValid = false;
    }

    return isValid;
  };

  const onYearChange = e => {
    const year = e.target.value;
    if (!year) return;

    const currentMonth = formik.values.month;
    if (currentMonth !== 0 && !currentMonth) return;

    const currentMonthNum = Number(currentMonth);
    const incompleteYear = incompleteYearsOfStrategy?.[year];

    if (incompleteYear && (currentMonthNum < incompleteYear.start || currentMonthNum > incompleteYear.end - 1)) {
      formik.setFieldValue('month', incompleteYear.start);
    }
  };

  useEffect(() => {
    let values = { ...initialValues };
    [{ monthKey: 'month', yearKey: 'year' }].forEach(({ monthKey, yearKey }) => {
      const year = values[yearKey];
      if (!year) return;

      const currentMonth = values[monthKey];
      if (currentMonth !== 0 && !currentMonth) return;

      const currentMonthNum = Number(currentMonth);
      const incompleteYear = incompleteYearsOfStrategy?.[year];

      if (incompleteYear && (currentMonthNum < incompleteYear.start || currentMonthNum > incompleteYear.end - 1)) {
        values[monthKey] = incompleteYear.start;
      }
    });

    formik.setValues(values);
  }, [initialValues]);

  const isEdit = useMemo(() => !!initialValues.description, [initialValues]);
  return (
    <Modal show={show} onHide={hideDialog} centered backdrop="static">
      <Modal.Header closeButton={!isSubmitting}>
        <Modal.Title>
          <h6 className="mb-0 pt-2">{isEdit ? 'Update One Off Event' : 'Add One Off Event'}</h6>
        </Modal.Title>
      </Modal.Header>
      <form onSubmit={formik.handleSubmit}>
        <Modal.Body className="overflow-auto">
          <Row className="mt-1">
            <Col>
              <label className="mid" htmlFor="type">
                {translate('notes')}
              </label>
              <FormControl
                size="sm"
                as={'textarea'}
                rows={2}
                id="notes"
                onChange={formik.handleChange}
                value={formik.values.notes}
                name="notes"
                onBlur={() => formik.handleBlur}
              />

              {formik.errors.type ? (
                <p style={{ color: 'red' }} className="mid">
                  {formik.errors.type}
                </p>
              ) : (
                <></>
              )}
            </Col>
          </Row>

          <Row className="mt-1">
            <Col>
              <label className="mid" htmlFor="type">
                {translate('type')}
              </label>
              <select
                id="type"
                onChange={formik.handleChange}
                name="type"
                className="form-select form-select-sm mb-3"
                onBlur={() => formik.handleBlur}
              >
                <option value=""></option>
                {oneOffEventTypeOptions.map((type, index) => {
                  return (
                    <option value={type} selected={formik.values.type === type} key={index}>
                      {type}
                    </option>
                  );
                })}
              </select>
              {formik.errors.type ? (
                <p style={{ color: 'red' }} className="mid">
                  {formik.errors.type}
                </p>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <label className="mid" htmlFor="editMode">
                {translate('mode')}
              </label>
              <select
                id="editMode"
                onChange={formik.handleChange}
                name="editMode"
                className="form-select form-select-sm mb-3"
                onBlur={() => formik.handleBlur}
                disabled={isPrimaryBankAccount}
              >
                {[
                  { label: 'Edit Mode', value: true },
                  { label: 'Setup Mode', value: false }
                ].map((item, index) => {
                  return (
                    <option value={item.value} selected={formik.values.editMode === item.value} key={index}>
                      {item.label}
                    </option>
                  );
                })}
              </select>
              {formik.errors.editMode ? (
                <p style={{ color: 'red' }} className="mid">
                  {formik.errors.editMode}
                </p>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <label className="mid" htmlFor="description">
                {translate('description')}
              </label>
              <input
                id="description"
                type="text"
                autoComplete="off"
                className="form-control form-control-sm"
                name="description"
                onChange={formik.handleChange}
                value={formik.values.description}
                onBlur={() => formik.handleBlur}
              />
              {formik.errors.description ? (
                <p style={{ color: 'red' }} className="mid">
                  {formik.errors.description}
                </p>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <label className="mid" htmlFor="action-select">
                {translate('action')}
              </label>
              <select
                id="action"
                onChange={formik.handleChange}
                name="action"
                className="form-select form-select-sm mb-3"
                onBlur={() => formik.handleBlur}
              >
                <option value=""></option>
                {eventActionTypeOptions.map((action, index) => {
                  return (
                    <option value={action} selected={formik.values.action === action} key={index}>
                      {action}
                    </option>
                  );
                })}
              </select>

              {formik.errors.action ? (
                <p style={{ color: 'red' }} className="mid">
                  {formik.errors.action}
                </p>
              ) : (
                <></>
              )}
            </Col>
            <Col>
              <label className="mid" htmlFor="value">
                {translate('value')}
              </label>
              <input
                autoComplete="off"
                id="value"
                type="text"
                className="form-control form-control-sm"
                name="value"
                onChange={e => {
                  let inputValue = Number(e.target.value.replaceAll(',', '') || 0);
                  if (Number.isNaN(inputValue)) return;
                  formik.setFieldValue('value', inputValue);
                }}
                value={formatCurrency(formik.values.value)}
                onBlur={() => formik.handleBlur}
              />
              {formik.errors.value ? (
                <p style={{ color: 'red' }} className="mid">
                  {formik.errors.value}
                </p>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row className="mt-1">
            <Col>
              <label className="mid" htmlFor="year">
                {translate('year')}
              </label>
              <select
                id="year"
                onChange={e => {
                  formik.handleChange(e);
                  onYearChange(e);
                }}
                name="year"
                className="form-select form-select-sm mb-3"
                onBlur={() => formik.handleBlur}
              >
                <option value=""></option>
                {strategyYears.map((year, index) => {
                  return (
                    <option value={year} selected={formik.values.year === year} key={index}>
                      {year}
                    </option>
                  );
                })}
              </select>
              {formik.errors.year ? (
                <p style={{ color: 'red' }} className="mid">
                  {formik.errors.year}
                </p>
              ) : (
                <></>
              )}
            </Col>
            <Col>
              <label className="mid" htmlFor="month">
                {translate('month')}
              </label>
              <select
                id="month"
                onChange={formik.handleChange}
                name="month"
                className="form-select form-select-sm mb-3"
                onBlur={() => formik.handleBlur}
                value={formik.values.month}
              >
                <option value=""></option>
                {(incompleteYearsOfStrategy?.[formik.values.year]
                  ? monthSmall.slice(
                      incompleteYearsOfStrategy?.[formik.values.year].start,
                      incompleteYearsOfStrategy?.[formik.values.year].end
                    )
                  : monthSmall
                ).map((month, index) => (
                  <option value={index + (incompleteYearsOfStrategy?.[formik.values.year]?.start || 0)} key={index}>
                    {month}
                  </option>
                ))}
              </select>
              {formik.errors.month ? (
                <p style={{ color: 'red' }} className="mid">
                  {formik.errors.month}
                </p>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <button disabled={isSubmitting} size="sm" className="mr-2 text-white btn btn-success" type="submit">
            {translate(isEdit ? 'update' : 'add')}
          </button>
          <Button
            size="sm"
            variant="outline-secondary"
            style={{ color: 'black' }}
            className="mr-2 text-dark"
            onClick={() => {
              formik.resetForm();
              hideDialog();
            }}
          >
            {translate('close')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default AddEditOneOffEventModal;
