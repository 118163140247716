import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { ArrowRightShort, CurrencyExchange, X } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import {
  formatCurrency,
  getNumberFromFormattedValue
} from '../../components/admin/manage-users/customer/strategies/helper';
import CircularProgressBar from '../../components/common/circular-progress';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS, currencyOptions } from '../../helpers/constants';
import { isRtl } from '../../helpers/localization';
import { normalizeId } from '../helpers/utility';

const convertToCurrencyFormat = (value = '') => {
  const newString = value.toString().replace(/[^.\d.-]/g, '');
  return formatCurrency(newString, false);
};

const CurrencyInput = ({
  id,
  title,
  onChange,
  onChangeWindow,
  value,
  preValue,
  className = '',
  hint = '',
  required = false,
  onFormChange,
  onClick,
  disabled = false,
  size,
  withCurrencyConverter,
  onCurrencyConvert,
  onCurrencyConvertWindow,
  currencyConversionPreValue
}) => {
  const [showCurrencyConverter, setShowCurrencyConverter] = useState(Boolean(currencyConversionPreValue));
  const { translate, langCode } = useContext(LocalizeContext);
  const [currencyValue, setCurrencyValue] = useState(
    formatCurrency(currencyConversionPreValue?.conversionAmount, false)
  );
  const [currencyToConvertFrom, setCurrencyToConvertFrom] = useState(
    currencyOptions.map(c => c.symbol).includes(currencyConversionPreValue?.conversionCurrency)
      ? currencyConversionPreValue?.conversionCurrency
      : currencyOptions[0].symbol
  );
  const [fetchingConvertedValue, setFetchingConvertedValue] = useState(false);

  const isRTL = useMemo(() => isRtl(langCode), [langCode]);
  const currencySelectOptions = useMemo(
    () =>
      currencyOptions.map(option => ({
        value: option.symbol,
        label: option.name
      })),
    []
  );

  useEffect(() => {
    internalOnChange(null);
  }, []);

  const internalOnChange = e => {
    const elem = e ? e.target : document.getElementById(id || normalizeId(title));
    if (elem) elem.value = convertToCurrencyFormat(elem.value);

    if (onChange) onChange(e);
    if (onChangeWindow && window[onChangeWindow]) {
      window[onChangeWindow](e);
    }
    if (onFormChange) onFormChange(e);
  };

  const convertCurrency = async () => {
    if (!currencyValue) return;

    setFetchingConvertedValue(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.UTILS_CONVERT_CURRENCY,
      method: 'POST',
      requestBody: {
        amount: getNumberFromFormattedValue(currencyValue),
        fromCurrency: currencyToConvertFrom
      }
    });

    setFetchingConvertedValue(false);
    if (error) {
      toast.error(error);
      return;
    }

    if (response.convertedAmount !== null) {
      const elem = document.getElementById(id || normalizeId(title));
      elem.value = formatCurrency(response.convertedAmount);
      internalOnChange({ target: elem });
      onCurrencyConvert && onCurrencyConvert(response);
      onCurrencyConvertWindow && window[onCurrencyConvertWindow] && window[onCurrencyConvertWindow](response);
    }
  };

  return (
    <>
      {withCurrencyConverter && showCurrencyConverter && (
        <div className="mb-1 border rounded p-1">
          <div className="d-flex mb-1 align-items-center">
            <h6 className="xs mb-0 flex-grow-1">{translate('convert_from_another_currency')}</h6>
            <X className="hover-light" onClick={() => setShowCurrencyConverter(false)} />
          </div>
          <InputGroup dir="ltr">
            <InputGroup.Text className="p-0 bg-white" style={{ width: '35%' }}>
              <ReactSelect
                options={currencySelectOptions}
                onChange={selectedOption => setCurrencyToConvertFrom(selectedOption.value)}
                value={currencySelectOptions.find(option => option.value === currencyToConvertFrom)}
                classNamePrefix="select"
                className={`p-0 w-100`}
                menuPortalTarget={document.body}
                formatOptionLabel={(option, { context }) => {
                  // Add a divider after EUR when showing in the menu
                  if (option.value === 'EUR' && context === 'menu') {
                    return (
                      <div style={{ position: 'relative' }}>
                        <span className="fw-bold">{option.value} </span> ({option.label})
                        <div
                          className="bg-secondary-dark mt-2"
                          style={{
                            height: '2px',
                            background: '#000'
                          }}
                        />
                      </div>
                    );
                  }
                  return (
                    <>
                      <span className="fw-bold">{option.value} </span> ({option.label})
                    </>
                  ); // Normal display for other options
                }}
                isDisabled={disabled}
                styles={{
                  control: base => ({
                    ...base,
                    border: 0,
                    borderRadius: 0,
                    background: 'transparent',
                    fontSize: 12,
                    padding: 0,
                    fontWeight: 'bold'
                  }),
                  menu: base => ({
                    ...base,
                    width: '200px',
                    zIndex: 10000,
                    position: 'absolute'
                  }),
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                  option: base => ({ ...base, padding: 5, textAlign: 'start', fontSize: 13 }),
                  dropdownIndicator: base => ({
                    display: 'none' // Hides the dropdown arrow
                  }),
                  indicatorSeparator: base => ({
                    display: 'none' // Hides the separator next to the arrow
                  }),
                  indicatorsContainer: base => ({
                    padding: 0 // Removes any padding to reduce space taken
                  })
                }}
              />
            </InputGroup.Text>

            <FormControl
              dir={'ltr'}
              style={{ fontSize: 11 }}
              autoComplete="off"
              onChange={e => {
                const formattedText = convertToCurrencyFormat(e.target.value);
                setCurrencyValue(formattedText);
              }}
              value={currencyValue}
              className={`p-1 text-end ${className}`}
              placeholder={translate('value_in_currency', { currency: currencyToConvertFrom })}
              disabled={disabled}
              size={size}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  if (disabled || !currencyValue || fetchingConvertedValue) return;

                  convertCurrency();
                }
              }}
            />

            <Button
              style={{ fontSize: 11 }}
              className="p-1"
              variant="dark"
              required={required}
              onClick={convertCurrency}
              disabled={disabled || !currencyValue || fetchingConvertedValue}
              size={size}
            >
              {fetchingConvertedValue ? <CircularProgressBar size={1} light /> : <ArrowRightShort size={16} />}
            </Button>
          </InputGroup>
        </div>
      )}
      <InputGroup dir="ltr">
        <FormControl
          dir={'ltr'}
          autoComplete="off"
          id={id || normalizeId(title)}
          onChange={internalOnChange}
          value={value}
          defaultValue={value || preValue}
          className={`text-${isRTL ? 'end' : 'start'} ${className}`}
          placeholder={hint}
          required={required}
          onClick={onClick}
          disabled={disabled}
          size={size}
        />
        {withCurrencyConverter && (
          <Button
            variant={!showCurrencyConverter ? 'outline-dark' : 'dark'}
            required={required}
            onClick={() => setShowCurrencyConverter(!showCurrencyConverter)}
            disabled={disabled}
            size={size}
          >
            <CurrencyExchange />
          </Button>
        )}
      </InputGroup>
    </>
  );
};

export default CurrencyInput;
