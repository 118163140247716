import React, { useContext, useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import LTRNumberCell from '../../../../common/LTRNumberCell';

const getTableHeader = translate => [
  { label: translate('asset'), objectKey: 'asset' },
  { label: translate('buying_month'), objectKey: 'buyingMonth' },
  { label: translate('buying_year'), objectKey: 'buyingYear' },
  { label: translate('buying_price'), objectKey: 'buyValue', shouldFormatCurrency: true },
  { label: translate('buying_tax'), objectKey: 'buyTaxAmount', shouldFormatCurrency: true },
  { label: translate('additional_buy_cost'), objectKey: 'additionalBuyCosts', shouldFormatCurrency: true },
  { label: translate('selling_month'), objectKey: 'sellingMonth' },
  { label: translate('selling_year'), objectKey: 'sellingYear' },
  { label: translate('selling_price'), objectKey: 'soldValue', shouldFormatCurrency: true },
  { label: translate('selling_tax'), objectKey: 'sellTaxAmount', shouldFormatCurrency: true },
  { label: translate('additional_sell_cost'), objectKey: 'additionalSalesCosts', shouldFormatCurrency: true },
  { label: translate('total_equity_for_buying'), objectKey: 'totalEquityForBuying', shouldFormatCurrency: true }
];

const TaxSheet = ({ taxSummary }) => {
  const { translate, langCode } = useContext(LocalizeContext);

  const tableHeader = useMemo(() => getTableHeader(translate), [langCode]);

  return (
    <div className="py-3">
      <h6>
        <b>{translate('tax_and_additional_costs')}</b>
      </h6>
      {taxSummary.length > 0 ? (
        <Table responsive bordered className="smallFont mb-2">
          <thead>
            <tr className="bg-dark text-white">
              {tableHeader.map(h => (
                <th style={{ minWidth: 100 }} key={h.label}>
                  {h.label}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {taxSummary.map((item, index) => (
              <tr className="bg-white" key={index}>
                {tableHeader.map(({ objectKey, shouldFormatCurrency }) => (
                  <td style={{ minWidth: 100 }} key={objectKey}>
                    {shouldFormatCurrency ? <LTRNumberCell value={item[objectKey]} /> : item[objectKey]}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      ) : (
        <h6 className="p-5 text-center">{translate('nothing_to_show')}</h6>
      )}
    </div>
  );
};

export default TaxSheet;
