import React, { createContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import CircularProgressBar from '../components/common/circular-progress';
import { makeApiRequests } from '../helpers/api';
import { ALL_ROLES, ENDPOINTS } from '../helpers/constants';
import { isAdmin } from '../helpers/global';
import { getIsUserLoggedInFromLocal, setIsUserLoggedInToLocal } from '../helpers/session';

//A provider that fetches user if user has already signed up.
//we only store the user access token
//from this access token, we fetch user info on every refresh

const getUserPermissions = user => {
  let permissions;
  if (isAdmin(user.role)) {
    permissions = {
      view: 'All',
      edit: 'All'
    };
  } else {
    let userCRMPermissions = user?.specialRoles?.app;
    if (userCRMPermissions === 'Block') {
      permissions = {
        view: 'Block',
        edit: 'Block'
      };
    } else {
      let userPermissions = userCRMPermissions.split(' | ');
      permissions = {
        view: (userPermissions[0]?.split(' ') || [])[1],
        edit: (userPermissions[1]?.split(' ') || [])[1]
      };
    }
  }
  return permissions;
};

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const as = searchParams.get('as');
  const customerId = searchParams.get('customerId');

  const [fetchingUser, setFetchingUser] = useState(false);
  const [user, setUser] = useState(null);
  const [isUserLoggedIn, setUserLoggedIn] = useState(getIsUserLoggedInFromLocal());
  const [viewAsUserMode, setViewAsUserMode] = useState(null);

  const [parentUser, setParentUser] = useState(null);
  const [fetchingViewingUser, setFetchingViewingUser] = useState(true);

  useEffect(() => {
    if (!parentUser) return;

    if (!as && !customerId) {
      setFetchingViewingUser(false);
      setViewAsUserMode(false);
      setUser(parentUser);
      sessionStorage.removeItem('updating-user');
      return;
    }

    const fetchUser = async () => {
      setFetchingViewingUser(true);

      const { response, error } = await makeApiRequests({
        endpoint: ENDPOINTS.USERS_LIST,
        requestBody: {
          filter: {
            role: customerId ? ['Customer'] : ALL_ROLES,
            ...(customerId ? { _id: customerId } : {}),
            ...(as ? { email: as } : {})
          }
        },
        doNotSendUpdatingAs: true
      });
      const user = response?.[0];
      if (user) {
        user.specialRoles = getUserPermissions(user);
        sessionStorage.setItem('updating-user', JSON.stringify(user));
        setViewAsUserMode(true);
        setUser(user);
      } else {
        setViewAsUserMode(false);
        setUser(parentUser);
        sessionStorage.removeItem('updating-user');
      }
      setFetchingViewingUser(false);
    };
    fetchUser();
  }, [parentUser]);

  useEffect(() => {
    if (isUserLoggedIn) {
      getMe();
    } else {
      setUser(null);
      setParentUser(null);
    }
  }, [isUserLoggedIn]);

  const getMe = async () => {
    setFetchingUser(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_ME,
      method: 'GET',
      doNotSendUpdatingAs: true
    });

    setFetchingUser(false);

    if (error) {
      toast.error(error);
      return;
    }
    response.specialRoles = getUserPermissions(response);
    setParentUser(response);
  };

  const login = () => {
    setUserLoggedIn(true);
    setIsUserLoggedInToLocal(true);
  };

  const logout = () => {
    makeApiRequests({
      endpoint: ENDPOINTS.USERS_LOGOUT,
      method: 'GET'
    }).catch(() => {});

    setIsUserLoggedInToLocal(false);
    setUserLoggedIn(false);
  };

  const onUserChange = userObj => {
    setUser(userObj);
  };

  const contextObj = {
    isUserLoggedIn,
    login,
    logout,
    user,
    onUserChange,
    fetchingViewingUser,
    viewAsUserMode,
    parentUser
  };

  return !isUserLoggedIn || (user && !fetchingUser && !fetchingViewingUser) ? (
    <UserContext.Provider value={contextObj}>{children}</UserContext.Provider>
  ) : (
    <div className="d-flex justify-content-center align-items-center h-100">
      <CircularProgressBar size={10} />
    </div>
  );
};
