import React from 'react';
import { CurrencyInput } from '../../../../../form-generator/components';
import PercentageInput from '../../../../../form-generator/components/PercentageInput';
import { formatCurrency, formatPercentage } from './helper';
import { ArrowLeftRight } from 'react-bootstrap-icons';

const SellingTax = ({
  sellingTax,
  onTotalTaxChange,
  onTotalTaxPercentageChange,
  isApartmentAbroad,
  isTaxCalculatedAutomatically,
  onAutoCalculateModeChange
}) => {
  return (
    <>
      <div className="mt-1 d-flex align-items-center">
        <div className="flex-grow-1">
          <h6 className="mid mb-0">Expected Selling Tax:</h6>
        </div>
        {!isApartmentAbroad && (
          <div>
            <h6 className={`a tiny text-primary hover mb-0`} onClick={onAutoCalculateModeChange}>
              <ArrowLeftRight className="mx-1" />
              Switch to <b>{isTaxCalculatedAutomatically ? 'Manual' : 'Auto'}</b> mode
            </h6>
          </div>
        )}
      </div>
      <hr className="my-2" />
      <div className="px-2">
        {[
          { title: 'Asset value at selling month and year', key: 'appreciatedValue' },
          { title: 'Profit', key: 'pnl' }
        ].map(({ title, key }) => (
          <h6 key={key} className="smallFont text-muted text-end">
            {title}: <span className="text-dark">{formatCurrency(sellingTax[key])}</span>
          </h6>
        ))}

        {sellingTax?.taxes?.map(t => (
          <div>
            <h6 className="mb-0  smallFont text-dark text-end">
              Upto {formatCurrency(t.to)}(<span>{t.percentage}%</span>)
            </h6>

            <h6 className="text-end text-muted smallFont mt-2">
              <b>={formatCurrency(t.actualTax)}</b>
            </h6>
          </div>
        ))}

        <div className="smallFont mb-3 d-flex">
          <div className={isApartmentAbroad ? 'w-50' : 'w-100'}>
            <b>Total Tax: </b>
            <CurrencyInput
              id={'totalSellingTax'}
              className="d-inline-block p-1 py-0"
              style={{ minHeight: 10, fontSize: 13, width: 140 }}
              size="sm"
              type="number"
              disabled={!isApartmentAbroad && isTaxCalculatedAutomatically}
              onChange={e => e && onTotalTaxChange(e.target.value ? Number(e.target.value.replaceAll(',', '')) : 0)}
              value={sellingTax.total === 0 ? '' : formatCurrency(sellingTax.total)}
            />
          </div>
          {isApartmentAbroad && (
            <div className="mx-2 w-50">
              <b>Tax (%): </b>
              <PercentageInput
                id={'totalSellingTaxInPercentage'}
                className="d-inline-block p-1 py-0"
                isControlled
                size="sm"
                type="number"
                onChange={e => {
                  e && onTotalTaxPercentageChange(e.target.value ? Number(e.target.value.replaceAll('%', '')) : 0);
                }}
                value={formatPercentage(sellingTax.totalPercentage || 0, false, true, 2)}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default SellingTax;
