import { useMemo, useContext } from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { months, primaryBankAccountName } from '../../../../../helpers/constants';
import { arrayToCommaSeparatedString, formatCurrency } from './helper';
import { LocalizeContext } from 'react-locale-language';

const transactionTypeToColorMap = {
  Start: 'success',
  End: 'danger',
  Buy: 'success',
  Sell: 'danger',
  Add: 'success',
  Subtract: 'danger',
  Set: 'primary'
};

const createTimeline = strategy => {
  const strategyStartYear = strategy.initialYear;
  const assetsTimeline = strategy['assets']
    ?.filter(a => a.name !== primaryBankAccountName && a.category !== 'Fixed Income')
    .flatMap(asset => {
      const actions = [];
      //buy
      if (asset.editMode || asset.buyingYear !== strategyStartYear || asset.buyingMonth !== 0) {
        actions.push({
          year: asset.buyingYear,
          month: asset.buyingMonth,
          category: asset?.category,
          name: asset?.name,
          transactionType: 'Buy',
          value: asset.value,
          type: 'Asset',
          isEditMode: asset.editMode,
          sortingPrecedence: 1
        });
      }
      //todo get sold value
      if (asset.sellingYear) {
        actions.push({
          year: asset.sellingYear,
          month: asset.sellingMonth,
          category: asset?.category,
          name: asset?.name,
          transactionType: 'Sell',
          value: asset.soldValue,
          type: 'Asset',
          isEditMode: asset.editMode,
          sortingPrecedence: 1
        });
      }
      return actions;
    });

  const fixedIncomeTimeline = strategy['assets']
    ?.filter(a => a.category === 'Fixed Income')
    .flatMap(asset => {
      const actions = [];
      //buy
      if (asset.editMode || asset.startYear !== strategyStartYear || asset.startMonth !== 0) {
        actions.push({
          year: asset.startYear,
          month: asset.startMonth,
          category: asset?.category,
          name: asset?.name,
          transactionType: 'Start',
          value: asset.value,
          type: 'Asset',
          isEditMode: asset.editMode,
          sortingPrecedence: 1
        });
      }
      //todo get sold value
      if (asset.endYear) {
        actions.push({
          year: asset.endYear,
          month: asset.endMonth,
          category: asset?.category,
          name: asset?.name,
          transactionType: 'End',
          value: asset.endValue,
          type: 'Asset',
          isEditMode: asset.editMode,
          sortingPrecedence: 1
        });
      }
      return actions;
    });

  const liabilitiesTimeline = strategy['liabilities']?.flatMap(liability => {
    const actions = [];

    //start
    if (liability.editMode || liability.startYear !== strategyStartYear || liability.startMonth !== 0) {
      actions.push({
        year: liability.startYear,
        month: liability.startMonth,
        category: 'Liability',
        name: liability.name,
        transactionType: 'Start',
        value: liability.value,
        type: 'Liability',
        isEditMode: liability.editMode,
        sortingPrecedence: 1,
        relatedAssets: liability.relatedAssets
      });
    }

    //todo get end value
    if (liability.endYear) {
      actions.push({
        year: liability.endYear,
        month: liability.endMonth,
        category: 'Liability',
        name: liability.name,
        transactionType: 'End',
        value: liability.endValue,
        type: 'Liability',
        isEditMode: liability.editMode,
        sortingPrecedence: 1,
        relatedAssets: liability.relatedAssets
      });
    }
    return actions;
  });

  const oneOffChangesTimeline = strategy['oneOffChanges']
    .filter(o => o._id)
    .map(oneOffChange => {
      const relatedAttribute =
        oneOffChange.type === 'Asset'
          ? strategy.assets.find(asset => asset._id === oneOffChange.refId)
          : strategy.liabilities.find(liability => liability._id === oneOffChange.refId);
      return {
        ...oneOffChange,
        name: relatedAttribute?.name,
        category: 'One Off Change',
        isOneOffChange: true,
        sortingPrecedence: 0.01 + oneOffChange.month * 0.01
      };
    });

  const timeline = [...assetsTimeline, ...fixedIncomeTimeline, ...liabilitiesTimeline, ...oneOffChangesTimeline];
  const sortedTimeline = timeline.sort((a, b) => {
    return a.year === b.year
      ? a.month - a.sortingPrecedence - (b.month - b.sortingPrecedence)
      : a.year - a.sortingPrecedence - (b.year - b.sortingPrecedence);
  });

  const yearlyBankBalances = strategy['assets'].find(asset => asset.name === primaryBankAccountName)?.yearlyValues;

  let currentBalance = {};

  const sortedTimelineWithBankBalance = sortedTimeline.map(action => {
    if (currentBalance?.year !== action.year) {
      currentBalance = {
        year: action.year,
        value: yearlyBankBalances.find(balance => balance.year === action.year)?.valueBeforeTransactions || 0
      };
    }
    if (action.type === 'Asset' && action.isEditMode === true) {
      currentBalance.value =
        action.transactionType === 'Buy'
          ? currentBalance.value - parseInt(action.value)
          : currentBalance.value + parseInt(action.value);
    }
    return { ...action, primaryBankBalance: currentBalance.value };
  });

  return sortedTimelineWithBankBalance;
};

const ActionSummaryView = ({ strategy }) => {
  const timeline = useMemo(() => createTimeline(strategy), [strategy]);
  const { translate } = useContext(LocalizeContext);

  return timeline.length > 0 ? (
    <VerticalTimeline layout="1-column-right">
      {timeline.map((action, index) => {
        return (
          <VerticalTimelineElement
            key={index}
            className=" my-2"
            textClassName="bg-light border"
            contentStyle={{
              paddingBottom: 0
            }}
            contentArrowStyle={{ borderLeft: '7px solid #000' }}
            dateClassName="text-dark fw-bold"
            iconClassName={`bg-${transactionTypeToColorMap[action.transactionType || action.action] || 'primary'}`}
          >
            <>
              <div
                className="vertical-timeline-element-title"
                style={{ display: 'flex', justifyContent: 'space-between', fontSize: 13, flexWrap: 'wrap' }}
              >
                <b>
                  <span className="text-primary">{action.category}</span> : {action.name}
                </b>
                {action.isOneOffChange && <b className="text-muted">{action.description}</b>}
                {action.type === 'Liability' && action.relatedAssets?.length && (
                  <span>
                    {translate('linked_asset')} : <b>{arrayToCommaSeparatedString(action.relatedAssets)}</b>
                  </span>
                )}
                <span>
                  {action.transactionType || action.action} {translate('value')} : <b>{formatCurrency(action.value)}</b>
                </span>
              </div>
              <h6
                className={`smallFont mb-0 mt-1 text-${transactionTypeToColorMap[
                  action.transactionType || action.action
                ] || 'primary'}`}
              >
                <b>
                  {months[action.month]} {action.year}
                </b>
              </h6>
            </>
          </VerticalTimelineElement>
        );
      })}
    </VerticalTimeline>
  ) : (
    <h6 className="text-muted p-5">{translate('nothing_to_show')}</h6>
  );
};

export default ActionSummaryView;
