import { useContext, useEffect, useMemo, useState } from 'react';
import { Col, Row, Table } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import LTRNumberCell from '../../../../../common/LTRNumberCell';
import { calculateCashflowCushion, findHighestCashflowOfStrategy } from '../helper';
import CushionTable from './CushionTable';

const CashFlowValueLost = ({ adminCompTable, strategy, weakestMonthYear }) => {
  const [highestCashflows, setHighestCashFlows] = useState();
  const [cushionValues, setCushionValues] = useState();
  const { translate, langCode } = useContext(LocalizeContext);

  useEffect(() => {
    const highestRentCashFlow = findHighestCashflowOfStrategy(
      strategy,
      'Real Estate',
      weakestMonthYear.month,
      weakestMonthYear.year
    );

    const highestJobCashFlow = findHighestCashflowOfStrategy(
      strategy,
      'Custom',
      weakestMonthYear.month,
      weakestMonthYear.year
    );

    setHighestCashFlows([highestJobCashFlow, highestRentCashFlow]);
    setCushionValues(calculateCashflowCushion(strategy, weakestMonthYear));
  }, [strategy, weakestMonthYear]);

  const tableHeaders = useMemo(() => [translate('highest_earner'), translate('value_lost_in_months')], [langCode]);
  const losesAPlaceWorkLabel = useMemo(() => translate('loses_place_of_work'), [langCode]);
  const tenantStopsPayingLabel = useMemo(() => translate('tenant_stops_paying'), [langCode]);

  const showCashflowImpactToClient = useMemo(() => adminCompTable?.appConfig?.showCashflowImpactToClient, [
    adminCompTable
  ]);

  const showCushionsTableToClient = useMemo(() => adminCompTable?.appConfig?.showCushionsTableToClient, [
    adminCompTable
  ]);

  return highestCashflows && cushionValues ? (
    <Row className="my-3">
      {showCashflowImpactToClient && (
        <Col xs={12} md={showCushionsTableToClient ? 6 : 12}>
          <h6>
            <b>{translate('cashflow_impacts')}</b>
          </h6>
          <Table responsive bordered className="smallFont mb-2">
            <thead>
              <tr className="bg-dark text-white">
                <th></th>
                {tableHeaders.map(h => (
                  <th key={h}>{h}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {highestCashflows.map((hc, index) => {
                const title = index === 0 ? losesAPlaceWorkLabel : tenantStopsPayingLabel;
                const valueLostIn6Months = hc.value * 6;
                const isMoreThanCushion = valueLostIn6Months > cushionValues[1];

                return (
                  <tr key={title}>
                    <td className={isMoreThanCushion ? 'bg-danger bg-opacity-25' : ''}>{title}</td>
                    <td className={isMoreThanCushion ? 'bg-danger bg-opacity-25' : ''}>{hc.name}</td>
                    <td className={isMoreThanCushion ? 'bg-danger bg-opacity-25' : ''}>
                      <LTRNumberCell value={valueLostIn6Months} />
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Col>
      )}
      {showCushionsTableToClient && (
        <Col xs={12} md={showCashflowImpactToClient ? 6 : 12}>
          <CushionTable cushionValues={cushionValues} />
        </Col>
      )}
    </Row>
  ) : (
    ''
  );
};

export default CashFlowValueLost;
