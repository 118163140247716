import React, { useState, useEffect } from 'react';
import { FormControl } from 'react-bootstrap';

const AutoSaveTextArea = ({ text = '', onTextChange, onTextSubmit, disabled, autoSaveDelay = 1500 }) => {
  const [debouncedText, setDebouncedText] = useState(null);

  useEffect(() => {
    if (debouncedText === null) return;

    const debounceTimeout = setTimeout(() => {
      onTextSubmit(debouncedText);
    }, autoSaveDelay);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [debouncedText]);

  const handleTextChange = e => {
    const newText = e.target.value;
    onTextChange(newText);
    setDebouncedText(newText);
  };

  return (
    <>
      <FormControl
        autoComplete="off"
        autoFocus={false}
        className="border-0"
        style={{ background: 'transparent', fontSize: 13 }}
        as="textarea"
        rows={5}
        placeholder="Nothing to show"
        value={text}
        onChange={handleTextChange}
        disabled={disabled}
      />
    </>
  );
};

export default AutoSaveTextArea;
