import React, { useCallback, useEffect, useRef, useState } from 'react';
import FilterSideBar from './FilterSidebar';
import SearchArea from './SearchArea';
import { debounce } from 'lodash';
import { useLocation } from 'react-router-dom';

const getFilterValues = (filterOptions = []) => {
  const returnValues = {};

  filterOptions.forEach(option => {
    returnValues[option.title] = option.defaultChecked ? option.options : [];
  });

  return returnValues;
};

const SearchBox = ({
  filterOptions = [],
  sortByOptions = [],
  maxLimitOptions = [],
  onSearchOptionsChange,
  disabled,
  containerClass = '',
  recommendationGetter,
  showArchiveCheckbox = false,
  showOnlySearch
}) => {
  const [filterBarVisible, setFilterBarVisible] = useState(false);

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);

  const [maxLimit, setMaxLimit] = useState(maxLimitOptions[0]);
  const [query, setQuery] = useState(searchParams.get('q') || '');
  const [sortBy, setSortBy] = useState(sortByOptions[0]);
  const [descSort, setDescSort] = useState(false);
  const [showArchived, setShowArchived] = useState(false);

  const [filters, setFilters] = useState(getFilterValues(filterOptions));
  const [recommendations, setRecommendations] = useState([]);

  const requestCounterRef = useRef(0);

  useEffect(() => {
    setSortBy(sortByOptions[0]);
  }, [sortByOptions]);

  useEffect(() => {
    setMaxLimit(maxLimitOptions[0]);
  }, [maxLimitOptions]);

  useEffect(() => {
    onSearchClick();
  }, [sortBy, maxLimit, descSort, filters, showArchived]);

  const onSearchClick = recommendation => {
    setRecommendations([]);
    onSearchOptionsChange &&
      onSearchOptionsChange({
        sortBy,
        maxLimit,
        descSort,
        filters,
        query: recommendation && typeof recommendation === 'string' ? recommendation : query,
        showArchived
      });
  };

  const onFilterApply = filter => {
    setFilters(filter);
    setFilterBarVisible(false);
  };

  const fetchRecommendationsDebounced = useCallback(
    debounce(async query => {
      if (recommendationGetter && query.length > 2) {
        // Increment the request counter for each new request
        requestCounterRef.current += 1;
        const currentCounterValue = requestCounterRef.current;

        const recommendations = await recommendationGetter(query);

        // Only set the recommendations if the current counter matches the counter at the time of the request
        if (currentCounterValue === requestCounterRef.current) {
          setRecommendations(recommendations);
        }
      } else {
        setRecommendations([]); // Clear recommendations for short queries
      }
    }, 150),
    [recommendationGetter]
  );

  const onQueryChangeHandler = newQuery => {
    setQuery(newQuery);
    fetchRecommendationsDebounced(newQuery);
  };

  const onRecommendationClick = recommendation => {
    setQuery(recommendation);
    onSearchClick(recommendation);
  };

  return (
    <>
      <div className={`${containerClass}`}>
        <SearchArea
          query={query}
          onQueryChange={onQueryChangeHandler}
          recommendations={recommendations}
          setRecommendations={setRecommendations}
          onRecommendationClick={onRecommendationClick}
          onSearchClick={onSearchClick}
          disabled={disabled}
          maxLimit={maxLimit}
          showArchiveCheckbox={showArchiveCheckbox}
          showArchived={showArchived}
          onShowArchivedChange={setShowArchived}
          onMaxLimitOptionChange={setMaxLimit}
          maxLimitOptions={maxLimitOptions}
          sortBy={sortBy}
          onSortByOptionChange={setSortBy}
          sortByOptions={sortByOptions}
          descSort={descSort}
          onDescSortButtonClick={() => setDescSort(!descSort)}
          onFilterButtonClick={() => setFilterBarVisible(true)}
          showOnlySearch={showOnlySearch}
        />
      </div>
      {!showOnlySearch && (
        <FilterSideBar
          show={filterBarVisible}
          initialFilterValues={filters}
          filterOptions={filterOptions}
          onFilterApply={onFilterApply}
          onSideBarClose={() => setFilterBarVisible(false)}
        />
      )}
    </>
  );
};

export default SearchBox;
