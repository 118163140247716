import React from 'react';
import LogItem from './LogItem';

const Logs = ({ user, logs = [], disabled, onUndoRedoClick }) => {
  return logs.length === 0 ? (
    <div style={{ height: 200 }} className="d-flex justify-content-center text-muted">
      <p className="align-self-center">No Logs To Show!</p>
    </div>
  ) : (
    <div className={`py-3 px-2 d-flex flex-column align-items-start`}>
      {logs.map(l => (
        <LogItem
          user={user}
          log={l}
          key={l._id}
          disabled={disabled}
          onUndoRedoClick={action => onUndoRedoClick(action, l._id)}
        />
      ))}
    </div>
  );
};

export default Logs;
