import React, { useContext, useEffect, useState } from 'react';
import { LocalizeContext } from 'react-locale-language';
import { monthSmall } from '../../../../../../helpers/constants';
import LineChart from '../../../../../common/line-chart/LineChart';
import { getValueAtMonthYear } from '../helper';
import ChartContainer from './ChartContainer';

const createChartData = (strategy, strategyYears, translate) => {
  const labels = [];
  const assetData = [];
  const liabilityData = [];
  const equityData = [];

  const getMonthlyValue = (sectionName, monthNum, year) => {
    return strategy[sectionName]
      .filter(a => !a.isInvalid && !a.toBeMerged)
      .reduce((prevValue, a) => prevValue + getValueAtMonthYear(a, monthNum, year), 0);
  };

  const initialMonth = strategy.initialMonth;

  strategyYears.forEach((year, yearIndex) => {
    monthSmall.forEach((month, monthNum) => {
      if (yearIndex === 0 && monthNum < initialMonth) return;
      if (initialMonth > 0 && yearIndex === strategyYears.length - 1 && monthNum >= initialMonth) return;

      labels.push(`${month} ${year}`);

      const assetValueAtThisMonth = getMonthlyValue('assets', monthNum, year);
      const liabilityValueAtThisMonth = getMonthlyValue('liabilities', monthNum, year);
      const equityAtThisMonth = assetValueAtThisMonth - liabilityValueAtThisMonth;
      assetData.push(assetValueAtThisMonth.toFixed(0));
      liabilityData.push(liabilityValueAtThisMonth.toFixed(0));
      equityData.push(equityAtThisMonth.toFixed(0));
    });
  });

  return {
    labels,
    datasets: [
      {
        label: translate('assets'),
        data: assetData,
        borderColor: 'rgba(75, 192, 192, 1)',
        backgroundColor: 'rgba(75, 192, 192, 1)'
      },
      {
        label: translate('liabilities'),
        data: liabilityData,
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 1)'
      },
      {
        label: translate('equity'),
        data: equityData,
        borderColor: 'rgba(53, 162, 235, 1)',
        backgroundColor: 'rgba(53, 162, 235, 1)'
      }
    ]
  };
};

const BalanceSheetLineChart = ({ strategy = {}, strategyYears }) => {
  const [chartData, setChartData] = useState();
  const { translate } = useContext(LocalizeContext);

  useEffect(() => {
    setChartData(createChartData(strategy, strategyYears, translate));
  }, [strategy]);

  return chartData ? (
    <ChartContainer title={translate('balance_sheet_growth_with_time')} maxWidth={'80%'}>
      <LineChart chartData={chartData} />
    </ChartContainer>
  ) : (
    ''
  );
};

export default BalanceSheetLineChart;
