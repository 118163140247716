import React from 'react';
import StressTestProblems from './StressTestProblems';

const StressTest = ({ strategy }) => {
  return (
    <div>
      <StressTestProblems
        problems={strategy.stressTestProblems}
        ignoredAssets={strategy.stressTestIgnoredAssets}
        ignoredLoans={strategy.stressTestIgnoredLiabilities}
      />
    </div>
  );
};

export default StressTest;
