import React, { useEffect } from 'react';
import { FormControl } from 'react-bootstrap';
import { formatPercentage } from '../../components/admin/manage-users/customer/strategies/helper';
import { normalizeId, setCaretPosition } from '../helpers/utility';

const PercentageInput = ({
  id,
  title,
  onChange,
  onChangeWindow,
  value,
  preValue,
  className = '',
  hint = '',
  required = false,
  onFormChange,
  onClick,
  disabled = false,
  size,
  tabIndex,
  isControlled = true,
  round,
  roundDecimal = 2,
  style
}) => {
  useEffect(() => {
    internalOnChange(null);
  }, []);

  const updateCaretPosition = e => {
    const textElem = e.target;

    const text = textElem.value;
    if (text) {
      const currentCaretPosition = textElem.selectionStart;

      if (text.trim().endsWith('%') && (e.type === 'focus' || currentCaretPosition === text.length)) {
        setCaretPosition(textElem, text.length - 2);
      }
    }
  };

  const internalOnChange = e => {
    const elem = e ? e.target : document.getElementById(id || normalizeId(title));
    if (!elem) return;

    const newString = elem.value.replace(/[^.\d.-]/g, '');
    elem.value = formatPercentage(newString, false);

    if (onChange) onChange(e);
    if (onChangeWindow && window[onChangeWindow]) {
      window[onChangeWindow](e);
    }
    if (onFormChange) onFormChange(e);

    if (e) updateCaretPosition(e);
  };

  return (
    <FormControl
      autoComplete="off"
      id={id || normalizeId(title)}
      onChange={internalOnChange}
      value={
        isControlled
          ? value
            ? formatPercentage(value.toString().replace(/[^.\d.-]/g, ''), false, round, roundDecimal)
            : ''
          : undefined
      }
      defaultValue={value || preValue}
      className={className}
      placeholder={hint}
      required={required}
      onClick={onClick}
      disabled={disabled}
      size={size}
      onFocus={updateCaretPosition}
      tabIndex={tabIndex}
      style={style}
    />
  );
};

export default PercentageInput;
