import React, { useEffect } from 'react';
import { FormControl, InputGroup } from 'react-bootstrap';
import { normalizeId } from '../helpers/utility';

const NumberInput = ({
  id,
  title,
  onChange,
  onChangeWindow,
  value,
  preValue,
  className = '',
  hint = '',
  required = false,
  onFormChange,
  onClick,
  disabled = false,
  suffix,
  size
}) => {
  useEffect(() => {
    internalOnChange(null);
  }, []);

  const internalOnChange = e => {
    if (onChange) onChange(e);
    if (onChangeWindow && window[onChangeWindow]) {
      window[onChangeWindow](e);
    }
    if (onFormChange) onFormChange(e);
  };
  return (
    <InputGroup size={size}>
      <FormControl
        autoComplete="off"
        id={id || normalizeId(title)}
        type="number"
        onChange={internalOnChange}
        defaultValue={value || preValue}
        className={className}
        placeholder={hint}
        required={required}
        onClick={onClick}
        disabled={disabled}
      />
      {suffix && <InputGroup.Text>{suffix}</InputGroup.Text>}
    </InputGroup>
  );
};

export default NumberInput;
