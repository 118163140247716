import React, { useContext, useState } from 'react';
import { Card } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../../helpers/api';
import { ENDPOINTS } from '../../../helpers/constants';
import RichTextEditor from '../../common/rich-text-editor/RichTextEditor';
import useAuth from '../../../hooks/useAuth';

const CompGeneralInfo = ({ comp, onCompUpdate, readOnlyMode }) => {
  const { translate } = useContext(LocalizeContext);
  const [updatingComp, setUpdatingComp] = useState(false);
  const onUpdateGeneralInfoSubmit = async generalInfo => {
    setUpdatingComp(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.COMPS_WITH_ID(comp._id),
      method: 'PUT',
      requestBody: {
        ...comp,
        customerGeneralInformation: generalInfo
      },
    });

    setUpdatingComp(false);
    if (error) {
      toast.error(error);
      return;
    }

    if (!comp.customer && response) localStorage.setItem('comps', JSON.stringify(response));

    toast.success('Info updated succesfully!');
    onCompUpdate(response);
  };

  return (
    <Card className="mt-3">
      <Card.Header>{translate('general_information')}</Card.Header>
      <Card.Body className="p-3">
        <RichTextEditor
          rows={10}
          richText={comp?.customerGeneralInformation || ''}
          disabled={updatingComp || readOnlyMode}
          onSubmit={onUpdateGeneralInfoSubmit}
        />
      </Card.Body>
    </Card>
  );
};

export default CompGeneralInfo;
