import { uniqueId } from 'lodash';
import React, { useEffect, useRef } from 'react';
import { Badge, Card } from 'react-bootstrap';
import { CheckCircleFill, ExclamationCircleFill, Upload, X } from 'react-bootstrap-icons';
import useFileUpload from 'react-use-file-upload';
import { makeApiRequests } from '../../../helpers/api';
import { ENDPOINTS } from '../../../helpers/constants';
import CircularProgressBar from '../circular-progress';

const getMetadataParams = (metadata = {}) => {
  const params = Object.keys(metadata)
    .map(k => encodeURI(`${k}=${metadata[k]}`))
    .join('&');
  return `?${params}`;
};

const FileButton = ({ fileObject, onRemoveFile }) => (
  <Badge className="mx-1 p-2" variant="primary">
    {fileObject.status !== 'UPLOADING' && <X className="hover-light ms-1" size={14} onClick={onRemoveFile} />}
    <span className="ms-2">{fileObject.file.name}</span>
    {fileObject.status === 'UPLOADING' && <CircularProgressBar size={1} />}
    {fileObject.status === 'UPLOADED' && <CheckCircleFill className="text-success" />}
    {fileObject.status === 'ERROR' && <ExclamationCircleFill className="text-danger" />}
  </Badge>
);

// uploadedFile = {status: ['WAITING_FOR_UPLOAD','UPLOADING', 'UPLOADED', 'ERROR']}

const File = ({ containerClassName = '', uploadedFiles = [], onUploadedFilesChange, metaData = {} }) => {
  const { files, handleDragDropEvent, setFiles } = useFileUpload();
  const inputRef = useRef();

  useEffect(() => {
    //when new file is uploaded, set status to upload the file to server and push to uploaded files
    const newFiles = [];
    for (const f of files) {
      const fileToBeUploaded = { file: f, status: 'WAITING_FOR_UPLOAD', id: uniqueId() };
      newFiles.push(fileToBeUploaded);
    }

    onUploadedFilesChange && onUploadedFilesChange([...uploadedFiles, ...newFiles]);
  }, [files]);

  useEffect(() => {
    uploadedFiles.filter(f => f.status === 'WAITING_FOR_UPLOAD').forEach(uploadFile);
  }, [uploadedFiles]);

  const uploadFile = async fileToBeUploaded => {
    updateUploadedFiles(fileToBeUploaded, 'UPLOADING');

    const formData = new FormData();
    formData.append('file', fileToBeUploaded.file);

    const { error, response } = await makeApiRequests({
      requestBody: formData,
      endpoint: ENDPOINTS.FILE, // `${ENDPOINTS.FILE}${getMetadataParams(metaData)}`,
      stringify: false
    });

    if (error) {
      updateUploadedFiles(fileToBeUploaded, 'ERROR');
    } else {
      updateUploadedFiles(fileToBeUploaded, 'UPLOADED', response);
    }
  };

  const updateUploadedFiles = (fileObject, status, response = {}) => {
    uploadedFiles = uploadedFiles.map(f => (f.id === fileObject.id ? { ...f, ...response, status } : f));
    onUploadedFilesChange && onUploadedFilesChange(uploadedFiles);
  };

  const onRemoveFile = fileObject => {
    if (fileObject.status === 'UPLOADED') {
      //we do not care for response
      makeApiRequests({
        endpoint: ENDPOINTS.FILE_WITH_ID(fileObject.filename),
        method: 'DELETE'
      });
    }

    uploadedFiles.splice(
      uploadedFiles.findIndex(f => f.id === fileObject.id),
      1
    );
    onUploadedFilesChange([...uploadedFiles]);
  };

  return (
    <div className={`mb-2 ${containerClassName}`}>
      <h6 className="midFont">Upload Files</h6>

      <Card>
        <Card.Body className="p-0">
          <div className="p-1 px-2  border-bottom">
            {uploadedFiles.length > 0 ? (
              uploadedFiles.map(fileObject => (
                <FileButton key={fileObject.id} fileObject={fileObject} onRemoveFile={() => onRemoveFile(fileObject)} />
              ))
            ) : (
              <p className="mb-0 smallFont">No files uploaded.</p>
            )}
          </div>
          <div
            className="py-3 text-center hover-light rounded-bottom"
            onDragEnter={handleDragDropEvent}
            onDragOver={handleDragDropEvent}
            onDrop={e => {
              handleDragDropEvent(e);
              setFiles(e);
            }}
            onClick={() => inputRef.current.click()}
          >
            <Upload className="text-muted" size={22} />
            <p className="mb-0 mt-2 midFont">Click Or Drag and drop files here</p>
            <input
              autoComplete="off"
              ref={inputRef}
              type="file"
              multiple
              style={{ display: 'none' }}
              onChange={e => {
                setFiles(e);
                inputRef.current.value = null;
              }}
            />
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default File;
