import { useFormik } from 'formik';
import { snakeCase, uniqueId } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, FormCheck, FormControl, Modal, ProgressBar, Row } from 'react-bootstrap';
import { ArrowUpRightCircle, Pen, PlusCircle, Trash } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import FormGenerator from '../../../../../form-generator/FormGenerator';
import { CurrencyInput } from '../../../../../form-generator/components';
import PercentageInput from '../../../../../form-generator/components/PercentageInput';
import {
  cashflowTypeOptions,
  getCashflowChangesFields,
  monthSmall,
  primaryBankAccountName
} from '../../../../../helpers/constants';
import cashflowSchema from '../../../../../schema/cashflowSchema';
import EntityTable from '../../../../common/EntityTable';
import ExplainatoryVideoButton from '../../../../common/ExplainatoryVideoButton';
import { addOrUpdateObjectInArray, deleteObjectInArray, formatCurrency, formatPercentage } from './helper';

const getRows = (headers = [], excludedFields = []) => [
  {
    columns: headers
      .filter(h => !excludedFields.includes(h.key))
      .map(({ colWidth = 6, name, formTitle, key: id, type = 'text', formType, required = false, ...rest }) => ({
        default: colWidth,
        field: {
          title: formTitle || name,
          id,
          key: id,
          type: formType || (type === 'boolean' ? 'checkbox' : type),
          required,
          ...rest
        }
      }))
  }
];

const getForm = ({ type = 'Add', translate, strategyYears }) => ({
  forms: [
    {
      name: `${type} Change`,
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: type,
        show: true,
        onSubmit: `onCashflowChangeFormSubmit`
      },
      rows: getRows(getCashflowChangesFields(translate, strategyYears))
    }
  ]
});

const CashflowChanges = ({
  cashflowChanges = [],
  onCashflowChangeAddClick,
  onCashflowChangeEditClick,
  onCashflowChangeDeleteClick,
  strategyYears = []
}) => {
  const { translate, langCode } = useContext(LocalizeContext);
  const cashflowChangesHeader = useMemo(
    () => getCashflowChangesFields(translate, strategyYears).filter(f => f.key !== 'notes'),
    [langCode, strategyYears]
  );

  return (
    <>
      <EntityTable
        quickSearch={false}
        rowKeyField={'id'}
        data={cashflowChanges.map(c => ({ ...c, endMonth: c.endYear ? c.endMonth : '' }))}
        headers={cashflowChangesHeader}
        searchQueryKeys={[]}
        additionalComponents={() => (
          <>
            <b className="mx-3 mid">{translate('one_off_change')}</b>
            <Button
              size="sm"
              style={{ fontSize: 12 }}
              className="text-white px-2 py-1"
              variant="success"
              onClick={() => onCashflowChangeAddClick()}
            >
              <PlusCircle className="mx-1" size={10} /> {translate('add_new_change')}
            </Button>
          </>
        )}
        actionCell={item => (
          <div>
            <Button
              variant="outline-primary"
              className="px-1 py-0 mr-1"
              onClick={() => onCashflowChangeEditClick(item)}
            >
              <Pen size={10} />
            </Button>
            <Button variant="outline-danger" className="px-1 py-0 " onClick={() => onCashflowChangeDeleteClick(item)}>
              <Trash size={10} />
            </Button>
          </div>
        )}
      />
    </>
  );
};

const CashflowChangeAddEditModal = ({
  show,
  addFormPrefill = {},
  toBeEditedItem,
  onHide,
  showProgress,
  addForm,
  updateForm,
  translate
}) => (
  <Modal size={'lg'} show={show} onHide={onHide} centered backdrop="static">
    <Modal.Header closeButton={!showProgress}>
      <Modal.Title>
        <h6 className="mb-0">
          {toBeEditedItem ? translate(`update_cashflow_change`) : translate(`add_new_cashflow_change`)}
        </h6>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="overflow-auto px-1">
      <FormGenerator
        formJson={toBeEditedItem ? updateForm : addForm}
        formValues={toBeEditedItem ? { [`Update Change`]: toBeEditedItem } : { [`Add Change`]: addFormPrefill }}
      />
      {showProgress && (
        <ProgressBar
          now={100}
          animated
          striped
          variant="dark"
          label={
            toBeEditedItem
              ? `${translate(`updating_cashflow_changes`)}...`
              : `${translate(`saving_cashflow_changes`)}...`
          }
        />
      )}
    </Modal.Body>
  </Modal>
);

const CashFlowModal = ({
  initialValues,
  isAutomated,
  cashflowChanges = [],
  onCashflowChangesUpdate,
  show,
  hideDialog,
  strategyYears,
  handleCashflowSubmit,
  cashAssetOptions = [],
  apartmentOptions = [],
  isSubmitting: externalSubmitting,
  incompleteYearsOfStrategy
}) => {
  const isEdit = useMemo(() => isAutomated || !!initialValues._id, [isAutomated, initialValues]);
  const [isSubmitting, setSubmitting] = useState(false);
  const { translate } = useContext(LocalizeContext);
  const [cashflowChangeActionMeta, setCashflowChangeActionMeta] = useState(null);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: !isAutomated && cashflowSchema,
    validateOnChange: false,
    validateOnBlur: true,
    onSubmit: async (values, formik) => {
      if (!isAutomated && !validateContextually()) {
        return;
      }

      setSubmitting(true);
      values = {
        ...values
      };

      const success = await handleCashflowSubmit(values, isEdit);
      setSubmitting(false);
      if (!success) return;

      formik.resetForm();
      hideDialog();
    }
  });

  const validateContextually = () => {
    if (formik.values.isRent && !formik.values.linkedApartment) {
      formik.setFieldError('linkedApartment', 'An apartment must be linked when cashflow is rent');
      return false;
    }

    if (formik.values.endYear) {
      if (formik.values.startYear > formik.values.endYear) {
        formik.setFieldError('endYear', 'End year must be after start year');
        formik.setFieldError('startYear', 'Start year must be before end year');
        return false;
      }

      if (formik.values.startYear == formik.values.endYear && formik.values.startMonth > formik.values.endMonth) {
        formik.setFieldError('endMonth', 'End month must be after start month');
        formik.setFieldError('startMonth', 'Start month must be before end month');
        return false;
      }
    }
    return true;
  };

  const onStartYearChange = e => {
    const startYear = e.target.value;
    if (!startYear) return;

    const currentMonth = formik.values.startMonth;
    if (currentMonth !== 0 && !currentMonth) return;

    const currentMonthNum = Number(currentMonth);
    const incompleteYear = incompleteYearsOfStrategy?.[startYear];

    if (incompleteYear && (currentMonthNum < incompleteYear.start || currentMonthNum > incompleteYear.end - 1)) {
      formik.setFieldValue('startMonth', incompleteYear.start);
    }
  };

  const onEndYearChange = e => {
    const endYear = e.target.value;
    if (!endYear) return;

    const currentMonth = formik.values.endMonth;
    if (currentMonth !== 0 && !currentMonth) return;

    const currentMonthNum = Number(currentMonth);
    const incompleteYear = incompleteYearsOfStrategy?.[endYear];

    if (incompleteYear && (currentMonthNum < incompleteYear.start || currentMonthNum > incompleteYear.end - 1)) {
      formik.setFieldValue('endMonth', incompleteYear.start);
    }
  };

  useEffect(() => {
    let values = { ...initialValues };
    [{ monthKey: 'startMonth', yearKey: 'startYear' }].forEach(({ monthKey, yearKey }) => {
      const year = values[yearKey];
      if (!year) return;

      const currentMonth = values[monthKey];
      if (currentMonth !== 0 && !currentMonth) return;

      const currentMonthNum = Number(currentMonth);
      const incompleteYear = incompleteYearsOfStrategy?.[year];

      if (incompleteYear && (currentMonthNum < incompleteYear.start || currentMonthNum > incompleteYear.end - 1)) {
        values[monthKey] = incompleteYear.start;
      }
    });
    formik.setValues(values);
  }, [initialValues]);

  const onCashflowChangeAddClick = () => {
    setCashflowChangeActionMeta({});
  };

  const onCashflowChangeEditClick = item => {
    setCashflowChangeActionMeta({ item });
  };
  const onCashflowChangeDeleteClick = item => {
    const newCashflows = deleteObjectInArray(cashflowChanges, item, 'id');

    onCashflowChangesUpdate(newCashflows);
    setCashflowChangeActionMeta(null);
  };

  const onCashflowChangeFormSubmit = async form => {
    const { item: editingChange } = cashflowChangeActionMeta;

    const newCashflows = addOrUpdateObjectInArray(
      cashflowChanges,
      editingChange ? { ...editingChange, ...form } : { ...form, id: uniqueId() },
      'id'
    );

    newCashflows.sort((c1, c2) =>
      c1.startYear === c2.startYear ? c1.startMonth - c2.startMonth : c1.startYear - c2.startYear
    );

    onCashflowChangesUpdate(newCashflows);
    setCashflowChangeActionMeta(null);
  };

  window['onCashflowChangeFormSubmit'] = onCashflowChangeFormSubmit;

  const onGrowthRateChange = e => {
    if (!e) return;
    let value = e.target.value;
    if (value.split('.').length - 1 > 1) return;
    formik.setFieldValue('yearlyGrowthRate', value ? value.replaceAll('%', '').trim() : 0);
  };

  const onAutomatedGrowthRateChange = e => {
    if (!e) return;

    formik.setFieldValue('cashflowAppreciation', e.target.value ? Number(e.target.value.replaceAll('%', '')) : 0);
  };

  const onTaxOnCashflowChange = e => {
    if (!e) return;

    formik.setFieldValue('taxOnCashflow', e.target.value ? Number(e.target.value.replaceAll('%', '')) : 0);
  };

  return (
    <Modal size="lg" show={show} onHide={hideDialog} centered backdrop="static">
      <Modal.Header closeButton={!(isSubmitting || externalSubmitting)}>
        <Modal.Title className="d-flex align-items-center">
          <h6 className="mb-0">{translate(isEdit ? 'update_cashflow' : 'add_cashflow')}</h6>{' '}
        </Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body className="overflow-auto">
          <ExplainatoryVideoButton videoKey="add_cashflow" />

          {!isAutomated && (
            <>
              <Row className="mt-1">
                <Col>
                  <label className="mid" htmlFor="type">
                    {translate('notes')}
                  </label>
                  <FormControl
                    size="sm"
                    as={'textarea'}
                    rows={2}
                    id="notes"
                    onChange={formik.handleChange}
                    value={formik.values.notes}
                    name="notes"
                    onBlur={() => formik.handleBlur}
                  />

                  {formik.errors.notes ? (
                    <p style={{ color: 'red' }} className="mid">
                      {formik.errors.notes}
                    </p>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <Row style={{ padding: '5px' }}>
                <Col xs={6} md={4}>
                  <label className="mid" htmlFor="type">
                    {translate('type')}
                  </label>
                  <select
                    id="type"
                    name="type"
                    className="form-select form-select-sm"
                    onChange={formik.handleChange}
                    value={formik.values.type}
                  >
                    <option value=""></option>
                    {cashflowTypeOptions.map((option, index) => (
                      <option value={option} key={index}>
                        {translate(snakeCase(option))}
                      </option>
                    ))}
                  </select>
                  {formik.errors.type ? (
                    <p style={{ color: 'red' }} className="mid">
                      {formik.errors.type}
                    </p>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs={6} md={4}>
                  <label className="mid" htmlFor="linkedBankAccount">
                    {translate('linked_bank_account')}
                  </label>
                  <select
                    id="linkedBankAccount"
                    name="linkedBankAccount"
                    className="form-select form-select-sm"
                    onChange={formik.handleChange}
                    value={formik.values.linkedBankAccount}
                  >
                    {cashAssetOptions.map(asset => (
                      <option value={asset._id} key={asset._id}>
                        {asset.name === primaryBankAccountName ? translate('primary_bank_account') : asset.name}
                      </option>
                    ))}
                  </select>
                  {formik.errors.linkedBankAccount ? (
                    <p style={{ color: 'red' }} className="mid">
                      {formik.errors.linkedBankAccount}
                    </p>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs={12} md={4}>
                  <label className="mid" htmlFor="name">
                    {translate('name')}
                  </label>
                  <input
                    autoComplete="off"
                    type="text"
                    className="form-control form-control-sm"
                    id="name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.name ? (
                    <p style={{ color: 'red' }} className="mid">
                      {formik.errors.name}
                    </p>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>

              <Row style={{ padding: '5px' }}>
                <Col xs={6} md={5}>
                  <label className="mid" htmlFor="value">
                    {translate('monthly_value')}
                  </label>
                  <CurrencyInput
                    size={'sm'}
                    withCurrencyConverter
                    currencyConversionPreValue={
                      formik.values.monthlyValueCurrencyConversionInfo
                        ? {
                            conversionAmount: formik.values.monthlyValueCurrencyConversionInfo.conversionAmount,
                            conversionCurrency: formik.values.monthlyValueCurrencyConversionInfo.conversionCurrency
                          }
                        : null
                    }
                    autoComplete="off"
                    type="text"
                    className="form-control form-control-sm"
                    id="monthlyValue"
                    name="monthlyValue"
                    value={formatCurrency(formik.values.monthlyValue)}
                    onChange={e => {
                      if (!e) return;
                      let inputValue = Number(e.target.value.replaceAll(',', '') || 0);
                      if (Number.isNaN(inputValue)) return;
                      formik.setFieldValue('monthlyValue', inputValue);
                    }}
                    onCurrencyConvert={info => {
                      formik.setFieldValue('monthlyValueCurrencyConversionInfo', {
                        conversionCurrency: info.fromCurrency,
                        conversionRate: info.conversionRate,
                        conversionAmount: info.amount
                      });
                    }}
                  />
                  {formik.errors.value ? (
                    <p style={{ color: 'red' }} className="mid">
                      {formik.errors.monthlyValue}
                    </p>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs={6} md={3} className="align-self-end ">
                  <label className="mid" htmlFor="yearlyGrowthRate">
                    {translate('yearly_growth_rate')}
                  </label>

                  <PercentageInput
                    id={'yearlyGrowthRate'}
                    size="sm"
                    onChange={onGrowthRateChange}
                    value={formik.values.yearlyGrowthRate === 0 ? '' : formatPercentage(formik.values.yearlyGrowthRate)}
                  />

                  {formik.errors.yearlyGrowthRate ? (
                    <p style={{ color: 'red' }} className="mid">
                      {formik.errors.yearlyGrowthRate}
                    </p>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs={6} md={2} className="align-self-end mt-3 mt-md-0">
                  <FormCheck
                    className="mid"
                    size="sm"
                    name="affectsRiskManagement"
                    id="affectsRiskManagement"
                    label={translate('is_salary')}
                    checked={formik.values.affectsRiskManagement}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.affectsRiskManagement ? (
                    <p style={{ color: 'red' }} className="mid">
                      {formik.errors.affectsRiskManagement}
                    </p>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs={6} md={2} className="align-self-end mt-3 mt-md-0">
                  <FormCheck
                    className="mid"
                    size="sm"
                    name="isRent"
                    id="isRent"
                    label={translate('is_rent')}
                    checked={formik.values.isRent}
                    onChange={e => {
                      const isRent = e.target.checked;
                      if (isRent) {
                        const app = apartmentOptions[0]?._id;
                        formik.setFieldValue('linkedApartment', app);
                      }
                      formik.setFieldValue('isRent', isRent);
                    }}
                  />
                  {formik.errors.isRent ? (
                    <p style={{ color: 'red' }} className="mid">
                      {formik.errors.isRent}
                    </p>
                  ) : (
                    <></>
                  )}
                </Col>
                {formik.values.isRent && (
                  <Col xs={12} md={12}>
                    <label className="mid" htmlFor="linkedApartment">
                      {translate('linked_apartment')}
                    </label>
                    <select
                      id="linkedApartment"
                      name="linkedApartment"
                      className="form-select form-select-sm"
                      onChange={formik.handleChange}
                      value={formik.values.linkedApartment}
                    >
                      {apartmentOptions.map(asset => (
                        <option value={asset._id} key={asset._id}>
                          {asset.name}
                        </option>
                      ))}
                    </select>
                    {formik.errors.linkedApartment ? (
                      <p style={{ color: 'red' }} className="mid">
                        {formik.errors.linkedApartment}
                      </p>
                    ) : (
                      <></>
                    )}
                  </Col>
                )}
              </Row>
              <Row style={{ padding: '5px' }}>
                <Col xs={6} md={3}>
                  <label className="mid" htmlFor="startMonth">
                    {translate('start_month')}
                  </label>
                  <select
                    id="startMonth"
                    name="startMonth"
                    className="form-select form-select-sm mb-3"
                    value={formik.values.startMonth}
                    onChange={formik.handleChange}
                  >
                    <option value=""></option>
                    {(incompleteYearsOfStrategy?.[formik.values.startYear]
                      ? monthSmall.slice(
                          incompleteYearsOfStrategy?.[formik.values.startYear].start,
                          incompleteYearsOfStrategy?.[formik.values.startYear].end
                        )
                      : monthSmall
                    ).map((month, index) => (
                      <option
                        value={index + (incompleteYearsOfStrategy?.[formik.values.startYear]?.start || 0)}
                        key={index}
                      >
                        {month}
                      </option>
                    ))}
                  </select>
                  {formik.errors.startMonth ? (
                    <p style={{ color: 'red' }} className="mid">
                      {formik.errors.startMonth}
                    </p>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs={6} md={3}>
                  <label className="mid" htmlFor="startYear">
                    {translate('start_year')}
                  </label>
                  <select
                    id="startYear"
                    name="startYear"
                    className="form-select form-select-sm mb-3"
                    value={formik.values.startYear}
                    onChange={e => {
                      onStartYearChange(e);
                      formik.handleChange(e);
                    }}
                  >
                    <option value=""></option>
                    {strategyYears.map((year, index) => (
                      <option value={year} key={index}>
                        {year}
                      </option>
                    ))}
                  </select>
                  {formik.errors.startYear ? (
                    <p style={{ color: 'red' }} className="mid">
                      {formik.errors.startYear}
                    </p>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs={6} md={3}>
                  <label className="mid" htmlFor="endMonth">
                    {translate('end_month')}
                  </label>
                  <select
                    id="endMonth"
                    name="endMonth"
                    className="form-select form-select-sm mb-3"
                    value={formik.values.endMonth}
                    onChange={formik.handleChange}
                  >
                    <option value=""></option>
                    {(incompleteYearsOfStrategy?.[formik.values.endYear]
                      ? monthSmall.slice(
                          incompleteYearsOfStrategy?.[formik.values.endYear].start,
                          incompleteYearsOfStrategy?.[formik.values.endYear].end
                        )
                      : monthSmall
                    ).map((month, index) => (
                      <option
                        value={index + (incompleteYearsOfStrategy?.[formik.values.endYear]?.start || 0)}
                        key={index}
                      >
                        {month}
                      </option>
                    ))}
                  </select>
                  {formik.errors.endMonth ? (
                    <p style={{ color: 'red' }} className="mid">
                      {formik.errors.endMonth}
                    </p>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs={6} md={3}>
                  <label className="mid" htmlFor="endYear">
                    {translate('end_year')}
                  </label>
                  <select
                    id="endYear"
                    name="endYear"
                    className="form-select form-select-sm mb-3"
                    value={formik.values.endYear}
                    onChange={e => {
                      onEndYearChange(e);
                      formik.handleChange(e);
                    }}
                  >
                    <option value=""></option>
                    {strategyYears.map((year, index) => (
                      <option value={year} key={index}>
                        {year}
                      </option>
                    ))}
                  </select>
                  {formik.errors.endYear ? (
                    <p style={{ color: 'red' }} className="mid">
                      {formik.errors.endYear}
                    </p>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <hr className="my-2" />
            </>
          )}

          {isAutomated && (
            <>
              <Row className="mb-3" style={{ padding: '5px' }}>
                <Col xs={4}>
                  <label className="mid" htmlFor="returnCashflow">
                    {translate('return_cashflow_monthly')}
                  </label>
                  <input
                    autoComplete="off"
                    type="text"
                    className="form-control form-control-sm"
                    id="returnCashflow"
                    name="returnCashflow"
                    value={formatCurrency(formik.values.returnCashflow)}
                    onChange={e => {
                      let inputValue = Number(e.target.value.replaceAll(',', '') || 0);
                      if (Number.isNaN(inputValue)) return;
                      formik.setFieldValue('returnCashflow', inputValue);
                    }}
                  />
                  {formik.errors.returnCashflow ? (
                    <p style={{ color: 'red' }} className="mid">
                      {formik.errors.returnCashflow}
                    </p>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs={4}>
                  <label className="mid" htmlFor="cashflowAppreciation">
                    {translate('cashflow_appreciation')}
                  </label>

                  <PercentageInput
                    id={'cashflowAppreciation'}
                    size="sm"
                    onChange={onAutomatedGrowthRateChange}
                    value={
                      formik.values.cashflowAppreciation === 0
                        ? ''
                        : formatPercentage(formik.values.cashflowAppreciation)
                    }
                  />

                  {formik.errors.cashflowAppreciation ? (
                    <p style={{ color: 'red' }} className="mid">
                      {formik.errors.cashflowAppreciation}
                    </p>
                  ) : (
                    <></>
                  )}
                </Col>
                <Col xs={4}>
                  <label className="mid" htmlFor="taxOnCashflow">
                    {translate('tax_on_cashflow')}
                  </label>

                  <PercentageInput
                    id={'taxOnCashflow'}
                    size="sm"
                    onChange={onTaxOnCashflowChange}
                    value={formik.values.taxOnCashflow === 0 ? '' : formatPercentage(formik.values.taxOnCashflow)}
                  />

                  {formik.errors.taxOnCashflow ? (
                    <p style={{ color: 'red' }} className="mid">
                      {formik.errors.taxOnCashflow}
                    </p>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
              <hr className="my-2" />
            </>
          )}

          <CashflowChanges
            strategyYears={strategyYears}
            translate={translate}
            cashflowChanges={cashflowChanges}
            onCashflowChangeAddClick={onCashflowChangeAddClick}
            onCashflowChangeEditClick={onCashflowChangeEditClick}
            onCashflowChangeDeleteClick={onCashflowChangeDeleteClick}
          />

          <CashflowChangeAddEditModal
            show={cashflowChangeActionMeta !== null}
            onHide={() => setCashflowChangeActionMeta(null)}
            toBeEditedItem={cashflowChangeActionMeta?.item}
            addFormPrefill={cashflowChangeActionMeta?.addFormPrefill}
            showProgress={cashflowChangeActionMeta?.actionInProgress}
            addForm={getForm({ entityName: cashflowChangeActionMeta?.type, translate, strategyYears })}
            updateForm={getForm({
              entityName: cashflowChangeActionMeta?.type,
              type: 'Update',
              translate,
              strategyYears
            })}
            translate={translate}
          />
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-between align-items-center w-100">
            <Button
              className="px-2 py-0 mx-2 d-flex align-items-center"
              size="sm"
              variant="outline-dark"
              href="https://www.xn----1hcmgxnk8ede.co.il/"
              target="_blank"
            >
              מחשבון ברוטו- נטו
              <ArrowUpRightCircle size={13} className="mx-1" />
            </Button>
            <div className="d-flex align-items-center">
              <Button
                disabled={isSubmitting || externalSubmitting}
                size="sm"
                className="mr-2 text-white btn btn-success"
                onClick={() => {
                  formik.setFieldValue('updateGroup', false);
                  formik.submitForm();
                }}
              >
                {translate(isEdit ? 'update' : 'add')}
              </Button>

              <Button
                disabled={isSubmitting || externalSubmitting}
                size="sm"
                variant="success"
                className="mr-2 text-white"
                onClick={() => {
                  formik.setFieldValue('updateGroup', true);
                  formik.submitForm();
                }}
              >
                {translate(isEdit ? 'group_update' : 'group_add')}
              </Button>

              <Button
                disabled={isSubmitting || externalSubmitting}
                size="sm"
                variant="secondary"
                onClick={() => {
                  formik.resetForm();
                  hideDialog();
                }}
              >
                {translate('close')}
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CashFlowModal;
