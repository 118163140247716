import React from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';

const CompactBlockSelectInput = ({
  id,
  options,
  value,
  onChange,
  disabled = false,
  multiple = false,
  required = false,
  blockMaxWidth = null,
  isRtlLanguage
}) => {
  const onBlockClick = (e, option) => {
    if (disabled) return;

    let newValue;
    if (multiple) {
      newValue = Array.isArray(value) ? [...value] : [];
      if (newValue.includes(option)) {
        newValue.splice(
          newValue.findIndex(b => b === option),
          1
        );
      } else {
        newValue.push(option);
      }
    } else {
      newValue = value === option ? null : option;
    }
    onChange(newValue);
  };

  const isSelected = option => {
    return multiple ? value.includes(option) : value === option;
  };

  return (
    <ButtonGroup
      className={`d-flex align-items-center flex-wrap ${!blockMaxWidth && 'w-100'}  m-0 ${isRtlLanguage &&
        'flex-row-reverse'}`}
    >
      {options.map((option, index) => (
        <Button
          onClick={e => onBlockClick(e, option)}
          variant={isSelected(option) ? 'dark' : 'outline-secondary-dark'}
          className={`block ${!blockMaxWidth && 'flex-grow-1'} ${
            isSelected(option) ? 'text-white' : 'text-dark'
          } text-center no-float p-0 m-0`}
          key={index}
          style={
            blockMaxWidth && {
              width: blockMaxWidth
            }
          }
        >
          <p className={`mb-0 mid ${isSelected(option) && 'fw-bold'}`}>{option}</p>
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default CompactBlockSelectInput;
