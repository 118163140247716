import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import { getVariableFromCompTable } from '../../../../../calculators/helpers';
import LTRNumberCell from '../../../../../common/LTRNumberCell';
import {
  calculateAvailableLevel1Funds,
  calculateBalanceSheetWithChangedInterestRate,
  calculatePMTImpactForRateChanges,
  findWeakestMonthYearForInterestRateChanges,
  getNextNYear,
  getStrategyYears
} from '../helper';
import CashFlowValueLost from './CashFlowValueLost';
import WeakestMonthYear from './WeakestMonthYear';
import useAuth from '../../../../../../hooks/useAuth';
import { findIfUserIsInterestedInCourseAndOfSpecifiedLevel } from '../../../../../../helpers/global';

const extremeCaseInterestRate = 6;
const interestRateChanges = [-3, -2, -1, 0, 1, 2, 3, extremeCaseInterestRate];

const InterestRatesImpact = ({
  strategy,
  originalStrategy,
  armageddon,
  stressTestApplied,
  adminCompTable,
  customerCompTable
}) => {
  const { user } = useAuth();
  const [weakestMonthYear, setWeakestMonthYear] = useState();
  const [yearsToConsider, setYearsToConsider] = useState([]);
  const [pmtImpacts, setPMTImpacts] = useState([]);
  const [availableLevel1Funds, setAvailableLevel1Funds] = useState([]);
  const [sustainableMonths, setSustainableMonths] = useState([]);
  const { translate } = useContext(LocalizeContext);
  const strategyYears = useMemo(() => getStrategyYears(strategy), [strategy]);

  const isUserInterested = useMemo(() => findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]), [user]);

  const showExtremeCaseTableToClient = useMemo(() => adminCompTable?.appConfig?.showExtremeCaseTableToClient, [
    adminCompTable
  ]);

  const shouldHideExtremeCaseTableToClient = useMemo(() => isUserInterested || !showExtremeCaseTableToClient, [
    isUserInterested,
    showExtremeCaseTableToClient
  ]);

  const centralBankInterestRate = useMemo(() => getVariableFromCompTable(customerCompTable, 'A2'), [customerCompTable]);
  const findWeakestMonthYear = () => {
    const strategyWithExtremeCaseInterestRateChange = calculateBalanceSheetWithChangedInterestRate(
      originalStrategy,
      armageddon,
      stressTestApplied,
      translate,
      extremeCaseInterestRate,
      adminCompTable,
      customerCompTable
    );
    setWeakestMonthYear(
      findWeakestMonthYearForInterestRateChanges(strategy, strategyYears, strategyWithExtremeCaseInterestRateChange)
    );
  };

  useEffect(() => {
    findWeakestMonthYear();
  }, [strategy]);

  useEffect(() => {
    if (!weakestMonthYear) return;

    setYearsToConsider(getNextNYear(weakestMonthYear.year, 3));

    const pmtImpacts = calculatePMTImpactForRateChanges(
      strategy,
      weakestMonthYear,
      3,
      interestRateChanges.map(interestRateChange =>
        calculateBalanceSheetWithChangedInterestRate(
          originalStrategy,
          armageddon,
          stressTestApplied,
          translate,
          interestRateChange,
          adminCompTable,
          customerCompTable
        )
      )
    );
    setPMTImpacts(pmtImpacts);

    const availableLevel1Funds = calculateAvailableLevel1Funds(strategy, weakestMonthYear, 3);
    setAvailableLevel1Funds(availableLevel1Funds);

    const extremeCasePmtImpacts = pmtImpacts[interestRateChanges.indexOf(extremeCaseInterestRate)];

    setSustainableMonths(
      extremeCasePmtImpacts.map((impact, index) => {
        return impact === 0 ? '-' : Math.floor(availableLevel1Funds[index].after / impact);
      })
    );
  }, [weakestMonthYear]);

  return weakestMonthYear ? (
    <div className="mt-3">
      <WeakestMonthYear
        strategyYears={strategyYears}
        weakestMonthYear={weakestMonthYear}
        onYearChange={year => setWeakestMonthYear({ ...weakestMonthYear, year })}
        onMonthChange={month => setWeakestMonthYear({ ...weakestMonthYear, month })}
        onResetClick={() => findWeakestMonthYear()}
      />
      <Table responsive bordered className="smallFont mb-2">
        <thead>
          <tr className="bg-dark text-white">
            <th>{translate('interest_rate_changes')}</th>
            {yearsToConsider.map(year => (
              <th key={year}>{year}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {interestRateChanges.map((rateChanges, interestIndex) =>
            shouldHideExtremeCaseTableToClient && rateChanges === extremeCaseInterestRate ? null : (
              <React.Fragment key={rateChanges}>
                {rateChanges === extremeCaseInterestRate && (
                  <tr>
                    <td colSpan={yearsToConsider + 1} className="bg-danger text-white py-1">
                      <b>{translate('extreme_case')}</b>
                    </td>
                  </tr>
                )}
                <tr>
                  <td>
                    <b>
                      <LTRNumberCell
                        value={centralBankInterestRate + rateChanges}
                        formatType="percentage"
                        decimalNumber={2}
                      />
                    </b>
                  </td>
                  {yearsToConsider.map((year, yearIndex) => (
                    <td key={year}>
                      <LTRNumberCell value={pmtImpacts[interestIndex][yearIndex]} />
                    </td>
                  ))}
                </tr>
              </React.Fragment>
            )
          )}
          {!shouldHideExtremeCaseTableToClient && (
            <>
              {/* Available level 1 cash before crash */}
              <tr>
                <td>
                  <b>{translate('available_level1_funds_before_crash')}</b>
                </td>
                {availableLevel1Funds.map((funds, index) => (
                  <td key={index}>
                    <LTRNumberCell value={funds.before} />
                  </td>
                ))}
              </tr>
              {/* Available level 1 cash after crash */}
              <tr>
                <td>
                  <b>{translate('available_level1_funds_after_crash')}</b>
                </td>
                {availableLevel1Funds.map((funds, index) => (
                  <td key={index}>
                    <LTRNumberCell value={funds.after} />
                  </td>
                ))}
              </tr>
              {/* Sustainable Months */}
              <tr>
                <td>
                  <b>{translate('months_to_sustain_negative')}</b>
                </td>
                {sustainableMonths.map((months, index) => (
                  <td key={index}>{months}</td>
                ))}
              </tr>
            </>
          )}
        </tbody>
      </Table>
      {!isUserInterested && (
        <CashFlowValueLost adminCompTable={adminCompTable} weakestMonthYear={weakestMonthYear} strategy={strategy} />
      )}
    </div>
  ) : (
    <></>
  );
};

export default InterestRatesImpact;
