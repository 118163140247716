import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { X } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import { isRtl } from '../../../helpers/localization';

const SlidingSideBar = ({
  children,
  visible = false,
  title = '',
  showCloseButton = true,
  onClose,
  fullScreen,
  disableCloseButton,
  zIndex = 1002,
  className = '',
  placement = 'right', //left,
  smallScreenWidth = 100, // default value
  largeScreenWidth = 60 // default value
}) => {
  const { translate, langCode } = useContext(LocalizeContext);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const containerRef = useRef();

  const placementToUse = useMemo(() => (isRtl(langCode) ? (placement === 'right' ? 'left' : 'right') : placement), [
    placement,
    langCode
  ]);

  const Header = () => {
    return (
      <>
        <div className="d-flex align-items-center">
          <h5 className="flex-grow-1 mb-0 xlarge">{title}</h5>
          {showCloseButton && (
            <Button size="sm" className="close_button" onClick={onClose} variant="danger" disabled={disableCloseButton}>
              {translate('close')} <X size={20} className="align-text-top" />
            </Button>
          )}
        </div>
        <hr className="my-2" />
      </>
    );
  };

  //to prevent scrolling of content underneath, when sidebar is visible
  useEffect(() => {
    const isSidebarVisibleInDOM = () => {
      const isDisplayed = containerRef.current && window.getComputedStyle(containerRef.current).display !== 'none';
      return visible && isDisplayed;
    };

    if (isSidebarVisibleInDOM()) {
      document.body.classList.add('overflow-hidden');
    } else {
      document.body.classList.remove('overflow-hidden');
    }

    // Cleanup to ensure we remove the class when component is unmounted or if the sidebar is destroyed/hidden for some other reason
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, [visible]);

  useEffect(() => {
    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const currentScreenWidth = fullScreen ? 100 : windowWidth < 480 ? smallScreenWidth : largeScreenWidth;

  return (
    <div
      ref={containerRef}
      style={{ zIndex }}
      className={`bar-container ${visible ? 'bar-visible' : 'bar-collapsed'} bar-${placementToUse} ${className}`}
    >
      <div
        style={{
          width: `${currentScreenWidth}%`
        }}
        className={`bar-content d-flex h-100 flex-column`}
      >
        {(title || showCloseButton) && <Header />}
        <div id="bar-body" className="flex-grow-1">
          {children}
        </div>
      </div>
    </div>
  );
};

export default SlidingSideBar;
