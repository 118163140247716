import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { ArrowClockwise } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import { months } from '../../../../../../helpers/constants';
import SmallDropdownToggle from '../../../../../common/SmallDropdownToggle';

const WeakestMonthYear = ({ strategyYears, weakestMonthYear, onYearChange, onMonthChange, onResetClick }) => {
  const { translate } = useContext(LocalizeContext);

  return (
    <>
      <h6 className="d-inline-block ms-2">{translate('the_weakest_month_and_year')}</h6>
      <Dropdown className="d-inline-block ms-1">
        <SmallDropdownToggle variant="primary">{months[weakestMonthYear.month]}</SmallDropdownToggle>

        <Dropdown.Menu>
          {months.map((m, index) => (
            <Dropdown.Item onClick={() => onMonthChange(index)}>{m}</Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="d-inline-block">
        <SmallDropdownToggle variant="primary">{weakestMonthYear.year}</SmallDropdownToggle>
        <Dropdown.Menu>
          {strategyYears.map(y => (
            <Dropdown.Item onClick={() => onYearChange(y)}>{y}</Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>

      <ArrowClockwise className="me-2 hover-light" size={14} onClick={onResetClick} />
    </>
  );
};

export default WeakestMonthYear;
