import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Dropdown, FormCheck, Row } from 'react-bootstrap';
import { ChevronLeft, ChevronRight } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import SmallDropdownToggle from '../../../../common/SmallDropdownToggle';
import { isRtl } from '../../../../../helpers/localization';

const FloatingStrategyNavigator = ({
  showStressTestAndArmaggedonToClient,
  selectedStrategy,
  onNextStrategyClick,
  onPrevStrategyClick,
  activeScenario,
  onActiveScenarioChange,
  armageddonDetails,
  stressTestApplied,
  onStressTestAppliedChange,
  onArmagedonClick,
  scenarioOptions
}) => {
  const { translate, langCode } = useContext(LocalizeContext);
  const [showNavigator, setShowNavigator] = useState(false);

  // For calculating scroll width
  useEffect(() => {
    function isElementVisible(element) {
      var rect = element.getBoundingClientRect();
      var windowHeight = window.innerHeight || document.documentElement.clientHeight;
      var windowWidth = window.innerWidth || document.documentElement.clientWidth;

      // Check if the element is inside the viewport
      var isVisible = rect.bottom >= 0 && rect.left >= 0 && rect.right <= windowWidth;
      return isVisible;
    }

    const parentElement = document;

    const listener = () => {
      setShowNavigator(!isElementVisible(document.getElementById('top-strategy-navigator')));
    };

    parentElement.addEventListener('scroll', listener);
    return () => parentElement.removeEventListener('scroll', listener);
  }, []);

  const isRTL = useMemo(() => isRtl(langCode), [langCode]);
  const position = useMemo(() => (isRTL ? { bottom: 0, right: 0 } : { bottom: 0, left: 0 }), [isRTL]);

  if (!showNavigator) return '';

  return (
    <div className="w-100 p-3 mb-1" style={{ position: 'fixed', zIndex: 1000, ...position }}>
      <Card className=" fade-in-quick">
        <Card.Body className="px-2 py-1 ">
          <Row>
            <Col className="mb-1 mb-lg-0" xs={12} md={12} lg={4}>
              {/* navigation */}
              <div className="d-flex">
                <div>
                  {isRTL ? (
                    <ChevronRight className="hover-light fw-bold" size={12} onClick={onPrevStrategyClick} />
                  ) : (
                    <ChevronLeft className="hover-light fw-bold" size={12} onClick={onPrevStrategyClick} />
                  )}
                </div>
                <div
                  className="flex-grow-1 text-center"
                  style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                >
                  <b className="mx-2 smallFont">{selectedStrategy?.name}</b>
                </div>

                <div>
                  {isRTL ? (
                    <ChevronLeft className="hover-light fw-bold" size={12} onClick={onNextStrategyClick} />
                  ) : (
                    <ChevronRight className="hover-light fw-bold" size={12} onClick={onNextStrategyClick} />
                  )}
                </div>
              </div>
              <hr className="my-1 d-lg-none" />
            </Col>

            <Col className="mb-1 mb-sm-0" xs={12} sm={6} lg={4}>
              {/* scenarios */}
              <div className="d-inline-block">
                <h6 className="d-inline-block mb-0 smallFont fw-bold text-muted">{translate('current_scenario')}: </h6>
                <Dropdown className="d-inline-block mx-1">
                  <SmallDropdownToggle variant="primary" style={{ fontSize: 12 }}>
                    {activeScenario.name}
                  </SmallDropdownToggle>
                  <Dropdown.Menu>
                    {scenarioOptions.map(s => (
                      <Dropdown.Item key={s.name} onClick={() => onActiveScenarioChange(s)}>
                        {s.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              {/* configure */}
              {activeScenario.isArmageddon && armageddonDetails && (
                <div className="d-inline-block mx-1">
                  <Button
                    style={{
                      padding: '0px 5px',
                      fontSize: 13
                    }}
                    size="sm"
                    variant="danger"
                    onClick={onArmagedonClick}
                  >
                    {translate('configure')}
                  </Button>
                </div>
              )}
            </Col>
            <Col xs={12} sm={6} lg={4}>
              {/* stress test */}
              {showStressTestAndArmaggedonToClient && (
                <FormCheck
                  onChange={e => onStressTestAppliedChange(e.target.checked)}
                  checked={stressTestApplied}
                  className="mid"
                  inline
                  label={translate('apply_stress_test')}
                />
              )}
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </div>
  );
};

export default FloatingStrategyNavigator;
