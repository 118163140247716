import React, { useContext } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import SlidingSideBar from '../../../../common/SlidingSideBar/SlidingSideBar';
import CemeteryButton from './CemeteryButton';

const Cemetry = ({ strategies = [], onStrategyClick, onRetreiveClick, onEditClick, onDeleteClick, translate }) => {
  return (
    <div className="p-3">
      <Card>
        {strategies.length > 0 ? (
          <>
            <Card.Header className="bg-dark">
              <h5 className="mid text-white text-center fw-bold mb-0">{translate('strategies_archived')}</h5>
            </Card.Header>
            <Card.Body className="bg-light pt-1">
              <Row>
                {strategies.map(strategy => (
                  <Col xs={12} md={6} key={strategy._id} className="mt-3">
                    <CemeteryButton
                      strategy={strategy}
                      onEditClick={onEditClick}
                      onDeleteClick={onDeleteClick}
                      onStrategyClick={onStrategyClick}
                      onRetreiveClick={onRetreiveClick}
                    />
                  </Col>
                ))}
              </Row>
            </Card.Body>
          </>
        ) : (
          <Card.Body>
            <h5 className="mid text-muted text-center p-5">{translate('nothing_to_show')}</h5>
          </Card.Body>
        )}
      </Card>
    </div>
  );
};

const CemetrySidebar = ({ strategies, show, onHide, onRetreiveClick, onEditClick, onDeleteClick, onStrategyClick }) => {
  const { translate } = useContext(LocalizeContext);
  return (
    <SlidingSideBar
      zIndex={1002}
      visible={show}
      onClose={onHide}
      showCloseButton
      title={`${translate('strategy_archive')}`}
    >
      {show && (
        <Cemetry
          strategies={strategies}
          onRetreiveClick={onRetreiveClick}
          onEditClick={onEditClick}
          onDeleteClick={onDeleteClick}
          onStrategyClick={onStrategyClick}
          translate={translate}
        />
      )}
    </SlidingSideBar>
  );
};

export default CemetrySidebar;
