import moment from 'moment';
import React, { useContext, useMemo, useState, useEffect } from 'react';
import { Exclamation, ExclamationTriangle, FileEarmark, Folder, Folder2, Trash } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import FilePopup from './FilePopup';
import SlidingSideBar from './SlidingSideBar/SlidingSideBar';
import CircularProgressBar from './circular-progress';
import { Alert, Button } from 'react-bootstrap';
import UnderlineButton from './UnderlineButton';
import {  isSystemUser } from '../../helpers/global';
import { UserContext } from '../../context/UserContext';
import { toast } from 'react-toastify';
import File from './file';
import HorizontalProgress from './HorizontalProgress';

const FileRow = ({ file, onFileClick, onFileDeleteClick }) => {
  return (
    <div onClick={onFileClick} className="p-1 hover-light">
      <div className="d-flex">
        <div className="flex-grow-1 mid">
          <FileEarmark className="mr-2" />
          <span className="align-middle">
            {file.metadata && file.metadata.originalName ? file.metadata.originalName : file.filename}
          </span>
        </div>
        {onFileDeleteClick && (
          <div
            onClick={e => {
              e.stopPropagation();
              onFileDeleteClick();
            }}
            className="mx-1 hover-dark"
          >
            <Trash className="text-danger" />
          </div>
        )}
      </div>
      <h6 className="smallFont pl-4 mb-0 text-muted">{moment(file['uploadDate']).format('MMMM Do, YYYY hh:mm a')}</h6>
      <hr className="my-2" />
    </div>
  );
};

const DriveFileRow = ({ file }) => {
  return (
    <a style={{ textDecoration: 'none' }} href={file.url} target="_blank">
      <div className="p-1 text-dark hover-light">
        <div className="d-flex">
          <div className="flex-grow-1 mid">
            <FileEarmark className="mr-2" />
            <span className="align-middle">{file.fileName}</span>
          </div>
        </div>
        <hr className="my-2" />
      </div>
    </a>
  );
};

const FileAndFolders = ({ filesAndFolders }) => {
  return (
    <>
      {filesAndFolders.files?.length > 0 ? (
        filesAndFolders.files?.map(f => <DriveFileRow key={f._id} file={f} />)
      ) : (
        <p className="p-2 mb-0 text-center">No files to show!</p>
      )}
    </>
  );
};

const DriveFileReader = ({ driveFolder, show }) => {
  const [files, setFiles] = useState();
  const [fileLoading, setFileLoading] = useState(true);

  const getFiles = async () => {
    setFileLoading(true);
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.GET_DRIVE_FILES(driveFolder),
      method: 'GET'
    });

    setFileLoading(false);

    if (error) {
      return toast.error(error);
    }

    setFiles(response);
  };

  useEffect(() => {
    if (!show || !driveFolder) {
      setFiles([]);
      return;
    }

    getFiles();
  }, [driveFolder, show]);

  return (
    <>
      <div>
        {fileLoading ? (
          <div className="p-3 text-center">
            <CircularProgressBar size={2} />
          </div>
        ) : (
          <FileAndFolders filesAndFolders={files} />
        )}
      </div>
    </>
  );
};

const FileViewer = ({
  driveFolder,
  customerForThisDriveFolder,
  onCustomerUpdate,
  files = [],
  show,
  onHide,
  sidebarZIndex,
  onFileDeleteClick,
  title,
  withFileUploader,
  showProgress,
  uploadedFiles,
  onFilesUpload,
  onFileSaveClick
}) => {
  const [visibleFile, setVisibleFile] = useState();
  const [creatingDriveFolder, setCreatingDriveFolder] = useState(false);
  const reversedFiles = useMemo(() => [...files].reverse(), [files]);
  const { translate } = useContext(LocalizeContext);
  const { user } = useContext(UserContext);

  const onNextFileClick = () => {
    const currentIndex = reversedFiles.findIndex(f => f._id === visibleFile._id);
    if (currentIndex + 1 !== reversedFiles.length) {
      setVisibleFile(reversedFiles[currentIndex + 1]);
    }
  };
  const onPrevFileClick = () => {
    const currentIndex = reversedFiles.findIndex(f => f._id === visibleFile._id);
    if (currentIndex > 0) {
      setVisibleFile(reversedFiles[currentIndex - 1]);
    }
  };

  const createDriveFolder = async () => {
    setCreatingDriveFolder(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_CREATE_DRIVE_FOLDER,
      requestBody: { _id: customerForThisDriveFolder._id }
    });

    setCreatingDriveFolder(false);
    if (error) {
      toast.error(error);
      return;
    }

    toast.success('Drive Folder created successfully!');

    onCustomerUpdate(response);
  };

  return (
    <>
      <SlidingSideBar zIndex={sidebarZIndex} visible={show} onClose={onHide} title={title || translate('view_files')}>
        {driveFolder && (
          <>
            <h6 className="mt-2 fw-bold midFont d-inline-block">{translate('files_from_drive')}</h6>
            <a target="_blank" href={`https://drive.google.com/drive/folders/${driveFolder}`}>
              <UnderlineButton Icon={Folder2} text={translate('open_drive_folder')} />
            </a>
            <DriveFileReader show={show} driveFolder={driveFolder} />
            <h6 className="fw-bold mt-3 midFont">{translate('other_files')}</h6>
          </>
        )}
        {isSystemUser(user?.role) && !driveFolder && customerForThisDriveFolder && (
          <Alert className="text-center">
            {creatingDriveFolder ? (
              <>
                <CircularProgressBar size={2} />
              </>
            ) : (
              <UnderlineButton
                fontSize="smallFont"
                iconSize={14}
                Icon={ExclamationTriangle}
                text={translate('drive_folder_not_created')}
                onClick={createDriveFolder}
              />
            )}
          </Alert>
        )}
        {withFileUploader && (
          <>
            {show && (
              <File uploadedFiles={uploadedFiles} onUploadedFilesChange={onFilesUpload} containerClassName="mx-3" />
            )}
            {showProgress && <HorizontalProgress text={'Saving files...'} />}
            <div className="text-end">
              <Button
                disabled={showProgress}
                onClick={onFileSaveClick}
                variant="success"
                size="sm"
                className="mx-1 text-white"
              >
                {translate('upload')}
              </Button>
            </div>
            <hr />
          </>
        )}
        {reversedFiles.length > 0 ? (
          reversedFiles.map(f => (
            <FileRow
              key={f._id}
              file={f}
              onFileClick={() => setVisibleFile(f)}
              onFileDeleteClick={onFileDeleteClick && (() => onFileDeleteClick(f))}
            />
          ))
        ) : (
          <p className="p-5 text-center">{translate('no_files_to_show')}</p>
        )}
      </SlidingSideBar>
      {visibleFile && (
        <FilePopup
          file={visibleFile}
          onHide={() => setVisibleFile()}
          showNext={reversedFiles.length !== reversedFiles.findIndex(f => f._id === visibleFile._id) + 1}
          onNextClick={onNextFileClick}
          showPrev={reversedFiles.length > 0 && reversedFiles.findIndex(f => f._id === visibleFile._id) > 0}
          onPrevFileClick={onPrevFileClick}
          translate={translate}
        />
      )}
    </>
  );
};

export default FileViewer;
