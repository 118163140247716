import React, { useContext, useEffect, useState } from 'react';
import { Button, Card, Col, Form, Row } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import { makeApiRequests } from '../../../helpers/api';
import { ENDPOINTS } from '../../../helpers/constants';
import { toast } from 'react-toastify';
import useAuth from '../../../hooks/useAuth';

const AppConfiguration = ({ comp, onCompUpdate, readOnlyMode }) => {
  const { translate } = useContext(LocalizeContext);
  const [updatingComp, setUpdatingComp] = useState(false);

  const [editingAppConfig, setEditingAppConfig] = useState(comp.appConfig);

  useEffect(() => {
    setEditingAppConfig(comp.appConfig);
  }, [comp]);

  const onAppConfigSubmit = async () => {
    setUpdatingComp(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.COMPS_WITH_ID(comp._id),
      method: 'PUT',
      requestBody: {
        ...comp,
        appConfig: editingAppConfig
      }
    });

    setUpdatingComp(false);
    if (error) {
      toast.error(error);
      return;
    }

    if (!comp.customer && response) localStorage.setItem('comps', JSON.stringify(response));

    toast.success('Info updated succesfully!');
    onCompUpdate(response);
  };

  return (
    <Card className="my-3">
      <Card.Header>{translate('app_configuration')}</Card.Header>
      <Card.Body className="p-3">
        <Row>
          {[
            { title: 'show_stress_test_to_client', key: 'showStressTestToClient' },
            { title: 'show_liqudation_table_to_client', key: 'showLiquidationTableToClient' },
            { title: 'show_extreme_case_to_client', key: 'showExtremeCaseTableToClient' },
            { title: 'show_cashflow_impact_to_client', key: 'showCashflowImpactToClient' },
            { title: 'show_cushion_to_client', key: 'showCushionsTableToClient' },
            { title: 'show_archived_to_client', key: 'showArchivedToClient' },
          ].map(({ title, key }) => (
            <Col xs={12} md={4}>
              <div key={key} className="mb-1">
                <Form.Check
                  type={'checkbox'}
                  label={translate(title)}
                  disabled={readOnlyMode}
                  checked={editingAppConfig[key]}
                  onChange={e => setEditingAppConfig({ ...editingAppConfig, [key]: e.target.checked })}
                />
              </div>
            </Col>
          ))}
        </Row>
        {!readOnlyMode && (
          <div className="text-end mt-2">
            <Button
              className="text-white"
              size="sm"
              variant="success"
              disabled={updatingComp}
              onClick={onAppConfigSubmit}
            >
              {translate('save')}
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default AppConfiguration;
