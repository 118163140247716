import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Table } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import { stockTypeOptions } from '../../../../../../helpers/constants';
import LTRNumberCell from '../../../../../common/LTRNumberCell';
import {
  calculateMarginCallForStocks,
  findLinkedLiabilities,
  findWeakestMonthYearOfMargin,
  getStrategyYears
} from '../helper';
import CushionTable from './CushionTable';
import WeakestMonthYear from './WeakestMonthYear';

const percentageDecreaseToConsider = [0, 10, 20, 30, 40, 50, 60, 70, 80];

const MarginCall = ({ adminCompTable, strategy }) => {
  const [marginCallStocks, setMarginCallStocks] = useState([]);
  const [marginCallTableValues, setMarginCallTableValues] = useState([]);
  const { translate, langCode } = useContext(LocalizeContext);

  const loanToAssetHeader = useMemo(() => translate('loan_to_asset'), [langCode]);
  const strategyYears = useMemo(() => getStrategyYears(strategy), [strategy]);

  const showCushionsTableToClient = useMemo(() => adminCompTable?.appConfig?.showCushionsTableToClient, [
    adminCompTable
  ]);

  useEffect(() => {
    const { assets, liabilities } = strategy;

    const stocksToConsider = assets.filter(a => {
      if (a.category !== 'Stocks') return;
      //only take care of stocks which have loans to them
      const linkedLiabilities = findLinkedLiabilities(
        liabilities.filter(l => !l.isInvalid),
        a
      );
      return linkedLiabilities.length > 0;
    });

    setMarginCallStocks(
      stocksToConsider
        .flatMap(s => {
          const linkedLiabilities = findLinkedLiabilities(
            liabilities.filter(l => !l.isInvalid),
            s
          );

          return linkedLiabilities.map(l => ({
            stock: s,
            weakestMonthYear: findWeakestMonthYearOfMargin(strategy, s, l),
            linkedLiability: l
          }));
        })
        .filter(s => s.weakestMonthYear)
    );
  }, [strategy]);

  useEffect(() => {
    setMarginCallTableValues(calculateMarginCallForStocks(strategy, marginCallStocks, percentageDecreaseToConsider));
  }, [marginCallStocks]);

  return (
    <div>
      <h6>
        <b>{translate('margin_call')}</b>
      </h6>
      {marginCallTableValues.length > 0 &&
      marginCallStocks.length > 0 &&
      marginCallTableValues.length === marginCallStocks.length ? (
        marginCallTableValues.map(
          (
            {
              name,
              stockType,
              stockValue,
              loanValue,
              loanToEquity,
              assetsDecreaseValues = [],
              maximalDrawdown,
              cushionValues
            },
            tableIndex
          ) => (
            <div className="p-2 rounded border mt-3">
              <h6 className="large">
                <b>
                  {name}:{marginCallStocks[tableIndex].linkedLiability.name}{' '}
                  <span className="text-muted">({stockType || stockTypeOptions[0]})</span>
                </b>
              </h6>
              <hr className="my-2" />
              <WeakestMonthYear
                strategyYears={strategyYears}
                weakestMonthYear={marginCallStocks[tableIndex].weakestMonthYear}
                onYearChange={year =>
                  setMarginCallStocks(
                    marginCallStocks.map((s, stockIndex) =>
                      stockIndex !== tableIndex ? s : { ...s, weakestMonthYear: { ...s.weakestMonthYear, year } }
                    )
                  )
                }
                onMonthChange={month =>
                  setMarginCallStocks(
                    marginCallStocks.map((s, stockIndex) =>
                      stockIndex !== tableIndex ? s : { ...s, weakestMonthYear: { ...s.weakestMonthYear, month } }
                    )
                  )
                }
                onResetClick={() =>
                  setMarginCallStocks(
                    marginCallStocks.map((s, stockIndex) =>
                      stockIndex !== tableIndex
                        ? s
                        : { ...s, weakestMonthYear: findWeakestMonthYearOfMargin(strategy, s.stock) }
                    )
                  )
                }
              />
              {[
                { label: translate('initial_value'), value: stockValue },
                { label: translate('loan_value'), value: loanValue },
                { label: translate('loan_to_asset'), value: loanToEquity }
              ].map(({ label, value }) => (
                <h6 key={label} className="mid mt-2">
                  {label}:
                  <b>
                    <LTRNumberCell value={value} formatType={label === loanToAssetHeader ? 'percentage' : 'currency'} />
                  </b>
                </h6>
              ))}
              <Table responsive bordered className="smallFont mb-2">
                <thead>
                  <tr className="bg-dark text-white">
                    {(stockType === stockTypeOptions[1]
                      ? [
                          translate('assets_decrease'),
                          translate('assets'),
                          translate('equity'),
                          translate('equity_to_assets')
                        ]
                      : [translate('assets_decrease'), translate('assets')]
                    ).map(h => (
                      <th key={h}>{h}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {assetsDecreaseValues.map(({ percentage, portfolioValue, equityValue, equityToPortfolio }) => (
                    <tr key={percentage}>
                      <td>
                        <LTRNumberCell value={percentage} formatType="percentage" decimalNumber={0} />
                      </td>
                      <td>
                        <LTRNumberCell value={portfolioValue} />
                      </td>
                      {stockType === stockTypeOptions[1] && (
                        <>
                          <td>
                            <LTRNumberCell value={equityValue} />
                          </td>
                          <td>
                            <LTRNumberCell value={equityToPortfolio} formatType="percentage" />
                          </td>
                        </>
                      )}
                    </tr>
                  ))}
                  <tr className="bg-dark text-white">
                    <td colSpan={stockType === stockTypeOptions[1] ? 3 : 1} className="text-start">
                      <b>{translate('maximal_drawdown')}</b>
                    </td>
                    <td>
                      <b>
                        <LTRNumberCell value={maximalDrawdown} formatType="percentage" />
                      </b>
                    </td>
                  </tr>
                </tbody>
              </Table>
              {showCushionsTableToClient && <CushionTable cushionValues={cushionValues} />}
            </div>
          )
        )
      ) : (
        <h6 className="text-center p-5 text-muted">{translate('no_stocks_with_linked_liabilities')}</h6>
      )}
    </div>
  );
};

export default MarginCall;
