import React from 'react';
import { Button } from 'react-bootstrap';

const FormGeneratorButton = ({
  id,
  disabled,
  onClick,
  title,
  variant = 'primary',
  size = 'sm',
  className = 'px-1 py-0'
}) => {
  return (
    <Button
      id={id}
      disabled={disabled}
      className={className}
      variant={variant}
      size={size}
      onClick={e => {
        if (onClick && window[onClick]) {
          window[onClick](e);
        }
      }}
    >
      {title}
    </Button>
  );
};

export default FormGeneratorButton;
