import React from 'react';
import { ArrowLeftRight } from 'react-bootstrap-icons';
import { CurrencyInput } from '../../../../../form-generator/components';
import PercentageInput from '../../../../../form-generator/components/PercentageInput';
import { formatCurrency, formatPercentage } from './helper';

const BuyingTax = ({
  buyingTax,
  totalTax,
  totalTaxPercentage,
  onTotalTaxChange,
  onTotalTaxPercentageChange,
  disabled,
  isApartmentAbroad,
  isTaxCalculatedAutomatically,
  onAutoCalculateModeChange
}) => {
  return (
    <>
      <div className="mt-1 d-flex align-items-center">
        <div className="flex-grow-1">
          <h6 className="mid mb-0">Buying Tax:</h6>
        </div>
        {!isApartmentAbroad && (
          <div>
            <h6 className="a tiny text-primary hover mb-0" onClick={onAutoCalculateModeChange}>
              <ArrowLeftRight className="mx-1" />
              Switch to <b>{isTaxCalculatedAutomatically ? 'Manual' : 'Auto'}</b> mode
            </h6>
          </div>
        )}
      </div>
      <hr className="my-2" />
      <div className="px-2">
        <div>
          {buyingTax.map(t => (
            <div>
              <h6 className="mb-0  smallFont text-dark text-end">
                Upto {formatCurrency(t.to)}(<span>{t.percentage}%</span>)
              </h6>

              <h6 className="text-end text-muted smallFont mt-2">
                <b>={formatCurrency(t.actualTax)}</b>
              </h6>
            </div>
          ))}
        </div>

        <div className="smallFont mb-3 d-flex">
          <div className={isApartmentAbroad ? 'w-50' : 'w-100'}>
            <b>Total Tax: </b>
            <CurrencyInput
              id={'totalBuyingTax'}
              className="d-inline-block p-1 py-0"
              style={{ minHeight: 10, fontSize: 13, width: 140 }}
              size="sm"
              type="number"
              disabled={disabled || (!isApartmentAbroad && isTaxCalculatedAutomatically)}
              onChange={e => e && onTotalTaxChange(e.target.value ? Number(e.target.value.replaceAll(',', '')) : 0)}
              value={disabled || totalTax === 0 ? '' : formatCurrency(totalTax)}
            />
          </div>
          {isApartmentAbroad && (
            <div className="mx-2 w-50">
              <b>Tax (%): </b>
              <PercentageInput
                id={'totalBuyingTaxInPercentage'}
                className="d-inline-block p-1 py-0"
                isControlled
                size="sm"
                type="number"
                disabled={disabled}
                onChange={e => {
                  e && onTotalTaxPercentageChange(e.target.value ? Number(e.target.value.replaceAll('%', '')) : 0);
                }}
                value={formatPercentage(totalTaxPercentage, false, true, 2)}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BuyingTax;
