import React from 'react';
import SlidingSideBar from '../../../common/SlidingSideBar/SlidingSideBar';
import CustomerGeneralInfo from './strategies/CustomerGeneralInfo';

const CustomerGeneralInfoSidebar = ({ customer, show, onHide, editable, onCustomerUpdate, translate }) => (
  <SlidingSideBar
    zIndex={1002}
    visible={show}
    onClose={onHide}
    showCloseButton
    fullScreen
    title={`${translate('general_information')} (${customer?.name || translate('no_name')})`}
  >
    {customer && show && (
      <CustomerGeneralInfo editable={editable} customer={customer} onCustomerUpdate={onCustomerUpdate} />
    )}
  </SlidingSideBar>
);

export default CustomerGeneralInfoSidebar;
