import React, { useContext, useEffect } from 'react';
import { normalizeId } from '../helpers/utility';
import { LocalizeContext } from 'react-locale-language';
import { snakeCase } from 'lodash';

const SwitchInput = ({
  id,
  title,
  groupName = '',
  defaultChecked,
  onChange,
  onChangeFunction,
  value,
  showLabel = false,
  className = '',
  label = '',
  required = false,
  preValue,
  onFormChange,
  disabled = false,
  boldLabel = false,
  size = 'lg',
  checked,
  dependentElems = [],
  showDependentOn = true
}) => {
  useEffect(() => {
    internalOnChange(null, false);
  }, []);

  const showHideDependentElement = element => {
    const elementContainer = document.getElementById(`${element.id}-col-container`);
    if (document.getElementById(id).checked === showDependentOn) {
      elementContainer.classList.remove('d-none');
      if (element.type !== 'checkbox') element.required = true;
    } else {
      elementContainer.classList.add('d-none');
      element.required = null;
    }
  };

  const internalOnChange = (e, checkForm = true) => {
    //run internal functions here
    if (checkForm && onFormChange) onFormChange();

    //run internal functions here
    if (dependentElems.length > 0) {
      dependentElems
        .map(dependentElem => document.getElementById(dependentElem))
        .forEach(dependentElement => {
          dependentElement && showHideDependentElement(dependentElement);
        });
    }

    if (onChange && window[onChange]) {
      window[onChange](e);
    }
    if (onChangeFunction) {
      onChangeFunction(e);
    }
  };

  const inputId = id || normalizeId(groupName + '-' + (label || title));
  return (
    <label className={'switch ' + className}>
      <input
        autoComplete="off"
        id={inputId}
        type="checkbox"
        name={groupName}
        defaultChecked={
          checked === undefined ? (preValue === null || preValue === undefined ? defaultChecked : preValue) : undefined
        }
        checked={checked !== undefined ? checked : undefined}
        onChange={internalOnChange}
        value={value || label}
        required={required}
        disabled={disabled}
      />
      <span class="slider round"></span>
    </label>
  );
};

const styles = {
  checkbox: {
    height: 22,
    width: '100%'
  }
};

export default SwitchInput;
