import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Container, Modal, ProgressBar } from 'react-bootstrap';
import { ArrowClockwise } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import { toast } from 'react-toastify';
import FormGenerator from '../../../form-generator/FormGenerator';
import { highlightError } from '../../../form-generator/helpers/utility';
import { makeApiRequests } from '../../../helpers/api';
import {
  ENDPOINTS,
  fieldsWithFormulas,
  getPresetTableHeaders,
  getTaxBracketFields,
  getVariableTableHeaders
} from '../../../helpers/constants';
import { checkIfUserCanEdit, evalFormula } from '../../../helpers/global';
import AlertModal from '../../common/AlertModal';
import Loader from '../../common/Loader';
import { addOrUpdateObjectInArray } from '../manage-users/customer/strategies/helper';
import Presets from './Presets';
import TaxBrackets from './TaxBrackets';
import Variables from './Variables';
import CompGeneralInfo from './CompGeneralInfo';
import AppConfiguration from './AppConfiguration';
import AppVideos from './AppVideos';
import useAuth from '../../../hooks/useAuth';

const getRows = (headers = [], excludedFields = []) => [
  {
    columns: headers
      .filter(h => !excludedFields.includes(h.key))
      .map(
        ({ colWidth = 6, name, formTitle, titleKey, key: id, type = 'text', formType, required = false, ...rest }) => ({
          default: colWidth,
          field: {
            title: formTitle || name,
            id,
            titleKey,
            key: id,
            type: formType || (type === 'boolean' ? 'checkbox' : type),
            required,
            ...rest
          }
        })
      )
  }
];

const getForm = ({ entityName, type = 'Add', translate }) => ({
  forms: [
    {
      name: `${type} ${entityName?.toLowerCase()}`,
      markCompulsoryFields: true,
      hideFormName: true,
      compact: true,
      submit: {
        name: type,
        show: true,
        onSubmit: `on${entityName}FormSubmit`
      },
      rows: entityName
        ? getRows(
            entityName === 'Variable'
              ? getVariableTableHeaders(translate)
              : entityName === 'TaxBrackets'
              ? getTaxBracketFields(translate)
              : getPresetTableHeaders(translate)
          )
        : []
    }
  ]
});

const checkValidityOfPreset = (preset, variables) => {
  for (const field of fieldsWithFormulas) {
    const evaluatedValue = preset[field] ? evalFormula(preset[field], variables) : 0;
    if (evaluatedValue === undefined || isNaN(evaluatedValue)) return field;
  }
};

const checkValidityOfBrackets = (newBrackets, bracket) => {
  if (bracket.to === 0 || bracket.to <= (bracket.from || 0)) {
    return {
      field: 'from',
      msg: 'Start must be less than end'
    };
  }

  /*   const isContinuos = newBrackets.every(({ from, to }, index) => index === 0 || from === newBrackets[index - 1].to);
  if (!isContinuos) {
    return {
      field: 'from',
      msg: 'Brackets must be continous.'
    };
  } */
};

const AddEditModal = ({
  show,
  addFormPrefill,
  toBeEditedItem,
  onHide,
  showProgress,
  addForm,
  updateForm,
  entityName = '',
  translate
}) => (
  <Modal size={'lg'} show={show} onHide={onHide} centered backdrop="static">
    <Modal.Header closeButton={!showProgress}>
      <Modal.Title>
        <h6 className="mb-0">
          {toBeEditedItem
            ? translate(`update_${entityName.toLowerCase()}`)
            : translate(`add_new_${entityName.toLowerCase()}`)}
        </h6>
      </Modal.Title>
    </Modal.Header>
    <Modal.Body className="overflow-auto px-1">
      <FormGenerator
        formJson={toBeEditedItem ? updateForm : addForm}
        formValues={
          toBeEditedItem
            ? { [`Update ${entityName?.toLowerCase()}`]: toBeEditedItem }
            : { [`Add ${entityName?.toLowerCase()}`]: addFormPrefill }
        }
      />
      {showProgress && (
        <ProgressBar
          now={100}
          animated
          striped
          variant="dark"
          label={
            toBeEditedItem
              ? `${translate(`updating_${entityName?.toLowerCase()}`)}...`
              : `${translate(`saving_${entityName?.toLowerCase()}`)}...`
          }
        />
      )}
    </Modal.Body>
  </Modal>
);

const Actions = ({ onResetClick, translate }) => (
  <div className="my-2">
    <Button variant="outline-primary" size="sm" className="px-1 py-0 ms-2" onClick={onResetClick}>
      <ArrowClockwise size={10} />
      <span className="align-middle me-2">{translate('reset_to_default')}</span>
    </Button>
  </div>
);

const CompTable = ({ compId = null, onCompUpdate }) => {
  const { user } = useAuth();
  const [compData, setCompData] = useState();
  const [loading, setLoading] = useState(true);
  const [deleteMeta, setDeleteMeta] = useState(null);
  const [resetMeta, setResetMeta] = useState(null);
  const [itemActionMeta, setItemActionMeta] = useState(null);
  const { translate } = useContext(LocalizeContext);

  const isAdminComp = useMemo(() => compId === null, [compId]);
  const readOnlyMode = useMemo(() => !checkIfUserCanEdit(user), [user]);
  const fetchCompData = async () => {
    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.COMPS_LIST,
      requestBody: {
        filter: !isAdminComp ? { _id: compId } : { customer: false }
      },
    });
    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    setCompData(response[0]);
    if (isAdminComp) localStorage.setItem('comps', JSON.stringify(response[0]));
  };

  useEffect(() => {
    fetchCompData();
  }, []);

  const onVariableAddClick = () => {
    setItemActionMeta({ type: 'Variable' });
  };
  const onVariableEditClick = variable => {
    setItemActionMeta({ type: 'Variable', item: variable });
  };
  const onVariableDeleteClick = variable => {
    setDeleteMeta({ type: 'Variable', item: variable });
  };

  const onPresetAddClick = filterCategoriesForPresets => {
    setItemActionMeta({ type: 'Preset', addFormPrefill: { assetType: filterCategoriesForPresets[0] } });
  };

  const onPresetEditClick = preset => {
    setItemActionMeta({ type: 'Preset', item: preset });
  };
  const onPresetDeleteClick = preset => {
    setDeleteMeta({ type: 'Preset', item: preset });
  };

  const onTaxBracketAddClick = key => {
    const previousBracket = compData[key][compData[key].length - 1];

    setItemActionMeta({ type: 'TaxBrackets', key, addFormPrefill: { from: previousBracket?.to } });
  };

  const onTaxBracketEditClick = (key, item) => {
    setItemActionMeta({ type: 'TaxBrackets', key, item });
  };
  const onTaxBracketDeleteClick = (key, item) => {
    setDeleteMeta({ type: 'TaxBrackets', key, item });
  };

  const onVariableFormSubmit = async form => {
    const { item: editingVariable } = itemActionMeta;
    const { variables: existingVariables } = compData;

    const { name, symbol, value } = form;

    const variablesWithoutEditing = existingVariables.filter(v => v._id !== editingVariable?._id);

    if (variablesWithoutEditing.map(v => v.name).includes(name.trim())) {
      return toast.error('This name is already taken');
    }

    if (variablesWithoutEditing.map(v => v.symbol).includes(symbol.trim())) {
      return toast.error('This symbol is already taken');
    }

    if (['[', ']'].some(bracket => symbol.includes(bracket))) {
      return toast.error('Symbol cannot include "[" or "]"');
    }

    const variables = addOrUpdateObjectInArray(
      compData.variables,
      editingVariable ? { ...editingVariable, ...form } : form
    );
    addOrUpdateComp({ variables });
  };

  window['onVariableFormSubmit'] = onVariableFormSubmit;

  const onPresetFormSubmit = async form => {
    const { item: editingPreset } = itemActionMeta;
    const { presets: existingPresets } = compData;

    const { name } = form;

    const presetsWithoutEditing = existingPresets.filter(v => v._id !== editingPreset?._id);
    if (presetsWithoutEditing.map(v => v.name).includes(name.trim())) {
      return toast.error('This name is already taken');
    }

    const errorInPresets = checkValidityOfPreset(form, compData.variables);
    if (errorInPresets) {
      return highlightError(document.getElementById(errorInPresets), `Formula is not valid.`);
    }

    const presets = addOrUpdateObjectInArray(compData.presets, editingPreset ? { ...editingPreset, ...form } : form);
    addOrUpdateComp({ presets });
  };

  window['onPresetFormSubmit'] = onPresetFormSubmit;

  const onTaxBracketsFormSubmit = async form => {
    const { item: editingBracket, key } = itemActionMeta;

    const newBrackets = addOrUpdateObjectInArray(
      compData[key],
      editingBracket ? { ...editingBracket, ...form } : form
    ).sort((a, b) => (a.from || 0) - (b.from || 0));

    const errorInTaxBrackets = checkValidityOfBrackets(newBrackets, form);
    if (errorInTaxBrackets) {
      return highlightError(document.getElementById(errorInTaxBrackets.field), errorInTaxBrackets.msg);
    }

    addOrUpdateComp({ [key]: newBrackets });
  };

  window['onTaxBracketsFormSubmit'] = onTaxBracketsFormSubmit;

  const deleteItem = async () => {
    setDeleteMeta({ ...deleteMeta, actionInProgress: true });

    const { type, item, key } = deleteMeta;
    const newComp = cloneDeep(compData);

    const itemToRemove = key || `${type.toLowerCase()}s`;
    newComp[itemToRemove] = newComp[itemToRemove].filter(i => i._id !== item._id);

    const { error, response } = await updateComp(newComp);

    setDeleteMeta({ ...deleteMeta, actionInProgress: false });

    if (error) {
      return toast.error(error);
    }

    setCompData(response);
    setDeleteMeta(null);
    onCompUpdate && onCompUpdate(response);
    toast.success(`${type} deleted successfully!`);
  };

  const addOrUpdateComp = async (updatingFields = {}) => {
    setItemActionMeta({ ...itemActionMeta, actionInProgress: true });

    const { error, response } = await updateComp(updatingFields);

    setItemActionMeta({ ...itemActionMeta, actionInProgress: false });
    if (error) {
      return toast.error(error);
    }

    setCompData(response);
    setItemActionMeta(null);
    onCompUpdate && onCompUpdate(response);
  };

  const onResetToDefaultClick = () => {
    setResetMeta({});
  };

  const resetComp = async () => {
    setResetMeta({ ...resetMeta, actionInProgress: true });

    const { error, response } = await makeApiRequests({
      endpoint: ENDPOINTS.COMPS_WITH_ID(compData._id),
      method: 'PUT',
      requestBody: {
        resetComp: true
      },
    });

    setResetMeta({ ...resetMeta, actionInProgress: false });

    if (error) {
      return toast.error(error);
    }

    toast.success('Reset succesfully!');
    setCompData(response);
    setResetMeta(null);
    onCompUpdate && onCompUpdate(response);
  };

  const updateComp = async updatingFields => {
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.COMPS_WITH_ID(compData._id),
      method: 'PUT',
      requestBody: {
        ...compData,
        ...updatingFields
      },
    });

    if (isAdminComp && response) localStorage.setItem('comps', JSON.stringify(response));

    return { response, error };
  };

  return (
    <Container fluid className={'h-100 py-2 px-md-3 text-start'}>
      {loading && <Loader />}
      {!loading && compData && (
        <>
          {compData.customer && <Actions translate={translate} onResetClick={onResetToDefaultClick} />}

          <Variables
            variables={compData.variables}
            onVariableAddClick={onVariableAddClick}
            onVariableEditClick={onVariableEditClick}
            onVariableDeleteClick={onVariableDeleteClick}
            readOnlyMode={readOnlyMode}
          />
          <Presets
            presets={compData.presets}
            variables={compData.variables}
            onPresetAddClick={onPresetAddClick}
            onPresetEditClick={onPresetEditClick}
            onPresetDeleteClick={onPresetDeleteClick}
            readOnlyMode={readOnlyMode}
          />
          {isAdminComp && (
            <>
              <TaxBrackets
                buyingTaxBrackets={compData.buyingTaxBrackets}
                sellingTaxBrackets={compData.sellingTaxBrackets}
                buyingTaxBracketsForOnlyApartment={compData.buyingTaxBracketsForOnlyApartment}
                sellingTaxBracketsForOnlyApartment={compData.sellingTaxBracketsForOnlyApartment}
                onTaxBracketAddClick={onTaxBracketAddClick}
                onTaxBracketEditClick={onTaxBracketEditClick}
                onTaxBracketDeleteClick={onTaxBracketDeleteClick}
                readOnlyMode={readOnlyMode}
              />
              <CompGeneralInfo comp={compData} onCompUpdate={setCompData} readOnlyMode={readOnlyMode} />
              <AppConfiguration comp={compData} onCompUpdate={setCompData}  readOnlyMode={readOnlyMode} />
              <AppVideos readOnlyMode={readOnlyMode}/>
            </>
          )}
          <AddEditModal
            entityName={itemActionMeta?.type}
            show={itemActionMeta !== null}
            onHide={() => setItemActionMeta(null)}
            toBeEditedItem={itemActionMeta ? itemActionMeta.item : null}
            addFormPrefill={itemActionMeta?.addFormPrefill}
            showProgress={itemActionMeta?.actionInProgress}
            addForm={getForm({ entityName: itemActionMeta?.type, translate })}
            updateForm={getForm({ entityName: itemActionMeta?.type, type: 'Update', translate })}
            translate={translate}
          />
          <AlertModal
            show={deleteMeta !== null}
            alertText={translate(`are_you_sure_you_want_to_delete_${deleteMeta?.type?.toLowerCase()}`)}
            onHide={() => setDeleteMeta(null)}
            onDismissClick={() => setDeleteMeta(null)}
            onContinueClick={deleteItem}
            progressText={`${translate(`deleting_${deleteMeta?.type?.toLowerCase()}`)}...`}
            showProgress={deleteMeta && deleteMeta.actionInProgress}
          />
          <AlertModal
            show={resetMeta !== null}
            alertText={translate('are_you_sure_reset_comp_table')}
            onHide={() => setResetMeta(null)}
            onDismissClick={() => setResetMeta(null)}
            onContinueClick={resetComp}
            progressText={`${translate('resetting_comp')}...`}
            showProgress={resetMeta && resetMeta.actionInProgress}
          />
        </>
      )}
    </Container>
  );
};

export default CompTable;
