import { Chart as ChartJS, registerables } from 'chart.js';
import zoomPlugin from 'chartjs-plugin-zoom';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Alert, FormCheck } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import { monthSmall } from '../../../../../helpers/constants';
import { isRtl } from '../../../../../helpers/localization';
import AutoSaveTextArea from '../../../../common/AutoSaveTextArea';
import ActionSummaryView from './ActionSummaryView';
import BalanceSheet from './BalanceSheet';
import StrategyActions from './StrategyActions';
import StrategyChart from './charts/StrategyChart';
import { calculateBalanceSheet, findLinkedLiabilities } from './helper';
import RiskManagement from './risk-management/RiskManagement';
import StressTest from './stress-test/StressTest';

ChartJS.register(...registerables, zoomPlugin);

const getTabs = (translate, stressTestApplied) => [
  { label: translate('balance_sheet'), value: 1 },
  { label: translate('risk_management'), value: 2 },
  { label: translate('action_summary'), value: 3 },
  { label: translate('charts'), value: 4 },
  ...(stressTestApplied ? [{ label: translate('stress_test'), value: 5 }] : [])
];

const calculateTaxSummary = strategy => {
  return strategy.assets
    .filter(asset => asset.category === 'Real Estate')
    .map(asset => {
      const liabilities = findLinkedLiabilities(strategy.liabilities, asset);

      const totalLoanValue = liabilities.reduce((acc, l) => acc + l.value, 0);

      return {
        asset: asset.name,
        buyingMonth: monthSmall[asset.buyingMonth],
        buyingYear: asset.buyingYear,
        buyValue: asset.value,
        buyTaxAmount: asset.editMode ? asset?.tax?.buy || 0 : 0,
        additionalBuyCosts: asset.editMode ? asset.purchaseCost || 0 : 0,
        sellingMonth: asset.sellingYear ? monthSmall[asset.sellingMonth] : '',
        sellingYear: asset.sellingYear,
        soldValue: asset.valueAtSellingMonthYear,
        sellTaxAmount: asset.tax?.sell,
        additionalSalesCosts: asset.salesCost,
        totalEquityForBuying: asset.value - totalLoanValue
      };
    })
    ?.sort((a, b) => a.buyingYear - b.buyingYear);
};

const Strategy = ({
  strategy,
  armageddon,
  stressTestApplied,
  disabled,
  quickRenaming,
  editable,
  assetLiabilityEditable,
  cashflowEditable,
  reinvestmentsEditable,
  onAssetResort,
  onShownToCustomerChange,
  onAddAssetClick,
  onAddLiabilityClick,
  onDuplicateAssetLiabilityClick,
  onAssetLiabilityEditClick,
  onAssetLiabilityDeleteClick,
  onAddEventClick,
  onEditEventClick,
  onOneOffEventDeleteClick,
  onItemQuickRenameSubmit,
  onAddCashflowClick,
  onEditCashflowClick,
  onDeleteCashflowClick,
  onAddReinvestmentOption,
  onEditReinvestmentOption,
  onDeleteReinvestmentOption,
  onDisableReinvestmentOption,
  onDisableAllReinvestmentsOptions,
  fromCustomerPortal,
  savingNotes,
  editingNotes,
  onEditingNotesChange,
  onNotesSave,
  onNotesAreaClick,
  adminCompTable,
  customerCompTable,
  readOnlyMode
}) => {
  const { translate, langCode } = useContext(LocalizeContext);
  const [activeTab, setActiveTab] = useState(1);
  const [calculatedStrategy, setCalculatedStrategy] = useState();

  const tabs = useMemo(() => getTabs(translate, stressTestApplied), [langCode, stressTestApplied]);
  const isLanguageRtl = useMemo(() => isRtl(langCode), [langCode]);

  const selectTab = value => {
    setActiveTab(() => value);
  };

  useEffect(() => {
    if (!stressTestApplied && activeTab === 5) {
      setActiveTab(1);
    }
  }, [stressTestApplied]);

  useEffect(() => {
    setCalculatedStrategy(
      calculateBalanceSheet(
        strategy,
        armageddon,
        stressTestApplied,
        translate,
        false,
        adminCompTable,
        customerCompTable
      )
    );
  }, [strategy, armageddon, stressTestApplied]);

  const taxSummary = useMemo(() => calculatedStrategy && calculateTaxSummary(calculatedStrategy), [calculatedStrategy]);

  return (
    <div className={`py-2 fade-in`}>
      {editable && (
        <div className="d-flex align-items-center">
          <StrategyActions
            assetLiabilityEditable={assetLiabilityEditable}
            cashflowEditable={cashflowEditable}
            reinvestmentsEditable={reinvestmentsEditable}
            onAddAssetClick={onAddAssetClick}
            onAddLiabilityClick={onAddLiabilityClick}
            onAddCashflowClick={onAddCashflowClick}
            onAddReinvestmentOption={onAddReinvestmentOption}
            disabled={disabled}
            translate={translate}
          />
          {!fromCustomerPortal && (
            <div className={`flex-grow-1 text-${isLanguageRtl ? 'start' : 'end'}`}>
              <FormCheck
                disabled={disabled}
                onChange={e => onShownToCustomerChange(e.target.checked)}
                className="d-inline-block large"
                label={translate('shown_to_customer')}
                checked={strategy.showToCustomer}
              />
            </div>
          )}
        </div>
      )}
      <div className="mt-3">
        <h6 className="mb-1 large">
          <b>{translate('notes')}:</b>
        </h6>
        <Alert className="p-2 mb-0 large" onClick={editable ? onNotesAreaClick : undefined}>
          <div className="text-dark" style={{ whiteSpace: 'pre-line' }}>
            <AutoSaveTextArea
              text={editingNotes}
              onTextChange={onEditingNotesChange}
              onTextSubmit={onNotesSave}
              updating={savingNotes}
              disabled={readOnlyMode}
            />
          </div>
        </Alert>
      </div>

      <div className="py-3 overflow-auto" style={{ whiteSpace: 'nowrap' }}>
        {tabs.map(tab => (
          <h6
            onClick={() => selectTab(tab.value)}
            style={{ display: 'inline-block', margin: '5px', cursor: 'pointer' }}
          >
            <b>{tab.label}</b>
            {activeTab == tab.value ? <hr style={{ margin: '0' }} /> : <></>}
          </h6>
        ))}
      </div>

      {calculatedStrategy && (
        <div>
          {activeTab == 1 && (
            <BalanceSheet
              editable={editable}
              strategy={strategy}
              cashflowEditable={cashflowEditable}
              assetLiabilityEditable={assetLiabilityEditable}
              reinvestmentsEditable={reinvestmentsEditable}
              calculatedStrategy={calculatedStrategy}
              onDuplicateAssetLiabilityClick={onDuplicateAssetLiabilityClick}
              onAssetLiabilityEditClick={onAssetLiabilityEditClick}
              onAssetLiabilityDeleteClick={onAssetLiabilityDeleteClick}
              onAddEventClick={onAddEventClick}
              onEditCashflowClick={onEditCashflowClick}
              onDeleteCashflowClick={onDeleteCashflowClick}
              onAddCashflowClick={onAddCashflowClick}
              onAddLiabilityClick={onAddLiabilityClick}
              onEditEventClick={onEditEventClick}
              onNameUpdateSubmit={onItemQuickRenameSubmit}
              onOneOffEventDeleteClick={onOneOffEventDeleteClick}
              onAddReinvestmentOption={onAddReinvestmentOption}
              onEditReinvestmentOption={onEditReinvestmentOption}
              onDeleteReinvestmentOption={onDeleteReinvestmentOption}
              onDisableReinvestmentClick={onDisableReinvestmentOption}
              onDisableAllReinvestmentsCheck={onDisableAllReinvestmentsOptions}
              taxSummary={taxSummary}
              disabled={disabled}
              quickRenaming={quickRenaming}
            />
          )}
          {activeTab == 2 && (
            <RiskManagement
              adminCompTable={adminCompTable}
              customerCompTable={customerCompTable}
              strategy={strategy}
              armageddon={armageddon}
              stressTestApplied={stressTestApplied}
              calculatedStrategy={calculatedStrategy}
              onAssetResort={onAssetResort}
              disabled={disabled}
              editable={editable}
            />
          )}
          {activeTab == 3 && <ActionSummaryView strategy={calculatedStrategy} />}
          {activeTab == 4 && <StrategyChart strategy={calculatedStrategy} />}
          {activeTab == 5 && <StressTest strategy={calculatedStrategy} />}
        </div>
      )}
    </div>
  );
};

export default Strategy;
