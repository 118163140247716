import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Badge, Button, Modal } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import 'react-multi-email/dist/style.css';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS, getCustomerSortNameToField, searchFilterOptions } from '../../helpers/constants';
import CustomerList from '../admin/manage-users/customer/CustomerList';
import HorizontalProgress from '../common/HorizontalProgress';
import SearchBox from '../common/searchbox';
import { PersonCircle, X } from 'react-bootstrap-icons';

const CustomerSelectModal = ({
  show,
  onHide,
  headerText = 'copy_apartment',
  multiSelect = false,
  selectedCustomer,
  onCustomerSelect,
  showProgress,
  onSubmit,
  submitButtonText = 'copy',
  showSubmitButton = true
}) => {
  if (!show) return;

  const { translate, langCode } = useContext(LocalizeContext);

  const [customers, setCustomers] = useState([]);
  const [loading, setLoading] = useState(false);
  const sortNameToField = useMemo(() => getCustomerSortNameToField(translate), [langCode]);
  const sortOptions = useMemo(() => Object.keys(sortNameToField), [sortNameToField]);
  const maxLimitOptions = useMemo(() => [50, 100, 200, 'all'], []);

  /*   //this is done to ensure that only customer in current list is selected
  useEffect(() => {
    if (customers?.length === 0) return;
    if (!Array.isArray(selectedCustomer)) {
      onCustomerSelect(customers.find(c => c._id === selectedCustomer?._id));
    }
  }, [customers]);
 */
  const fetchCustomers = async ({ sortBy, maxLimit, descSort, filters, query, showArchived }) => {
    setLoading(true);
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_LIST,
      requestBody: {
        filter: {
          role: 'Customer',
          active: filters['Active'].map(a => (a === 'Yes' ? true : false)),
          archived: showArchived ? undefined : false
        },
        query,
        maxLimit: maxLimit === 'all' ? undefined : maxLimit,
        sort: { [sortNameToField[sortBy]]: descSort ? 1 : -1 },
        compactMode: true
      }
    });
    setLoading(false);

    if (error) {
      toast.error(error);
      return;
    }

    setCustomers(response);
  };

  const getCustomerRecommendation = async query => {
    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_LIST,
      requestBody: {
        filter: {
          role: 'Customer'
        },
        compactMode: true,
        query,
        maxLimit: 5,
        sort: { updatedAt: -1 }
      }
    });

    if (error) {
      return [];
    }

    return response.map(r => r.name);
  };
  return (
    <Modal
      show={show}
      onHide={onHide}
      centered
      backdrop="static"
      dialogClassName="h-100 my-0"
      contentClassName="d-flex flex-column h-100 justify-self-center"
    >
      <Modal.Header closeButton={!showProgress}>
        <h6 className="mb-0">{translate(headerText) || headerText}</h6>
      </Modal.Header>
      <Modal.Body className="overflow-auto flex-grow-1 p-1">
        {multiSelect && (
          <div className="rounded  mx-2">
            <h6 className="mid mb-0 fw-bold  underline">Selected customers</h6>
            {selectedCustomer?.length === 0 ? (
              <h6 className="smallFont my-2">No any customers selected</h6>
            ) : (
              <div className="d-flex align-items-center flex-wrap gap-1 my-1">
                {selectedCustomer?.map(c => (
                  <div
                    key={c?._id}
                    className="bg-primary d-flex gap-1 align-items-center text-white hover rounded p-1"
                    onClick={() => onCustomerSelect(c)}
                  >
                    <PersonCircle /> <h6 className="smaller fw-bold mb-0">{c?.name || c?.email || 'No name'}</h6>{' '}
                    <X size={18} />
                  </div>
                ))}
              </div>
            )}
          </div>
        )}
        <h6 className="mid fw-bold px-2 underline">{translate('select_customer')}:</h6>
        <SearchBox
          containerClass=""
          filterOptions={searchFilterOptions}
          sortByOptions={sortOptions}
          maxLimitOptions={maxLimitOptions}
          onSearchOptionsChange={fetchCustomers}
          disabled={loading || showProgress}
          recommendationGetter={getCustomerRecommendation}
          showOnlySearch
        />
        {loading && <HorizontalProgress text={`${translate('fetching_customers')}...`} />}
        {showProgress && <HorizontalProgress text={translate('please_wait')} />}

        <CustomerList
          customers={customers}
          onSelect={onCustomerSelect}
          activeId={Array.isArray(selectedCustomer) ? selectedCustomer?.map(c => c?._id) : selectedCustomer?._id}
          quickSearch={false}
        />
      </Modal.Body>
      <Modal.Footer className="px-1 py-1">
        {showSubmitButton && (
          <Button
            disabled={showProgress || !selectedCustomer}
            variant="success"
            size="sm"
            className="me-2 text-white"
            onClick={onSubmit}
          >
            {translate(submitButtonText) || submitButtonText}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default CustomerSelectModal;
