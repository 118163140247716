import { Eye } from 'react-bootstrap-icons';
import { IRR_CALCULATOR_KEY, PAPER_CALCULATOR_KEY, PINUI_BINUI_CALCULATOR_KEY } from '../../helpers/constants';
import cities from '../../helpers/israel-cities.json';

let yesNoOptions = ['Yes', 'No'];
let rangeOptions = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

export const getInformationalFields = (translate, type, addApartmentMode) => ({
  [translate('apartment_info')]: [
    {
      label: 'area_in_square_meters',
      objectKey: 'areaInSquareMeters',
      defaultValueCompSymbol: 'A14',
      type: 'number'
    },
    { label: 'city', objectKey: 'city', dataListOptions: cities.map(c => c.city), hide: addApartmentMode },
    { label: 'realtor_seller_name', objectKey: 'realtorSellerName' },
    { label: 'apartment_floor_number', objectKey: 'apartmentFloorNumber' },
    { label: 'number_floor_in_building', objectKey: 'numberOfFloorInBuilding' },
    { label: 'number_of_rooms', objectKey: 'numberOfRooms' },
    { label: 'is_there_an_elevator', objectKey: 'isThereElevator', type: 'text' },
    { label: 'is_there_an_balcony', objectKey: 'isThereBalcony', type: 'text' },
    { label: 'is_there_parking', objectKey: 'isThereParking', type: 'text' },
    { label: 'is_there_garden', objectKey: 'isThereGarden', type: 'text' },
    { label: 'is_there_a_safe_room', objectKey: 'isThereSafeRoom', type: 'text' },
    { label: 'is_there_construction_without_permit', objectKey: 'isThereConstructionWithoutPermit', type: 'text' },
    { label: 'is_apartment_properly_registered', objectKey: 'isApartmentProperlyRegistered', type: 'text' },
    {
      label: 'is_apartment_registered_on_seller_name',
      objectKey: 'isApartmentRegisteredOnSellerName',
      type: 'text'
    },
    { label: 'who_is_typical_renter_for_apartment', objectKey: 'whoIsTypicalRenter' },
    { label: 'info_about_the_neighborhood', objectKey: 'neighbourhoodInfo' },
    { label: 'transportation', objectKey: 'transportation' },
    { label: 'parking', objectKey: 'parking' },
    { label: 'physical_condition', objectKey: 'physicalCondition', hide: type === PAPER_CALCULATOR_KEY },
    { label: 'dangerous_building', objectKey: 'dangerousBuilding', hide: type === PAPER_CALCULATOR_KEY },
    { label: 'number_of_air_directions', objectKey: 'numOfAirDirections' },
    { label: 'developer_company', objectKey: 'developerCompany', hide: type === IRR_CALCULATOR_KEY },
    { label: 'developer_website', objectKey: 'developerWebsite', hide: type === IRR_CALCULATOR_KEY },
    { label: 'signature_percentage', objectKey: 'signaturePercentage', hide: type !== PINUI_BINUI_CALCULATOR_KEY },
    { label: 'additional_comments', objectKey: 'additionalComments', as: 'textarea' }
  ]
    .filter(fi => !fi?.hide)
    .map(f => ({ ...f, label: translate(f.label), isInput: true, type: f.type || 'text' })),
  ...(type !== IRR_CALCULATOR_KEY
    ? {
        [translate('profile_fields')]: [
          {
            label: 'required_cash_for_deal',
            objectKey: 'requiredCashForDeal',
            type: 'number',
            hide: type === PAPER_CALCULATOR_KEY
          },
          {
            label: 'developer_loan',
            objectKey: 'developerLoan',
            type: 'switch',
            trueValue: 'Yes',
            falseValue: 'No',
            hide: type === PINUI_BINUI_CALCULATOR_KEY
          },
          {
            label: 'linear_payment',
            objectKey: 'linearPayment',
            type: 'switch',
            trueValue: 'Yes',
            falseValue: 'No',
            hide: type === PINUI_BINUI_CALCULATOR_KEY
          },
          {
            label: 'developer_company_scale',
            objectKey: 'developerCompanyScale',
            type: 'range',
            min: 1,
            max: 10,
            hide: type === PINUI_BINUI_CALCULATOR_KEY
          },
          {
            label: 'buying_decision_scale',
            objectKey: 'buyingDecisionScale',
            type: 'range',
            min: 1,
            max: 10
          },
          {
            label: 'apartment_looks',
            objectKey: 'apartmentLooks',
            type: 'range',
            min: 1,
            max: 10,
            hide: type === PAPER_CALCULATOR_KEY
          },
          {
            label: 'large_scale_renovation',
            objectKey: 'largeRenovation',
            type: 'range',
            min: 1,
            max: 10,
            hide: type === PAPER_CALCULATOR_KEY
          },
          {
            label: 'early_stage_deals',
            objectKey: 'earlyDeal',
            type: 'switch',
            trueValue: 'Yes',
            falseValue: 'No',
            hide: type === PAPER_CALCULATOR_KEY
          },
          {
            label: 'has_3_floor_or_higher_without_elevator',
            objectKey: 'noElevator',
            type: 'switch',
            trueValue: 'Yes',
            falseValue: 'No',
            hide: type === PAPER_CALCULATOR_KEY
          },
          {
            label: 'complicated_deals',
            objectKey: 'complicatedDeals',
            type: 'switch',
            trueValue: 'Yes',
            falseValue: 'No',
            hide: type === PAPER_CALCULATOR_KEY
          }
        ]
          .filter(fi => !fi?.hide)
          .map(f => ({ ...f, label: translate(f.label), isInput: true, type: f.type || 'text' }))
      }
    : {})

  // [translate('rate_from_1_to_10')]: [
  //   { label: 'how_good_is_location', objectKey: 'howGoodIsLocation' },
  //   { label: 'how_easy_to_find_renters', objectKey: 'howEasyToFindRenters' },
  //   { label: 'how_well_maintained', objectKey: 'howWellMaintained' },
  //   { label: 'how_good_are_current_renters', objectKey: 'howGoodAreCurrentRenters' }
  // ].map(f => ({ ...f, label: translate(f.label), isInput: true, type: f.type || 'rate' }))
});

export const getIrrFields = (translate, addApartmentMode = false) => {
  let sectionToHide = {
    [translate('general_information')]: false,
    [translate('additional_information')]: false,
    [translate('buying')]: addApartmentMode,
    [translate('mortgage')]: addApartmentMode,
    [translate('rent')]: addApartmentMode,
    [translate('selling')]: addApartmentMode,
    [translate('profit')]: addApartmentMode
  };
  let fields = {
    [translate('general_information')]: [
      { label: translate('asked_price'), objectKey: 'askedPrice', isInput: true },
      { label: translate('buying_price'), objectKey: 'inputPrice', isInput: true },
      { label: translate('appraised_value'), objectKey: 'appraisedValue', isInput: true },
      {
        label: translate('monthly_rent'),
        objectKey: 'monthlyRent',
        isInput: true,
        percentageOfField: 'inputPrice'
      },
      { label: translate('rent_increase'), objectKey: 'rentIncrease', isInput: true, type: 'percentage' },
      {
        label: translate('maximal_mortgage_available'),
        objectKey: 'maximalMortgageAvailable',
        isInput: true,
        hide: addApartmentMode
      } //? Not used
    ],
    [translate('additional_information')]: [
      {
        label: translate('time_to_delivery_after_buying'),
        objectKey: 'timeToDeliveryAfterBuyingInMonths',
        isInput: true,
        defaultValueCompSymbol: 'A15'
      },
      {
        label: translate('mortgage_percentage'),
        objectKey: 'mortgagePercentage',
        isInput: true,
        defaultValueCompSymbol: 'A16',
        type: 'percentage',
        hide: addApartmentMode
      },
      {
        label: translate('mortgage_to_be_taken'),
        objectKey: 'mortgageToBeTakenDependingOnSeveralOptions',
        hide: addApartmentMode
      },
      {
        label: translate('mortgage_interest'),
        objectKey: 'mortgageInterest',
        isInput: true,
        type: 'percentage',
        defaultValueCompSymbol: 'A11',
        hide: addApartmentMode
      },
      {
        label: translate('mortgage_time_to_maturity'),
        objectKey: 'mortgageTimeToMaturityInMonths',
        isInput: true,
        defaultValueCompSymbol: 'A17',
        hide: addApartmentMode
      },
      {
        label: translate('expected_appreciation'),
        objectKey: 'expectedAppreciation',
        isInput: true,
        type: 'percentage',
        defaultValueCompSymbol: 'A12'
      },
      {
        label: translate('real_estate_holding_period'),
        objectKey: 'realEstateHoldingPeriodInYears',
        isInput: true,
        type: 'monthYearInput',
        defaultValueCompSymbol: 'A18',
        hide: addApartmentMode
      },
      {
        //this has been changed to include vat although the symbol still says Pre vat
        label: translate('realtor_cost'),
        objectKey: 'realtorCostsInPercentageTermsPreVat',
        isInput: true,
        type: 'percentage',
        defaultValueCompSymbol: 'A19'
      },
      {
        label: translate('renovation_cost'),
        objectKey: 'renovationCost',
        isInput: true,
        defaultValueCompSymbol: 'A20'
      },
      { label: translate('value_added_by_renovation'), objectKey: 'valueAddedByRenovation', isInput: true },
      { label: translate('value_after_renovation'), objectKey: 'valueAfterRenovation' },
      {
        label: translate('additional_costs_lawyer_apraiser'),
        objectKey: 'relatedCostsLawyerAppraiserEtc',
        isInput: true,
        defaultValueCompSymbol: 'A21'
      },
      {
        label: translate('is_it_the_only_apartment_buying'),
        objectKey: 'onlyApartmentWhileBuying',
        isInput: true,
        defaultValue: true,
        type: 'boolean',
        hide: addApartmentMode
      },
      {
        label: translate('is_it_the_only_apartment_selling'),
        objectKey: 'onlyApartmentWhileSelling',
        isInput: true,
        defaultValue: true,
        type: 'boolean',
        hide: addApartmentMode
      },
      {
        label: translate('tax_on_rent'),
        objectKey: 'taxOnRent',
        isInput: true,
        defaultValueCompSymbol: 'A22',
        type: 'percentage',
        hide: addApartmentMode
      }
    ],
    [translate('buying')]: [
      { label: translate('price_per_square_meter'), objectKey: 'pricePerSquareMeter' },
      { label: translate('buying_tax'), objectKey: 'buyingTax' },
      { label: translate('realtor_cost'), objectKey: 'realtorCost' },
      {
        label: translate('difference_between_value_and_price'),
        objectKey: 'differenceBetweenAppraisedValueAndAttractivePrice'
      },
      { label: translate('cost_of_time_to_delivery_after_buying'), objectKey: 'costOfTimeToDeliveryAfterBuying' },
      { label: translate('renovation_cost'), objectKey: 'renovationCost' },
      { label: translate('equity_for_taking_mortgage'), objectKey: 'equityForTakingMortgage' },
      {
        label: translate('cost_of_retailer_lawyer'),
        objectKey: 'costOfRealtorBuyerAndAllRelatedCosts'
      },
      { label: translate('total_equity_to_put_in_the_deal'), objectKey: 'totalEquityToPutInTheDeal' },
      { label: translate('total_deal_cost'), objectKey: 'totalDealCost' }
    ],
    [translate('mortgage')]: [
      {
        label: translate('mortgage_to_be_taken'),
        objectKey: 'mortgageToBeTakenDependingOnSeveralOptions'
      },
      { label: translate('monthly_mortgage_payment'), objectKey: 'mortgageMonthlyPayment' },
      { label: translate('total_monthly_payments'), objectKey: 'totalMonthlyPayments' },
      { label: translate('mortgage_left_at_the_end'), objectKey: 'mortgageLeftAtTheEndPeriodOfHolding' },
      { label: translate('total_loan_decrease'), objectKey: 'totalLoanDecrease' }
    ],
    [translate('rent')]: [
      { label: translate('monthly_rent'), objectKey: 'monthlyRent' },
      { label: translate('cost_of_vacancy_and_annual_repairs'), objectKey: 'costOfVacancyAndAnnualRepairs' },
      { label: translate('yearly_return_from_rent'), objectKey: 'yearlyReturnFromRent', type: 'percentage' },
      { label: translate('tax_on_rent'), objectKey: 'taxOnRent', type: 'percentage' },
      { label: translate('net_yearly_return_on_rent'), objectKey: 'netYearlyReturnOnRent', type: 'percentage' },
      { label: translate('total_income_from_rent_after_vacancy_and_tax'), objectKey: 'totalIncomeFromRent' },
      {
        label: translate('total_income_from_rent_minus_monthly_payment'),
        objectKey: 'totalIncomeFromRentMinusTotalMonthlyPayments'
      }
    ],
    [translate('selling')]: [
      { label: translate('selling_price'), objectKey: 'sellingPrice' },
      { label: translate('profit_from_selling_brfore_tax'), objectKey: 'profitFromSellingBeforeTax' },
      { label: translate('profit_from_selling_after_tax'), objectKey: 'profitFromSellingAfterTax' }
    ],
    [translate('profit')]: [
      { label: translate('total_net_profit'), objectKey: 'totalNetProfit' },
      { label: translate('total_roi'), objectKey: 'totalRoi', type: 'percentage' },
      {
        label: translate('irr'),
        objectKey: 'irr',
        type: 'percentage',
        extraInfo: {
          Icon: Eye,
          key: 'irrBreakdown',
          timeLabel: 'month'
        }
      }
    ]
  };
  const filteredFields = {};
  for (const section in fields) {
    if (!sectionToHide[section]) {
      const filteredSection = fields[section].filter(field => !field.hide);
      if (filteredSection.length > 0) {
        filteredFields[section] = filteredSection;
      }
    }
  }

  return filteredFields;
};

export const getAllIrrFields = (translate, addApartmentMode) => {
  const irrFields = getIrrFields(translate);
  const informationFields = getInformationalFields(translate, IRR_CALCULATOR_KEY, addApartmentMode);

  return [irrFields, informationFields].flatMap(fields => {
    return Object.keys(fields).flatMap(sectionName => fields[sectionName]);
  });
};

export const getPinuiBinuiIrrFields = (translate, addApartmentMode = false) => {
  let sectionToHide = {
    [translate('general_information')]: false,
    [translate('additional_information')]: false,
    [translate('buying')]: addApartmentMode,
    [translate('mortgage')]: addApartmentMode,
    [translate('rent')]: addApartmentMode,
    [translate('selling')]: addApartmentMode,
    [translate('profit')]: addApartmentMode
  };

  let fields = {
    [translate('general_information')]: [
      { label: translate('asked_price'), objectKey: 'askedPrice', isInput: true },
      { label: translate('buying_price'), objectKey: 'inputPrice', isInput: true },
      { label: translate('appraised_value'), objectKey: 'appraisedValue', isInput: true },
      {
        label: translate('estimated_time_for_demolition'),
        objectKey: 'estimatedTimeForDemolition',
        isInput: true,
        type: 'monthYearInput'
      },
      {
        label: translate('estimated_time_for_finishing_contruction_from_demolition'),
        objectKey: 'estimatedTimeForFinishingContruction',
        isInput: true,
        defaultValueCompSymbol: 'A24',
        type: 'monthYearInput'
      },
      { label: translate('value_of_apartment_today'), objectKey: 'valueOfApartmentToday', isInput: true },
      {
        label: translate('rent_pre_contruction'),
        objectKey: 'monthlyRent',
        isInput: true,
        percentageOfField: 'inputPrice'
      },
      {
        label: translate('rent_post_contruction'),
        objectKey: 'rentPostConstruction',
        isInput: true,
        percentageOfField: 'valueOfApartmentToday'
      },
      { label: translate('rent_increase'), objectKey: 'rentIncrease', isInput: true, type: 'percentage' },
      {
        label: translate('maximal_mortgage_available'),
        objectKey: 'maximalMortgageAvailable',
        isInput: true,
        hide: addApartmentMode
      }
    ],
    [translate('additional_information')]: [
      {
        label: translate('apartment_size_after_reconstruction'),
        objectKey: 'apartmentSizeAfterReconstruction',
        isInput: true
      },
      {
        label: translate('time_to_delivery_after_buying'),
        objectKey: 'timeToDeliveryAfterBuyingInMonths',
        isInput: true,
        defaultValueCompSymbol: 'A15'
      },
      {
        label: translate('mortgage_percentage'),
        objectKey: 'mortgagePercentage',
        isInput: true,
        defaultValueCompSymbol: 'A16',
        type: 'percentage',
        hide: addApartmentMode
      },
      {
        label: translate('mortgage_to_be_taken'),
        objectKey: 'mortgageToBeTakenDependingOnSeveralOptions',
        hide: addApartmentMode
      },
      {
        label: translate('mortgage_interest'),
        objectKey: 'mortgageInterest',
        isInput: true,
        type: 'percentage',
        defaultValueCompSymbol: 'A11',
        hide: addApartmentMode
      },
      {
        label: translate('mortgage_time_to_maturity'),
        objectKey: 'mortgageTimeToMaturityInMonths',
        isInput: true,
        defaultValueCompSymbol: 'A17',
        hide: addApartmentMode
      },
      {
        label: translate('expected_appreciation'),
        objectKey: 'expectedAppreciation',
        isInput: true,
        type: 'percentage',
        defaultValueCompSymbol: 'A12'
      },
      {
        label: translate('real_estate_holding_period'),
        objectKey: 'realEstateHoldingPeriodInYears',
        isInput: true,
        defaultValueCompSymbol: 'A18',
        type: 'monthYearInput',
        hide: addApartmentMode
      },
      {
        label: translate('realtor_cost'),
        objectKey: 'realtorCostsInPercentageTermsPreVat',
        isInput: true,
        type: 'percentage',
        defaultValueCompSymbol: 'A19'
      },
      {
        label: translate('renovation_cost'),
        objectKey: 'renovationCost',
        isInput: true,
        defaultValueCompSymbol: 'A20'
      },
      { label: translate('value_added_by_renovation'), objectKey: 'valueAddedByRenovation', isInput: true },

      { label: translate('value_after_renovation'), objectKey: 'valueAfterRenovation' },
      {
        label: translate('additional_costs_lawyer_apraiser'),
        objectKey: 'relatedCostsLawyerAppraiserEtc',
        isInput: true,
        defaultValueCompSymbol: 'A21'
      },
      {
        label: translate('is_it_the_only_apartment_buying'),
        objectKey: 'onlyApartmentWhileBuying',
        isInput: true,
        defaultValue: true,
        type: 'boolean',
        hide: addApartmentMode
      },
      {
        label: translate('is_it_the_only_apartment_selling'),
        objectKey: 'onlyApartmentWhileSelling',
        isInput: true,
        defaultValue: true,
        type: 'boolean',
        hide: addApartmentMode
      },
      {
        label: translate('tax_on_rent'),
        objectKey: 'taxOnRent',
        isInput: true,
        defaultValueCompSymbol: 'A22',
        type: 'percentage',
        hide: addApartmentMode
      }
    ],
    [translate('buying')]: [
      { label: translate('price_per_square_meter'), objectKey: 'pricePerSquareMeter' },
      { label: translate('buying_tax'), objectKey: 'buyingTax' },
      { label: translate('realtor_cost'), objectKey: 'realtorCost' },
      {
        label: translate('difference_between_value_and_price'),
        objectKey: 'differenceBetweenAppraisedValueAndAttractivePrice'
      },
      { label: translate('cost_of_time_to_delivery_after_buying'), objectKey: 'costOfTimeToDeliveryAfterBuying' },
      { label: translate('renovation_cost'), objectKey: 'renovationCost' },
      { label: translate('equity_for_taking_mortgage'), objectKey: 'equityForTakingMortgage' },
      {
        label: translate('cost_of_retailer_lawyer'),
        objectKey: 'costOfRealtorBuyerAndAllRelatedCosts'
      },
      { label: translate('total_equity_to_put_in_the_deal'), objectKey: 'totalEquityToPutInTheDeal' },
      { label: translate('total_deal_cost'), objectKey: 'totalDealCost' }
    ],
    [translate('mortgage')]: [
      {
        label: translate('mortgage_to_be_taken'),
        objectKey: 'mortgageToBeTakenDependingOnSeveralOptions'
      },
      { label: translate('monthly_mortgage_payment'), objectKey: 'mortgageMonthlyPayment' },
      { label: translate('total_monthly_payments'), objectKey: 'totalMonthlyPayments' },
      { label: translate('mortgage_left_at_the_end'), objectKey: 'mortgageLeftAtTheEndPeriodOfHolding' },
      { label: translate('total_loan_decrease'), objectKey: 'totalLoanDecrease' }
    ],
    [translate('rent')]: [
      { label: translate('rent_pre_contruction'), objectKey: 'monthlyRent' },
      { label: translate('rent_during_contruction'), objectKey: 'rentDuringContruction' },
      { label: translate('rent_post_contruction'), objectKey: 'rentPostConstruction' },
      { label: translate('total_rent'), objectKey: 'totalRent' },
      { label: translate('cost_of_vacancy_and_annual_repairs'), objectKey: 'costOfVacancyAndAnnualRepairs' },
      { label: translate('yearly_return_from_rent'), objectKey: 'yearlyReturnFromRent', type: 'percentage' },
      { label: translate('tax_on_rent'), objectKey: 'taxOnRent', type: 'percentage' },
      { label: translate('net_yearly_return_on_rent'), objectKey: 'netYearlyReturnOnRent', type: 'percentage' }
    ],
    [translate('selling')]: [
      { label: translate('selling_price'), objectKey: 'sellingPrice' },
      { label: translate('profit_from_selling_brfore_tax'), objectKey: 'profitFromSellingBeforeTax' },
      { label: translate('profit_from_selling_after_tax'), objectKey: 'profitFromSellingAfterTax' }
    ],
    [translate('profit')]: [
      { label: translate('total_net_profit'), objectKey: 'totalNetProfit' },
      { label: translate('total_roi'), objectKey: 'totalRoi', type: 'percentage' },
      {
        label: translate('irr'),
        objectKey: 'irr',
        type: 'percentage',
        extraInfo: {
          Icon: Eye,
          key: 'irrBreakdown',
          timeLabel: 'month'
        }
      },
      {
        label: translate('irr_no_reconstruction'),
        objectKey: 'irrInNoReconstruction',
        type: 'percentage',
        extraInfo: {
          Icon: Eye,
          key: 'irrNoReconstructionBreakdown',
          timeLabel: 'month'
        }
      }
    ]
  };

  const filteredFields = {};
  for (const section in fields) {
    if (!sectionToHide[section]) {
      const filteredSection = fields[section].filter(field => !field.hide);
      if (filteredSection.length > 0) {
        filteredFields[section] = filteredSection;
      }
    }
  }

  return filteredFields;
};

export const getAllPinuiBinuiIrrFields = (translate, addApartmentMode) => {
  const irrFields = getPinuiBinuiIrrFields(translate);
  const informationFields = getInformationalFields(translate, PINUI_BINUI_CALCULATOR_KEY, addApartmentMode);

  return [irrFields, informationFields].flatMap(fields => {
    return Object.keys(fields).flatMap(sectionName => fields[sectionName]);
  });
};

export const getPaperApartmentIrrFields = (translate, addApartmentMode = false) => {
  let sectionToHide = {
    [translate('general_information')]: false,
    [translate('additional_information')]: false,
    [translate('buying')]: addApartmentMode,
    [translate('mortgage')]: addApartmentMode,
    [translate('rent')]: addApartmentMode,
    [translate('selling')]: addApartmentMode,
    [translate('profit')]: addApartmentMode
  };
  let fields = {
    [translate('general_information')]: [
      { label: translate('asked_price'), objectKey: 'askedPrice', isInput: true },
      { label: translate('buying_price'), objectKey: 'inputPrice', isInput: true },
      {
        label: translate('down_payment'),
        objectKey: 'downPayment',
        isInput: true,
        type: 'percentage'
      },
      {
        label: translate('remaining_payment'),
        objectKey: 'remainingPayment',
        isInput: true,
        type: 'dropdown',
        defaultValue: 'oneFinalPayment',
        options: [
          { label: translate('one_final_payment'), value: 'oneFinalPayment' },
          { label: translate('gradual_payment'), value: 'gradualPayment' }
        ]
      },
      {
        label: translate('payments'),
        objectKey: 'paperApartmentPayments',
        isInput: true,
        type: 'paperApartmentPayments',
        defaultValue: []
      },
      {
        label: translate('yearly_materials_cost_index_rise_paper_calculator'),
        objectKey: 'yearlyMaterialCostIndexRise',
        isInput: true,
        type: 'percentage'
      },
      {
        label: translate('estimated_time_for_contruction'),
        objectKey: 'estimatedTimeForFinishingContruction',
        isInput: true,
        type: 'monthYearInput',
        defaultValueCompSymbol: 'A24'
      },
      {
        label: translate('additional_costs_for_making_the_apartment_ready_for_rent_paper_apartment'),
        objectKey: 'additionalCostsForMakingApartmentReadyForRent',
        isInput: true
      },
      { label: translate('value_of_apartment_today'), objectKey: 'valueOfApartmentToday', isInput: true },
      {
        label: translate('rent_post_contruction'),
        objectKey: 'monthlyRent',
        isInput: true,
        percentageOfField: 'inputPrice'
      },
      { label: translate('rent_increase'), objectKey: 'rentIncrease', isInput: true, type: 'percentage' },
      {
        label: translate('maximal_mortgage_available'),
        objectKey: 'maximalMortgageAvailable',
        isInput: true,
        hide: addApartmentMode
      } //? Not used
    ],
    [translate('additional_information')]: [
      {
        label: translate('mortgage_percentage'),
        objectKey: 'mortgagePercentage',
        isInput: true,
        defaultValueCompSymbol: 'A16',
        type: 'percentage',
        hide: addApartmentMode
      },
      {
        label: translate('mortgage_to_be_taken'),
        objectKey: 'mortgageToBeTakenDependingOnSeveralOptions',
        hide: addApartmentMode
      },
      {
        label: translate('mortgage_interest'),
        objectKey: 'mortgageInterest',
        isInput: true,
        type: 'percentage',
        defaultValueCompSymbol: 'A11',
        hide: addApartmentMode
      },
      {
        label: translate('mortgage_time_to_maturity'),
        objectKey: 'mortgageTimeToMaturityInMonths',
        isInput: true,
        defaultValueCompSymbol: 'A17',
        hide: addApartmentMode
      },
      {
        label: translate('expected_appreciation'),
        objectKey: 'expectedAppreciation',
        isInput: true,
        type: 'percentage',
        defaultValueCompSymbol: 'A12'
      },
      {
        label: translate('real_estate_holding_period'),
        objectKey: 'realEstateHoldingPeriodInYears',
        isInput: true,
        type: 'monthYearInput',
        defaultValueCompSymbol: 'A18'
      },
      {
        label: translate('realtor_cost'),
        objectKey: 'realtorCostsInPercentageTermsPreVat',
        isInput: true,
        type: 'percentage',
        defaultValueCompSymbol: 'A19'
      },
      {
        label: translate('additional_costs_lawyer_apraiser'),
        objectKey: 'relatedCostsLawyerAppraiserEtc',
        isInput: true,
        defaultValueCompSymbol: 'A21'
      },
      {
        label: translate('is_it_the_only_apartment_buying'),
        objectKey: 'onlyApartmentWhileBuying',
        isInput: true,
        defaultValue: true,
        type: 'boolean',
        hide: addApartmentMode
      },
      {
        label: translate('is_it_the_only_apartment_selling'),
        objectKey: 'onlyApartmentWhileSelling',
        isInput: true,
        defaultValue: true,
        type: 'boolean',
        hide: addApartmentMode
      },
      {
        label: translate('tax_on_rent'),
        objectKey: 'taxOnRent',
        isInput: true,
        defaultValueCompSymbol: 'A22',
        type: 'percentage',
        hide: addApartmentMode
      }
    ],
    [translate('buying')]: [
      { label: translate('price_per_square_meter'), objectKey: 'pricePerSquareMeter' },
      { label: translate('buying_tax'), objectKey: 'buyingTax' },
      { label: translate('realtor_cost'), objectKey: 'realtorCost' },
      { label: translate('equity_for_taking_mortgage'), objectKey: 'equityForTakingMortgage' },
      {
        label: translate('cost_of_retailer_lawyer'),
        objectKey: 'costOfRealtorBuyerAndAllRelatedCosts'
      },
      { label: translate('total_equity_to_put_in_the_deal'), objectKey: 'totalEquityToPutInTheDeal' },
      { label: translate('total_deal_cost'), objectKey: 'totalDealCost' },
      { label: translate('still_insufficient_amount_for_buying'), objectKey: 'stillInsufficientAmountForBuying' }
    ],
    [translate('mortgage')]: [
      {
        label: translate('mortgage_to_be_taken'),
        objectKey: 'mortgageToBeTakenDependingOnSeveralOptions'
      },
      { label: translate('monthly_mortgage_payment'), objectKey: 'mortgageMonthlyPayment' },
      { label: translate('total_monthly_payments'), objectKey: 'totalMonthlyPayments' },
      { label: translate('mortgage_left_at_the_end'), objectKey: 'mortgageLeftAtTheEndPeriodOfHolding' },
      { label: translate('total_loan_decrease'), objectKey: 'totalLoanDecrease' }
    ],
    [translate('rent')]: [
      { label: translate('rent_post_contruction'), objectKey: 'monthlyRent' },
      { label: translate('cost_of_vacancy_and_annual_repairs'), objectKey: 'costOfVacancyAndAnnualRepairs' },
      { label: translate('tax_on_rent'), objectKey: 'taxOnRent', type: 'percentage' },
      { label: translate('total_income_from_rent_after_vacancy_and_tax'), objectKey: 'totalIncomeFromRent' },
      {
        label: translate('total_income_from_rent_minus_monthly_payment'),
        objectKey: 'totalIncomeFromRentMinusTotalMonthlyPayments'
      }
    ],
    [translate('selling')]: [
      { label: translate('selling_price'), objectKey: 'sellingPrice' },
      { label: translate('profit_from_selling_brfore_tax'), objectKey: 'profitFromSellingBeforeTax' },
      { label: translate('profit_from_selling_after_tax'), objectKey: 'profitFromSellingAfterTax' }
    ],
    [translate('profit')]: [
      { label: translate('total_net_profit'), objectKey: 'totalNetProfit' },
      { label: translate('total_roi'), objectKey: 'totalRoi', type: 'percentage' },
      {
        label: translate('irr'),
        objectKey: 'irr',
        type: 'percentage',
        extraInfo: {
          Icon: Eye,
          key: 'irrBreakdown',
          timeLabel: 'month'
        }
      }
    ]
  };

  const filteredFields = {};
  for (const section in fields) {
    if (!sectionToHide[section]) {
      const filteredSection = fields[section].filter(field => !field.hide);
      if (filteredSection.length > 0) {
        filteredFields[section] = filteredSection;
      }
    }
  }

  return filteredFields;
};

export const getAllPaperApartmentIrrFields = (translate, addApartmentMode) => {
  const irrFields = getPaperApartmentIrrFields(translate);
  const informationFields = getInformationalFields(translate, PAPER_CALCULATOR_KEY, getInformationalFields);

  return [irrFields, informationFields].flatMap(fields => {
    return Object.keys(fields).flatMap(sectionName => fields[sectionName]);
  });
};

export const getAllRSUFields = translate => [
  {
    label: translate('marginal_rate_of_income'),
    objectKey: 'MarginalRateOfIncome',
    isInput: true,
    defaultValueCompSymbol: 'A26',
    type: 'percentage',
    linkedWithFirstSection: true
  },
  {
    label: translate('capital_appreciation_tax'),
    objectKey: 'CapitalAppreciationTax',
    isInput: true,
    defaultValueCompSymbol: 'A27',
    type: 'percentage',
    linkedWithFirstSection: true
  },
  {
    label: translate('value_of_stocks_with_marginal_tax_rate'),
    objectKey: 'ValueWithMarginalTaxRate',
    isInput: true,
    linkedWithFirstSection: true
  },
  {
    label: translate('value_of_stocks_with_capital_aprreciation_tax_rate'),
    objectKey: 'ValueWithCapitalAppreciationTaxRate',
    isInput: true,
    linkedWithFirstSection: true
  },
  {
    label: translate('total_value'),
    objectKey: 'TotalValue'
  },
  {
    label: translate('yearly_appreciation'),
    objectKey: 'YearlyAppreciation',
    isInput: true,
    type: 'percentage'
  },
  {
    label: translate('holding_period_in_years'),
    objectKey: 'realEstateHoldingPeriodInYears',
    isInput: true,
    linkedWithFirstSection: true
  },
  {
    label: translate('paying_tax_at_period_end'),
    objectKey: 'PayingTaxAtPeriodEnd',
    isInput: true,
    type: 'boolean',
    defaultValue: true
  },
  {
    label: translate('future_value_pre_tax'),
    objectKey: 'FutureValuePreTax'
  },
  {
    label: translate('tax'),
    objectKey: 'Tax'
  },
  {
    label: translate('net_value'),
    objectKey: 'NetValue'
  }
];

export const getAllOptionsFields = translate => [
  {
    label: translate('marginal_rate_of_income'),
    objectKey: 'MarginalRateOfIncome',
    isInput: true,
    defaultValueCompSymbol: 'A26',
    type: 'percentage',
    linkedWithFirstSection: true
  },
  {
    label: translate('capital_appreciation_tax'),
    objectKey: 'CapitalAppreciationTax',
    isInput: true,
    defaultValueCompSymbol: 'A27',
    type: 'percentage',
    linkedWithFirstSection: true
  },
  {
    label: translate('options_strike_price'),
    objectKey: 'OptionsStrikePrice',
    isInput: true,
    linkedWithFirstSection: true
  },
  {
    label: translate('stock_value_at_options_receival_date'),
    objectKey: 'StockValueAtOptionsReceivalDate',
    isInput: true,
    linkedWithFirstSection: true
  },
  {
    label: translate('current_stock_price'),
    objectKey: 'CurrentStockPrice',
    isInput: true,
    linkedWithFirstSection: true
  },
  {
    label: translate('stock_amount'),
    objectKey: 'StockAmount',
    isInput: true,
    linkedWithFirstSection: true
  },
  //
  {
    label: translate('required_equity_for_excercising'),
    objectKey: 'RequiredEquityForExercising'
  },
  {
    label: translate('profit_at_excersising_pre_tax'),
    objectKey: 'ProfitAtExcersisingPreTax'
  },
  {
    label: translate('tax_on_excersising'),
    objectKey: 'TaxOnExercising'
  },
  {
    label: translate('net_after_excersising_and_selling_stocks'),
    objectKey: 'NetAfterExercisingAndSelling'
  }
];
