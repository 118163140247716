import React from 'react';
import { Dropdown } from 'react-bootstrap';

const SmallDropdownToggle = ({ children, style = {}, ...props }) => {
  return (
    <Dropdown.Toggle size="sm" style={{ ...styleLocal, ...style }} {...props}>
      {children}
    </Dropdown.Toggle>
  );
};

const styleLocal = {
  padding: '0px 5px',
  fontSize: 13
};

export default SmallDropdownToggle;
