import React from 'react';
import { Modal } from 'react-bootstrap';
import FormGenerator from '../../../../../form-generator/FormGenerator';
import HorizontalProgress from '../../../../common/HorizontalProgress';
import { addStrategyForm, updateStrategyForm } from './form';
import useAuth from '../../../../../hooks/useAuth';

const AddUpdateStrategyModal = ({
  show,
  onHide,
  showProgress,
  size = 'lg',
  existingStrategy,
  toBeClonedStrategy,
  translate
}) => {
  const { user } = useAuth();

  return (
    <Modal size={size} show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton={!showProgress}>
        <Modal.Title>
          <h6 className="mb-0">
            <b>
              {translate(
                existingStrategy ? 'update_strategy_name' : toBeClonedStrategy ? `clone_strategy` : 'new_strategy',
                { strategy: toBeClonedStrategy?.name }
              )}
            </b>
          </h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto py-4">
        {show && (
          <FormGenerator
            prefix="strategy"
            formJson={
              existingStrategy || toBeClonedStrategy
                ? updateStrategyForm(toBeClonedStrategy, translate)
                : addStrategyForm(translate)
            }
            formValues={{
              [existingStrategy || toBeClonedStrategy ? 'Update Strategy' : 'New Strategy']: {
                name: existingStrategy?.name || toBeClonedStrategy?.name || '',
                totalYears: existingStrategy?.totalYears || toBeClonedStrategy?.totalYears || 10
              }
            }}
          />
        )}
        {showProgress && (
          <HorizontalProgress
            text={`${translate(!existingStrategy ? 'adding_strategy' : 'updating_strategy_name')}...`}
          />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default AddUpdateStrategyModal;
