import React, { useContext, useState } from 'react';
import { Alert, Card, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import { Redirect } from 'react-router';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import { snakeCase } from 'lodash';
import { UserContext } from '../../context/UserContext';
import FormGenerator from '../../form-generator/FormGenerator';
import { highlightError } from '../../form-generator/helpers/utility';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import { getStartedForm } from '../../helpers/forms';
import LanguagePicker from '../common/LanguagePicker';
import Logo from '../common/Logo';

// const generateUserId = user => {
//   // Generate initial userId
//   let baseUserId = user.name ? snakeCase(user.name) : user.email.split('@')[0];

//   // Ensure baseUserId is at least 6 characters long
//   while (baseUserId.length < 6) {
//     const randomNumber = getRandomInt({ max: 10 }); // Single digit random number
//     baseUserId += randomNumber;
//   }

//   // Return the unique userId
//   return baseUserId;
// };

function getRandomInt({ min = 0, max = 9999 }) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min)) + min;
}

const Login = () => {
  const [signInError, setSignInError] = useState('');
  const [formSubmitting, setFormSubmitting] = useState(false);

  const { translate } = useContext(LocalizeContext);
  const { isUserLoggedIn, login } = useContext(UserContext);
  const history = useHistory();
  if (isUserLoggedIn) {
    return <Redirect from="/get-started" to="/" />;
  }

  const checkIfPasswordsAreValid = (password, confirmPassword) => {
    let result = { valid: true, message: { password: '', confirmPassword: '' } };
    if (password.length < 6) {
      result = { valid: false, message: { password: 'Password should be at least 6 characters', confirmPassword: '' } };
    }

    if (password !== confirmPassword) {
      result = { valid: false, message: { password: '', confirmPassword: 'Passwords do not match' } };
    }

    return result;
  };

  const onCheckEmailFormSubmit = async form => {
    setSignInError('');
    const firstName = form['First Name'];
    const lastName = form['Last Name'];
    const email = form['Email'];
    const phone = form['Phone Number'];

    setFormSubmitting(true);
    try {
      const { response, error } = await makeApiRequests({
        endpoint: ENDPOINTS.GET_STARTED,
        requestBody: { name: firstName + ' ' + lastName, email, phone },
        method: 'POST'
      });

      setFormSubmitting(false);

      if (error) {
        setSignInError(error);
        return;
      }
      if (response) {
        toast.success('A link to create your password has been sent to your email. Please check your mail box', {
          autoClose:5000,          
        });
      }
      // login();
      history.push('/login');
      return true;
    } catch (e) {
      setFormSubmitting(false);
      setSignInError('Something went wrong! Please try again');
      console.log(e);
    }
  };

  window['onCheckEmailFormSubmit'] = onCheckEmailFormSubmit;

  const GetStartedForm = () => {
    return (
      <div className="fade-in">
        <FormGenerator
          formJson={getStartedForm}
          formValues={{
            'User ID': 'ahhaha'
          }}
        />{' '}
        <hr />
        <a style={styles.loginSignupText} className={`text-center text-dark`} href="/login">
          {translate('already_have_an_account')}
        </a>
      </div>
    );
  };

  return (
    <Container fluid className="h-100">
      <Row className="h-100">
        <Col xs={1} className="bg-dark d-block"></Col>
        <Col xs={11} className="pt-2 pb-3 px-0 px-md-5 bg-light">
          <div className="text-end">
            <LanguagePicker />
          </div>

          <Row className="justify-content-center h-100">
            <Col xs={10} md={6} className="align-self-center">
              <Card className="">
                <Card.Header className="p-3 bg-dark">
                  <div className="">
                    <Logo />
                  </div>
                </Card.Header>
                <Card.Body>
                  <h6 className="text-muted mx-2 my-1">{translate('welcome_to_ETICA_financials')}</h6>
                  <h6 className="text-muted mx-2 my-1">
                    <b className="text-dark">{translate('please_fill_the_form_to_continue')}</b>
                  </h6>
                  <hr />
                  {GetStartedForm()}
                  {formSubmitting && (
                    <ProgressBar
                      className="mt-1"
                      striped
                      animated
                      variant="dark"
                      now={100}
                      label={`${translate('creating_user')}...`}
                    />
                  )}
                  {signInError && (
                    <Alert style={{ fontSize: 14 }} className="mx-2 p-2" variant="danger">
                      {signInError}
                    </Alert>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const styles = {
  loginSignupText: {
    fontSize: 16,
    cursor: 'pointer',
    textDecoration: 'underline'
  }
};
export default Login;
