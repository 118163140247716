import { Button, Dropdown } from 'react-bootstrap';
import { PlusCircle } from 'react-bootstrap-icons';
import SmallDropdownToggle from '../../../../common/SmallDropdownToggle';
import SmallButton from '../../../../common/SmallButton';

const StrategyActions = ({
  assetLiabilityEditable,
  cashflowEditable,
  reinvestmentsEditable,
  onAddAssetClick,
  onAddLiabilityClick,
  onAddCashflowClick,
  onAddReinvestmentOption,
  disabled,
  translate
}) => {
  const actionsConfig = [
    {
      condition: assetLiabilityEditable,
      onClick: onAddAssetClick,
      text: translate('add_new_asset'),
      variant: 'success'
    },
    {
      condition: assetLiabilityEditable,
      onClick: onAddLiabilityClick,
      text: translate('add_liability'),
      variant: 'danger'
    },
    {
      condition: cashflowEditable,
      onClick: onAddCashflowClick,
      text: translate('add_cashflow'),
      variant: 'success'
    },
    {
      condition: reinvestmentsEditable,
      onClick: onAddReinvestmentOption,
      text: translate('add_reinvestment'),
      variant: 'success'
    }
  ].filter(a => Boolean(a.condition));

  return (
    <div>
      <div className="d-none d-md-block">
        {actionsConfig.map((action, index) => (
          <SmallButton
            key={index}
            size="sm"
            disabled={disabled}
            variant={action.variant}
            className="text-white mx-1 px-2 py-1"
            onClick={action.onClick}
          >
            {action.text}
          </SmallButton>
        ))}
      </div>

      <div className="d-block d-md-none">
        <Dropdown>
          <SmallDropdownToggle variant="outline-success" size="sm" aria-label="More actions">
            <span className="mid align-text-center">{translate('add_new')} ...</span>
          </SmallDropdownToggle>

          <Dropdown.Menu>
            {actionsConfig.map((action, index) => (
              <Dropdown.Item key={index} onClick={action.onClick} disabled={disabled}>
                {action.text}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
};

export default StrategyActions;
