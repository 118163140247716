import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Container, Row, ProgressBar, Alert, Modal } from 'react-bootstrap';
import { Redirect, useHistory } from 'react-router';
import FormGenerator from '../form-generator/FormGenerator';
import { emailForm, forgetPaswordForm, loginForm, registerForm, resetPasswordForm } from '../helpers/forms';
import { makeApiRequests } from '../helpers/api';
import { highlightError } from '../form-generator/helpers/utility';
import { toast } from 'react-toastify';
import Logo from '../components/common/Logo';
import { ENDPOINTS } from '../helpers/constants';
import CircularProgressBar from '../components/common/circular-progress';
import { LocalizeContext } from 'react-locale-language';
import LanguagePicker from '../components/common/LanguagePicker';
import { UserContext } from '../context/UserContext';
import ResendOTPButton from '../components/common/ResendOTPButton';

const Login = () => {
  const [signInError, setSignInError] = useState(false);
  const [loginMode, setLoginMode] = useState(true);
  const [formSubmitting, setFormSubmitting] = useState(false);
  const [otpResending, setOtpResending] = useState(false);

  const [showForgotPassModal, setShowForgotPassModal] = useState(false);
  const [forgotPasswordSubmitting, setForgotPasswordSubmitting] = useState(false);
  const [toBeRegisteredUser, setToBeRegisteredUser] = useState(null);
  const [forceHideForm, setForceHideForm] = useState(true);
  const [resettingPasswordEmail, setResettingPasswordEmail] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();
  const { translate } = useContext(LocalizeContext);
  const { isUserLoggedIn, login: loginLocally } = useContext(UserContext);

  useEffect(() => {
    setSignInError('');
    setForceHideForm(true);
    setTimeout(() => setForceHideForm(false), 300);
  }, [toBeRegisteredUser, loginMode]);

  if (isUserLoggedIn) {
    return <Redirect from="/login" to="/" />;
  }

  const auth = async ({ authEndpoint, requestBody }) => {
    setFormSubmitting(true);

    try {
      setSignInError('');
      const { response: authResult, error } = await makeApiRequests({
        endpoint: authEndpoint,
        requestBody: requestBody
      });

      setFormSubmitting(false);
      if (error) {
        setSignInError(error);
        return;
      }

      loginLocally();
    } catch (e) {
      setFormSubmitting(false);
      setSignInError('Something went wrong! Please try again');
      console.log(e);
    }
  };

  const onLoginFormSubmit = async form => {
    setSignInError('');
    const email = form['Email'];
    const password = form['Password'];

    auth({ authEndpoint: ENDPOINTS.LOGIN, requestBody: { email, password } });
  };

  const onRegisterFormSubmit = async form => {
    setSignInError('');

    const password = form['Password'];
    const confirmPassword = form['Confirm Password'];
    const otp = form['OTP'];

    if (password !== confirmPassword) {
      highlightError(document.getElementById('confirmPassword'), 'Passwords do not match');
      return;
    }

    auth({
      authEndpoint: ENDPOINTS.REGISTER,
      requestBody: { email: toBeRegisteredUser.email, password, temporaryKey: otp }
    });
  };

  const onForgotPasswordFormSubmit = async form => {
    const email = form['Email'];
    setForgotPasswordSubmitting(true);
    try {
      const { response, error } = await makeApiRequests({
        endpoint: ENDPOINTS.FORGOT_PASSWORD,
        requestBody: { email }
      });
      setForgotPasswordSubmitting(false);

      if (error) {
        setErrorMessage(error);
        return;
      }
      toast.success('A link to reset password has been sent to your email!');
      onForgotPasswordModalHide()
    } catch (e) {
      setForgotPasswordSubmitting(false);
      toast.error('Something went wrong! Please try again');
      console.log(e);
    }
  };

  const onCheckEmailFormSubmit = async form => {
    const isResending = form.resend;
    setSignInError('');
    const email = form['Email'];
    if (isResending) {
      setOtpResending(true);
    } else {
      setFormSubmitting(true);
    }
    try {
      const { response, error } = await makeApiRequests({
        endpoint: ENDPOINTS.GENERATE_REGISTER_OTP,
        requestBody: { email }
      });

      if (isResending) {
        setOtpResending(false);
      } else {
        setFormSubmitting(false);
      }

      if (error) {
        if (isResending) {
          toast.error(error);
        } else {
          setSignInError(error);
        }
        return;
      }

      setToBeRegisteredUser({ email });
      toast.info('If we recognize you, an OTP has been sent to your email address!', { autoClose: 3000 });
      return true;
    } catch (e) {
      if (isResending) {
        setOtpResending(false);
        toast.error('Something went wrong! Please try again');
      } else {
        setFormSubmitting(false);
        setSignInError('Something went wrong! Please try again');
      }
      console.log(e);
    }
  };

  const onResetPasswordFormSubmit = async form => {
    const otp = form['OTP'];
    const newPassword = form['New Password'];
    const confirmPassword = form['Confirm Password'];

    if (newPassword !== confirmPassword) {
      highlightError(document.getElementById('confirmPassword'), 'Passwords do not match');
      return;
    }

    setForgotPasswordSubmitting(true);

    try {
      const { response: authResult, error } = await makeApiRequests({
        endpoint: ENDPOINTS.RESET_PASSWORD,
        requestBody: { email: resettingPasswordEmail, newPassword, temporaryKey: otp }
      });

      setForgotPasswordSubmitting(false);

      if (error) {
        toast.error(error);
        return;
      }

      setResettingPasswordEmail(null);
      setShowForgotPassModal(false);
      toast.success('Password reset successfully! You can now login with your new password');
      history.push('/login');
    } catch (e) {
      setForgotPasswordSubmitting(false);
      toast.error('Something went wrong! Please try again');
      console.log(e);
    }
  };

  window['onResetPasswordFormSubmit'] = onResetPasswordFormSubmit;
  window['onCheckEmailFormSubmit'] = onCheckEmailFormSubmit;
  window['onForgotPasswordFormSubmit'] = onForgotPasswordFormSubmit;
  window['onLoginFormSubmit'] = onLoginFormSubmit;
  window['onRegisterFormSubmit'] = onRegisterFormSubmit;

  const onForgotPasswordModalHide = () => {
    setResettingPasswordEmail(false);
    setShowForgotPassModal(false);
    setErrorMessage('')
  };

  const ForgotPasswordModal = () => {
    return (
      <Modal show={showForgotPassModal} onHide={onForgotPasswordModalHide} centered backdrop="static">
        <Modal.Header closeButton={!forgotPasswordSubmitting}>
          <Modal.Title>{translate('forgot_password')}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          {!forgotPasswordSubmitting ? (
            <div className="fade-in">
              <FormGenerator prefix="fp" formJson={resettingPasswordEmail ? resetPasswordForm : forgetPaswordForm} />
              {errorMessage && (
                <Alert className="p-2 mx-2 mt-3 mid" variant="danger">
                  {errorMessage}
                </Alert>
              )}
            </div>
          ) : (
            <div className="p-5">
              <div className="p-5 text-center">
                <CircularProgressBar size={5} />
              </div>
            </div>
          )}
        </Modal.Body>
      </Modal>
    );
  };

  const LoginForm = () => {
    return (
      <div className="fade-in">
        {ForgotPasswordModal()}
        <FormGenerator formJson={loginForm} />
        <p
          style={styles.loginSignupText}
          className={`text-right mx-2 ${formSubmitting ? 'text-muted' : ''}`}
          onClick={() => {
            if (formSubmitting) return;

            setShowForgotPassModal(true);
          }}
        >
          {translate('forgot_password')}
        </p>
        <hr />
        <div className="d-flex justify-content-center">
          <a
            style={styles.loginSignupText}
            className={`text-dark mx-2 ${formSubmitting ? 'text-muted' : ''}`}
            // onClick={() => {
            //   if (formSubmitting) return;

            //   setToBeRegisteredUser(null);
            //   setLoginMode(false);
            // }}
            href="mailto:office@ethica.finance"
          >
            {translate('need_help_email_us')}
          </a>
        </div>
      </div>
    );
  };

  const RegisterForm = () => {
    return (
      <div className="fade-in">
        <FormGenerator formJson={registerForm} />
        <ResendOTPButton
          otpSendInProgress={otpResending}
          onSendOTP={() => onCheckEmailFormSubmit({ Email: toBeRegisteredUser.email, resend: true })}
        />

        <hr />
        <p
          style={styles.loginSignupText}
          className={`text-center ${formSubmitting ? 'text-muted' : ''}`}
          onClick={() => {
            if (formSubmitting) return;

            setToBeRegisteredUser(null);
            setLoginMode(true);
          }}
        >
          {translate('already_have_an_account')}
        </p>
      </div>
    );
  };

  const EmailRegisterForm = () => {
    return (
      <div className="fade-in">
        <FormGenerator formJson={emailForm} />

        <hr />
        <p
          style={styles.loginSignupText}
          className={`text-center ${formSubmitting ? 'text-muted' : ''}`}
          onClick={() => {
            if (formSubmitting) return;

            setToBeRegisteredUser(null);
            setLoginMode(true);
          }}
        >
          {translate('already_have_an_account')}
        </p>
      </div>
    );
  };

  return (
    <Container fluid className="h-100">
      <Row className="h-100">
        <Col xs={1} className="bg-dark d-block"></Col>
        <Col xs={11} className="pt-2 pb-3 px-0 px-md-5 bg-light">
          <div className="text-end">
            <LanguagePicker />
          </div>

          <Row className="justify-content-center h-100">
            <Col xs={10} md={6} className="align-self-center">
              <Card className="">
                <Card.Header className="p-3 bg-dark">
                  <div className="">
                    <Logo />
                  </div>
                </Card.Header>
                <Card.Body>
                  {!forceHideForm ? (
                    <>
                      <h6 className="text-muted mx-2 my-1">{translate('welcome_to_ETICA_financials')}</h6>
                      <h6 className="text-muted mx-2 my-1">
                        <b className="text-dark">
                          {toBeRegisteredUser !== null
                            ? translate('creating_an_account', {
                                email: toBeRegisteredUser.email
                              })
                            : loginMode
                            ? translate('sign_in_to_continue')
                            : translate('please_provide_your_email_address')}
                        </b>
                      </h6>
                      <hr />
                      {toBeRegisteredUser !== null ? RegisterForm() : loginMode ? LoginForm() : EmailRegisterForm()}
                    </>
                  ) : (
                    <div className="p-5">
                      <div className="p-5 text-center">
                        <CircularProgressBar size={5} />
                      </div>
                    </div>
                  )}
                  {formSubmitting && (
                    <ProgressBar
                      className="mt-1"
                      striped
                      animated
                      variant="dark"
                      now={100}
                      label={`${translate(
                        loginMode ? 'logging_in' : toBeRegisteredUser === null ? 'checking_email' : 'creating_account'
                      )}...`}
                    />
                  )}
                  {signInError && (
                    <Alert style={{ fontSize: 14 }} className="mx-2 p-2" variant="danger">
                      {signInError}
                    </Alert>
                  )}
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

const styles = {
  loginSignupText: {
    fontSize: 16,
    cursor: 'pointer',
    textDecoration: 'underline'
  }
};

export default Login;
