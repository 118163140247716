import React, { useContext, useEffect, useMemo, useState } from 'react';
import { ArrowDownCircle, ArrowUpCircle } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import { toast } from 'react-toastify';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import UnderlineButton from './UnderlineButton';
import VideoPlayer from './VideoPlayer';
import CircularProgressBar from './circular-progress';

const VideoPlayerPopup = ({ videoKey, autoplay }) => {
  const [videoLink, setVideoLink] = useState();
  const [videoLoading, setVideoLoading] = useState(false);
  const { translate } = useContext(LocalizeContext);

  const getAppVideo = async () => {
    setVideoLoading(true);
    try {
      const { response, error } = await makeApiRequests({
        endpoint: ENDPOINTS.APP_VIDEOS_LIST,
        requestBody: {
          filter: { key: videoKey }
        }
      });
      setVideoLoading(false);

      if (error) {
        toast.error(error);
        return;
      }

      setVideoLink(response?.[0]?.url);
    } catch (e) {
      setVideoLoading(false);
      toast.error('Could not get video, please try again!');
      console.log(e);
    }
  };

  useEffect(() => {
    getAppVideo();
  }, [videoKey]);

  return (
    <>
      {videoLoading && (
        <div className="p-5 text-center">
          <CircularProgressBar size={5} />
        </div>
      )}
      {!videoLoading &&
        (videoLink ? (
          <VideoPlayer videoLinks={[videoLink]} autoplay={autoplay} height={320} />
        ) : (
          <div className="p-5 text-center">
            <h4 className="text-muted large">{translate('video_not_found_please_contact_admin')}</h4>
          </div>
        ))}
    </>
  );
};

const ExplainatoryVideoButton = ({ videoKey, showLabel, showPlayer }) => {
  const { translate } = useContext(LocalizeContext);
  const [showVideoPlayer, setShowVideoPlayer] = useState(false);

  useEffect(() => {
    setShowVideoPlayer(showPlayer);
  }, [showPlayer]);

  let autoPlay = useMemo(() => !showPlayer, [showPlayer]);

  return (
    <div className="border rounded p-1">
      <UnderlineButton
        variant="primary"
        fontSize="mid"
        iconSize={12}
        Icon={!showVideoPlayer ? ArrowDownCircle : ArrowUpCircle}
        text={translate(
          showLabel ? videoKey : !showVideoPlayer ? 'show_explainatory_video' : 'hide_explainatory_video'
        )}
        onClick={() => setShowVideoPlayer(!showVideoPlayer)}
        className="mb-2"
      />
      {showVideoPlayer && <VideoPlayerPopup videoKey={videoKey} autoplay={autoPlay} />}
    </div>
  );
};

export default ExplainatoryVideoButton;
