import React from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Table } from 'react-bootstrap';
import { GripVertical } from 'react-bootstrap-icons';
import LTRNumberCell from '../LTRNumberCell';

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getItemStyle = (isDragging, draggableStyle) => ({
  background: isDragging ? '#f7ebd6' : 'white',
  ...draggableStyle
});

export const DraggableTable = ({ list, columns, onListSort, draggable }) => {
  const onDragEnd = result => {
    if (!result.destination) {
      return;
    }
    let movedItems = reorder(list, result.source.index, result.destination.index);
    onListSort && onListSort(movedItems);
  };

  return (
    <Table bordered responsive hover className="smallFont mt-2">
      <thead>
        <tr className="bg-dark text-white">
          <th></th>
          {columns.map(column => {
            return <th>{column.label}</th>;
          })}
        </tr>
      </thead>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable isDropDisabled={!draggable} droppableId="droppable">
          {provided => (
            <tbody {...provided.droppableProps} ref={provided.innerRef}>
              {list && list.length > 0 ? (
                list.map((rowItem, index) => (
                  <Draggable
                    isDragDisabled={!draggable}
                    key={rowItem._id}
                    draggableId={'q-' + rowItem._id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                      >
                        <td style={{ width: '5%' }}>
                          <GripVertical />
                        </td>

                        {columns.map(column => (
                          <td>
                            {column.isMonetaryField ? (
                              <LTRNumberCell value={rowItem[column.key]} />
                            ) : (
                              rowItem[column.key]
                            )}
                          </td>
                        ))}
                      </tr>
                    )}
                  </Draggable>
                ))
              ) : (
                <tr>
                  <td colSpan={columns.length + 2}>
                    <h4 style={{ textAlign: 'center', fontSize: '18px' }}>No Data Available</h4>
                  </td>
                </tr>
              )}
              {provided.placeholder}
            </tbody>
          )}
        </Droppable>
      </DragDropContext>
    </Table>
  );
};
