import { useFormik } from 'formik';
import { isNull, snakeCase } from 'lodash';
import moment from 'moment';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Col, FormControl, Modal, Row } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import {
  maxTimeStamp,
  monthSmall,
  primaryBankAccountName,
  transferFrequencyOptions,
  transferTypeOptions
} from '../../../../../helpers/constants';
import investmentOptionSchema from '../../../../../schema/investmentOptionSchema';
import { formatCurrency, isRSUAsset } from './helper';
import ExplainatoryVideoButton from '../../../../common/ExplainatoryVideoButton';
import { isNullOrUndefined } from '../../../../../helpers/global';

const ReinvestmentModal = ({
  initialValues,
  show,
  hideDialog,
  strategyYears,
  handleReinvestmentSubmit,
  assets,
  fixedIncomeAssets,
  liabilities,
  isSubmitting: externalSubmitting,
  incompleteYearsOfStrategy
}) => {
  const isEdit = useMemo(() => !!initialValues._id, [initialValues]);
  const [isSubmitting, setSubmitting] = useState(false);
  const { translate } = useContext(LocalizeContext);

  const assetOption = useMemo(() =>
    [...assets, ...fixedIncomeAssets]
      .filter(a => !isRSUAsset(a))
      .map(asset => ({
        label: asset.name === primaryBankAccountName ? translate('primary_bank_account') : asset.name,
        value: `asset:${asset._id}`
      }))
  );
  const liabilityOption = useMemo(() =>
    liabilities.map(liability => ({ label: liability.name, value: `liability:${liability._id}` }))
  );

  const onStartYearChange = e => {
    const startYear = e.target.value;
    if (!startYear) return;

    const currentMonth = formik.values.startMonth;
    if (currentMonth !== 0 && !currentMonth) return;

    const currentMonthNum = Number(currentMonth);
    const incompleteYear = incompleteYearsOfStrategy?.[startYear];

    if (incompleteYear && (currentMonthNum < incompleteYear.start || currentMonthNum > incompleteYear.end - 1)) {
      formik.setFieldValue('startMonth', incompleteYear.start);
    }
  };

  const onEndYearChange = e => {
    const endYear = e.target.value;
    if (!endYear) return;

    const currentMonth = formik.values.endMonth;
    if (currentMonth !== 0 && !currentMonth) return;

    const currentMonthNum = Number(currentMonth);
    const incompleteYear = incompleteYearsOfStrategy?.[endYear];

    if (incompleteYear && (currentMonthNum < incompleteYear.start || currentMonthNum > incompleteYear.end - 1)) {
      formik.setFieldValue('endMonth', incompleteYear.start);
    }
  };

  useEffect(() => {
    let values = { ...initialValues };
    [{ monthKey: 'startMonth', yearKey: 'startYear' }].forEach(({ monthKey, yearKey }) => {
      const year = values[yearKey];
      if (!year) return;

      const currentMonth = values[monthKey];
      if (currentMonth !== 0 && !currentMonth) return;

      const currentMonthNum = Number(currentMonth);
      const incompleteYear = incompleteYearsOfStrategy?.[year];

      if (incompleteYear && (currentMonthNum < incompleteYear.start || currentMonthNum > incompleteYear.end - 1)) {
        values[monthKey] = incompleteYear.start;
      }
    });
    formik.setValues(values);
  }, [initialValues]);

  const formik = useFormik({
    initialValues,
    validationSchema: investmentOptionSchema,
    validateOnChange: false,
    onSubmit: async values => {
      if (!validateContextually()) {
        return;
      }
      setSubmitting(true);
      const success = await handleReinvestmentSubmit(values);
      setSubmitting(false);
      if (!success) return;

      formik.resetForm();
      hideDialog();
    }
  });

  const validateContextually = () => {
    if (formik.values.endYear && isNullOrUndefined(formik.values.endMonth)) {
      formik.setFieldError('endMonth', 'End Month is required when end year is provided');
      return false;
    }

    if (formik.values.startYear > formik.values.endYear) {
      formik.setFieldError('startYear', 'Start date cannot be after end date');
      formik.setFieldError('endYear', 'End date cannot be before start date');
      return false;
    }

    if (formik.values.startYear === formik.values.endYear) {
      if (formik.values.startMonth > formik.values.endMonth) {
        formik.setFieldError('startMonth', 'Start date cannot be after end date');
        formik.setFieldError('endMonth', 'End date cannot be before start date');
        return false;
      }
    }

    //one of fromId or toId is required
    if (!formik.values['fromId'] && !formik.values['toId']) {
      formik.setFieldError('fromId', 'Either "Transfer Applied To" or a "Balance With" is required');
      return false;
    }

    //IF ONLY TARGET IS PRESENT TRANSFER TYPE CANNOT BE RELATIVE [We do not know relative to what source]
    if (!formik.values['fromId'] && formik.values['toId'] && formik.values['transferValueType'] !== 'fixed') {
      formik.setFieldError('transferValueType', '"Transfer Applied To" is required when transfer type is not fixed');
      return false;
    }

    const [fromType, fromId] = formik.values['fromId'].split(':');
    const [toType, toId] = formik.values['toId'].split(':');

    if (fromId === toId) {
      formik.setFieldError('fromId', '"Transfer Applied To" and "Balance With" cannot be same');
      return false;
    }

    const checks = [
      { type: fromType, id: fromId },
      { type: toType, id: toId }
    ];

    for (const { type, id } of checks) {
      if (!type) continue;

      const selectedFromAsset = (type === 'asset' ? assets : liabilities).find(asset => asset._id === id);
      const selectedAssetBuyingDate = moment(
        new Date().setFullYear(
          selectedFromAsset.buyingYear || selectedFromAsset.startYear,
          selectedFromAsset.buyingMonth || selectedFromAsset.startMonth || 0,
          1
        )
      );
      const selectedAssetSellingDate =
        selectedFromAsset.sellingYear || selectedFromAsset.endYear
          ? moment(
              new Date().setFullYear(
                selectedFromAsset.sellingYear || selectedFromAsset.endYear,
                selectedFromAsset.sellingMonth || selectedFromAsset.endMonth || 0,
                1
              )
            )
          : moment(new Date(maxTimeStamp));

      const startDate = moment(new Date().setFullYear(formik.values.startYear, formik.values.startMonth, 1));
      const endDate =
        formik.values.endYear && formik.values.endMonth
          ? moment(new Date().setFullYear(formik.values.endYear, formik.values.endMonth, 1))
          : null;

      if (!startDate.isBetween(selectedAssetBuyingDate, selectedAssetSellingDate, undefined, '[]')) {
        formik.setFieldError(
          'startYear',
          `Date must be within buying/selling year of the asset ${selectedFromAsset.name}`
        );
        return false;
      }

      if (endDate && !endDate.isBetween(selectedAssetBuyingDate, selectedAssetSellingDate, undefined, '[]')) {
        formik.setFieldError(
          'endYear',
          `Date must be within buying/selling year of the asset ${selectedFromAsset.name}`
        );
        return false;
      }
    }
    return true;
  };

  return (
    <Modal size="lg" show={show} onHide={hideDialog} centered backdrop="static">
      <Modal.Header closeButton={!(isSubmitting || externalSubmitting)}>
        <Modal.Title>
          <h6 className="mb-0 pt-2">{translate(isEdit ? 'update_reinvestment' : 'add_reinvestment')}</h6>
        </Modal.Title>
      </Modal.Header>
      <form>
        <Modal.Body className="overflow-auto">
          <ExplainatoryVideoButton videoKey="add_reinvestment" />

          <Row className="mt-1">
            <Col>
              <label className="mid" htmlFor="type">
                {translate('notes')}
              </label>
              <FormControl
                size="sm"
                as={'textarea'}
                rows={2}
                id="notes"
                onChange={formik.handleChange}
                value={formik.values.notes}
                name="notes"
                onBlur={() => formik.handleBlur}
              />

              {formik.errors.notes ? (
                <p style={{ color: 'red' }} className="mid">
                  {formik.errors.notes}
                </p>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row style={{ padding: '5px' }}>
            <Col xs={4}>
              <label className="mid" htmlFor="value">
                {translate('value')}
              </label>
              <input
                autoComplete="off"
                id="value"
                type="text"
                className="form-control form-control-sm"
                name="value"
                onChange={e => {
                  let inputValue = Number(e.target.value.replaceAll(',', '') || 0);
                  if (Number.isNaN(inputValue)) return;
                  formik.setFieldValue('value', inputValue);
                }}
                value={formatCurrency(formik.values.value)}
                onBlur={() => formik.handleBlur}
              />
              {formik.errors.value ? (
                <p style={{ color: 'red' }} className="mid">
                  {formik.errors.value}
                </p>
              ) : (
                <></>
              )}
            </Col>
            <Col xs={4}>
              <label className="mid" htmlFor="type">
                {translate('transfer_type')}
              </label>
              <select
                id="transferValueType"
                name="transferValueType"
                className="form-select form-select-sm mb-3"
                onChange={formik.handleChange}
                value={formik.values.transferValueType}
              >
                <option value=""></option>
                {transferTypeOptions.map((option, index) => (
                  <option value={option.value} key={option.value}>
                    {translate(snakeCase(option.label))}
                  </option>
                ))}
              </select>
              {formik.errors.transferValueType ? (
                <p style={{ color: 'red' }} className="mid">
                  {formik.errors.transferValueType}
                </p>
              ) : (
                <></>
              )}
            </Col>
            <Col xs={4}>
              <label className="mid" htmlFor="type">
                {translate('frequency')}
              </label>
              <select
                id="frequency"
                name="frequency"
                className="form-select form-select-sm mb-3"
                onChange={formik.handleChange}
                value={formik.values.frequency}
              >
                {transferFrequencyOptions.map((option, index) => (
                  <option value={option.value} key={option.value}>
                    {translate(snakeCase(option.value))}
                  </option>
                ))}
              </select>
              {formik.errors.frequency ? (
                <p style={{ color: 'red' }} className="mid">
                  {formik.errors.frequency}
                </p>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row style={{ padding: '5px' }}>
            <Col xs={6}>
              <label className="mid" htmlFor="type">
                {translate('transfer_applied_to')}
              </label>
              <select
                id="fromId"
                name="fromId"
                className="form-select form-select-sm mb-3"
                onChange={formik.handleChange}
                value={formik.values.fromId}
              >
                <option value=""></option>
                {[...assetOption, ...liabilityOption].map((option, index) => (
                  <option value={option.value} key={index}>
                    {option.label}
                  </option>
                ))}
              </select>
              {formik.errors.fromId ? (
                <p style={{ color: 'red' }} className="mid">
                  {formik.errors.fromId}
                </p>
              ) : (
                <></>
              )}
            </Col>
            <Col xs={6}>
              <label className="mid" htmlFor="type">
                {translate('balance_with')}
              </label>
              <select
                id="toId"
                name="toId"
                className="form-select form-select-sm mb-3"
                onChange={formik.handleChange}
                value={formik.values.toId}
              >
                <option value=""></option>
                {[...assetOption, ...liabilityOption].map((option, index) => (
                  <option value={option.value} key={index}>
                    {option.label}
                  </option>
                ))}
              </select>
              {formik.errors.toId ? (
                <p style={{ color: 'red' }} className="mid">
                  {formik.errors.toId}
                </p>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row style={{ padding: '5px' }}>
            <Col>
              <label className="mid" htmlFor="startYear">
                {translate('start_year')}
              </label>
              <select
                id="startYear"
                name="startYear"
                className="form-select form-select-sm mb-3"
                value={formik.values.startYear}
                onChange={e => {
                  onStartYearChange(e);
                  formik.handleChange(e);
                }}
              >
                <option value=""></option>
                {strategyYears.map((year, index) => (
                  <option value={year} key={index}>
                    {year}
                  </option>
                ))}
              </select>
              {formik.errors.startYear ? (
                <p style={{ color: 'red' }} className="mid">
                  {formik.errors.startYear}
                </p>
              ) : (
                <></>
              )}
            </Col>
            <Col>
              <label className="mid" htmlFor="startMonth">
                {translate('start_month')}
              </label>
              <select
                id="startMonth"
                name="startMonth"
                className="form-select form-select-sm mb-3"
                value={formik.values.startMonth}
                onChange={formik.handleChange}
              >
                <option value=""></option>
                {(incompleteYearsOfStrategy?.[formik.values.startYear]
                  ? monthSmall.slice(
                      incompleteYearsOfStrategy?.[formik.values.startYear].start,
                      incompleteYearsOfStrategy?.[formik.values.startYear].end
                    )
                  : monthSmall
                ).map((month, index) => (
                  <option
                    value={index + (incompleteYearsOfStrategy?.[formik.values.startYear]?.start || 0)}
                    key={index}
                  >
                    {month}
                  </option>
                ))}
              </select>
              {formik.errors.startMonth ? (
                <p style={{ color: 'red' }} className="mid">
                  {formik.errors.startMonth}
                </p>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <Row style={{ padding: '5px' }}>
            <Col>
              <label className="mid" htmlFor="endYear">
                {translate('end_year')}
              </label>
              <select
                id="endYear"
                name="endYear"
                className="form-select form-select-sm mb-3"
                value={formik.values.endYear}
                onChange={e => {
                  onEndYearChange(e);
                  formik.handleChange(e);
                }}
              >
                <option value=""></option>
                {strategyYears.map((year, index) => (
                  <option value={year} key={index}>
                    {year}
                  </option>
                ))}
              </select>
              {formik.errors.endYear ? (
                <p style={{ color: 'red' }} className="mid">
                  {formik.errors.endYear}
                </p>
              ) : (
                <></>
              )}
            </Col>
            <Col>
              <label className="mid" htmlFor="endMonth">
                {translate('end_month')}
              </label>
              <select
                id="endMonth "
                name="endMonth"
                className="form-select form-select-sm mb-3"
                value={formik.values.endMonth}
                onChange={formik.handleChange}
              >
                <option value=""></option>
                {(incompleteYearsOfStrategy?.[formik.values.endYear]
                  ? monthSmall.slice(
                      incompleteYearsOfStrategy?.[formik.values.endYear].start,
                      incompleteYearsOfStrategy?.[formik.values.endYear].end
                    )
                  : monthSmall
                ).map((month, index) => (
                  <option value={index + (incompleteYearsOfStrategy?.[formik.values.endYear]?.start || 0)} key={index}>
                    {month}
                  </option>
                ))}
              </select>
              {formik.errors.endMonth ? (
                <p style={{ color: 'red' }} className="mid">
                  {formik.errors.endMonth}
                </p>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            disabled={isSubmitting || externalSubmitting}
            size="sm"
            className="mr-2 text-white btn btn-success"
            onClick={() => {
              formik.setFieldValue('updateGroup', false);
              formik.submitForm();
            }}
          >
            {translate(isEdit ? 'update' : 'add')}
          </Button>

          <Button
            disabled={isSubmitting || externalSubmitting}
            size="sm"
            variant="success"
            className="mr-2 text-white"
            onClick={() => {
              formik.setFieldValue('updateGroup', true);
              formik.submitForm();
            }}
          >
            {translate(isEdit ? 'group_update' : 'group_add')}
          </Button>

          <Button
            disabled={isSubmitting || externalSubmitting}
            size="sm"
            variant="secondary"
            onClick={() => {
              formik.resetForm();
              hideDialog();
            }}
          >
            {translate('close')}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ReinvestmentModal;
