import React from 'react';
import { Button, FormControl } from 'react-bootstrap';
import { Clipboard, Envelope, Person, PlusCircle, Trash } from 'react-bootstrap-icons';
import SlidingSideBar from '../../../common/SlidingSideBar/SlidingSideBar';
import { toast } from 'react-toastify';

const CustomerShare = ({
  delegations = [],
  onUserRemoveClick,
  translate,
  userBeingAdded,
  onUserBeingAddedChange,
  inProgress,
  onAddUserClick
}) => {
  const copyShareLink = token => {
    navigator.clipboard.writeText(`${process.env.REACT_APP_APP_URL}/share/${token}`);
    toast.success('Shared link has been copied');
  };

  return (
    <>
      <div className="mb-4">
        <h6 className="mb-1 px-2 mid fw-bold">
          <PlusCircle />
          <span className="mx-1 align-middle">{translate('share_with_new_user')}:</span>
        </h6>
        <div className="d-flex">
          <div className="flex-grow-1 px-2">
            <FormControl
              autoComplete="off"
              placeholder={`${translate('email')}...`}
              size="sm"
              type="email"
              value={userBeingAdded}
              onChange={e => onUserBeingAddedChange(e.target.value)}
              disabled={inProgress}
            />
          </div>
          <div>
            <Button size="sm" disabled={!userBeingAdded || inProgress} onClick={onAddUserClick}>
              {translate('share')}
            </Button>
          </div>
        </div>
      </div>
      <hr />
      {delegations.length > 0 ? (
        <>
          <h5 className="mid text-muted fw-bold mb-3">{translate('customer_shared')}</h5>
          {delegations.map(u => (
            <>
              <div className="d-flex border rounded my-1 p-2">
                <div className="flex-grow-1 d-flex align-items-center px-1">
                  <h6 className="mb-0 text-muted mid mx-1">{u.email || translate('no_email')}</h6>
                  <Clipboard size={15} onClick={() => copyShareLink(u?.shareToken)} className="hover mx-1" />
                </div>
                <div className="mx-2 align-self-center">
                  <Trash onClick={() => onUserRemoveClick(u)} className="text-danger hover-light" size={15} />
                </div>
              </div>
            </>
          ))}
        </>
      ) : (
        <h5 className="mid text-muted text-center p-5">{translate('customer_not_shared')}</h5>
      )}
    </>
  );
};

const CustomerShareSidebar = ({
  customer,
  show,
  onHide,
  onUserRemoveClick,
  userBeingAdded,
  onUserBeingAddedChange,
  onAddUserClick,
  translate,
  showProgress
}) => {
  return (
    <SlidingSideBar
      zIndex={1002}
      visible={show}
      onClose={onHide}
      showCloseButton
      title={`${translate('share_strategies')} (${customer?.name || translate('no_name')})`}
    >
      {show && (
        <CustomerShare
          delegations={customer['sharedWith']}
          onUserRemoveClick={onUserRemoveClick}
          userBeingAdded={userBeingAdded}
          onUserBeingAddedChange={onUserBeingAddedChange}
          onAddUserClick={onAddUserClick}
          translate={translate}
          inProgress={showProgress}
        />
      )}
    </SlidingSideBar>
  );
};

export default CustomerShareSidebar;
