import React from 'react';

const SectionRow = ({ sectionName, span, isRtlLanguage }) => (
  <tr className={`bg-dark  text-white text-${isRtlLanguage ? 'end' : 'start'} no-pointer-events`}>
    <td className="p-1">
      <b>{sectionName}</b>
    </td>
    <td className="p-1" colSpan={span - 1}></td>
  </tr>
);

export default SectionRow;
