import React, { useContext } from 'react';
import { Dropdown } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import { languageOptions, setActiveLanguage } from '../../helpers/localization';

const LanguagePicker = () => {
  const { changeLanguage, langCode } = useContext(LocalizeContext);

  const onLanguageChange = lang => {
    changeLanguage(lang);
    setActiveLanguage(lang);
  };

  return (
    <Dropdown className="mx-3">
      <Dropdown.Toggle size="sm" variant="outline-dark" style={{ fontSize: 12, border: 0 }}>
        {langCode === 'Hebrew' ? 'עִברִית' : langCode}
      </Dropdown.Toggle>
      <Dropdown.Menu>
        {languageOptions.map(l => (
          <Dropdown.Item onClick={() => onLanguageChange(l)} key={l} active={l === langCode}>
            {l === 'Hebrew' ? 'עִברִית' : l}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguagePicker;
