import React, { useContext, useMemo } from 'react';
import { Redirect, Route, Switch, useLocation, withRouter } from 'react-router';
import Admin from '../components/admin';
import Calculators from '../components/calculators';
import Header from '../components/common/Header';
import NotFound from '../components/common/NotFound';
import Customer from '../components/customer';
import Profile from '../components/profile';
import { UserContext } from '../context/UserContext';
import { isCustomer, isSystemUser } from '../helpers/global';
import BrowseAppartments from '../components/appartment/BrowseAppartments';
import CachedResponse from '../components/common/CachedResponse';

const AuthenticatedApp = () => {
  const { isUserLoggedIn, user, viewingUser } = useContext(UserContext);
  const authRole = useMemo(() => (viewingUser ?? user)?.['role'], [user, viewingUser]);

  const location = useLocation();
  if (!isUserLoggedIn) {
    return <Redirect from="/" to="/login" />;
  }

  if (location.pathname === '/') {
    return <Redirect from="/" to={isCustomer(authRole) ? '/customer' : '/admin'} />;
  }

  return (
    <>
      <Header />
      <Switch>
        {isCustomer(authRole) ? (
          <Route path="/customer">
            <Customer />
          </Route>
        ) : (
          <Route path="/admin">
            <Admin />
          </Route>
        )}
        {/* <Route path="/customer">
            <Customer />
          </Route> */}
        <Route path="/calculators">
          <Calculators />
        </Route>
        <Route path="/profile">
          <Profile />
        </Route>
        {isSystemUser(user?.role) && (
          <Route path="/apartments">
            <CachedResponse
              listEndpoint={'comps'}
              requestBody={{ filter: { customer: false } }}
              responseModifier={response => response[0]}
              render={compTable => <BrowseAppartments adminCompTable={compTable} />}
            />
          </Route>
        )}
        <Route path="*">
          <NotFound />
        </Route>
      </Switch>
    </>
  );
};

export default withRouter(AuthenticatedApp);
