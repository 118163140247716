import * as Yup from 'yup';
import { eventActionTypeOptions, oneOffEventTypeOptions } from '../helpers/constants';

const OneOffEventSchema = Yup.object().shape({
  description: Yup.string()
    .trim()
    .max(128, `Description must be less than ${128} characters`)
    .required('Please enter the name'),
  notes: Yup.string().trim(),
  type: Yup.string().oneOf(oneOffEventTypeOptions),
  action: Yup.string()
    .oneOf(eventActionTypeOptions)
    .required('action is required'),
  value: Yup.number('Value must be a number')
    .min(0)
    .required('Value is required'),
  year: Yup.number().required('Please select a year'),
  month: Yup.number()
    .min(0)
    .max(11)
    .required('Please select a month')
});

export default OneOffEventSchema;
