import React from 'react';
import LiabilitySheet from './LiabilitySheet';
import OneOffEventSheet from './OneOffEventSheet';
import ReinvestmentSheet from './Reinvestment';
import StrategyBalanceSheet from './StrategyBalanceSheet';
import TaxSheet from './TaxSheet';

const BalanceSheet = ({
  strategy,
  editable,
  cashflowEditable,
  assetLiabilityEditable,
  reinvestmentsEditable,
  calculatedStrategy,
  onDuplicateAssetLiabilityClick,
  onAssetLiabilityEditClick,
  onAssetLiabilityDeleteClick,
  onAddEventClick,
  onEditCashflowClick,
  onDeleteCashflowClick,
  onAddCashflowClick,
  onAddLiabilityClick,
  onEditEventClick,
  onOneOffEventDeleteClick,
  onAddReinvestmentOption,
  onEditReinvestmentOption,
  onDeleteReinvestmentOption,
  onDisableReinvestmentClick,
  onDisableAllReinvestmentsCheck,
  taxSummary,
  disabled,
  onNameUpdateSubmit,
  quickRenaming
}) => {
  return (
    <>
      <StrategyBalanceSheet
        editable={editable}
        cashflowEditable={editable && cashflowEditable}
        assetLiabilityEditable={editable && assetLiabilityEditable}
        disabled={disabled}
        onDuplicateAssetLiabilityClick={onDuplicateAssetLiabilityClick}
        strategy={calculatedStrategy}
        onEditClick={onAssetLiabilityEditClick}
        onDeleteClick={onAssetLiabilityDeleteClick}
        onAddEventClick={onAddEventClick}
        onEditCashflowClick={onEditCashflowClick}
        onDeleteCashflowClick={onDeleteCashflowClick}
        onAddCashflowClick={onAddCashflowClick}
        onNameUpdateSubmit={onNameUpdateSubmit}
        quickRenaming={quickRenaming}
      />
      <LiabilitySheet
        editable={editable && assetLiabilityEditable}
        liabilities={calculatedStrategy['liabilities']}
        onAddLiabilityClick={onAddLiabilityClick}
        disabled={disabled}
      />
      <OneOffEventSheet
        strategy={strategy}
        editable={editable && assetLiabilityEditable}
        onEditEventClick={onEditEventClick}
        onOneOffEventDeleteClick={onOneOffEventDeleteClick}
      />
      <ReinvestmentSheet
        disabled={disabled}
        editable={editable && reinvestmentsEditable}
        hasCyclicInvestments={calculatedStrategy.hasCyclicInvestments}
        reInvestments={calculatedStrategy.investments}
        onAddReinvestmentOption={onAddReinvestmentOption}
        assets={calculatedStrategy.assets}
        liabilities={calculatedStrategy.liabilities}
        onEditReinvestmentClick={onEditReinvestmentOption}
        onDeleteReinvestmentClick={onDeleteReinvestmentOption}
        onDisableReinvestmentClick={onDisableReinvestmentClick}
        onDisableAllReinvestmentsCheck={onDisableAllReinvestmentsCheck}
      />
      {taxSummary && <TaxSheet taxSummary={taxSummary} />}
    </>
  );
};

export default BalanceSheet;
