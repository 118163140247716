import React, { useContext, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { isRtl } from '../../helpers/localization';
import { LocalizeContext } from 'react-locale-language';

const FloatingButton = ({ text, variant = 'dark', disabled = false, Icon, onClick, className = '', style = {} }) => {
  const { langCode } = useContext(LocalizeContext);

  const position = useMemo(() => (!isRtl(langCode) ? { bottom: 0, right: 0 } : { bottom: 0, left: 0 }), [langCode]);

  return (
    <Button
      variant={variant}
      style={{ position: 'fixed', zIndex: 1000, ...position, ...style }}
      className={`m-4 ${className}`}
      disabled={disabled}
      size="sm"
      onClick={onClick}
    >
      {Icon && <Icon className="mx-2" />}
      <span className="align-middle">{text}</span>
    </Button>
  );
};

export default FloatingButton;
