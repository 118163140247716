import React, { useMemo, useState } from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';
import { Card, FormCheck } from 'react-bootstrap';
import { monthSmall } from '../../../../../../helpers/constants';
import Tabs from '../../../../../common/Tabs';
import { LocalizeContext } from 'react-locale-language';

const mergeDates = ({ datesToMerge = [] }) => {
  const mergedProblems = [];

  let problemStartAndEnd;
  datesToMerge.forEach((p, index) => {
    const { month, year } = p;
    if (!problemStartAndEnd) {
      problemStartAndEnd = { startMonth: month, startYear: year, endYear: year, endMonth: month };
    } else {
      const { endMonth, endYear } = problemStartAndEnd;
      //if it is same as previous then ignore
      if (areDateEquals(month, year, endMonth, endYear)) {
        if (index === datesToMerge.length - 1) {
          mergedProblems.push({ ...p, ...problemStartAndEnd });
        }
        return;
      }

      //find if this problem is continued from previous month

      const currentDate = new Date(year, month, 1);
      currentDate.setMonth(currentDate.getMonth() - 1);

      if (areDateEquals(currentDate.getMonth(), currentDate.getFullYear(), endMonth, endYear)) {
        problemStartAndEnd.endYear = year;
        problemStartAndEnd.endMonth = month;
      } else {
        mergedProblems.push({ ...p, ...problemStartAndEnd });
        problemStartAndEnd = { startMonth: month, startYear: year, endYear: year, endMonth: month };
        return;
      }
    }

    if (index === datesToMerge.length - 1) {
      mergedProblems.push({ ...p, ...problemStartAndEnd });
    }
  });

  return mergedProblems;
};

const areDateEquals = (month1, year1, month2, year2) => month1 === month2 && year1 === year2;

const getTextFromProblem = (problem, translate) => {
  switch (problem.type) {
    case 'negativeAssetValue':
      return translate('asset_has_negative_value', {
        assetName: problem.assetName,
        date: `${monthSmall[problem.month]} ${problem.year}`
      });
    case 'marginNotMet':
      return translate('margin_not_met', {
        stockType: problem.stockType,
        loanName: problem.loanName,
        date: `${monthSmall[problem.month]} ${problem.year}`
      });
    default:
      return '';
  }
};

const getLiquidationTextFromMergedDate = (date, translate) => {
  return areDateEquals(date.startMonth, date.startYear, date.endMonth, date.endYear)
    ? translate('asset_liquidated_on', {
        date: `${monthSmall[date.startMonth]} ${date.startYear}`
      })
    : translate('asset_liquidated_from_to', {
        date1: `${monthSmall[date.startMonth]} ${date.startYear}`,
        date2: `${monthSmall[date.endMonth]} ${date.endYear}`
      });
};

const combineAssetLiquidations = (problems = []) => {
  const combinedAssetLiquidations = {};
  const allAssetLiquidations = problems.flatMap(p => p.assetLiquidations || []);

  const allAssets = [...new Set(allAssetLiquidations.map(a => a.assetName))];

  allAssets.forEach(aName => {
    const liquidationsOfThisAsset = allAssetLiquidations.filter(l => l.assetName === aName);
    combinedAssetLiquidations[aName] = mergeDates({ datesToMerge: liquidationsOfThisAsset });
  });
  return combinedAssetLiquidations;
};

const Problem = ({ problem, translate }) => {
  return (
    <Card className="mb-2">
      <Card.Header className="bg-dark text-white">
        <h6 className="mb-0 mid">
          <div className="mid mb-1" dangerouslySetInnerHTML={{ __html: getTextFromProblem(problem, translate) }} />
        </h6>
      </Card.Header>
      <Card.Body className="py-2">
        {problem.solutionSteps.length > 0 ? (
          problem.solutionSteps.map((step, index) => (
            <div
              className="mid mb-1"
              key={step}
              dangerouslySetInnerHTML={{ __html: `<span class='ms-2'>${index + 1}.</span> ${step}` }}
            />
          ))
        ) : (
          <div className="large text-muted text-center">{translate('nothing_to_show')}</div>
        )}
      </Card.Body>
    </Card>
  );
};

const DetailedStressTestReport = ({ problems, translate }) =>
  problems.length > 0 ? (
    <div className="px-3 py-2">
      {problems.map(p => (
        <Problem translate={translate} key={`${p.type}-${p.month}-${p.year}-${p.assetId}`} problem={p} />
      ))}
    </div>
  ) : (
    <h6 className="text-muted p-5">{translate('no_problems_detected')}</h6>
  );

const AssetLiquidationReport = ({ assetLiquidations, translate }) => (
  <Card className="mt-2">
    <Card.Body className="py-2">
      {assetLiquidations.map((lq, index) => (
        <div
          className="mid mb-1"
          key={lq}
          dangerouslySetInnerHTML={{
            __html: `<span class="ms-2">${index + 1}.</span> ${getLiquidationTextFromMergedDate(lq, translate)}`
          }}
        />
      ))}
    </Card.Body>
  </Card>
);

const AssetIgnoredReport = ({ ignoredAssets }) => (
  <Card className="mt-2">
    <Card.Body className="py-2">
      {ignoredAssets.map((asset, index) => (
        <div
          className="mid mb-1"
          key={asset._id}
          dangerouslySetInnerHTML={{
            __html: `<span class="ms-2">${index + 1}.</span> <b>${asset.name}</b>`
          }}
        />
      ))}
    </Card.Body>
  </Card>
);

const StressTestProblems = ({ problems = [], ignoredAssets = [], ignoredLoans = [] }) => {
  const [isDetailedMode, setDetailedMode] = useState(false);
  const combinedAssetLiquidations = useMemo(() => combineAssetLiquidations(problems), [problems]);
  const [activeAsset, setActiveAsset] = useState();
  const { translate } = useContext(LocalizeContext);

  useEffect(() => {
    const assetNames = Object.keys(combinedAssetLiquidations);
    if (!assetNames.includes(activeAsset)) {
      setActiveAsset(assetNames[0]);
    }
  }, [combinedAssetLiquidations]);

  return (
    <>
      <div className="d-flex px-3">
        <div className="flex-grow-1">
          <h6 className="mb-0">
            <b>{translate('problems_detected')}:</b>
          </h6>
        </div>
        <div>
          <FormCheck
            label={translate('detailed_mode')}
            checked={isDetailedMode}
            onChange={e => setDetailedMode(e.target.checked)}
          />
        </div>
      </div>
      <hr className="my-2" />
      {isDetailedMode ? (
        <DetailedStressTestReport problems={problems} translate={translate} />
      ) : (
        <>
          {Object.keys(combinedAssetLiquidations).length > 0 ? (
            <>
              <Tabs
                activeTab={activeAsset}
                tabs={Object.keys(combinedAssetLiquidations)}
                keys={Object.keys(combinedAssetLiquidations)}
                onTabClick={setActiveAsset}
              />
              {combinedAssetLiquidations[activeAsset] && (
                <AssetLiquidationReport
                  translate={translate}
                  assetLiquidations={combinedAssetLiquidations[activeAsset]}
                />
              )}
            </>
          ) : (
            <h6 className="text-muted p-5">{translate('nothing_to_show')}</h6>
          )}
          {[
            { title: 'ignored_assets', ignoredInstruments: ignoredAssets },
            { title: 'ignored_liabilities', ignoredInstruments: ignoredLoans }
          ].map(({ title, ignoredInstruments = [] }) => (
            <div className='mt-3' key={title}>
              <h6 className="mb-0 px-3">
                <b>{translate(title)}:</b>
              </h6>
              <hr className="my-2" />
              {ignoredInstruments.length > 0 ? (
                <AssetIgnoredReport ignoredAssets={ignoredInstruments} />
              ) : (
                <h6 className="text-muted p-5">{translate('nothing_to_show')}</h6>
              )}
            </div>
          ))}
        </>
      )}
    </>
  );
};

export default StressTestProblems;
