import React, { useMemo } from 'react';
import AssetDistributionPieChart from './AssetDistributionPieChart';
import BalanceSheetLineChart from './BalanceSheetLineChart';
import { getStrategyYears } from '../helper';
import CashflowLineChart from './CashflowChart';

const Charts = ({ strategy }) => {
  const strategyYears = useMemo(() => getStrategyYears(strategy), [strategy]);
  return (
    <div className="py-2">
      <AssetDistributionPieChart strategy={strategy} assets={strategy.assets} strategyYears={strategyYears} />
      <BalanceSheetLineChart strategy={strategy} strategyYears={strategyYears} />
      <CashflowLineChart strategy={strategy} strategyYears={strategyYears} />
    </div>
  );
};

export default Charts;
