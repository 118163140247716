import React, { useMemo } from 'react';
import { Range } from 'react-range';

const RangeInput = ({ value = 0, step = 1, isRtlLanguage = false, onChange, min = 0, max = 100, maxWidth = 270 }) => {
  const internalValue = useMemo(() => {
    let v = value < min ? min : value;
    return [v];
  }, [value, min]);
  return (
    <div className="px-2 py-1" style={{ width: maxWidth }}>
      {' '}
      <Range
        label=""
        step={step}
        min={min}
        max={max}
        renderMark={({ props, index }) => (
          <div
            {...props}
            key={props.key}
            style={{
              ...props.style,
              height: '20px',
              width: '20px',
              borderRadius: '50%',
              backgroundColor: '#e5e9f0'
            }}
            className="d-flex border border-light text-dark align-items-center justify-content-center fw-bold tiny"
          >
            {Number(index + 1) || index + 1}
          </div>
        )}
        values={internalValue}
        onChange={values => onChange(values[0])}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: '10px',
              width: '100%',
              backgroundColor: '#e5e9f0'
            }}
            className="rounded"
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => {
          return (
            <div
              {...props}
              key={props.key}
              style={{
                ...props.style,
                height: '20px',
                width: '20px',
                borderRadius: '50%',
                backgroundColor: '#0c1b33'
              }}
              className="d-flex align-items-center justify-content-center fw-bold text-white smallFont"
            >
              {Number(props['aria-valuenow']) || min}
            </div>
          );
        }}
        rtl={isRtlLanguage}
      />
    </div>
  );
};

export default RangeInput;
