import React, { useEffect, useState, useContext, useMemo } from 'react';
import { Button } from 'react-bootstrap';
import { ArrowLeft, ArrowRight, Download, X } from 'react-bootstrap-icons';
import { toast } from 'react-toastify';
import { readFile } from '../../helpers/api';
import { downloadFileFromBase64, humanFileSize } from '../../helpers/global';
import useKeyPress from '../../hooks/useKeyPress';
import CircularProgressBar from './circular-progress';
import FileRenderer from './FileRenderer';
import { LocalizeContext } from 'react-locale-language';
import { isRtl } from '../../helpers/localization';

let fetchingFileName;

const FilePopup = ({ file, onHide, showNext, showPrev, onNextClick, onPrevFileClick }) => {
  const [fileHref, setFileHref] = useState();
  const rightArrowPressed = useKeyPress('ArrowRight');
  const leftArrowPressed = useKeyPress('ArrowLeft');
  const { translate, langCode } = useContext(LocalizeContext);

  const isRTL = useMemo(() => isRtl(langCode), [langCode]);

  const getFileHREF = async () => {
    setFileHref(null);
    fetchingFileName = file.filename;
    const { response, error } = await readFile({
      fileName: fetchingFileName,
      mimeType: file.contentType
    });

    if (fetchingFileName === file?.filename) {
      if (response) {
        setFileHref(response);
      } else {
        toast.error(error);
      }
    }
  };

  useEffect(() => {
    if (file) getFileHREF();
  }, [file]);

  useEffect(() => {
    if (rightArrowPressed) {
      onNextClick && onNextClick();
    }
  }, [rightArrowPressed]);

  useEffect(() => {
    if (leftArrowPressed) {
      onPrevFileClick && onPrevFileClick();
    }
  }, [leftArrowPressed]);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        position: 'fixed',
        zIndex: 20000,
        left: 0,
        top: 0,
        overflowX: 'hidden'
      }}
      className="bg-light p-3 p-md-5 d-flex justify-content-center"
    >
      {!fileHref ? (
        <div className="align-self-center text-center">
          <CircularProgressBar size={4} />
          <h6 className="text-muted midFont mt-3">
            <i>
              {translate('loading_file')} ({humanFileSize(file.length)})...
            </i>
          </h6>
        </div>
      ) : (
        <FileRenderer fileType={file.contentType} src={fileHref} />
      )}

      <Button
        style={{
          position: 'absolute',
          right: 0,
          top: 0
        }}
        size="sm"
        className="m-2"
        onClick={() => {
          setFileHref();
          onHide();
        }}
      >
        {file.metadata && file.metadata.originalName ? file.metadata.originalName : file.filename}
        <X size={18} />
      </Button>

      <div
        className="d-flex px-3 py-2 w-100"
        style={{
          position: 'absolute',
          right: 0,
          bottom: 0
        }}
      >
        <div className="flex-grow-1">
          {isRTL ? (
            <>
              {showNext && (
                <Button size="sm" onClick={onNextClick}>
                  <ArrowRight size={18} />
                </Button>
              )}
              {showPrev && (
                <Button className="mx-2" size="sm" onClick={onPrevFileClick}>
                  <ArrowLeft size={18} />
                </Button>
              )}
            </>
          ) : (
            <>
              {showPrev && (
                <Button className="mx-2" size="sm" onClick={onPrevFileClick}>
                  <ArrowLeft size={18} />
                </Button>
              )}
              {showNext && (
                <Button size="sm" onClick={onNextClick}>
                  <ArrowRight size={18} />
                </Button>
              )}
            </>
          )}
        </div>
        <div className="text-end">
          {fileHref && (
            <Button
              size="sm"
              className=""
              onClick={() => {
                downloadFileFromBase64({
                  data: fileHref,
                  fileName: file.metadata && file.metadata.originalName ? file.metadata.originalName : file.filename
                });
              }}
            >
              <Download size={18} />
              <span className="align-middle ml-2">{translate('download')}</span>
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FilePopup;
