import React, { useEffect, useState, useMemo } from 'react';
import { Button, FormControl, InputGroup } from 'react-bootstrap';
import { ArrowRightShort, CurrencyExchange, X } from 'react-bootstrap-icons';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import CircularProgressBar from '../../components/common/circular-progress';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS, currencyOptions } from '../../helpers/constants';
import useLocalization from '../../hooks/useLocalization';

const formatValue = num => {
  if (num === undefined || num === null) return '';

  let numToFormat = num;
  if (typeof num === 'string') {
    if (!num?.trim()) return '';

    const numCleaned = Number(num.toString().replace(/[^0-9.-]/g, ''));
    if (isNaN(numCleaned)) return '';

    numToFormat = numCleaned;
  }

  return numToFormat.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
};

const CurrencyInput = ({
  value,
  onChange,
  className,
  returnZeroByDefault = true,
  withCurrencyConverter,
  disabled,
  ...props
}) => {
  const { isRTL, translate } = useLocalization();
  const [inputValue, setInputValue] = useState(formatValue(value));
  const [showCurrencyConverter, setShowCurrencyConverter] = useState(false);
  const [currencyValue, setCurrencyValue] = useState(0);
  const [currencyToConvertFrom, setCurrencyToConvertFrom] = useState(currencyOptions[0].symbol);
  const [fetchingConvertedValue, setFetchingConvertedValue] = useState(false);

  const currencySelectOptions = useMemo(
    () =>
      currencyOptions.map(option => ({
        value: option.symbol,
        label: option.name
      })),
    []
  );

  const handleInputChange = e => {
    const rawValue = e.target.value;
    // Remove all non-numeric characters except the first occurrence of "-" and "."
    const sanitizedValue = rawValue.replace(/[^0-9.-]/g, '').trim();
    if (sanitizedValue === '-') {
      setInputValue(sanitizedValue);
      onChange(returnZeroByDefault ? 0 : null, sanitizedValue);
      return;
    }

    const numberValue = parseFloat(sanitizedValue);

    // Call onChange if the value is a valid number
    if (!isNaN(numberValue)) {
      let formatedValue = formatValue(numberValue);
      if (sanitizedValue.endsWith('.')) {
        formatedValue += '.';
      }
      setInputValue(formatedValue);
      onChange(numberValue, formatedValue);
    } else {
      setInputValue('');
      onChange(returnZeroByDefault ? 0 : null, '');
    }
  };

  useEffect(() => {
    setInputValue(formatValue(value));
  }, [value]);

  const convertCurrency = async () => {
    if (!currencyValue) return;

    setFetchingConvertedValue(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.UTILS_CONVERT_CURRENCY,
      method: 'POST',
      requestBody: {
        amount: currencyValue,
        fromCurrency: currencyToConvertFrom
      }
    });

    setFetchingConvertedValue(false);
    if (error) {
      toast.error(error);
      return;
    }

    if (response.convertedAmount !== null) {
      onChange(response.convertedAmount);
    }
  };

  return (
    <>
      {withCurrencyConverter && showCurrencyConverter && (
        <div className="mb-1">
          <div className="d-flex mb-1 align-items-center p-1">
            <h6 className="xs mb-0 flex-grow-1">{translate('convert_from_another_currency')}</h6>
            <X className="hover-light" onClick={() => setShowCurrencyConverter(false)} />
          </div>
          <InputGroup dir="ltr">
            <InputGroup.Text className="p-0 bg-white" style={{ width: '45%' }}>
              <ReactSelect
                options={currencySelectOptions}
                onChange={selectedOption => setCurrencyToConvertFrom(selectedOption.value)}
                value={currencySelectOptions.find(option => option.value === currencyToConvertFrom)}
                classNamePrefix="select"
                className={`p-0 w-100`}
                menuPortalTarget={document.body}
                formatOptionLabel={(option, { context }) => {
                  // Add a divider after EUR when showing in the menu
                  if (option.value === 'EUR' && context === 'menu') {
                    return (
                      <div style={{ position: 'relative' }}>
                        <span className="fw-bold">{option.value} </span> ({option.label})
                        <div
                          className="bg-secondary-dark mt-2"
                          style={{
                            height: '2px',
                            background: '#000'
                          }}
                        />
                      </div>
                    );
                  }
                  return (
                    <>
                      <span className="fw-bold">{option.value} </span> ({option.label})
                    </>
                  ); // Normal display for other options
                }}
                isDisabled={disabled}
                styles={{
                  control: base => ({
                    ...base,
                    border: 0,
                    borderRadius: 0,
                    background: 'transparent',
                    fontSize: 12,
                    padding: 0,
                    fontWeight: 'bold'
                  }),
                  menu: base => ({
                    ...base,
                    width: '200px',
                    zIndex: 10000,
                    position: 'absolute'
                  }),
                  menuPortal: base => ({ ...base, zIndex: 9999 }),
                  option: base => ({ ...base, padding: 5, textAlign: 'start', fontSize: 13 }),
                  dropdownIndicator: base => ({
                    display: 'none' // Hides the dropdown arrow
                  }),
                  indicatorSeparator: base => ({
                    display: 'none' // Hides the separator next to the arrow
                  }),
                  indicatorsContainer: base => ({
                    padding: 0 // Removes any padding to reduce space taken
                  })
                }}
              />
            </InputGroup.Text>

            <CurrencyInput
              dir={'ltr'}
              onChange={value => {
                setCurrencyValue(value);
              }}
              value={currencyValue}
              className={`p-1 text-end`}
              placeholder={translate('value_in_currency', { currency: currencyToConvertFrom })}
              disabled={disabled}
              onKeyPress={event => {
                if (event.key === 'Enter') {
                  if (disabled || !currencyValue || fetchingConvertedValue) return;

                  convertCurrency();
                }
              }}
            />

            <Button
              style={{ fontSize: 11 }}
              className="p-1 mx-0"
              variant="dark"
              onClick={convertCurrency}
              disabled={disabled || !currencyValue || fetchingConvertedValue}
            >
              {fetchingConvertedValue ? <CircularProgressBar size={1} light /> : <ArrowRightShort size={16} />}
            </Button>
          </InputGroup>
        </div>
      )}
      {withCurrencyConverter ? (
        <InputGroup dir="ltr">
          <FormControl
            size="sm"
            type="text"
            value={inputValue}
            onChange={handleInputChange}
            dir={'ltr'}
            className={`mx-0 text-${isRTL ? 'end' : 'start'} ${className}`}
            {...props}
          />

          <Button
            size="sm"
            className="mx-0"
            variant={!showCurrencyConverter ? 'outline-dark' : 'dark'}
            onClick={() => setShowCurrencyConverter(!showCurrencyConverter)}
            disabled={disabled}
          >
            <CurrencyExchange />
          </Button>
        </InputGroup>
      ) : (
        <FormControl
          size="sm"
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          dir={'ltr'}
          className={`mx-0 text-${isRTL ? 'end' : 'start'} ${className}`}
          {...props}
        />
      )}
    </>
  );
};

export default CurrencyInput;
