import React, { useEffect, useMemo, useState, useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import HorizontalProgress from '../../../../common/HorizontalProgress';
import { DraggableTable } from '../../../../common/draggable-table/DraggableTable';

const getStrategyColumns = translate => [{ label: translate('strategies'), key: 'name' }];

const StrategyRearrangeModal = ({ show, onHide, strategies = [], onSubmit, showProgress }) => {
  const { translate, langCode } = useContext(LocalizeContext);
  const columns = useMemo(() => getStrategyColumns(translate), [langCode]);

  const [sortedStrategies, setSortedStrategies] = useState([]);

  useEffect(() => {
    setSortedStrategies([...strategies]);
  }, [strategies, show]);

  return (
    <Modal show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton={!showProgress}>
        <Modal.Title>
          <h6 className="mb-0">
            <b>{translate('rearrange_strategies')}</b>
          </h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto py-4">
        <DraggableTable
          columns={columns}
          list={sortedStrategies}
          onListSort={setSortedStrategies}
          draggable={!showProgress}
        />
        {showProgress && <HorizontalProgress text={`${translate('updating_customer')}...`} />}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={showProgress}
          onClick={() => onSubmit(sortedStrategies)}
          size="sm"
          className="mr-2 text-white btn btn-success"
        >
          {translate('update')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default StrategyRearrangeModal;
