import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Button, Card, Col, Form, FormControl, Modal, Row } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import { makeApiRequests } from '../../../helpers/api';
import { ENDPOINTS } from '../../../helpers/constants';
import { toast } from 'react-toastify';
import CircularProgressBar from '../../common/circular-progress';
import EntityTable from '../../common/EntityTable';
import { Pen } from 'react-bootstrap-icons';
import HorizontalProgress from '../../common/HorizontalProgress';
import useAuth from '../../../hooks/useAuth';

export const getVideoTableHeaders = translate => [
  { name: translate('name'), key: 'key', required: true },
  { name: translate('video_link'), key: 'url', linkToKey: 'url', linkPlaceHolder: 'Open Link', required: true }
];

const VideoEditModal = ({ show, videoKey, url, onUrlChange, onSubmit, showProgress, onHide }) => {
  const { translate } = useContext(LocalizeContext);

  return (
    <Modal show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton={!showProgress}>
        <h6 className="mb-0">{translate(videoKey)}</h6>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        {[
          {
            value: url,
            onChange: onUrlChange,
            label: 'video_link'
          }
        ].map(({ value, onChange, label }) => (
          <div key={label} className="py-2">
            <h6 className="mid">{translate(label)}</h6>
            <FormControl onChange={e => onChange(e.target.value)} value={value} className="mid" size="sm" />
          </div>
        ))}
        {showProgress && <HorizontalProgress text={translate('updating_video_link')} />}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={showProgress} variant="success" size="sm" className="me-2 text-white" onClick={onSubmit}>
          {translate('update')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

const AppVideos = ({ readOnlyMode }) => {
  const { translate, langCode } = useContext(LocalizeContext);
  const [appVideos, setAppVideos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editingAppVideo, setEditingAppVideo] = useState(null);

  const videoTableHeaders = useMemo(() => getVideoTableHeaders(translate), [langCode]);

  const getAppVideos = async () => {
    setLoading(true);
    try {
      const { response, error } = await makeApiRequests({
        endpoint: ENDPOINTS.APP_VIDEOS_LIST,
      });
      setLoading(false);

      if (error) {
        toast.error(error);
        return;
      }

      setAppVideos(response);
    } catch (e) {
      setLoading(false);
      toast.error('Could not load app videos, please try again!');
      console.log(e);
    }
  };

  useEffect(() => {
    getAppVideos();
  }, []);

  const onVideoEditClick = editingVideo => {
    setEditingAppVideo({ ...editingVideo });
  };

  const onEditingVideoSubmit = async () => {
    setEditingAppVideo({ ...editingAppVideo, showProgress: true });

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.APP_VIDEOS_WITH_ID(editingAppVideo._id),
      method: 'PUT',
      requestBody: {
        url: editingAppVideo.url
      },
    });

    setEditingAppVideo({ ...editingAppVideo, showProgress: false });
    if (error) {
      toast.error(error);
      return;
    }

    setAppVideos(appVideos.map(v => (v._id === response._id ? response : v)));
    toast.success('Video link updated succesfully!');
    setEditingAppVideo(null);
  };

  return (
    <Card className="my-3">
      <Card.Header>{translate('app_videos')}</Card.Header>
      <Card.Body className="p-3">
        {loading ? (
          <div className="p-5 text-center">
            <CircularProgressBar size={5} />
          </div>
        ) : (
          <>
            <EntityTable
              quickSearch={false}
              rowKeyField={'_id'}
              data={appVideos.map(v => ({ ...v, key: translate(v.key) }))}
              headers={videoTableHeaders}
              actionCell={item =>
                !readOnlyMode && (
                  <div>
                    <Button
                      variant="outline-primary"
                      className="px-1 py-0 mr-1"
                      onClick={() => onVideoEditClick(appVideos.find(v => v._id === item._id))}
                    >
                      <Pen size={10} />
                    </Button>
                  </div>
                )
              }
            />
            <VideoEditModal
              show={editingAppVideo !== null}
              onHide={() => setEditingAppVideo(null)}
              videoKey={editingAppVideo?.key}
              url={editingAppVideo?.url}
              onUrlChange={url => setEditingAppVideo({ ...editingAppVideo, url })}
              showProgress={editingAppVideo?.showProgress}
              onSubmit={onEditingVideoSubmit}
            />
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default AppVideos;
