import { ContentState, Editor, EditorState, RichUtils, convertFromRaw, convertToRaw } from 'draft-js';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import './editor.css';
import Toolbar from './toolbar/Toolbar';

const RichTextEditor = ({ rows, richText, onSubmit, disabled, editable = true, className = '' }) => {
  const [editorState, setEditorState] = useState();
  const { translate } = useContext(LocalizeContext);

  useEffect(() => {
    setEditorState(
      EditorState.createWithContent(
        typeof richText === 'string'
          ? ContentState.createFromText(richText)
          : convertFromRaw({ ...richText, entityMap: {} })
      )
    );
  }, [richText]);

  const editor = useRef(null);

  useEffect(() => {
    focusEditor();
  }, []);

  const focusEditor = () => {
    editor.current?.focus();
  };

  const handleKeyCommand = command => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      setEditorState(newState);
      return true;
    }
    return false;
  };

  // FOR INLINE STYLES
  const styleMap = {
    CODE: {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
      fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
      fontSize: 16,
      padding: 2
    },
    HIGHLIGHT: {
      backgroundColor: '#b4f8c8'
    },
    UPPERCASE: {
      textTransform: 'uppercase'
    },
    LOWERCASE: {
      textTransform: 'lowercase'
    },
    CODEBLOCK: {
      fontFamily: '"fira-code", "monospace"',
      fontSize: 'inherit',
      background: '#ffeff0',
      fontStyle: 'italic',
      lineHeight: 1.5,
      padding: '0.3rem 0.5rem',
      borderRadius: ' 0.2rem'
    },
    SUPERSCRIPT: {
      verticalAlign: 'super',
      fontSize: '80%'
    },
    SUBSCRIPT: {
      verticalAlign: 'sub',
      fontSize: '80%'
    }
  };

  // FOR BLOCK LEVEL STYLES(Returns CSS Class From DraftEditor.css)
  const myBlockStyleFn = contentBlock => {
    const type = contentBlock.getType();
    switch (type) {
      case 'blockQuote':
        return 'superFancyBlockquote';
      case 'leftAlign':
        return 'leftAlign';
      case 'rightAlign':
        return 'rightAlign';
      case 'centerAlign':
        return 'centerAlign';
      case 'justifyAlign':
        return 'justifyAlign';
      default:
        break;
    }
  };

  if (!editorState) return '';

  return (
    <>
      <div className="editor-wrapper" onClick={focusEditor}>
        {editable && <Toolbar editorState={editorState} setEditorState={setEditorState} />}
        <div className={`border rounded p-3 bg-light ${className}`}>
          <Editor
            ref={editor}
            handleKeyCommand={handleKeyCommand}
            editorState={editorState}
            customStyleMap={styleMap}
            readOnly={!editable}
            blockStyleFn={myBlockStyleFn}
            onChange={!editable ? undefined : setEditorState}
          />
        </div>
      </div>
      {editable && (
        <div className="text-end mt-2">
          <Button
            className="text-white"
            size="sm"
            variant="success"
            disabled={disabled}
            onClick={() => onSubmit(convertToRaw(editorState.getCurrentContent()))}
          >
            {translate('save')}
          </Button>
        </div>
      )}
    </>
  );
};

export default RichTextEditor;
