import { uniqueId, values } from 'lodash';
import cloneRFDC from 'rfdc';
import Finance from 'tvm-financejs';
import {
  assetCategoriesOptions,
  chartColors,
  monthSmall,
  primaryBankAccountName,
  transferFrequencyToMonthsMap
} from '../../../../../helpers/constants';
import { findCyclicPath, findDifferenceInObjects } from '../../../../../helpers/global';
import { getActiveLanguage, isRtl } from '../../../../../helpers/localization';

const cloneDeep = cloneRFDC();
const finance = new Finance();

//translate is fused for localization used in statements generated by stress test
export const calculateBalanceSheet = (
  originalStrategy,
  armageddon,
  stressTestApplied,
  translate,
  ignoreStressLiquidationStep,
  adminCompTable,
  customerCompTable
) => {
  const strategyYears = getStrategyYears(originalStrategy);
  let calculatedStrategy = cloneStrategy(originalStrategy);

  //used for stress test
  const liquidationOrderOfAsset = originalStrategy.assets.filter(a => a.category !== 'Fixed Income').map(a => a._id);

  let {
    assets = [],
    liabilities = [],
    fixedIncomeAssets = [],
    oneOffChanges = [],
    customCashflows = [],
    investments = []
  } = calculatedStrategy;

  //for past compatibility, linkedBankAccount was introduced at a later stage, so we need to default to
  //primary bank account for strategies which were created before that
  const primaryBankAccount = assets.find(a => a.name === primaryBankAccountName);

  //RSU asset type are treated in a different way
  manageRSUassets(calculatedStrategy);

  [assets, liabilities, fixedIncomeAssets, customCashflows].forEach((instruments, index) => {
    instruments.forEach(i => {
      //do not link primary bank account with primary bank account
      if (index === 0 && i.name === primaryBankAccountName) {
        return;
      }

      //for custom cashflows, cash asset is compulsion, so if not found we use
      if (index === 3) {
        const asset = assets.find(a => i['linkedBankAccount'] === a._id);
        if (!asset) {
          i.linkedBankAccount = primaryBankAccount._id;
        }
      } else {
        if (!i.hasOwnProperty('linkedBankAccount')) {
          i.linkedBankAccount = primaryBankAccount._id;
        }
      }
    });
  });

  // if asset is paper apartment, we appreciate with a number till construction
  // that accomodates increase in price because of finish in construction
  // we also need to add a debt to contractor loan
  assets.filter(doesApartmentHasContructionPhase).forEach(a => {
    const numberOfConstructionMonths = Number(a.estimatedTimeForFinishingContruction);
    a.estimatedTimeForFinishingContruction = isNaN(numberOfConstructionMonths) ? 0 : numberOfConstructionMonths;
    const { month: constructionEndMonth, year: constructionEndYear } = addMonthAndYear(
      a.buyingMonth,
      a.buyingYear,
      a.estimatedTimeForFinishingContruction,
      0
    );

    a.constructionEndMonth = constructionEndMonth;
    a.constructionEndYear = constructionEndYear;
    a.appreciationDuringConstruction = calculateAppreciationDuringConstruction(a);

    //TODO get multiplier from comp
    //debt loan is only for paper apartment
    if (isPaperApartment(a)) {
      const paperApartmentDebtLoan = createPaperApartmentDebtLoan(a);
      liabilities.push(paperApartmentDebtLoan);

      //add payments as one off change to debt
      oneOffChanges.push(
        ...a.paperApartmentPayments.flatMap(({ amount, month, year }) => [
          //emit cashflows as one off event for next month
          {
            type: 'Asset',
            refId: a.linkedBankAccount,
            action: 'Subtract',
            value: amount,
            editMode: false,
            description: `Paper Payment ${paperApartmentDebtLoan.name}`,
            month: month,
            year: year,
            transactionType: 'payInstallment',
            debtFor: paperApartmentDebtLoan.debtFor
          },
          {
            type: 'Liability',
            refId: paperApartmentDebtLoan.id,
            action: 'Subtract',
            value: amount,
            editMode: false,
            description: `Paper Payment`,
            month,
            year,
            transactionType: 'payInstallment',
            debtFor: paperApartmentDebtLoan.debtFor
          }
        ])
      );

      if (a.makeReadyCost) {
        oneOffChanges.push({
          type: 'Asset',
          refId: a.linkedBankAccount,
          action: 'Subtract',
          value: a.makeReadyCost,
          editMode: false,
          description: `Additional cost for making apartment ready for rent (${a.name})`,
          month: constructionEndMonth,
          year: constructionEndYear
        });
      }
    }
  });

  // if liability's asset is paper apartment, we take multiple liabilities at different intervals
  // determined by helper function
  const liabilitiesWithPaperApartment = liabilities.filter(l => {
    const linkedAssetName = l.relatedAssets[0];
    return linkedAssetName && isPaperApartment(assets.find(a => a.name === linkedAssetName));
  });

  liabilitiesWithPaperApartment.forEach(l => {
    const linkedAssetName = l.relatedAssets[0];
    const asset = assets.find(a => a.name === linkedAssetName);

    // we exclude the main paper liability completely from calculation such that they do not effect cashflows as well
    const indexOfThisLoan = liabilities.findIndex(li => li._id === l._id);
    liabilities.splice(indexOfThisLoan, 1, ...findAllLoansOfPaperApartment(asset, l));
  });

  //generated cashflows
  const cashflows = createAutomatedCashflows(assets, liabilities, fixedIncomeAssets, customCashflows);
  calculatedStrategy.cashflows = cashflows;

  const assetSortOrder = assetCategoriesOptions;
  assets.sort(
    (a, b) =>
      assetSortOrder.indexOf(a.categoryToShowIn || a.category) -
      assetSortOrder.indexOf(b.categoryToShowIn || b.category)
  );

  const stressTestProblems = [];
  calculatedStrategy.stressTestProblems = stressTestProblems;

  strategyYears.forEach(year => {
    monthSmall.forEach((_, month) => {
      fillStrategyForThisMonth({ strategyWithPreviousValue: calculatedStrategy, month, year, armageddon, translate });

      if (
        stressTestApplied &&
        !isLastMonthOfStrategy(originalStrategy.initialYear, originalStrategy.totalYears ?? 10, month, year)
      ) {
        stressTestProblems.push(
          ...applyStressTestThisMonth({
            strategy: calculatedStrategy,
            month,
            year,
            liquidationOrderOfAsset,
            armageddon,
            translate,
            adminCompTable,
            customerCompTable
          })
        );
      }
    });
  });

  assets.push(...fixedIncomeAssets.map(f => ({ ...f, category: 'Fixed Income' })));

  //Now we add back the excluded loan but this time there monthly values is the combined monthly value of its installments
  //there are children loans for parent loan in case of paper apartment
  //this is indentified by a flag toBeMerged [this is assigned in function findAllLoansOfPaperApartment]
  //need to merge them in one single loan
  liabilitiesWithPaperApartment.forEach(lp => {
    const toBeMerged = liabilities.filter(l => l.toBeMerged && l.parentId === lp._id);
    const cashflowForThisLiability = createAutomatedCashflows([], [lp])[0];
    const toBeMergedCashflows = calculatedStrategy.cashflows.filter(l => l.toBeMerged && l.parentName === lp.name);

    strategyYears.forEach(year => {
      monthSmall.forEach((_, month) => {
        //update monthly value
        const newValue = toBeMerged.reduce((prev, lm) => prev + getValueAtMonthYear(lm, month, year), 0);
        setValueAtMonthYear(lp, month, year, newValue);

        //update payments
        const newPayment = toBeMerged.reduce(
          (prev, lm) => prev + getValueAtMonthYear(lm, month, year, 'payments', 'monthlyPayments'),
          0
        );
        setValueAtMonthYear(lp, month, year, newPayment, 'payments', 'monthlyPayments');
        //also update transations
        //apply appreciation
        const prevValue = getPrevValue(lp, month, year);
        pushTransactionsAtMonthYear(lp, month, year, [
          {
            type: 'opening_balance',
            value: newValue,
            appreciation: areMonthYearEqual(lp.startMonth, lp.startYear, month, year)
              ? 0
              : ((newValue - prevValue) * 100) / prevValue
          }
        ]);

        setValueAtMonthYear(
          cashflowForThisLiability,
          month,
          year,
          toBeMergedCashflows.reduce((prev, cf) => prev + getValueAtMonthYear(cf, month, year), 0)
        );
      });
    });

    const indexOfItsChildrens = liabilities.findIndex(li => li.parentId === toBeMerged[0]?.parentId);
    liabilities.splice(indexOfItsChildrens, 0, lp);

    const indexOfItsCashflowChildrens = calculatedStrategy.cashflows.findIndex(
      li => li.parentName === toBeMergedCashflows[0]?.parentName
    );
    calculatedStrategy.cashflows.splice(indexOfItsCashflowChildrens, 0, cashflowForThisLiability);
  });

  //if stress test is applied we need to make sure that
  //an asset is not bought at all if it causes liquidation of other level 2 or 3 assets
  if (stressTestApplied && !ignoreStressLiquidationStep) {
    const stressTestIgnoredAssets = [];
    let assetsLiquidatedWithin3Months = findLevel2And3AssetsLiquidatedWithin3Months(assets);
    while (assetsLiquidatedWithin3Months.length > 0) {
      //try to ignore assets bought in previous 3 month
      //if ignoring does not solve this asset's problem
      //ignore this asset

      const assetBeingSolved = assetsLiquidatedWithin3Months[0];
      const assetsBoughtWithIn3MonthsBeforeThisAsset = assets.filter(
        a =>
          a._id !== assetBeingSolved._id &&
          (a.liquidationScore == 2 || a.liquidationScore == 3) &&
          findDiffInNumberOfMonths(
            assetBeingSolved['buyingMonth'],
            assetBeingSolved['buyingYear'],
            a['buyingMonth'],
            a['buyingYear']
          ) <= 3
      );

      const strategyWithoutTheseAssets = cloneStrategy(originalStrategy);
      let problemSolved = false;
      let ignoredAssets = [];
      for (const assetBought of assetsBoughtWithIn3MonthsBeforeThisAsset) {
        removeAssetFromStrategy(strategyWithoutTheseAssets, assetBought);

        const newBalanceSheetWithoutThisAsset = calculateBalanceSheet(
          strategyWithoutTheseAssets,
          armageddon,
          stressTestApplied,
          translate,
          true,
          adminCompTable,
          customerCompTable
        );

        ignoredAssets.push(assetBought);
        //if the asset is now not being liquidated problem is solved
        if (
          !findLevel2And3AssetsLiquidatedWithin3Months(newBalanceSheetWithoutThisAsset.assets)
            .map(a => a._id)
            .includes(assetBeingSolved._id)
        ) {
          calculatedStrategy = newBalanceSheetWithoutThisAsset;
          problemSolved = true;
          break;
        }
      }

      //if problem is not solved, unbuy the asset being solved
      if (!problemSolved) {
        const strategyWithoutThisAsset = cloneStrategy(originalStrategy);
        removeAssetFromStrategy(strategyWithoutThisAsset, assetBeingSolved);

        const newBalanceSheetWithoutThisAsset = calculateBalanceSheet(
          strategyWithoutThisAsset,
          armageddon,
          stressTestApplied,
          translate,
          true,
          adminCompTable,
          customerCompTable
        );

        calculatedStrategy = newBalanceSheetWithoutThisAsset;
        ignoredAssets = [assetBeingSolved];
      }

      stressTestIgnoredAssets.push(...ignoredAssets);
      assetsLiquidatedWithin3Months = findLevel2And3AssetsLiquidatedWithin3Months(calculatedStrategy.assets);
    }

    calculatedStrategy.stressTestIgnoredAssets = stressTestIgnoredAssets;
  }

  return calculatedStrategy;
};

const manageRSUassets = ({ assets, oneOffChanges }) => {
  //1. For every rsu type, create a vested and unvested rsu
  // original rsu acts as unvested and a new vested is added

  //2. For each grant in both vested and unvested rsu create sub assets
  const rsuAssets = assets.filter(isRSUAsset);

  rsuAssets.forEach(rsuAsset => {
    rsuAsset.rsuGrants.sort((g1, g2) => {
      if (g1.month === g2.month && g1.year === g2.year) return 0;
      return isMonthYearEarlier(g1.month, g1.year, g2.month, g2.year) ? -1 : 1;
    });

    const subUnvestedAssets = rsuAsset.rsuGrants.map((g, index) => {
      const isGrantInThePast = isMonthYearEarlier(g.month, g.year, rsuAsset.buyingMonth, rsuAsset.buyingYear);
      const isVestingInThePast = isMonthYearEarlier(
        g.vestingMonth,
        g.vestingYear,
        rsuAsset.buyingMonth,
        rsuAsset.buyingYear
      );

      const stockPriceAtStart = isGrantInThePast
        ? rsuAsset.initialRSUStockValue
        : compoundInterestMonthly(
            rsuAsset.initialRSUStockValue,
            findDiffInNumberOfMonths(rsuAsset.buyingMonth, rsuAsset.buyingYear, g.month, g.year),
            rsuAsset.returnAppreciation
          );

      const valueAtStart = g.amount * stockPriceAtStart;
      return {
        ...rsuAsset,
        category: 'Stocks',
        categoryToShowIn: 'RSU',
        _id: undefined,
        id: `${rsuAsset._id}-unvested-${g._id}`,
        name: `${rsuAsset.name}-${index + 1}`,
        toBeMerged: true,
        parentId: rsuAsset._id,
        value: valueAtStart,
        partialSales: [],
        editMode: false,
        ...(isGrantInThePast && !isVestingInThePast
          ? { buyingMonth: rsuAsset.buyingMonth, buyingYear: rsuAsset.buyingYear }
          : { buyingMonth: g.month, buyingYear: g.year }),
        sellingMonth: g.vestingMonth,
        sellingYear: g.vestingYear
      };
    });

    const indexOfThisAsset = assets.findIndex(a => a._id === rsuAsset._id);
    assets.splice(indexOfThisAsset + 1, 0, ...subUnvestedAssets);

    const vestedRSUAsset = {
      ...rsuAsset,
      _id: undefined,
      derivedFrom: rsuAsset._id,
      id: `${rsuAsset._id}-vested`,
      isVestedRSU: true
    };

    const subVestedAssets = rsuAsset.rsuGrants.map((g, index) => {
      const isVestingInThePast = isMonthYearEarlier(
        g.vestingMonth,
        g.vestingYear,
        rsuAsset.buyingMonth,
        rsuAsset.buyingYear
      );

      const stockPriceAtVest = isVestingInThePast
        ? rsuAsset.initialRSUStockValue
        : compoundInterestMonthly(
            rsuAsset.initialRSUStockValue,
            findDiffInNumberOfMonths(rsuAsset.buyingMonth, rsuAsset.buyingYear, g.vestingMonth, g.vestingYear),
            rsuAsset.returnAppreciation
          );

      const valueAtStart = g.amount * stockPriceAtVest;

      return {
        ...rsuAsset,
        category: 'Stocks',
        categoryToShowIn: 'RSU',
        _id: undefined,
        id: `${vestedRSUAsset.id}-${g._id}`,
        name: `${rsuAsset.name}-${index + 1}`,
        isVestedRSU: true,
        toBeMerged: true,
        parentId: vestedRSUAsset.id,
        value: valueAtStart,
        partialSales: [],
        editMode: false,
        ...(isVestingInThePast
          ? { buyingMonth: rsuAsset.buyingMonth, buyingYear: rsuAsset.buyingYear }
          : { buyingMonth: g.vestingMonth, buyingYear: g.vestingYear }),
        sellingMonth: null,
        sellingYear: null
      };
    });

    const allVestedAssets = [vestedRSUAsset, ...subVestedAssets];

    assets.splice(indexOfThisAsset, 0, ...allVestedAssets);

    rsuAsset.rsuGrants
      .filter(g => !isMonthYearEarlier(g.month, g.year, rsuAsset.buyingMonth, rsuAsset.buyingYear, true))
      .forEach(g => {
        //add future grants as one off changes to unvested stock
        const valueOfStockAtGrantMonth = compoundInterestMonthly(
          rsuAsset.initialRSUStockValue || 0,
          findDiffInNumberOfMonths(rsuAsset.buyingMonth, rsuAsset.buyingYear, g.month, g.year),
          rsuAsset.returnAppreciation
        );

        oneOffChanges.push({
          type: 'Asset',
          refId: rsuAsset._id,
          action: 'Add',
          value: (g.amount || 0) * valueOfStockAtGrantMonth,
          editMode: false,
          description: `Grant ${g.amount} stock`,
          month: g.month,
          year: g.year
        });
      });

    vestedRSUAsset.rsuGrants
      .filter(g => !isMonthYearEarlier(g.vestingMonth, g.vestingYear, rsuAsset.buyingMonth, rsuAsset.buyingYear, true))
      .forEach(g => {
        const valueOfStockAtVestingMonth = compoundInterestMonthly(
          rsuAsset.initialRSUStockValue || 0,
          findDiffInNumberOfMonths(rsuAsset.buyingMonth, rsuAsset.buyingYear, g.vestingMonth, g.vestingYear),
          rsuAsset.returnAppreciation
        );

        const vestAmount = (g.amount || 0) * valueOfStockAtVestingMonth;
        //add future grants as one off changes for both vested and unvested
        //move from primary asset
        oneOffChanges.push({
          type: 'Asset',
          refId: vestedRSUAsset.id,
          action: 'Add',
          value: vestAmount,
          editMode: false,
          description: `Vest ${g.amount} stock`,
          month: g.vestingMonth,
          year: g.vestingYear
        });

        //add future grants as one off changes for both vested and unvested
        oneOffChanges.push({
          type: 'Asset',
          refId: rsuAsset._id,
          action: 'Subtract',
          value: vestAmount,
          editMode: false,
          description: `Vest ${g.amount} stock`,
          month: g.vestingMonth,
          year: g.vestingYear
        });
      });
  });
};

const removeAssetFromStrategy = (strategy, assetToRemove) => {
  strategy.assets = strategy.assets.filter(a => a._id !== assetToRemove._id);
  strategy.liabilities = strategy.liabilities.filter(l => !l.relatedAssets.includes(assetToRemove.name));
  strategy.oneOffChanges = strategy.oneOffChanges.filter(a => a.type !== 'Asset' && a.refId !== assetToRemove._id);
  strategy.investments = strategy.investments.filter(
    a =>
      a.fromType === 'asset' && a.fromId !== assetToRemove._id && a.toType === 'asset' && a.toId !== assetToRemove._id
  );
};

const findLevel2And3AssetsLiquidatedWithin3Months = assets => {
  return assets
    .filter(
      a =>
        a.hasOwnProperty('liquidationYear') &&
        (a.liquidationScore == 2 || a.liquidationScore == 3) &&
        findDiffInNumberOfMonths(a['buyingMonth'], a['buyingYear'], a['liquidationMonth'], a['liquidationYear']) <= 3
    )
    .sort((a1, a2) =>
      a1.liquidationYear === a2.liquidationYear
        ? a1.liquidationMonth - a2.liquidationMonth
        : a1.liquidationYear - a2.liquidationYear
    );
};

const fillStrategyForThisMonth = ({ strategyWithPreviousValue, month, year, armageddon, translate }) => {
  strategyWithPreviousValue.oneOffChanges = strategyWithPreviousValue.oneOffChanges?.filter(
    o => !isMonthYearEarlier(o.month, o.year, month, year)
  );

  const {
    assets = [],
    liabilities = [],
    fixedIncomeAssets = [],
    oneOffChanges = [],
    customCashflows = [],
    investments = [],
    cashflows,
    initialYear,
    initialMonth
  } = strategyWithPreviousValue;

  //find value for current month and year from previous value

  // Add all fixed transfers as one off event
  // because fixed income values are not dependent on any other asset/liability
  addAllFixedTransfersForThisMonthToOneOffChanges({
    assets,
    liabilities,
    investments,
    cashflows,
    oneOffChanges,
    month,
    year,
    initialYear,
    initialMonth
  });

  //both the functions also, transfer amount from and to linked bank account on buy/sell

  //assets (bank accounts need to be calculated first
  //because we need to reduce/add value from/to calculated bank balance of that month)
  //generates any one off event for next month [from cashflows]
  assets.forEach(asset => {
    const armageddonDepreciation =
      armageddon &&
      isWithInNumberOfMonths(month, year, armageddon.startMonth, armageddon.startYear, 18) &&
      armageddon.assetDepreciations.find(a => a._id === asset._id)?.monthlyDepreciation;

    calculateAssetValueForThisMonth({
      asset,
      month,
      year,
      oneOffChanges,
      cashflows,
      armageddonDepreciation,
      updatedAssets: assets,
      strategyStartYear: initialYear,
      strategyStartMonth: initialMonth,
      translate
    });
  });

  //liabilities
  liabilities.forEach(liability => {
    calculateLiabilityValueAndPaymentForThisMonth({
      liability,
      month,
      year,
      oneOffChanges,
      cashflows,
      updatedAssets: assets,
      strategyStartYear: initialYear,
      translate
    });
  });

  //fixedIncome [works exactly like liability but is an asset]
  fixedIncomeAssets.forEach(liability => {
    calculateLiabilityValueAndPaymentForThisMonth({
      liability,
      month,
      year,
      oneOffChanges,
      cashflows,
      isFixedIncome: true,
      updatedAssets: assets,
      strategyStartYear: initialYear,
      translate
    });
  });

  //cashflow
  customCashflows.forEach(cashflow =>
    calculateCashflowForThisMonth({ assets, cashflow, month, year, oneOffChanges, cashflows, translate })
  );

  //take care of other reinvestments after everything has been applied
  applyAllReinvestments({
    strategy: strategyWithPreviousValue,
    investments,
    month,
    year,
    assets,
    liabilities,
    fixedIncomeAssets,
    translate,
    cashflows
  });
};

const calculateAssetValueForThisMonth = ({
  asset,
  month,
  year,
  updatedAssets,
  oneOffChanges,
  cashflows,
  armageddonDepreciation,
  translate
}) => {
  let {
    name,
    value,
    buyingYear,
    buyingMonth,
    sellingYear,
    sellingMonth,
    liquidationMonth,
    liquidationYear,
    linkedBankAccount,
    purchaseCost = 0,
    realtorCost = 0,
    makeReadyCost = 0,
    salesCost = 0,
    returnCashflow = 0,
    anonaMonthlyPayment = 0,
    //this variable is added when monthly value is changed by one off change by cashflow changes
    updatedCashflowValue,
    cashflowChanges = [],
    cashflowAppreciation = 0,
    taxOnCashflow = 0,
    estimatedTimeForFinishingContruction = 0,
    isVestedRSU
  } = asset;

  const transactions = [];

  const linkedBankAccountAsset = updatedAssets.find(a => a._id === linkedBankAccount);

  const changesToApplyThisMonth = oneOffChanges.filter(
    ({ type, refId, month: changeMonth, year: changeYear, action, value }) =>
      type === 'Asset' &&
      (refId === asset._id || refId === asset.id) &&
      changeMonth === month &&
      changeYear === year &&
      (action === 'Set' || value !== 0)
  );

  //apply appreciation
  const prevValue = getPrevValue(asset, month, year);
  const appreciatedValue = getNewAssetValue(asset, month, year, armageddonDepreciation);
  transactions.push({
    type: 'opening_balance',
    value: appreciatedValue,
    appreciation: areMonthYearEqual(buyingMonth, buyingYear, month, year)
      ? 0
      : ((appreciatedValue - prevValue) * 100) / prevValue
  });

  //apply all one off events for this month for this asset
  let newValue = applyOneOffChanges(appreciatedValue, changesToApplyThisMonth);
  transactions.push(
    ...changesToApplyThisMonth.map(c => ({ ...c, type: c.action, value: c.value, label: c.description }))
  );

  //rsu is not manually sold, its childrens need to be calculated correctly
  const assetEndMonth = isRSUAsset(asset)
    ? null
    : asset.category === 'Real Estate'
    ? liquidationMonth ?? sellingMonth
    : sellingMonth;
  const assetEndYear = isRSUAsset(asset)
    ? null
    : asset.category === 'Real Estate'
    ? liquidationYear ?? sellingYear
    : sellingYear;

  //if has not been bought or has been sold
  if (!isMonthYearWithinRange(month, year, buyingMonth, buyingYear, assetEndMonth, assetEndYear)) {
    if (month === assetEndMonth && year === assetEndYear) {
      transactions.push({ label: translate('sell'), value: newValue, type: 'Subtract' });
    }
    //set new value as 0
    setValueAtMonthYear(asset, month, year, 0);
  } else {
    //set new value
    setValueAtMonthYear(asset, month, year, newValue);

    //emit cashflows as one off event for next month
    //cashflows for some apartment only start after construction is finished
    let { month: assetOwnMonth, year: assetOwnYear } = doesApartmentHasContructionPhase(asset, false)
      ? addMonthAndYear(buyingMonth, buyingYear, estimatedTimeForFinishingContruction, 0)
      : { year: buyingYear, month: buyingMonth };

    const isAssetOwned = isMonthYearWithinRange(month, year, assetOwnMonth, assetOwnYear, assetEndMonth, assetEndYear);

    const shouldEmitCashflow = isAssetOwned;

    let monthlyCashflow = !shouldEmitCashflow ? 0 : updatedCashflowValue ?? returnCashflow;

    if (shouldEmitCashflow) {
      const cashflowChangeForThisMonth = findCashflowChangeForThisMonth({
        cashflowChanges,
        month,
        year,
        cashflowStartMonth: assetOwnMonth,
        cashflowStartYear: assetOwnYear
      });

      const cashflowRevertForThisMonth = cashflowChanges.find(
        ({ endMonth, endYear }) => endMonth === month && endYear === year
      );

      monthlyCashflow = getUpdatedCashflowMonthlyValue({
        cashflow: cashflows.find(c => c.name === name),
        initialValue: returnCashflow,
        currentValue: monthlyCashflow,
        cashflowChangeToApply: cashflowChangeForThisMonth,
        cashflowChangeToRevert: cashflowRevertForThisMonth,
        yearlyGrowthRate: cashflowAppreciation,
        taxOnCashflow: taxOnCashflow,
        month,
        year,
        startYear: assetOwnYear
      });
      asset.updatedCashflowValue = monthlyCashflow;

      const cashflowAfterRent = monthlyCashflow * (1 - taxOnCashflow / 100);

      const cashflowForNextMonth = {
        type: 'Asset',
        refId: linkedBankAccount,
        action: 'Add',
        value: cashflowAfterRent,
        editMode: false,
        description: asset.name,
        ...getNextMonthYear(month, year)
      };

      oneOffChanges.push(cashflowForNextMonth);

      setValueAtMonthYearForCashflow(
        cashflows.find(c => c.name === name),
        month,
        year,
        cashflowAfterRent
      );
    }

    //anona cashflow
    const shouldEmitAnonaCashflow =
      isAssetOwned && asset.category === 'Anona' && newValue > 0 && anonaMonthlyPayment > 0;

    if (shouldEmitAnonaCashflow) {
      const cashflowValue = newValue > anonaMonthlyPayment ? anonaMonthlyPayment : newValue;
      const cashflowToBankForNextMonth = {
        type: 'Asset',
        refId: linkedBankAccount,
        action: 'Add',
        value: cashflowValue,
        editMode: false,
        description: getAnonaPaymentName(asset),
        ...getNextMonthYear(month, year)
      };

      const cashflowFromAnonaForNextMonth = {
        type: 'Asset',
        refId: asset._id,
        action: 'Subtract',
        value: cashflowValue,
        editMode: false,
        description: getAnonaPaymentName(asset),
        ...getNextMonthYear(month, year)
      };

      oneOffChanges.push(cashflowToBankForNextMonth);
      oneOffChanges.push(cashflowFromAnonaForNextMonth);

      setValueAtMonthYearForCashflow(
        cashflows.find(c => c.name === getAnonaPaymentName(asset)),
        month,
        year,
        cashflowValue
      );

      //also subtract cashflow from anona asset
      //newValue = newValue - cashflowValue;
      setValueAtMonthYear(asset, month, year, newValue);

      //transactions.push({ label: getAnonaPaymentName(asset), value: cashflowValue, type: 'Subtract' });
    }
  }

  //update bank account when bought, sold or any transfer takes place for this month
  if (linkedBankAccountAsset) {
    const transactionsToLinkedBankAccount = [];
    let accountValueAtCurrentMonth = getValueAtMonthYear(linkedBankAccountAsset, month, year);
    //buy only if it is editMode
    //if in setup mode, only consider setup mode in first month of strategy
    if (
      !isSetupModeValid(asset) &&
      areMonthYearEqual(month, year, buyingMonth, buyingYear) &&
      !isRSUAsset(asset) &&
      !asset.toBeMerged
    ) {
      //bought asset, so reduce value from linked bank account
      const buyingTax = asset.tax?.buy || 0;
      const valueToReduce = value + buyingTax + purchaseCost + realtorCost;
      accountValueAtCurrentMonth = accountValueAtCurrentMonth - valueToReduce;
      transactionsToLinkedBankAccount.push({
        type: 'Subtract',
        value: valueToReduce,
        label: `${translate('equity_for')} ${asset.name}`,
        transactionType: 'assetBuy',
        asset,
        buyingTax,
        purchaseCost,
        realtorCost
      });
    }

    //sell
    if (
      newValue > 0 &&
      areMonthYearEqual(month, year, sellingMonth, sellingYear) &&
      !isRSUAsset(asset) &&
      !asset.toBeMerged
    ) {
      //sold asset, so add value to linked bank account
      const sellingTax = asset.tax?.sell || 0;

      //tax and cost reduced from asset instead of bank account
      const sellingValue = newValue - sellingTax - salesCost;
      accountValueAtCurrentMonth = accountValueAtCurrentMonth + sellingValue;
      transactionsToLinkedBankAccount.push({
        type: 'Add',
        value: sellingValue,
        label: `${translate('equity_received')} (${asset.name})`,
        transactionType: 'assetSell',
        asset,
        sellingTax,
        salesCost
      });

      //? this is used in action summary
      asset.soldValue = sellingValue;
      asset.valueAtSellingMonthYear = newValue;
    }

    //transfer because of one off event
    const changesToBankAccount = changesToApplyThisMonth.filter(c => c.editMode);
    const editModeOneOffChangesValue = applyOneOffChanges(appreciatedValue, changesToBankAccount);

    const valueToAdjustFromBankAccount = appreciatedValue - editModeOneOffChangesValue;
    accountValueAtCurrentMonth = accountValueAtCurrentMonth + valueToAdjustFromBankAccount;
    setValueAtMonthYear(linkedBankAccountAsset, month, year, accountValueAtCurrentMonth);

    if (valueToAdjustFromBankAccount !== 0) {
      transactionsToLinkedBankAccount.push({
        type: valueToAdjustFromBankAccount > 0 ? 'Add' : 'Subtract',
        value: Math.abs(valueToAdjustFromBankAccount),
        label: `${translate('one_off_change_by')} ${asset.name}`
      });
    }
    //push to bank account
    pushTransactionsAtMonthYear(linkedBankAccountAsset, month, year, transactionsToLinkedBankAccount);
  }

  pushTransactionsAtMonthYear(asset, month, year, transactions);
};

const findCashflowChangeForThisMonth = ({
  cashflowChanges,
  month,
  year,
  cashflowStartMonth = 0,
  cashflowStartYear
}) => {
  //if cashflow change is applied before the cashflow itself, we consider change to start from the start of the cashflow
  return cashflowChanges
    .map(c =>
      cashflowStartYear && isMonthYearEarlier(c.startMonth, c.startYear, cashflowStartMonth, cashflowStartYear)
        ? { ...c, startMonth: cashflowStartMonth, startYear: cashflowStartYear }
        : c
    )
    .find(({ startMonth, startYear }) => startMonth === month && startYear === year);
};

const calculateLiabilityValueAndPaymentForThisMonth = ({
  liability,
  month,
  year,
  updatedAssets,
  oneOffChanges,
  cashflows,
  isFixedIncome = false,
  strategyStartYear,
  translate
}) => {
  const transactionsOfThisLiability = [];

  const {
    _id,
    id,
    name,
    value,
    startMonth,
    startYear,
    endMonth,
    endYear,
    gracePeriod,
    liquidationMonth,
    liquidationYear,
    linkedBankAccount,
    numberOfInstallments,
    baseInstallment,
    installmentStartDate,
    type,
    relatedAssets = [],
    debtFor,
    //this variable is added when monthly value is changed by one off change by cashflow changes
    updatedCashflowValue,
    cashflowChanges = []
  } = liability;

  const linkedBankAccountAsset = updatedAssets.find(a => a._id === linkedBankAccount);
  const linkedAsset = updatedAssets.find(a => a.name === (relatedAssets[0] || debtFor));

  const changesToApplyThisMonth = oneOffChanges.filter(
    ({ action, refId, month: changeMonth, year: changeYear }) =>
      // for loans generated by system, there is a id present
      (refId === _id || refId === id) &&
      changeMonth === month &&
      changeYear === year &&
      (action === 'Set' || value !== 0)
  );

  // for monthly payments
  // this is used to calculate value of current month
  const thisMonthPayment = getLiabilityMonthlyPayment(liability, month, year);

  //apply appreciation/depreciation
  let appreciatedValue = getNewLoanValue(liability, month, year, thisMonthPayment);
  const prevValue = getPrevValue(liability, month, year);

  transactionsOfThisLiability.push({
    type: 'opening_balance',
    value: appreciatedValue,
    appreciation: areMonthYearEqual(startMonth, startYear, month, year)
      ? 0
      : ((appreciatedValue - prevValue) * 100) / prevValue
  });

  //apply all one off events for this month for this loan
  let newValue = applyOneOffChanges(appreciatedValue, changesToApplyThisMonth);
  transactionsOfThisLiability.push(
    ...changesToApplyThisMonth.map(c => ({ ...c, type: c.action, value: c.value, label: c.description }))
  );

  //if has not been bought or has been sold or has been liquidated in stress test
  const isLoanNotStartedOrHasEnded =
    liquidationYear || !isMonthYearWithinRange(month, year, startMonth, startYear, endMonth, endYear);

  if (isLoanNotStartedOrHasEnded) {
    if (month === endMonth && year === endYear) {
      const newValueAfterInstallment = payInstallmentsIfAvailable({
        liability,
        month,
        year,
        linkedBankAccountAsset,
        linkedAsset,
        transactionsOfThisLiability,
        currentLoanValue: newValue,
        translate
      });

      newValue = newValueAfterInstallment;
      //set new value
      setValueAtMonthYear(liability, month, year, newValue);
      transactionsOfThisLiability.push({ label: translate('end'), value: newValue, type: 'Subtract' });
    }
    setValueAtMonthYear(liability, month, year, 0);
    //for monthly payments
    setValueAtMonthYear(liability, month, year, 0, 'payments', 'monthlyPayments');
  } else {
    //if margin loan, we need to update both loan and related assets value if does not fall within margin
    if (liability.type === 'Margin') {
      const linkedAsset = updatedAssets.find(a => a.name === relatedAssets[0]);

      //loan value added because in first month, the loan has not yet been started and added to the asset
      const loanValueAddedToStock = month === startMonth && year === startYear ? newValue : 0;
      const linkedAssetValue = getValueAtMonthYear(linkedAsset, month, year);

      const valueToCompare = linkedAssetValue + loanValueAddedToStock;
      const belowMargin = valueToCompare > 0 && newValue < valueToCompare * liability.marginFloor * 0.01;
      const aboveMargin = valueToCompare > 0 && newValue > valueToCompare * liability.marginCeiling * 0.01;

      if (belowMargin || aboveMargin) {
        const requiredloanValue = findMarginalValueOfLoan(
          linkedAssetValue - newValue + loanValueAddedToStock,
          belowMargin ? liability.marginFloor : liability.marginCeiling
        );

        const valueToAddOrReduce = requiredloanValue - newValue;
        newValue = requiredloanValue;

        //also add/reduce the amount from linked asset
        setValueAtMonthYear(linkedAsset, month, year, linkedAssetValue + valueToAddOrReduce);
        const transactionsForLinkedAsset = [...getTransactionsAtMonthYear(linkedAsset, month, year)];
        transactionsForLinkedAsset.push({
          type: valueToAddOrReduce < 0 ? 'Subtract' : 'Add',
          value: valueToAddOrReduce,
          label: `${translate('maintain_margin')} (${liability.name})`,
          liability
        });
        pushTransactionsAtMonthYear(linkedAsset, month, year, transactionsForLinkedAsset);

        transactionsOfThisLiability.push({
          type: valueToAddOrReduce < 0 ? 'Subtract' : 'Add',
          value: valueToAddOrReduce,
          label: `${translate('maintain_margin')} (${linkedAsset.name})`
        });
      }
    }

    //set new value
    setValueAtMonthYear(liability, month, year, newValue);

    //reduce loan value by the one off change
    //and update monthly payment accordingly
    const differenceInValue = appreciatedValue - newValue;
    liability.updatedValue = getLatestLoanValue(liability) - differenceInValue;
    const diffInMonth = findDiffInNumberOfMonths(startMonth, startYear, month, year);

    //TODO find a better way, use chaining instead of this
    let updatedMonthlyPayment =
      differenceInValue === 0 && diffInMonth !== gracePeriod
        ? updatedCashflowValue || getLiabilityMonthlyPayment(liability, month, year)
        : getLiabilityMonthlyPayment(liability, month, year);

    const cashflowChangeForThisMonth = findCashflowChangeForThisMonth({
      cashflowChanges,
      month,
      year,
      cashflowStartMonth: startMonth,
      cashflowStartYear: startYear
    });

    const cashflowRevertForThisMonth = cashflowChanges.find(
      ({ endMonth, endYear }) => endMonth === month && endYear === year
    );

    updatedMonthlyPayment = getUpdatedCashflowMonthlyValue({
      cashflow: cashflows.find(c => c.name === name),
      initialValue: getLiabilityMonthlyPayment(liability, startMonth, startYear),
      currentValue: updatedMonthlyPayment,
      cashflowChangeToApply: cashflowChangeForThisMonth,
      cashflowChangeToRevert: cashflowRevertForThisMonth,
      yearlyGrowthRate: 0,
      month,
      year,
      startYear
    });

    liability.updatedCashflowValue = updatedMonthlyPayment;

    //emit cashflows as one off event for next month
    const cashflowForNextMonth = {
      type: 'Asset',
      refId: linkedBankAccount,
      action: isFixedIncome ? 'Add' : 'Subtract',
      value: updatedMonthlyPayment,
      editMode: false,
      description: liability.name,
      ...getNextMonthYear(month, year)
    };

    oneOffChanges.push(cashflowForNextMonth);

    setValueAtMonthYearForCashflow(
      cashflows.find(c => c.name === name),
      month,
      year,
      updatedMonthlyPayment
    );

    const newValueAfterInstallment = payInstallmentsIfAvailable({
      liability,
      month,
      year,
      linkedBankAccountAsset,
      linkedAsset,
      transactionsOfThisLiability,
      currentLoanValue: newValue,
      translate
    });

    setValueAtMonthYear(liability, month, year, updatedMonthlyPayment, 'payments', 'monthlyPayments');
    //set new value
    setValueAtMonthYear(liability, month, year, newValueAfterInstallment);
  }

  //update bank account when bought and sold
  //if start and end are of same month and year then ignore

  // in a margin loan, money is added to reduced from linked asset itself
  // in other types, its added to bank account
  const assetToUpdateCash = type === 'Margin' ? linkedAsset : linkedBankAccountAsset;

  if (assetToUpdateCash) {
    const transactionsToLinkedAsset = [];

    let linkedAssetValueAtCurrentMonth = getValueAtMonthYear(assetToUpdateCash, month, year);

    //do not add money to bank account if it is setup mode and is start of strategy
    //in certain cases, like in multiple loans of paper apartment, aparment get liquidated even before loan is started
    //in those case also, ignore loans
    if (!liquidationYear && !isSetupModeValid(liability) && areMonthYearEqual(month, year, startMonth, startYear)) {
      //started loan, so add value to linked asset
      linkedAssetValueAtCurrentMonth = isFixedIncome
        ? linkedAssetValueAtCurrentMonth - value
        : linkedAssetValueAtCurrentMonth + value;
      setValueAtMonthYear(assetToUpdateCash, month, year, linkedAssetValueAtCurrentMonth);

      transactionsToLinkedAsset.push({
        type: isFixedIncome ? 'Subtract' : 'Add',
        value,
        label: `${translate('start')} ${liability.name}`,
        transactionType: 'loanStart',
        liability
      });
    }

    if (areMonthYearEqual(month, year, endMonth, endYear)) {
      // in a margin loan, money is added to reduced from linked asset itself
      // in other types, its added to bank account
      let linkedAssetForEndingLoan;
      if (type === 'Margin') {
        //in margin, money is reduced from stock, if loan ends before stock ends
        const linkedMarginAsset = updatedAssets.find(a => a.name === relatedAssets[0]);
        if (linkedMarginAsset) {
          if (linkedMarginAsset.sellingMonth === endMonth && linkedMarginAsset.sellingYear === endYear) {
            linkedAssetForEndingLoan = linkedBankAccountAsset;
          } else {
            linkedAssetForEndingLoan = linkedMarginAsset;
          }
        }
      } else {
        linkedAssetForEndingLoan = linkedBankAccountAsset;
      }

      if (linkedAssetForEndingLoan) {
        let valueAtEnd =
          linkedAssetForEndingLoan._id === assetToUpdateCash._id
            ? linkedAssetValueAtCurrentMonth
            : getValueAtMonthYear(linkedAssetForEndingLoan, month, year);

        //end loan, so reduce remaining loan amount from linked asset
        valueAtEnd = isFixedIncome ? valueAtEnd + newValue : valueAtEnd - newValue;
        setValueAtMonthYear(linkedAssetForEndingLoan, month, year, valueAtEnd);

        const transactionsOfEndingAsset =
          linkedAssetForEndingLoan._id === assetToUpdateCash._id
            ? transactionsToLinkedAsset
            : [...getTransactionsAtMonthYear(linkedAssetForEndingLoan, month, year)];

        transactionsOfEndingAsset.push({
          type: isFixedIncome ? 'Add' : 'Subtract',
          value: newValue,
          label: `${translate('end')} ${liability.name}`,
          transactionType: 'loanEnd',
          liability
        });

        if (linkedAssetForEndingLoan._id !== assetToUpdateCash._id)
          pushTransactionsAtMonthYear(linkedAssetForEndingLoan, month, year, transactionsOfEndingAsset);
      }

      //? this is used in action summary
      liability.endValue = newValue;
    }

    pushTransactionsAtMonthYear(assetToUpdateCash, month, year, transactionsToLinkedAsset);
  }

  if (areMonthYearEqual(month, year, endMonth, endYear)) {
    //? this is used in action summary
    liability.endValue = newValue;
  }

  pushTransactionsAtMonthYear(liability, month, year, transactionsOfThisLiability);
};

const payInstallmentsIfAvailable = ({
  liability,
  month,
  year,
  linkedBankAccountAsset,
  linkedAsset,
  transactionsOfThisLiability = [],
  currentLoanValue,
  translate
}) => {
  let newValue = currentLoanValue;
  const {
    startMonth,
    startYear,
    endMonth,
    endYear,
    numberOfInstallments,
    baseInstallment,
    installmentStartDate,
    type
  } = liability;

  // if is balloon loan and is time for installment we pay the installment and pay loan
  const installmentStartMonth = installmentStartDate ? installmentStartDate.month : startMonth;
  const installmentStartYear = installmentStartDate ? installmentStartDate.year : startYear;

  if (
    type === 'Balloon' &&
    (year > installmentStartYear || (year === installmentStartYear && month > installmentStartMonth))
  ) {
    const doesFinalInstallmentEndBeforeYearEnd = numberOfInstallments % 1 !== 0;
    const diffFromStart = findDiffInNumberOfMonths(installmentStartMonth, installmentStartYear, month, year);
    const installmentIntervalInMonths =
      findDiffInNumberOfMonths(installmentStartMonth, installmentStartYear, endMonth, endYear) / numberOfInstallments;

    const completedInstallments = Math.floor(diffFromStart / installmentIntervalInMonths) - 1;
    const isThisLastInstallment = month === endMonth && year === endYear;
    const isFinalInstallmentNotAtTheEndOfYear = doesFinalInstallmentEndBeforeYearEnd && isThisLastInstallment;

    const isNextMonthInstallment = isFinalInstallmentNotAtTheEndOfYear
      ? month === endMonth && year === endYear
      : diffFromStart % installmentIntervalInMonths === 0;

    if (isNextMonthInstallment) {
      const remainingYears = numberOfInstallments - completedInstallments;
      const totalAmountToPay = isFinalInstallmentNotAtTheEndOfYear ? newValue : newValue / remainingYears;

      const installment = isFinalInstallmentNotAtTheEndOfYear
        ? (numberOfInstallments % 1) * baseInstallment
        : baseInstallment;

      const interest = totalAmountToPay - installment;

      //TAKE FROM BANK
      let bankAccountValueAtCurrentMonth = getValueAtMonthYear(linkedBankAccountAsset, month, year);
      bankAccountValueAtCurrentMonth = bankAccountValueAtCurrentMonth - totalAmountToPay;
      setValueAtMonthYear(linkedBankAccountAsset, month, year, bankAccountValueAtCurrentMonth);

      const transactionsToLinkedBank = [];
      transactionsToLinkedBank.push(
        {
          type: 'Subtract',
          value: installment,
          label: `${translate('pay')} ${liability.name}`,
          transactionType: 'payInstallment',
          debtFor: liability.debtFor
        },
        {
          type: 'Subtract',
          value: interest,
          label: `${translate(isPaperApartment(linkedAsset) ? 'pay_indexing_cost' : 'pay_installment_cost')} (${
            liability.name
          })`
        }
      );
      pushTransactionsAtMonthYear(linkedBankAccountAsset, month, year, transactionsToLinkedBank);

      //PUT INTO LIABILITY
      transactionsOfThisLiability.push(
        {
          type: 'Subtract',
          value: installment,
          label: `${translate('pay')} ${liability.name}`,
          transactionType: 'payInstallment',
          debtFor: liability.debtFor
        },
        {
          type: 'Subtract',
          value: interest,
          label: `${translate(isPaperApartment(linkedAsset) ? 'pay_indexing_cost' : 'pay_installment_cost')} (${
            liability.name
          })`
        }
      );

      newValue = newValue - totalAmountToPay;
    }
  }

  return newValue;
};

const getNewAssetValue = (asset, month, year, armageddonDepreciation) => {
  const {
    returnAppreciation,
    value,
    buyingYear,
    buyingMonth,
    appreciationDuringConstruction,
    constructionEndMonth,
    constructionEndYear,
    initialRSUStockValue
  } = asset;

  if (buyingYear === year && buyingMonth === month) {
    if (isRSUAsset(asset)) {
      return asset.rsuGrants
        .filter(g => {
          const isGrantedByThisDate = isMonthYearEarlier(g.month, g.year, month, year, true);
          const isVestedByThisDate = isMonthYearEarlier(g.vestingMonth, g.vestingYear, month, year, true);

          return asset.isVestedRSU ? isVestedByThisDate : isGrantedByThisDate && !isVestedByThisDate;
        })
        .reduce((acc, g) => {
          return acc + g.amount * initialRSUStockValue;
        }, 0);
    }

    return value;
  }

  const prevValue = getPrevValue(asset, month, year);
  return getAppreciatedValue(
    prevValue,
    armageddonDepreciation ||
      (constructionEndYear &&
      isMonthYearWithinRange(month, year, buyingMonth, buyingYear, constructionEndMonth, constructionEndYear, true)
        ? appreciationDuringConstruction
        : returnAppreciation)
  );
};

const getNewLoanValue = (liability, month, year, monthlyPayment) => {
  // updated value appears when a one off change reduces or increases the value of loan
  // once it is reduced, the updated value is used instead of original value
  const { interest, startYear, startMonth, gracePeriod = 0, type } = liability;

  const value = getLatestLoanValue(liability);

  if (type === 'Annuity') {
    // if there is a grace period allowed, we need to find future value only for remaining months
    const numOfMonths = findDiffInNumberOfMonths(startMonth, startYear, month, year) - gracePeriod;
    return getLoanFutureValue(interest, numOfMonths, monthlyPayment, value);
  }

  if (['Margin', 'Balloon'].includes(type)) {
    if (startYear === year && startMonth === month) {
      return value;
    }

    const prevValue = getPrevValue(liability, month, year);
    return getAppreciatedValue(prevValue, interest);
  }

  return value;
};

const calculateCashflowForThisMonth = ({ assets, cashflow, month, year, oneOffChanges, cashflows }) => {
  let {
    type,
    _id,
    monthlyValue = 0,
    //this variable is added when monthly value is changed by one off change by cashflow changes
    updatedMonthlyValue,
    yearlyGrowthRate = 0,
    startYear,
    startMonth,
    endYear,
    endMonth,
    linkedBankAccount,
    name,
    cashflowChanges = []
  } = cashflow;

  if (!isMonthYearWithinRange(month, year, startMonth, startYear, endMonth, endYear)) {
    return;
  }

  let updatedValue = updatedMonthlyValue ?? monthlyValue;

  const cashflowChangeForThisMonth = findCashflowChangeForThisMonth({
    cashflowChanges,
    month,
    year,
    cashflowStartMonth: startMonth,
    cashflowStartYear: startYear
  });

  const cashflowRevertForThisMonth = cashflowChanges.find(
    ({ endMonth, endYear }) => endMonth === month && endYear === year
  );

  updatedValue = getUpdatedCashflowMonthlyValue({
    cashflow: cashflows.find(c => c._id === _id),
    initialValue: monthlyValue,
    currentValue: updatedValue,
    cashflowChangeToApply: cashflowChangeForThisMonth,
    cashflowChangeToRevert: cashflowRevertForThisMonth,
    yearlyGrowthRate,
    month,
    year,
    startYear
  });
  cashflow.updatedMonthlyValue = updatedValue;

  if (ignoreCashflow({ cashflow, assets, month, year })) return;

  const cashflowForNextMonth = {
    type: 'Asset',
    refId: linkedBankAccount,
    action: type === 'Income' ? 'Add' : 'Subtract',
    value: updatedValue,
    editMode: false,
    description: name,
    ...getNextMonthYear(month, year)
  };

  oneOffChanges.push(cashflowForNextMonth);

  setValueAtMonthYearForCashflow(
    cashflows.find(c => c._id === _id),
    month,
    year,
    updatedValue
  );
};

const ignoreCashflow = ({ cashflow, assets, month, year }) => {
  let { type, isRent, linkedApartment } = cashflow;

  //ignore cashflow for this month, if customer owns the apartment
  if (type !== 'Expenditure' || !isRent) return false;

  const linkedApartmentAsset = assets.find(a => a._id === linkedApartment);
  if (!linkedApartmentAsset) return false;

  let { month: assetOwnMonth, year: assetOwnYear } = doesApartmentHasContructionPhase(linkedApartmentAsset, false)
    ? addMonthAndYear(
        linkedApartmentAsset.buyingMonth,
        linkedApartmentAsset.buyingYear,
        linkedApartmentAsset.estimatedTimeForFinishingContruction,
        0
      )
    : { year: linkedApartmentAsset.buyingYear, month: linkedApartmentAsset.buyingMonth };

  return isMonthYearWithinRange(
    month,
    year,
    assetOwnMonth,
    assetOwnYear,
    linkedApartmentAsset.sellingMonth,
    linkedApartmentAsset.sellingYear
  );
};

const getUpdatedCashflowMonthlyValue = ({
  cashflow,
  initialValue,
  currentValue,
  yearlyGrowthRate,
  taxOnCashflow,
  cashflowChangeToApply,
  cashflowChangeToRevert,
  month,
  year
}) => {
  let updatedValue = currentValue;
  const { startYear: cashflowStartYear, startMonth: cashflowStartMonth } = cashflow;
  //also appreciate every year end
  if (month === 0 && year !== cashflowStartYear) {
    updatedValue = updatedValue + (updatedValue * yearlyGrowthRate) / 100;
  }

  if (cashflowChangeToApply) {
    const { action, value } = cashflowChangeToApply;

    if (action === 'Set') {
      updatedValue = value;
    } else {
      updatedValue = action === 'Add' ? updatedValue + value : updatedValue - value;
    }
  }

  if (cashflowChangeToRevert) {
    const { startMonth, startYear, action, value } = cashflowChangeToRevert;

    if (action === 'Set') {
      const { month: prevMonth, year: prevYear } = getPrevMonthYear(startMonth, startYear);
      //go back to the value that was at startMonth, startYear of this cashflow
      updatedValue =
        prevYear < cashflowStartYear || (prevYear === cashflowStartYear && prevMonth < cashflowStartMonth)
          ? initialValue
          : getValueAtMonthYear(cashflow, prevMonth, prevYear);
    } else {
      //revert
      updatedValue = action === 'Add' ? updatedValue - value : updatedValue + value;
    }
  }

  return updatedValue;
};

const createAutomatedCashflows = (assets = [], liabilities = [], fixedIncomeAssets = [], customCashflows = []) => {
  return [
    ...assets.flatMap(asset => {
      let { month: assetOwnMonth, year: assetOwnYear } = doesApartmentHasContructionPhase(asset)
        ? addMonthAndYear(asset.buyingMonth, asset.buyingYear, asset.estimatedTimeForFinishingContruction, 0)
        : { year: asset.buyingYear, month: asset.buyingMonth };

      const cashflows = [
        {
          type: 'inflow',
          name: asset.name,
          refId: asset._id,
          strategyKey: 'assets',
          isAutomated: true,
          bankAccount: asset.linkedBankAccount,
          value: 0,
          cashflowAppreciation: asset.cashflowAppreciation,
          returnCashflow: asset.returnCashflow,
          taxOnCashflow: asset.taxOnCashflow,
          startYear: assetOwnYear,
          startMonth: assetOwnMonth,
          endYear: asset.sellingYear,
          endMonth: asset.sellingMonth,
          category: asset.category,
          cashflowChanges: asset.cashflowChanges
        }
      ];

      if (asset.category === 'Anona') {
        cashflows.push({
          type: 'inflow',
          name: getAnonaPaymentName(asset),
          refId: asset._id,
          strategyKey: 'assets',
          isAutomated: true,
          isAnonaPayment: true,
          bankAccount: asset.linkedBankAccount,
          value: 0,
          startYear: assetOwnYear,
          startMonth: assetOwnMonth,
          endYear: asset.sellingYear,
          endMonth: asset.sellingMonth,
          category: asset.category,
          cashflowChanges: asset.cashflowChanges
        });
      }

      return cashflows;
    }),
    ...liabilities.map(liability => ({
      type: 'outflow',
      name: liability.name,
      refId: liability._id,
      strategyKey: 'liabilities',
      isAutomated: true,
      bankAccount: liability.linkedBankAccount,
      value: 0,
      startYear: liability.startYear,
      startMonth: liability.startMonth,
      endYear: liability.endYear,
      endMonth: liability.endMonth,
      category: liability.type,
      toBeMerged: liability.toBeMerged,
      cashflowChanges: liability.cashflowChanges,
      parentName: liability.parentName
    })),
    ...fixedIncomeAssets.map(liability => ({
      type: 'inflow',
      name: liability.name,
      refId: liability._id,
      strategyKey: 'fixedIncomeAssets',
      isAutomated: true,
      bankAccount: liability.linkedBankAccount,
      value: 0,
      startYear: liability.startYear,
      startMonth: liability.startMonth,
      endYear: liability.endYear,
      endMonth: liability.endMonth,
      cashflowChanges: liability.cashflowChanges,
      category: 'Fixed Income'
    })),
    ...customCashflows.map(custom => ({
      ...custom,
      type: custom.type === 'Income' ? 'inflow' : 'outflow',
      category: 'Custom'
    }))
  ];
};

const getLiabilityMonthlyPayment = (liability, month, year) => {
  const { interest, startYear, startMonth, gracePeriod = 0, type, timeToMaturity = 0 } = liability;
  const value = getLatestLoanValue(liability);

  if (type === 'Annuity') {
    // if within grace period only pays the interest
    if (gracePeriod && isWithInNumberOfMonths(month, year, startMonth, startYear, gracePeriod)) {
      return (interest * value) / 1200;
    }
    return getPMT(value, interest, timeToMaturity - gracePeriod);
  }

  if (type === 'Interest only') {
    return (interest * value) / 1200;
  }

  return 0;
};

const getMonthlyPayment = ({ interest, value, timeToMaturity, type }) => {
  if (type === 'Annuity') {
    return getPMT(value, interest, timeToMaturity);
  }

  if (type === 'Interest only') {
    return (interest * value) / 1200;
  }

  return 0;
};

const getLoanInterestFromMonthlyPayment = ({ value, monthlyPayment, timeToMaturity, type }) => {
  if (type === 'Annuity') {
    return finance.RATE(timeToMaturity, -1 * monthlyPayment, value) * 1200;
  }

  if (type === 'Interest only') {
    return (monthlyPayment * 1200) / value;
  }

  return 0;
};

const getTimeToMaturityFromMonthlyPayment = ({ value, monthlyPayment, interest, type }) => {
  if (type === 'Annuity') {
    return finance.NPER(interest / 100 / 12, -1 * monthlyPayment, value);
  }

  return 0;
};

//adding all fixed transfers and partial sales
const addAllFixedTransfersForThisMonthToOneOffChanges = ({
  assets = [],
  liabilities = [],
  investments = [],
  oneOffChanges,
  month,
  year,
  cashflows = [],
  initialYear,
  initialMonth
}) => {
  investments
    .filter(
      ({ startMonth, startYear, endMonth, endYear, transferValueType, frequency, disabled }) =>
        !disabled &&
        isMonthYearWithinRange(month, year, startMonth, startYear, endMonth, endYear, true) &&
        transferValueType === 'fixed' &&
        (!transferFrequencyToMonthsMap[frequency] || transferFrequencyToMonthsMap[frequency].includes(month))
    )
    .forEach(({ fromId, fromType, toId, toType, value }) => {
      const fromInstrument =
        fromId && fromType && (fromType === 'asset' ? assets : liabilities).find(i => i._id === fromId);
      const toInstrument = toType && toId && (toType === 'asset' ? assets : liabilities).find(i => i._id === toId);

      //if transfer involves real estate and real estate is already liquidated, no fixed transfers
      const shouldIgnoreTransfer = [
        { type: fromType, instrument: fromInstrument },
        { type: toType, instrument: toInstrument }
      ].some(({ type, instrument }) => {
        if (type === 'asset' && instrument?.category === 'Real Estate') {
          const assetEndMonth = instrument.liquidationMonth ?? instrument.sellingMonth;
          const assetEndYear = instrument.liquidationYear ?? instrument.sellingYear;

          //if has not been bought or has been sold
          if (
            !isMonthYearWithinRange(
              month,
              year,
              instrument.buyingMonth,
              instrument.buyingYear,
              assetEndMonth,
              assetEndYear
            )
          ) {
            return true;
          }
        }
        return false;
      });

      if (shouldIgnoreTransfer) return;

      if (fromInstrument) {
        //if from liability to asset
        //we add to both because we are essentially taking more loan to buy asset
        const fromOneOffChange = {
          type: fromType === 'asset' ? 'Asset' : 'Liability',
          refId: fromId,
          action: fromType === 'liability' && toType === 'asset' ? 'Add' : 'Subtract',
          value,
          year: year,
          month: month,
          editMode: false,
          description: `Fixed Transfer to ${toInstrument?.name || 'N/A'}` // used in transactions label
        };
        oneOffChanges.push(fromOneOffChange);
      }

      if (toInstrument) {
        //if from asset to liability
        //we reduce from both because we are essentially clearing loan
        const toOneOffChange = {
          type: toType === 'asset' ? 'Asset' : 'Liability',
          refId: toId,
          action: fromType === 'asset' && toType === 'liability' ? 'Subtract' : 'Add',
          value,
          year: year,
          month: month,
          editMode: false,
          description: `Fixed Transfer from ${fromInstrument?.name || 'N/A'}`
        };
        oneOffChanges.push(toOneOffChange);
      }

      //even though there is fromId, there is no fromInstrument
      //this may be paper apartment, because the original asset do not contain the parent paperapartment
      //it has removed from the asset, because the child paper apartemnt loans derive the parent paper apartment

      //finding paper apartment loan and adding value to its first available child
      if (fromType !== 'asset' && fromId && !fromInstrument) {
        const paperApartmentLoans = liabilities.filter(
          l =>
            l.isPaperApartmentLoan &&
            l.parentId === fromId &&
            isMonthYearWithinRange(month, year, l.startMonth, l.startYear, l.endMonth, l.endYear)
        );
        const childLoan = paperApartmentLoans[0];
        if (childLoan) {
          //if from liability to asset
          //we add to both because we are essentially taking more loan to buy asset
          const fromOneOffChange = {
            type: 'Liability',
            refId: childLoan._id,
            action: toType === 'asset' ? 'Add' : 'Subtract',
            value,
            year: year,
            month: month,
            editMode: false,
            description: `Fixed Transfer to ${toInstrument?.name || 'N/A'}` // used in transactions label
          };
          oneOffChanges.push(fromOneOffChange);
        }
      }

      //same finding paperapartment loans, and reducing values from its child
      if (toType !== 'asset' && toId && !toInstrument) {
        const paperApartmentLoans = liabilities.filter(
          l =>
            l.isPaperApartmentLoan &&
            l.parentId === toId &&
            isMonthYearWithinRange(month, year, l.startMonth, l.startYear, l.endMonth, l.endYear)
        );

        let valueToReduce = value;
        paperApartmentLoans.forEach((l, index, loans) => {
          if (valueToReduce <= 0) return;

          // this is used to calculate value of current month
          const thisMonthPayment = getLiabilityMonthlyPayment(l, month, year);
          //apply appreciation/depreciation
          let valueOfThisLoan = getNewLoanValue(l, month, year, thisMonthPayment);
          valueOfThisLoan = Math.max(valueOfThisLoan, 0);
          let valueToReduceFromThisLoan = valueOfThisLoan > valueToReduce ? valueToReduce : valueOfThisLoan;

          //if is the last loan and valueToReduceFromThisLoan still does not cover for valueToReduce
          //we send the loan to negative
          if (index === loans.length - 1 && valueToReduceFromThisLoan < valueToReduce) {
            valueToReduceFromThisLoan = valueToReduce;
          }

          valueToReduce -= valueToReduceFromThisLoan;

          //if from asset to liability
          //we reduce from both because we are essentially clearing loan
          const toOneOffChange = {
            type: 'Liability',
            refId: l._id,
            action: fromType === 'asset' ? 'Subtract' : 'Add',
            value: valueToReduceFromThisLoan,
            year: year,
            month: month,
            editMode: false,
            description: `Fixed Transfer from ${fromInstrument?.name || 'N/A'}`
          };
          oneOffChanges.push(toOneOffChange);
        });
      }
    });

  //subtracting taxRate from how much (originalAmount) give this netAmount
  function calculateDeductedTax(valueAfterDeductingTax, taxRate) {
    // Convert tax rate percentage to a decimal
    const taxRateDecimal = taxRate / 100;

    // Calculate the original amount before tax
    const originalAmount = valueAfterDeductingTax / (1 - taxRateDecimal);

    // Calculate the tax amount
    const taxAmount = originalAmount * taxRateDecimal;

    // Return the tax amount
    return taxAmount;
  }

  function liquidateRSUAsset(asset, liquidationTarget, afterTax, marginalTaxInWholeNumber, sellAll) {
    let totalLiquidated = 0;
    let liquidationDetails = [];

    const marginalTaxRate = marginalTaxInWholeNumber * 0.01;

    const { rsuGrants = [], buyingMonth, buyingYear, initialRSUStockValue, returnAppreciation } = asset;
    const vestedGrants = rsuGrants.filter(
      grant => !isMonthYearEarlier(month, year, grant.vestingMonth, grant.vestingYear)
    );

    for (let i = 0; i < vestedGrants.length; i++) {
      let grant = vestedGrants[i];
      let isLastGrant = i === vestedGrants.length - 1;

      if (!sellAll && totalLiquidated >= liquidationTarget) break;

      const isGrantInThePast = isMonthYearEarlier(grant.month, grant.year, buyingMonth, buyingYear);
      const { month: discountMonth, year: discountYear } = addMonthAndYear(grant.vestingMonth, grant.vestingYear, 24);
      const isTaxDiscounted = !isMonthYearEarlier(month, year, discountMonth, discountYear);
      const capitalGainsTaxRate = isTaxDiscounted ? 0.25 : marginalTaxRate;

      let quantity = grant.remainingAmount ?? grant.amount;
      let priceAtGrant = isGrantInThePast
        ? grant.value
        : compoundInterestMonthly(
            initialRSUStockValue,
            findDiffInNumberOfMonths(buyingMonth, buyingYear, grant.month, grant.year),
            returnAppreciation
          );

      let sellingPrice = compoundInterestMonthly(
        initialRSUStockValue,
        findDiffInNumberOfMonths(buyingMonth, buyingYear, month, year),
        returnAppreciation
      );

      // Determine how much of the asset needs to be sold
      let sellQuantity;
      if (sellAll) {
        sellQuantity = quantity;
      } else if (afterTax) {
        let requiredNetAmount = liquidationTarget - totalLiquidated;

        let numerator = requiredNetAmount;
        let denominator =
          sellingPrice - marginalTaxRate * priceAtGrant - capitalGainsTaxRate * (sellingPrice - priceAtGrant);
        sellQuantity = numerator / denominator;

        if (sellQuantity > quantity) {
          sellQuantity = quantity;
        }

        // Adjust for the last grant to meet the exact liquidation target if necessary
        if (isLastGrant && totalLiquidated + sellQuantity * sellingPrice < liquidationTarget) {
          sellQuantity = numerator / denominator; // Sell whatever is required
        }
      } else {
        let requiredSellAmount = liquidationTarget - totalLiquidated;
        sellQuantity = Math.min(requiredSellAmount / sellingPrice, quantity);

        // Adjust for the last grant to meet the exact liquidation target if necessary
        if (isLastGrant && totalLiquidated + sellQuantity * sellingPrice < liquidationTarget) {
          sellQuantity = requiredSellAmount / sellingPrice; // Sell whatever is required
        }
      }

      let actualSellValue = sellQuantity * sellingPrice;
      let marginalTax = sellQuantity * priceAtGrant * marginalTaxRate;
      let capitalGainsTax = (actualSellValue - sellQuantity * priceAtGrant) * capitalGainsTaxRate;
      let actualNetProceeds = afterTax ? actualSellValue - marginalTax - capitalGainsTax : actualSellValue;

      liquidationDetails.push({
        grant,
        quantitySold: sellQuantity,
        proceeds: actualSellValue,
        netProceeds: actualNetProceeds,
        marginalTax,
        capitalGainsTax,
        isTaxDiscounted
      });

      grant.remainingAmount = quantity - sellQuantity;
      totalLiquidated += actualNetProceeds; // Net amount is accumulated
    }

    return liquidationDetails;
  }

  function applyPartialSaleForRSU(asset, value, afterTax, marginalTaxPercentage = 0, sellAll) {
    const liquidations = liquidateRSUAsset(asset, value, afterTax, marginalTaxPercentage, sellAll);

    const totalAmountSold = liquidations.reduce((acc, g) => {
      return acc + g.proceeds;
    }, 0);

    //main rsu asset
    const fromOneOffChange = {
      type: 'Asset',
      refId: asset.id,
      action: 'Subtract',
      value: totalAmountSold,
      year: year,
      month: month,
      editMode: false,
      description: `Partial Sell` // used in transactions label
    };

    oneOffChanges.push(fromOneOffChange);

    //push to individual vested stocks
    liquidations.forEach(({ grant, isTaxDiscounted, proceeds, marginalTax, capitalGainsTax }, index) => {
      const fromOneOffChange = {
        type: 'Asset',
        refId: `${asset.id}-${grant._id}`,
        action: 'Subtract',
        value: proceeds,
        year: year,
        month: month,
        editMode: false,
        description: `Partial Sell` // used in transactions label
      };

      const toOneOffChange = {
        type: 'Asset',
        refId: asset.linkedBankAccount,
        action: 'Add',
        value: proceeds,
        year: year,
        month: month,
        editMode: false,
        description: `Partial Sell (${asset?.name}-${index + 1})` // used in transactions label
      };
      const toOneOffChangeMarginalTax = {
        type: 'Asset',
        refId: asset.linkedBankAccount,
        action: 'Subtract',
        value: marginalTax,
        year: year,
        month: month,
        editMode: false,
        description: `Tax on Grant (${asset?.name}-${index + 1}) [${marginalTaxPercentage || 0}%]`
      };
      const toOneOffChangeCapitalGainsTax = {
        type: 'Asset',
        refId: asset.linkedBankAccount,
        action: 'Subtract',
        value: capitalGainsTax,
        year: year,
        month: month,
        editMode: false,
        description: `Tax on Appreciation (${asset?.name}-${index + 1}) [${
          isTaxDiscounted ? '25' : `${marginalTaxPercentage || 0}`
        }%]`
      };
      oneOffChanges.push(fromOneOffChange, toOneOffChange, toOneOffChangeMarginalTax, toOneOffChangeCapitalGainsTax);
    });
  }

  assets
    .filter(a => !a.sellingYear || isMonthYearEarlier(month, year, a.sellingMonth, a.sellingYear))
    .forEach(asset => {
      if (asset.partialSales?.length > 0 && (!isRSUAsset(asset) || asset.isVestedRSU)) {
        const partialSalesThisMonth = asset.partialSales.filter(s => areMonthYearEqual(month, year, s.month, s.year));
        partialSalesThisMonth.forEach(({ afterTax: valueIsAfterDeductingTax, value, taxPercentage = 0 }) => {
          if (isRSUAsset(asset)) {
            applyPartialSaleForRSU(asset, value, valueIsAfterDeductingTax, taxPercentage);
          } else {
            const taxDeductedFromBank = valueIsAfterDeductingTax
              ? calculateDeductedTax(value, taxPercentage)
              : value * taxPercentage * 0.01;
            const valueReceivedAfterSell = valueIsAfterDeductingTax ? value + taxDeductedFromBank : value;

            const fromOneOffChange = {
              type: 'Asset',
              refId: asset._id || asset.id,
              action: 'Subtract',
              value: valueReceivedAfterSell,
              year: year,
              month: month,
              editMode: false,
              description: `Partial Sell` // used in transactions label
            };
            oneOffChanges.push(fromOneOffChange);

            const toOneOffChange = {
              type: 'Asset',
              refId: asset.linkedBankAccount,
              action: 'Add',
              value: valueReceivedAfterSell,
              year: year,
              month: month,
              editMode: false,
              description: `Partial Sell (${asset?.name})`
            };
            const toOneOffChangeTax = {
              type: 'Asset',
              refId: asset.linkedBankAccount,
              action: 'Subtract',
              value: taxDeductedFromBank,
              year: year,
              month: month,
              editMode: false,
              description: `Partial Sell (${asset?.name}) Tax`
            };
            oneOffChanges.push(toOneOffChange, toOneOffChangeTax);
          }
        });
      }
    });

  //for rsu assets which has a selling date
  //we need to sell all vested RSUs after selling
  assets
    .filter(
      a =>
        isRSUAsset(a) &&
        a.isVestedRSU &&
        a.sellingYear &&
        !isMonthYearEarlier(month, year, a.sellingMonth, a.sellingYear)
    )
    .forEach(asset => {
      applyPartialSaleForRSU(asset, 0, false, asset.rsuMarginalTax, true);
    });
};

const applyAllReinvestments = ({
  strategy,
  investments = [],
  month,
  year,
  assets = [],
  liabilities = [],
  fixedIncomeAssets = [],
  cashflows = []
}) => {
  const investmentsToApply = investments.filter(
    ({ startMonth, startYear, endMonth, endYear, transferValueType, frequency, disabled }) =>
      !disabled &&
      isMonthYearWithinRange(month, year, startMonth, startYear, endMonth, endYear, true) &&
      transferValueType !== 'fixed' &&
      (!transferFrequencyToMonthsMap[frequency] || transferFrequencyToMonthsMap[frequency].includes(month))
  );

  if (findCyclicReinvestment(investmentsToApply)) {
    strategy.hasCyclicInvestments = true;
    return;
  }

  let INFINITE_LOOP_CHECK_COUNT = 100;
  let infiniteLoopReached = true;

  while (INFINITE_LOOP_CHECK_COUNT > 0) {
    const valuesTransferred = [];

    investmentsToApply.forEach(investment => {
      const valueTransferred = applyRelativeReinvestment({
        updatedAssets: assets,
        updatedLiabilities: liabilities,
        updatedFixedIncomeAssets: fixedIncomeAssets,
        month,
        year,
        investment
      });

      valuesTransferred.push(valueTransferred);
    });

    if (valuesTransferred.every(v => v === 0)) {
      infiniteLoopReached = false;
      break;
    }

    INFINITE_LOOP_CHECK_COUNT--;
  }

  if (infiniteLoopReached) {
    strategy.hasCyclicInvestments = true;
  }
};

const findCyclicReinvestment = investmentsToApply => {
  // all nodes
  // [1,2,3]
  const allInstruments = [
    ...new Set(
      investmentsToApply.flatMap(({ fromId, fromType, toId, toType }) => {
        const instruments = [];
        if (fromId && fromType) {
          instruments.push(`${fromType}-${fromId}`);
        }
        if (toId && toType) {
          instruments.push(`${toType}-${toId}`);
        }
        return instruments;
      })
    )
  ];

  // all adjascent nodes from parent node
  // ex: here first array tells that 1-> 2 and 1->3, second array tells that 2->1
  // [[2,3], [1], []]
  const adjacents = allInstruments.map(i => {
    const [type, id] = i.split('-');
    return investmentsToApply
      .filter(({ fromId, fromType, toId, toType }) => toId && toType && fromId === id && fromType === type)
      .map(i => `${i.toType}-${i.toId}`);
  });

  return findCyclicPath(allInstruments, adjacents);
};

//only takes care of relative reinvestments
const applyRelativeReinvestment = ({
  updatedAssets,
  updatedLiabilities,
  updatedFixedIncomeAssets,
  month,
  year,
  investment
}) => {
  const { fromId, fromType, toId, toType, value: thresholdValue, transferValueType } = investment;

  const combinedAssets = [...updatedAssets, ...updatedFixedIncomeAssets];
  const fromInstrument =
    fromType &&
    fromId &&
    (fromType === 'asset' ? combinedAssets : updatedLiabilities).find(i => i._id && i._id === fromId);
  const toInstrument =
    toType && toId && (toType === 'asset' ? combinedAssets : updatedLiabilities).find(i => i._id && i._id === toId);

  if (!fromInstrument && !toInstrument) return 0;
  const valueOfFromInstrument = getValueAtMonthYear(fromInstrument, month, year);

  let value;
  if (transferValueType === 'relative-down') {
    // maintain floor
    // transfers any amount from target to make sure value of from is atleast the threshold value
    value = thresholdValue > valueOfFromInstrument ? thresholdValue - valueOfFromInstrument : 0;
  } else {
    // maintain ceiling
    // transfers any amount above threshold from source to target
    value = valueOfFromInstrument > thresholdValue ? valueOfFromInstrument - thresholdValue : 0;
  }

  if (fromInstrument) {
    //if from liability to asset
    //we add to both because we are essentially taking more loan to buy asset
    //if relative-down, we are trying to maintain floor of fromInstrument
    const fromOneOffChange = {
      type: fromType === 'asset' ? 'Asset' : 'Liability',
      refId: fromId,
      action:
        transferValueType === 'relative-down' || (fromType === 'liability' && toType === 'asset') ? 'Add' : 'Subtract',
      value,
      year: year,
      month: month,
      editMode: false
    };

    const valueAfterChange = applyOneOffChange(valueOfFromInstrument, fromOneOffChange);
    setValueAtMonthYear(fromInstrument, month, year, valueAfterChange);
    if (value > 0) {
      pushTransactionsAtMonthYear(
        (fromType === 'asset' ? updatedAssets : updatedLiabilities).find(i => i._id === fromId),
        month,
        year,
        [
          {
            type: fromOneOffChange.action,
            value,
            label:
              transferValueType === 'relative-down' //this is maintaining floor of from instrument
                ? `Take from ${toInstrument?.name || 'N/A'} to maintain floor ${formatCurrency(thresholdValue)}`
                : `Transfer to ${toInstrument?.name || 'N/A'} to maintain ceiling ${formatCurrency(thresholdValue)}`
          }
        ]
      );
    }
  }

  if (toInstrument) {
    //if from asset to liability
    //we reduce from both because we are essentially clearing loan
    const toOneOffChange = {
      type: toType === 'asset' ? 'Asset' : 'Liability',
      refId: toId,
      action:
        transferValueType === 'relative-down' || (fromType === 'asset' && toType === 'liability') ? 'Subtract' : 'Add',
      value,
      year: year,
      month: month,
      editMode: false
    };

    const valueOfToInstrument = getValueAtMonthYear(toInstrument, month, year);
    const valueAfterChange = applyOneOffChange(valueOfToInstrument, toOneOffChange);
    setValueAtMonthYear(toInstrument, month, year, valueAfterChange);
    if (value > 0) {
      pushTransactionsAtMonthYear(
        (toType === 'asset' ? updatedAssets : updatedLiabilities).find(i => i._id === toId),
        month,
        year,
        [
          {
            type: toOneOffChange.action,
            value,
            label:
              transferValueType === 'relative'
                ? `Take from ${fromInstrument?.name || 'N/A'} to maintain ceiling ${formatCurrency(thresholdValue)}`
                : `Transfer to ${fromInstrument?.name || 'N/A'} to maintain floor ${formatCurrency(thresholdValue)}`
          }
        ]
      );
    }
  }

  // we only consider value being transferred if there is a from instrument [which is the one that decided value to be transferred]
  return fromInstrument ? value : 0;
};

const applyOneOffChanges = (prevValue, oneOffChanges = []) =>
  oneOffChanges.reduce((updatedValue, change) => applyOneOffChange(updatedValue, change), prevValue);

const applyOneOffChange = (prevValue, oneOffChange) => {
  const { action, value } = oneOffChange;

  let newValue;
  switch (action) {
    case 'Add':
      newValue = prevValue + value;
      break;
    case 'Subtract':
      newValue = prevValue - value;
      break;
    default:
      newValue = value;
  }
  return newValue;
};

const getPMT = (presentValue, annualRate, timeToMaturity = 0) => {
  return finance.PMT(annualRate / 100 / 12, timeToMaturity, -1 * presentValue);
};

const getPresentValue = (monthlyPayment, annualRate, timeToMaturity = 0) => {
  return finance.PV(annualRate / 100 / 12, timeToMaturity, -1 * monthlyPayment);
};

const getLoanFutureValue = (annualRate, numberOfMonths, monthlyPayment, value) => {
  return -finance.FV(annualRate / 100 / 12, numberOfMonths, -monthlyPayment, value, 0);
};

const isWithInNumberOfMonths = (month, year, startMonth, startYear, numberOfMonths) => {
  const endDate = addMonthAndYear(startMonth, startYear, numberOfMonths);
  return isMonthYearWithinRange(month, year, startMonth, startYear, endDate.month, endDate.year, true);
};

// finds if given month year is within specified range
const isMonthYearWithinRange = (month, year, startMonth, startYear, endMonth, endYear, includeLastMonth) => {
  if (year < startYear || (endYear && year > endYear)) {
    return false;
  }

  if (year === startYear && startMonth > month) return false;

  //if month year is after end month of the liability
  if (endYear && year === endYear && (includeLastMonth ? month > endMonth : month >= endMonth)) return false;

  return true;
};

// finds if given month year is within specified range
export const areMonthYearEqual = (startMonth, startYear, endMonth, endYear) => {
  return startMonth === endMonth && startYear === endYear;
};

// applies simple interest on value for one month
const getAppreciatedValueCompounded = (value, yearlyAppreciation) => value + (value * yearlyAppreciation) / 1200;

const getAppreciatedValue = (value, yearlyAppreciation) => value + value * getMonthlyRate(yearlyAppreciation * 0.01);

//takes yearly appreciation in decimal
//returns monthly in decimal terms
const getMonthlyRate = yearlyRate => {
  let n = 12;
  let monthlyRate = Math.pow(1 + yearlyRate, 1 / n) - 1;

  return monthlyRate;
};
// gets value of any instrument for previous month and year
const getPrevValue = (instrument, month, year) => {
  const { month: prevMonth, year: prevYear } = getPrevMonthYear(month, year);
  return getValueAtMonthYear(instrument, prevMonth, prevYear);
};

const getLatestLoanValue = loan => {
  return loan.updatedValue === undefined ? loan.value : loan.updatedValue;
};

// gets value of any instrument for a particular month and year
export const getValueAtMonthYear = (
  instrument,
  month,
  year,
  yearlyKey = 'yearlyValues',
  monthlyKey = 'monthlyValues'
) => {
  return instrument?.[yearlyKey]?.find(yearlyValue => yearlyValue.year == year)?.[monthlyKey]?.[month] || 0;
};

const setValueAtMonthYearForCashflow = (cashflow, month, year, value) => {
  setValueAtMonthYear(cashflow, month, year, value);
};

// gets transactions of any instrument for a particular month and year
export const getTransactionsAtMonthYear = (instrument, month, year, defaultValue = []) => {
  return instrument?.transactions?.find(t => t.month === month && t.year === year)?.transactions || defaultValue;
};

// sets transactions of any instrument for a particular month and year
const pushTransactionsAtMonthYear = (instrument, month, year, transactions = []) => {
  if (!instrument) return;

  if (!instrument.transactions) {
    instrument.transactions = [];
  }

  const prevTransactions = getTransactionsAtMonthYear(instrument, month, year, '');
  if (!prevTransactions) {
    instrument.transactions.push({ month, year, transactions });
  } else {
    prevTransactions.push(...transactions);
  }
};

// sets value of any instrument for a particular month and year
const setValueAtMonthYear = (
  instrument,
  month,
  year,
  value,
  yearlyKey = 'yearlyValues',
  monthlyKey = 'monthlyValues'
) => {
  if (!instrument) return;

  if (!instrument[yearlyKey]) {
    const initialMonthlyArray = getBlankYearArray();
    initialMonthlyArray[month] = value;
    instrument[yearlyKey] = [{ year, [monthlyKey]: initialMonthlyArray, value: initialMonthlyArray[0] }];
    return;
  }

  const thisYear = instrument[yearlyKey].find(yearlyValue => yearlyValue.year == year);
  if (!thisYear) {
    const initialMonthlyArray = getBlankYearArray();
    initialMonthlyArray[month] = value;
    instrument[yearlyKey].push({ year, [monthlyKey]: initialMonthlyArray, value: initialMonthlyArray[0] });
    return;
  }

  thisYear[monthlyKey][month] = value;
  thisYear.value = thisYear[monthlyKey][0];
};

const getPrevMonthYear = (thisMonth, thisYear) => {
  let year = thisYear;
  let month = thisMonth;

  if (thisMonth === 0) {
    month = 11;
    year = thisYear - 1;
  } else {
    month = thisMonth - 1;
  }

  return { month, year };
};

const getNextMonthYear = (thisMonth, thisYear) => {
  let year = thisYear;
  let month = thisMonth;

  if (thisMonth === 11) {
    month = 0;
    year = thisYear + 1;
  } else {
    month = thisMonth + 1;
  }

  return { month, year };
};

// generates array with 12 zeros, each zero for one month
const getBlankYearArray = () => Array.from({ length: 12 }, () => 0);

export const getMonthlyCompoundedDepreciation = (numberOfMonths, totalPercentageDecline) =>
  ((1 - totalPercentageDecline / 100) ** (1 / numberOfMonths) - 1) * 1200;

export const createDefaultBankAccount = ({ initialCash, returnAppreciation, returnCashflow, month, year }) => ({
  category: 'Cash',
  name: primaryBankAccountName,
  value: initialCash,
  returnAppreciation: returnAppreciation,
  returnCashflow: returnCashflow,
  purchaseCost: 0,
  salesCost: 0,
  liquidationScore: 1,
  crashPercentage: 0,
  buyingYear: year,
  buyingMonth: month,
  linkedBankAccount: 'not_linked',
  canSellPartially: true
});

export const createDefaultStrategies = () => {
  const initialYear = new Date().getFullYear();
  const initialMonth = new Date().getMonth();

  return [
    {
      name: 'מצב קיים',
      totalYears: 10,
      isDefaultStrategy: true,
      showToCustomer: true,
      assets: [
        {
          category: 'Cash',
          name: primaryBankAccountName,
          purchaseCost: 0,
          salesCost: 0,
          liquidationScore: 1,
          crashPercentage: 0,
          buyingYear: initialYear,
          buyingMonth: initialMonth,
          linkedBankAccount: 'not_linked',
          canSellPartially: true,
          editMode: false
        }
      ],
      initialYear,
      initialMonth
    }
  ];
};

export const addOrUpdateObjectInArray = (array = [], arrayOfObjectsToBePushed, primaryKey = '_id') => {
  if (!arrayOfObjectsToBePushed) return array;

  if (!Array.isArray(arrayOfObjectsToBePushed)) arrayOfObjectsToBePushed = [arrayOfObjectsToBePushed];

  let arrayToReturn = [...array];

  arrayOfObjectsToBePushed.forEach(object => {
    const existingIndex = array.findIndex(o => o[primaryKey] === object[primaryKey]);
    if (existingIndex !== -1) {
      arrayToReturn = arrayToReturn.map(o => (o[primaryKey] === object[primaryKey] ? object : o));
    } else {
      arrayToReturn = [...array, object];
    }
  });

  return arrayToReturn;
};

export const deleteObjectInArray = (array = [], object, primaryKey = '_id') => {
  if (!object) return array;

  const tempArray = cloneDeep(array);
  const existingIndex = tempArray.findIndex(o => o[primaryKey] === object[primaryKey]);
  tempArray.splice(existingIndex, 1);
  return tempArray;
};

export const compoundInterest = (principal, numberOfMonths, interestRate) =>
  !principal ? 0 : principal * Math.pow(1 + interestRate, numberOfMonths);

export const compoundInterestMonthly = (principal, numberOfMonths, interestRate = 0) =>
  compoundInterest(principal, numberOfMonths, getMonthlyRate(interestRate * 0.01));

export const findAnnualRateOfCompounding = (amount, principal, numberOfMonths) => {
  const years = numberOfMonths / 12;
  return (Math.pow(amount / principal, 1 / years) - 1) * 100;
};

export const findYearlyRateOfCompouding = (amount, principal, numberOfYears) =>
  (Math.pow(amount / principal, 1 / numberOfYears) - 1) * 100;

// This function finds any problems with strategy for next month
// And solves the problem by liquidating in current month
const applyStressTestThisMonth = ({
  strategy: strategyWithCurrentMonthValue,
  month,
  year,
  liquidationOrderOfAsset = [],
  armageddon,
  translate,
  adminCompTable,
  customerCompTable
}) => {
  //predict values for next month
  const strategyWithNextMonthValue = cloneStrategy(strategyWithCurrentMonthValue);
  const { month: nextMonth, year: nextYear } = getNextMonthYear(month, year);
  fillStrategyForThisMonth({
    strategyWithPreviousValue: strategyWithNextMonthValue,
    armageddon,
    month: nextMonth,
    year: nextYear,
    translate
  });

  const problems = [];

  // we try to solve problems in reverse order of liquidation table
  strategyWithNextMonthValue.assets.sort((asset1, asset2) => {
    const a1Index = liquidationOrderOfAsset.indexOf(asset1._id);
    const a2Index = liquidationOrderOfAsset.indexOf(asset2._id);

    if (a1Index === -1) return 1;
    if (a2Index === -1) return -1;

    return a2Index - a1Index;
  });

  //Asset related problems
  // 1. An asset has negative value
  strategyWithNextMonthValue.assets.forEach(assetWithNextMonthValue => {
    const monthValue = getValueAtMonthYear(assetWithNextMonthValue, nextMonth, nextYear);

    if (monthValue < 0) {
      const problem = {
        type: 'negativeAssetValue',
        month: nextMonth,
        year: nextYear,
        assetId: assetWithNextMonthValue._id,
        assetName: assetWithNextMonthValue.name,
        value: monthValue
      };

      let amountNeeded = -monthValue;
      const { solutionSteps, assetLiquidations } = solveStressTestProblem({
        asset: strategyWithCurrentMonthValue.assets.find(a => a._id === assetWithNextMonthValue._id),
        amountNeeded,
        strategy: strategyWithCurrentMonthValue,
        month,
        year,
        liquidationOrderOfAsset,
        translate,
        adminCompTable,
        customerCompTable
      });

      problem.solutionSteps = solutionSteps;
      problem.assetLiquidations = assetLiquidations;
      problems.push(problem);
    }
  });

  strategyWithNextMonthValue.liabilities.sort((loan1, loan2) => {
    const l1Index = liquidationOrderOfAsset.indexOf(loan1.linkedStock);
    const l2Index = liquidationOrderOfAsset.indexOf(loan2.linkedStock);

    if (l1Index === -1) return 1;
    if (l2Index === -1) return -1;

    return l2Index - l1Index;
  });

  const stocks = strategyWithNextMonthValue.assets.filter(a => a.category === 'Stocks');

  //Loan related problems
  // A loan against stock does not meet the margin requirements
  strategyWithNextMonthValue.liabilities.forEach(liabilityWithNextMonthValue => {
    const linkedAssetName = liabilityWithNextMonthValue.relatedAssets?.[0];
    const linkedStock = stocks.find(s => s.name === linkedAssetName);
    if (!linkedStock) return;

    if (isMarginNotMet({ liability: liabilityWithNextMonthValue, linkedStock, month: nextMonth, year: nextYear })) {
      const loanValueAtNextMonth = getValueAtMonthYear(liabilityWithNextMonthValue, nextMonth, nextYear);
      const stockValueAtNextMonth = getValueAtMonthYear(linkedStock, nextMonth, nextYear);
      const equityAtNextMonth = stockValueAtNextMonth - loanValueAtNextMonth;

      const problem = {
        type: 'marginNotMet',
        month: nextMonth,
        year: nextYear,
        loanId: liabilityWithNextMonthValue._id,
        loanName: liabilityWithNextMonthValue.name,
        stockId: linkedStock._id,
        stockName: linkedStock.name,
        stockType: linkedStock.stockType,
        loanValue: loanValueAtNextMonth,
        stockValue: stockValueAtNextMonth,
        equityValue: equityAtNextMonth
      };

      //if there is margin problem at the start of loan,
      //it should not be taken
      if ((liabilityWithNextMonthValue.startYear === nextYear, liabilityWithNextMonthValue.startMonth === nextMonth)) {
        strategyWithCurrentMonthValue.liabilities = strategyWithCurrentMonthValue.liabilities.filter(
          a => a._id !== liabilityWithNextMonthValue._id
        );
        problem.solutionSteps = [translate('liability_ignored')];
        problem.assetLiquidations = [];

        strategyWithCurrentMonthValue.stressTestIgnoredLiabilities = [
          ...(strategyWithCurrentMonthValue.stressTestIgnoredLiabilities || []),
          liabilityWithNextMonthValue
        ];
      } else {
        const amountToReduce =
          linkedStock.stockType === 'IB'
            ? loanValueAtNextMonth - 0.7 * stockValueAtNextMonth
            : loanValueAtNextMonth - stockValueAtNextMonth;

        const { solutionSteps, assetLiquidations } = solveStressTestProblem({
          asset: strategyWithCurrentMonthValue.assets.find(a => a._id === linkedStock._id),
          liability: strategyWithCurrentMonthValue.liabilities.find(a => a._id === liabilityWithNextMonthValue._id),
          amountNeeded: amountToReduce,
          strategy: strategyWithCurrentMonthValue,
          month,
          year,
          liquidationOrderOfAsset,
          applyingTo: 'liability',
          translate
        });

        problem.solutionSteps = solutionSteps;
        problem.assetLiquidations = assetLiquidations;
      }

      problems.push(problem);
    }
  });

  return problems;
};

// when applyingTo is liability, we are reducing amount from liability
// else we are increasing amount of asset
const solveStressTestProblem = ({
  strategy,
  asset,
  liability,
  amountNeeded,
  month,
  year,
  liquidationOrderOfAsset,
  applyingTo = 'asset',
  translate,
  adminCompTable,
  customerCompTable
}) => {
  let amountLeft = amountNeeded;
  //solution steps are show in detailed mode in stress test
  const solutionSteps = [];
  //this is shown when not in detailed mode also in stress test
  const assetLiquidations = [];

  solutionSteps.push(
    translate(applyingTo === 'asset' ? 'negative_amount_is' : 'required_amount_for_margin_is', {
      amount: formatCurrency(amountNeeded)
    })
  );

  // first get money from bank account
  const linkedBankAccount = strategy.assets.find(
    a => a._id === (applyingTo === 'asset' ? asset.linkedBankAccount : liability.linkedBankAccount)
  );

  if (linkedBankAccount) {
    const moneyInLinkedBankAccount = getValueAtMonthYear(linkedBankAccount, month, year);

    if (moneyInLinkedBankAccount > 0) {
      const moneyTakenFromBankAccount = moneyInLinkedBankAccount >= amountLeft ? amountLeft : moneyInLinkedBankAccount;

      //reduce value from bank account
      const newBankAccountValue = moneyInLinkedBankAccount - moneyTakenFromBankAccount;
      setValueAtMonthYear(linkedBankAccount, month, year, newBankAccountValue);
      amountLeft = amountLeft - moneyTakenFromBankAccount;

      solutionSteps.push(
        translate('took_from_linked_bank_account', {
          amount: formatCurrency(moneyTakenFromBankAccount),
          accountName: linkedBankAccount.name
        })
      );

      assetLiquidations.push({
        assetName: linkedBankAccount.name,
        assetId: linkedBankAccount._id,
        month,
        year
      });

      pushTransactionsAtMonthYear(linkedBankAccount, month, year, [
        {
          label: `Stress Test [Sent to ${applyingTo === 'asset' ? asset.name : liability.name}]`,
          value: moneyTakenFromBankAccount,
          type: 'Subtract'
        }
      ]);
    } else {
      solutionSteps.push(
        translate('linked_bank_account_has_no_money', {
          accountName: linkedBankAccount.name
        })
      );
    }
  }

  if (amountLeft > 0) {
    //get remaining amount by liquidating assets
    const liquidatingAssets = liquidationOrderOfAsset
      .filter(
        id => !(applyingTo === 'asset' ? [asset._id, linkedBankAccount?._id] : [linkedBankAccount?._id]).includes(id)
      )
      .map(id => strategy.assets.find(a => a._id === id));

    while (liquidatingAssets.length > 0 && amountLeft > 0) {
      const assetToBeLiquidated = liquidatingAssets.shift();
      const valueOfLiquidatingAssetAtThisMonth = getValueAtMonthYear(assetToBeLiquidated, month, year);

      solutionSteps.push(
        translate('remaining_amount_trying_asset', {
          amount: formatCurrency(amountLeft),
          assetName: assetToBeLiquidated.name
        })
      );

      if (valueOfLiquidatingAssetAtThisMonth <= 0) {
        solutionSteps.push(translate('asset_has_no_money', { assetName: assetToBeLiquidated.name }));
        continue;
      }

      // if we reach the linked asset of loan in the liquidation order
      // if it is a IB stock, we reduce stock and loan such that 30% is maintained
      // else we carry on with the usual procedure
      // only for margin call problems
      if (applyingTo === 'liability' && assetToBeLiquidated._id === asset._id) {
        const amountAlreadyCleared = amountNeeded - amountLeft; // we might have already cleared some liability before this loop
        const remainingLoanValue = getValueAtMonthYear(liability, month, year) - amountAlreadyCleared;
        const amountToSellFromLinkedStock = (remainingLoanValue - 0.7 * valueOfLiquidatingAssetAtThisMonth) / 0.3;

        if (
          !(
            assetToBeLiquidated.canSellPartially &&
            assetToBeLiquidated.category === 'Stocks' &&
            assetToBeLiquidated.stockType === 'IB' &&
            amountToSellFromLinkedStock > 0
          )
        ) {
          //completely liquidate the same asset
          amountNeeded = getValueAtMonthYear(liability, month, year);
          amountLeft = amountNeeded - amountAlreadyCleared;

          amountLeft = liquidateLinkedAssetOfLiability({
            strategy,
            assetToBeLiquidated,
            valueOfLiquidatingAssetAtThisMonth,
            liability,
            remainingLoanAmount: amountLeft,
            solutionSteps,
            month,
            year,
            translate,
            adminCompTable,
            customerCompTable
          });

          assetLiquidations.push({
            assetName: assetToBeLiquidated.name,
            assetId: assetToBeLiquidated._id,
            month,
            year
          });
          continue;
        }

        solutionSteps.push(translate('asset_is_linked_ib_stock'));

        if (amountToSellFromLinkedStock > 0) {
          //partiall selling
          const reducedAssetValue = valueOfLiquidatingAssetAtThisMonth - amountToSellFromLinkedStock;

          setValueAtMonthYear(assetToBeLiquidated, month, year, reducedAssetValue);
          pushTransactionsAtMonthYear(assetToBeLiquidated, month, year, [
            {
              label: `Stress Test [Partially sell ${assetToBeLiquidated.name}]`,
              value: amountToSellFromLinkedStock,
              type: 'Subtract'
            }
          ]);

          setValueAtMonthYear(liability, month, year, remainingLoanValue - amountToSellFromLinkedStock);
          liability.updatedValue = remainingLoanValue - amountToSellFromLinkedStock;

          pushTransactionsAtMonthYear(liability, month, year, [
            {
              label: `Stress Test [Partially sell ${assetToBeLiquidated.name} to meet margin]`,
              value: amountToSellFromLinkedStock,
              type: 'Subtract'
            }
          ]);

          solutionSteps.push(
            translate('partially_sold_from_asset', {
              amount: formatCurrency(amountToSellFromLinkedStock),
              assetName: assetToBeLiquidated.name
            })
          );
          amountLeft = 0;

          assetLiquidations.push({
            assetName: assetToBeLiquidated.name,
            assetId: assetToBeLiquidated._id,
            month,
            year,
            type: 'partial',
            partialValue: amountToSellFromLinkedStock
          });

          return solutionSteps;
        } else {
          solutionSteps.push(translate('partial_selling_not_possible'));
        }
      }

      const linkedLoans = findLinkedLiabilities(strategy.liabilities, assetToBeLiquidated, true);

      // if partially selling is allowed and partially selling covers for the amount left and partially selling does not cause margin call in a stock
      // then sell partially
      if (
        assetToBeLiquidated._id !== asset._id &&
        assetToBeLiquidated.canSellPartially &&
        valueOfLiquidatingAssetAtThisMonth >= amountLeft &&
        (assetToBeLiquidated.category !== 'Stock' ||
          !partialSellingCauseMarginCall({
            stockType: assetToBeLiquidated.stockType,
            reducedValueOfStock: valueOfLiquidatingAssetAtThisMonth - amountLeft,
            //current liability might already have taken money from other assets
            loanValuesToCover: linkedLoans.map(loan =>
              loan._id === liability._id
                ? getValueAtMonthYear(loan, month, year) - (amountNeeded - amountLeft)
                : getValueAtMonthYear(loan, month, year)
            )
          }))
      ) {
        //partiall selling
        const reducedAssetValue = valueOfLiquidatingAssetAtThisMonth - amountLeft;
        setValueAtMonthYear(assetToBeLiquidated, month, year, reducedAssetValue);
        pushTransactionsAtMonthYear(assetToBeLiquidated, month, year, [
          {
            label: `Stress Test [Partially sell ${assetToBeLiquidated.name} ]`,
            value: amountLeft,
            type: 'Subtract'
          }
        ]);

        solutionSteps.push(
          translate('partially_sold_from_asset', {
            amount: formatCurrency(amountLeft),
            assetName: assetToBeLiquidated.name
          })
        );
        amountLeft = 0;

        assetLiquidations.push({
          assetName: assetToBeLiquidated.name,
          assetId: assetToBeLiquidated._id,
          month,
          year,
          type: 'partial',
          partialValue: amountLeft
        });

        continue;
      }

      const sellingTax = calculateSellingTax({
        strategyInitialYear: strategy.initialYear,
        assetValue: assetToBeLiquidated.value,
        assetBuyYear: assetToBeLiquidated.buyingYear,
        assetSellYear: year,
        assetBuyMonth: assetToBeLiquidated.buyingMonth,
        assetSellMonth: month,
        assetAppreciation: assetToBeLiquidated.returnAppreciation,
        onlyApartment: assetToBeLiquidated.onlyApartment?.selling,
        assetPurchaseCost: assetToBeLiquidated.editMode ? assetToBeLiquidated.purchaseCost : 0,
        assetRealtorCost: assetToBeLiquidated.editMode ? assetToBeLiquidated.realtorCost : 0,
        assetMakeReadyCost: isPaperApartment(assetToBeLiquidated) ? assetToBeLiquidated.makeReadyCost : 0,
        assetSalesCost: assetToBeLiquidated.salesCost,
        sellingTaxBrackets: adminCompTable?.sellingTaxBrackets,
        sellingTaxBracketsForOnlyApartment: adminCompTable?.sellingTaxBracketsForOnlyApartment,
        totalBuyingTax: assetToBeLiquidated.editMode ? assetToBeLiquidated.tax.buy : 0,
        isApartmentAbroad: isAbroadApartment(assetToBeLiquidated),
        prevSellingTaxPercentage: assetToBeLiquidated?.tax?.sellPercentage
      }).total;

      let remainingCashAfterSelling =
        valueOfLiquidatingAssetAtThisMonth - sellingTax - (assetToBeLiquidated.salesCost || 0);

      const amountAlreadyCleared = amountNeeded - amountLeft;
      //complete liquidation
      const totalLoanToBeCleared = linkedLoans.reduce(
        (prevValue, currentLoan) =>
          prevValue +
          (liability && currentLoan._id === liability._id
            ? getValueAtMonthYear(liability, month, year) - amountAlreadyCleared
            : getValueAtMonthYear(currentLoan, month, year)),
        0
      );

      if (totalLoanToBeCleared >= remainingCashAfterSelling) {
        solutionSteps.push(
          translate('could_not_use_asset_loan_is_greater', {
            liquidationAmount: formatCurrency(remainingCashAfterSelling),
            loanAmount: formatCurrency(totalLoanToBeCleared)
          })
        );

        continue;
      }

      linkedLoans.forEach(liability => {
        const liabilityValueReduced = getValueAtMonthYear(liability, month, year);
        setValueAtMonthYear(liability, month, year, 0);
        liability.updatedValue = 0;
        liability.liquidationMonth = month;
        liability.liquidationYear = year;
        pushTransactionsAtMonthYear(liability, month, year, [
          {
            label: `Stress Test [Clear loan ${liability.name} ]`,
            value: liabilityValueReduced,
            type: 'Subtract'
          }
        ]);
      });

      //clear loan from cash
      remainingCashAfterSelling = remainingCashAfterSelling - totalLoanToBeCleared;

      if (totalLoanToBeCleared > 0) {
        solutionSteps.push(
          translate('cleared_linked_loan_remaining_cash', {
            clearedLoan: formatCurrency(totalLoanToBeCleared),
            remainingCash: formatCurrency(remainingCashAfterSelling)
          })
        );
      }

      //take needed money  from available cash
      const moneyTakenFromThisAsset = remainingCashAfterSelling >= amountLeft ? amountLeft : remainingCashAfterSelling;
      remainingCashAfterSelling = remainingCashAfterSelling - moneyTakenFromThisAsset;
      amountLeft = amountLeft - moneyTakenFromThisAsset;

      solutionSteps.push(
        translate('took_from_asset', {
          amount: formatCurrency(moneyTakenFromThisAsset),
          assetName: assetToBeLiquidated.name
        })
      );

      //send remaining cash to bank account
      //if is bank account we keep the remaining amount
      if (assetToBeLiquidated.category === 'Cash') {
        if (remainingCashAfterSelling > 0) {
          solutionSteps.push(
            translate('kept_remaining_cash', {
              amount: formatCurrency(remainingCashAfterSelling)
            })
          );
        }

        pushTransactionsAtMonthYear(assetToBeLiquidated, month, year, [
          {
            label: `Stress Test [Took amount]`,
            value: moneyTakenFromThisAsset,
            type: 'Subtract'
          }
        ]);
        setValueAtMonthYear(assetToBeLiquidated, month, year, remainingCashAfterSelling);
      } else {
        //send any remaining amount to linked bank account
        const linkedBankAccountOfThisAsset = strategy.assets.find(a => assetToBeLiquidated.linkedBankAccount === a._id);
        if (linkedBankAccountOfThisAsset && remainingCashAfterSelling > 0) {
          const moneyInLinkedBankAccount = getValueAtMonthYear(linkedBankAccountOfThisAsset, month, year);
          const finalMoneyInBankAccount = moneyInLinkedBankAccount + remainingCashAfterSelling;
          setValueAtMonthYear(linkedBankAccountOfThisAsset, month, year, finalMoneyInBankAccount);

          solutionSteps.push(
            translate('sent_remaining_cash', {
              amount: formatCurrency(remainingCashAfterSelling),
              accountName: linkedBankAccountOfThisAsset.name
            })
          );
          pushTransactionsAtMonthYear(linkedBankAccountOfThisAsset, month, year, [
            //actual value taken
            //without reducing tax and sales cost
            {
              label: `Stress Test [Liquidated ${assetToBeLiquidated.name} ]`,
              value:
                remainingCashAfterSelling +
                (asset._id === linkedBankAccountOfThisAsset._id ? moneyTakenFromThisAsset : 0) +
                sellingTax +
                (assetToBeLiquidated.salesCost || 0),
              type: 'Add'
            },
            //sales cost
            {
              label: `Stress Test [Selling cost of ${assetToBeLiquidated.name} ]`,
              value: assetToBeLiquidated.salesCost || 0,
              type: 'Subtract'
            },
            //selling tax
            {
              label: `Stress Test [Selling tax of ${assetToBeLiquidated.name} ]`,
              value: sellingTax,
              type: 'Subtract'
            }
          ]);
        }

        assetToBeLiquidated.liquidationMonth = month;
        assetToBeLiquidated.liquidationYear = year;
        setValueAtMonthYear(assetToBeLiquidated, month, year, 0);
        pushTransactionsAtMonthYear(assetToBeLiquidated, month, year, [
          {
            label: `Stress Test [Liquidated ${assetToBeLiquidated.name} ]`,
            value: valueOfLiquidatingAssetAtThisMonth,
            type: 'Subtract'
          }
        ]);
      }

      assetLiquidations.push({
        assetName: assetToBeLiquidated.name,
        assetId: assetToBeLiquidated._id,
        month,
        year
      });
    }
  }

  const amountAdded = amountNeeded - amountLeft;
  if (applyingTo === 'liability') {
    const prevValue = getValueAtMonthYear(liability, month, year);
    setValueAtMonthYear(liability, month, year, prevValue - amountNeeded + amountLeft);
    liability.updatedValue = prevValue - amountAdded;

    pushTransactionsAtMonthYear(liability, month, year, [
      {
        label: `Stress Test [Reduced to meet margin]`,
        value: amountAdded,
        type: 'Subtract'
      }
    ]);
  } else {
    const prevValue = getValueAtMonthYear(asset, month, year);
    setValueAtMonthYear(asset, month, year, prevValue + amountAdded);

    pushTransactionsAtMonthYear(liability, month, year, [
      {
        label: `Stress Test [Added to make non-negative]`,
        value: amountAdded,
        type: 'Add'
      }
    ]);
  }

  if (amountLeft > 0) {
    solutionSteps.push(translate('not_enough_assets', { amount: formatCurrency(amountLeft) }));
  }

  return { solutionSteps, assetLiquidations };
};

const partialSellingCauseMarginCall = ({ stockType, reducedValueOfStock, loanValuesToCover = [] }) => {
  if (!stockType) return true;

  return loanValuesToCover.some(loanValue =>
    isMarginNotMetByValue({
      loanValue,
      stockType,
      stockValue: reducedValueOfStock
    })
  );
};

const liquidateLinkedAssetOfLiability = ({
  strategy,
  assetToBeLiquidated,
  valueOfLiquidatingAssetAtThisMonth,
  liability,
  remainingLoanAmount,
  solutionSteps,
  month,
  year,
  translate,
  adminCompTable,
  customerCompTable
}) => {
  //sell asset deducting any selling costs, pay linked loan
  //and use remaining value to pay for needed amount and send any remaining value to its linked bank account
  const sellingTax = calculateSellingTax({
    strategyInitialYear: strategy.initialYear,
    assetValue: assetToBeLiquidated.value,
    assetBuyYear: assetToBeLiquidated.buyingYear,
    assetSellYear: year,
    assetBuyMonth: assetToBeLiquidated.buyingMonth,
    assetSellMonth: month,
    assetAppreciation: assetToBeLiquidated.returnAppreciation,
    onlyApartment: assetToBeLiquidated.onlyApartment.selling,
    assetPurchaseCost: assetToBeLiquidated.editMode ? assetToBeLiquidated.purchaseCost : 0,
    assetRealtorCost: assetToBeLiquidated.editMode ? assetToBeLiquidated.realtorCost : 0,
    assetMakeReadyCost: isPaperApartment(assetToBeLiquidated) ? assetToBeLiquidated.makeReadyCost : 0,
    assetSalesCost: assetToBeLiquidated.salesCost,
    sellingTaxBrackets: adminCompTable?.sellingTaxBrackets,
    isApartmentAbroad: isAbroadApartment(assetToBeLiquidated),
    sellingTaxBracketsForOnlyApartment: adminCompTable?.sellingTaxBracketsForOnlyApartment,
    totalBuyingTax: assetToBeLiquidated.editMode ? assetToBeLiquidated.tax.buy : 0,
    prevSellingTaxPercentage: assetToBeLiquidated?.tax?.sellPercentage
  }).total;

  let cashAfterLiquidating = valueOfLiquidatingAssetAtThisMonth - sellingTax - assetToBeLiquidated.salesCost;

  solutionSteps.push(
    translate('asset_linked_asset_of_loan', {
      liquidatedValue: formatCurrency(cashAfterLiquidating),
      loanValue: formatCurrency(remainingLoanAmount)
    })
  );

  const linkedLoans = findLinkedLiabilities(strategy.liabilities, assetToBeLiquidated, true);

  let totalLoanReduced = 0;

  linkedLoans.forEach(currentLiability => {
    const liabilityLeft =
      liability._id === currentLiability._id ? remainingLoanAmount : getValueAtMonthYear(liability, month, year);

    const liabilityReduced = cashAfterLiquidating >= liabilityLeft ? liabilityLeft : cashAfterLiquidating;

    if (liability._id === currentLiability._id) {
      remainingLoanAmount = remainingLoanAmount - liabilityReduced;
    } else {
      setValueAtMonthYear(currentLiability, month, year, liabilityLeft - liabilityReduced);
      currentLiability.updatedValue = liabilityLeft - liabilityReduced;
    }

    currentLiability.liquidationMonth = month;
    currentLiability.liquidationYear = year;

    pushTransactionsAtMonthYear(currentLiability, month, year, [
      {
        label: `Stress Test [Cleared Linked loan of ${assetToBeLiquidated.name}]`,
        value: liabilityReduced,
        type: 'Subtract'
      }
    ]);
    totalLoanReduced = totalLoanReduced + liabilityReduced;
  });

  cashAfterLiquidating = cashAfterLiquidating - totalLoanReduced;

  //clear loan from cash
  if (totalLoanReduced > 0) {
    solutionSteps.push(translate('cleared_linked_loan', { clearedLoan: formatCurrency(totalLoanReduced) }));
  }

  //send any remaining amount to linked bank account
  const linkedBankAccountOfThisAsset = strategy.assets.find(a => assetToBeLiquidated.linkedBankAccount === a._id);
  if (linkedBankAccountOfThisAsset && cashAfterLiquidating > 0) {
    const moneyInLinkedBankAccount = getValueAtMonthYear(linkedBankAccountOfThisAsset, month, year);
    const finalMoneyInBankAccount = moneyInLinkedBankAccount + cashAfterLiquidating;
    setValueAtMonthYear(linkedBankAccountOfThisAsset, month, year, finalMoneyInBankAccount);

    pushTransactionsAtMonthYear(linkedBankAccountOfThisAsset, month, year, [
      //actual value added
      {
        label: `Stress Test [Rem. cash after liquidating ${assetToBeLiquidated.name}]`,
        value: cashAfterLiquidating + sellingTax + (assetToBeLiquidated.salesCost || 0),
        type: 'Add'
      },
      //sales cost
      {
        label: `Stress Test [Selling cost of ${assetToBeLiquidated.name} ]`,
        value: assetToBeLiquidated.salesCost || 0,
        type: 'Subtract'
      },
      //selling tax
      {
        label: `Stress Test [Selling tax of ${assetToBeLiquidated.name} ]`,
        value: sellingTax,
        type: 'Subtract'
      }
    ]);

    solutionSteps.push(
      translate('sent_remaining_cash', {
        amount: formatCurrency(cashAfterLiquidating),
        accountName: linkedBankAccountOfThisAsset.name
      })
    );
  }

  assetToBeLiquidated.liquidationMonth = month;
  assetToBeLiquidated.liquidationYear = year;
  setValueAtMonthYear(assetToBeLiquidated, month, year, 0);
  pushTransactionsAtMonthYear(assetToBeLiquidated, month, year, [
    {
      label: `Stress Test [Liquidated to clear loan ${liability.name}]`,
      value: valueOfLiquidatingAssetAtThisMonth,
      type: 'Subtract'
    }
  ]);

  if (remainingLoanAmount > 0) {
    solutionSteps.push(`
    <b class='text-danger'>Danger Zone</b>
    `);
  }

  return remainingLoanAmount;
};

const isMarginNotMet = ({ liability, linkedStock, month, year }) => {
  const loanValueAtThisMonth = getValueAtMonthYear(liability, month, year);
  const stockValueAtThisMonth = getValueAtMonthYear(linkedStock, month, year);
  const equityAtThisMonth = stockValueAtThisMonth - loanValueAtThisMonth;
  return linkedStock.stockType === 'IB'
    ? equityAtThisMonth !== 0 && equityAtThisMonth < 0.3 * stockValueAtThisMonth
    : loanValueAtThisMonth > stockValueAtThisMonth;
};

const isMarginNotMetByValue = ({ loanValue, stockValue, stockType }) => {
  const equityAtThisMonth = stockValue - loanValue;
  return stockType === 'IB' ? equityAtThisMonth !== 0 && equityAtThisMonth < 0.3 * stockValue : loanValue > stockValue;
};

export const formatCurrency = (value, returnZeroWhenBlank = true, round = true) => {
  if (!value) return returnZeroWhenBlank ? 0 : '';

  if (value == '-') return '-';

  if (typeof value === 'string') {
    if (value.endsWith('.') && !round) {
      return value;
    }

    value = Number(value.replace(/[^.\d.-]/g, ''));
  }

  if (isNaN(value) || !isFinite(value)) return '';

  return round ? Math.round(value).toLocaleString() : value.toLocaleString();
};

export const formatPercentage = (value, returnZeroWhenBlank = false, round, roundDecimal = 2) => {
  if (value !== 0 && !value) return returnZeroWhenBlank ? '0 %' : '';

  if (value == '-') return '-';

  const isRTL = isRtl(getActiveLanguage());
  const percentageFieldToAdd = isRTL ? '% ' : ' %';
  if (typeof value === 'string') {
    if (value.endsWith('.')) {
      return value + ' %';
    }

    value = Number(value.replace(/[^.\d.-]/g, ''));
  }

  if (isNaN(value) || !isFinite(value)) return '';

  return (
    (isRTL ? percentageFieldToAdd : '') +
    (round ? value.toFixed(roundDecimal) : value) +
    (!isRTL ? percentageFieldToAdd : '')
  );
};

export const getNumberFromFormattedValue = (value = '') => {
  if (!isNaN(value)) return value;

  let inputValue = Number(value?.replaceAll(',', '').trim() || 0);
  if (Number.isNaN(inputValue)) return;
  return inputValue;
};

export const getNumberFromFormattedPercentage = (value = '') => {
  if (!isNaN(value)) return value;

  let inputValue = Number(value?.replaceAll('%', '').trim() || 0);
  if (Number.isNaN(inputValue)) return;
  return inputValue;
};

//multiplyNumberBy field is specially used in returnCashflow
//there the percentage is based on the annual value of cashflow whereas the cashflow specified is monthly
export const updateNumberPercentage = (
  valueFieldId,
  numberFieldId,
  percentageFieldId,
  updatedField = 'value',
  multiplyNumberBy = 1
) => {
  const valueElem = document.getElementById(valueFieldId);
  const value = valueElem?.value ? Number(valueElem.value.replaceAll(',', '')) : 0;

  const numberElem = document.getElementById(numberFieldId);
  const number = (numberElem?.value ? Number(numberElem.value.replaceAll(',', '')) : 0) * multiplyNumberBy;

  const percentageElem = document.getElementById(percentageFieldId);
  const percentage = percentageElem?.value ? Number(percentageElem.value.replaceAll('%', '')) : 0;

  if (updatedField === 'number') {
    if (percentageElem)
      percentageElem.value =
        !value || (number * 100) / value === 0 ? '' : formatPercentage(((number * 100) / value).toFixed(2));
  } else {
    if (numberElem)
      numberElem.value =
        (percentage * value) / 100 === 0 ? '' : formatCurrency((percentage * value) / (multiplyNumberBy * 100));
  }
};

export const updateSalesCost = updatedField => {
  const valueElem = document.getElementById('value');
  const value = valueElem?.value ? Number(valueElem.value.replaceAll(',', '')) : 0;

  const numberElem = document.getElementById('salesCost');
  const number = numberElem.value ? Number(numberElem.value.replaceAll(',', '')) : 0;

  const percentageElem = document.getElementById('salesCostPercentage');
  const percentage = percentageElem.value ? Number(percentageElem.value.replaceAll('%', '')) : 0;

  const startYearElem = document.getElementById('buyingYear');
  const startYear = startYearElem.value;

  const startMonthElem = document.getElementById('buyingMonth');
  const startMonth = startMonthElem.value;

  const endYearElm = document.getElementById('sellingYear');
  const endYear = endYearElm.value;

  const endMonthElem = document.getElementById('sellingMonth');
  const endMonth = endMonthElem.value;

  const appreciationElm = document.getElementById('returnAppreciation');
  const appreciation = appreciationElm.value ? Number(appreciationElm.value.replaceAll('%', '')) : 0;

  if (!(value && startYear && endYear && appreciation && !(startYear === 'Select Year' || endYear === 'Select Year'))) {
    return;
  }

  const compoundedValue = compoundInterestMonthly(
    value,
    findDiffInNumberOfMonths(startMonth, startYear, endMonth, endYear),
    appreciation
  );

  if (updatedField === 'Number') {
    percentageElem.value =
      !compoundedValue || (number * 100) / compoundedValue === 0
        ? ''
        : formatPercentage(((number * 100) / compoundedValue).toFixed(2));
  } else {
    numberElem.value =
      (percentage * compoundedValue) / 100 === 0 ? '' : formatCurrency((percentage * compoundedValue) / 100);
  }
};

export const updateNumberPercentageFromValue = (value, numberFieldId, percentageFieldId, updatedField = 'value') => {
  const numberElem = document.getElementById(numberFieldId);
  const number = numberElem.value ? Number(numberElem.value.replaceAll(',', '')) : 0;

  const percentageElem = document.getElementById(percentageFieldId);
  const percentage = percentageElem.value ? Number(percentageElem.value.replaceAll('%', '')) : 0;

  //if input number was changed, then update percentage
  if (updatedField === 'number') {
    percentageElem.value =
      !value || (number * 100) / value === 0 ? '' : formatPercentage(((number * 100) / value).toFixed(2));
  } else {
    //else if value or percentage is changed, then update input number
    numberElem.value = (percentage * value) / 100 === 0 ? '' : formatCurrency((percentage * value) / 100);
  }
};

export const updateLoanValueAndPercentage = (loanAmount, loanPercentage) => {
  const loanValueElem = document.getElementById('value');
  loanValueElem.value = formatCurrency(loanAmount);

  const percentageElem = document.getElementById('valueInPercentage');
  percentageElem.value = formatPercentage(loanPercentage.toFixed(2));
};

export const updateMonthlyPaymentFromLoanValue = () => {
  const monthlyPaymentElem = document.getElementById('monthlyPayment');
  if (!monthlyPaymentElem) return;

  const loanType = document.getElementById('type').value;

  //value
  const valueElem = document.getElementById('value');
  const value = valueElem?.value ? Number(valueElem.value.replaceAll(',', '')) : 0;

  //time to maturity
  const timeToMaturityElem = document.getElementById('timeToMaturity');
  const timeToMaturity = timeToMaturityElem.value ? Number(timeToMaturityElem.value.replaceAll(',', '')) : 0;

  //interest
  const interestElem = document.getElementById('interest');
  const interest = interestElem.value ? Number(interestElem.value.replaceAll('%', '')) : 0;

  const monthlyPayment = getMonthlyPayment({ value, type: loanType, timeToMaturity, interest });

  monthlyPaymentElem.value = monthlyPayment ? formatCurrency(monthlyPayment) : '';
};

export const updateTimeToMaturityFromMonthlyPayment = () => {
  const loanType = document.getElementById('type').value;

  //value
  const valueElem = document.getElementById('value');
  const value = valueElem?.value ? Number(valueElem.value.replaceAll(',', '')) : 0;

  //monthlyPayment
  const monthlyPaymentElem = document.getElementById('monthlyPayment');
  const monthlyPayment = monthlyPaymentElem.value ? Number(monthlyPaymentElem.value.replaceAll(',', '')) : 0;

  //time to maturity
  const interestElem = document.getElementById('interest');
  const interest = interestElem.value ? Number(interestElem.value.replaceAll('%', '')) : 0;

  //time to maturity
  const timeToMaturityElem = document.getElementById('timeToMaturity');

  const timeToMaturity = getTimeToMaturityFromMonthlyPayment({ monthlyPayment, type: loanType, interest, value });
  timeToMaturityElem.value = timeToMaturity && !isNaN(timeToMaturity) ? Math.round(timeToMaturity) : '';
};

export const arrayToCommaSeparatedString = arrayOfString => {
  if (!Array.isArray(arrayOfString) || !arrayOfString.length) {
    return '';
  }
  return arrayOfString.reduce((commaSeparatedString, element, index, arr) => {
    if (!index === arr.length - 1) {
      return (commaSeparatedString += element + ',');
    } else {
      return (commaSeparatedString += element);
    }
  }, '');
};

export const yearlyCompoundInterest = (principal, numberOfYear, interestRate) =>
  principal * Math.pow(1 + interestRate / 100, numberOfYear);

export const findWeakestMonthYearOfMargin = (strategy, stock, liability) => {
  const startYear = strategy.initialYear;

  let weakestMonthYear;

  for (let yearIndex = 0; yearIndex <= 10; yearIndex++) {
    const yearToCheck = startYear + yearIndex;
    if (stock && (stock.buyingYear > yearToCheck || (stock.sellingYear && stock.sellingYear < yearToCheck))) {
      continue;
    }

    monthSmall.forEach((__, monthIndex) => {
      if (stock) {
        if (yearToCheck === stock.buyingYear && stock.buyingMonth > monthIndex) return;

        if (stock.sellingYear && yearToCheck === stock.sellingYear && monthIndex >= stock.sellingMonth) return;
      }

      //find total asset value at this month/year
      const totalAssetsValueAtThisMonth = strategy.assets
        .filter(a => (stock ? a._id === stock._id : true))
        .reduce((prevValue, asset) => prevValue + getValueAtMonthYear(asset, monthIndex, yearToCheck), 0);

      //find total liability value at this month/year
      // if stock to filter, then only use liability linked to that stock
      const totalLiabilityValueAtThisMonth = strategy.liabilities
        .filter(l =>
          liability ? liability._id === l._id : stock ? l.relatedAssets && l.relatedAssets.includes(stock.name) : true
        )
        .reduce((prevValue, liability) => prevValue + getValueAtMonthYear(liability, monthIndex, yearToCheck), 0);

      const assetToLiability = totalAssetsValueAtThisMonth / totalLiabilityValueAtThisMonth;
      if (!weakestMonthYear || weakestMonthYear.assetToLiability > assetToLiability) {
        weakestMonthYear = {
          year: yearToCheck,
          month: monthIndex,
          assetToLiability
        };
      }
    });
  }

  return weakestMonthYear;
};

export const findWeakestMonthYearOfStrategy = strategy => {
  const { assets, liabilities } = strategy;
  const strategyYears = getStrategyYears(originalStrategy);

  let weakestMonthYear;

  strategyYears.forEach(year => {
    monthSmall.forEach((m, monthNum) => {
      const assetValueAtThisMonthYear = assets.reduce(
        (prevValue, a) => prevValue + getValueAtMonthYear(a, monthNum, year),
        0
      );
      const liabilityValueAtThisMonthYear = liabilities.reduce(
        (prevValue, l) => prevValue + getValueAtMonthYear(l, monthNum, year),
        0
      );

      const assetToLiability = assetValueAtThisMonthYear / liabilityValueAtThisMonthYear;
      if (!weakestMonthYear || weakestMonthYear.assetToLiability > assetToLiability) {
        weakestMonthYear = {
          year,
          month: monthNum,
          assetToLiability
        };
      }
    });
  });

  return weakestMonthYear;
};

export const findWeakestMonthYearForInterestRateChanges = (
  strategy,
  strategyYears = [],
  strategiesWithExtremeInterestChange
) => {
  let weakestMonthYear;

  strategyYears.forEach(year => {
    monthSmall.forEach((m, monthNum) => {
      const yearMonth = { year, month: monthNum };
      const extremeCasePmtImpactsForThisMonthYear = calculatePMTImpactForRateChanges(strategy, yearMonth, 1, [
        strategiesWithExtremeInterestChange
      ])[0][0];

      const availableLevel1FundsForThisMonthYear = calculateAvailableLevel1Funds(strategy, yearMonth, 1)[0];
      const monthsToSustain =
        extremeCasePmtImpactsForThisMonthYear === 0
          ? '-'
          : Math.floor(availableLevel1FundsForThisMonthYear.after / extremeCasePmtImpactsForThisMonthYear);

      if (monthsToSustain !== '-' && (!weakestMonthYear || weakestMonthYear.monthsToSustain > monthsToSustain)) {
        weakestMonthYear = {
          year,
          month: monthNum,
          monthsToSustain
        };
      }
    });
  });

  return weakestMonthYear;
};

export const calculatePMTImpactForRateChanges = (
  originalCalculatedStrategy,
  weakestMonthYear,
  numOfYear = 3,
  strategiesWithChangedInterestRate = []
) => {
  const { liabilities } = originalCalculatedStrategy;

  const yearToStart = weakestMonthYear.year;
  const weakestMonth = weakestMonthYear.month;

  const impacts = [];
  const liabilitiesToConsider = liabilities.filter(
    l => !l.isInvalid && !l.toBeMerged && ['Mortgage Interest', 'Variable'].includes(l.typeOfInterest)
  );

  strategiesWithChangedInterestRate.forEach(strategyWithChangedInterestRate => {
    const yearlyImpacts = [];

    for (let yearIndex = 0; yearIndex < numOfYear; yearIndex++) {
      let increaseInValues = 0;
      const currentYear = yearToStart + yearIndex;
      liabilitiesToConsider.forEach(l => {
        const prevPMT = getValueAtMonthYear(l, weakestMonth, currentYear, 'payments', 'monthlyPayments');

        const liabilityWithChangedInterest = strategyWithChangedInterestRate.liabilities.find(lc => lc._id === l._id);
        const newPMT = getValueAtMonthYear(
          liabilityWithChangedInterest,
          weakestMonth,
          currentYear,
          'payments',
          'monthlyPayments'
        );

        const changeInPMT = newPMT - prevPMT;
        increaseInValues = increaseInValues + changeInPMT;
      });

      yearlyImpacts.push(increaseInValues);
    }

    impacts.push(yearlyImpacts);
  });

  return impacts;
};

export const calculateBalanceSheetWithChangedInterestRate = (
  originalStrategy,
  armageddon,
  stressTestApplied,
  translate,
  changeInRate,
  adminCompTable,
  customerCompTable
) => {
  //find same strategy if calculated with different interest rate
  let strategyWithChangedInterestRate = cloneStrategy(originalStrategy);
  strategyWithChangedInterestRate.liabilities
    .filter(l => !l.isInvalid && !l.toBeMerged && ['Mortgage Interest', 'Variable'].includes(l.typeOfInterest))
    .forEach(liabilityWithChangedInterest => {
      const changeInInterest =
        liabilityWithChangedInterest.typeOfInterest === 'Mortgage Interest' ? changeInRate / 2 : changeInRate;
      liabilityWithChangedInterest.interest = liabilityWithChangedInterest.interest + changeInInterest;
    });

  return calculateBalanceSheet(
    strategyWithChangedInterestRate,
    armageddon,
    stressTestApplied,
    translate,
    true,
    adminCompTable,
    customerCompTable
  );
};

export const calculateAvailableLevel1Funds = (strategy, weakestMonthYear, numOfYear = 3) => {
  const { assets, liabilities } = strategy;
  const assetsToConsider = assets.filter(a => a.liquidationScore === 1);
  const yearToStart = weakestMonthYear.year;
  const weakestMonth = weakestMonthYear.month;

  const totalValuesAtEachYear = [];

  for (let yearIndex = 0; yearIndex < numOfYear; yearIndex++) {
    const year = yearToStart + yearIndex;

    const totalAssetsValueAtThisMonthBeforeCrash = assetsToConsider.reduce((prevValue, asset) => {
      let monthlyValueOfThisAsset = getValueAtMonthYear(asset, weakestMonth, year);

      const linkedLiabilities = findLinkedLiabilities(liabilities, asset);

      const valueOfLiabilityAtThisMonth = linkedLiabilities.reduce(
        (prevValue, linkedLiability) => prevValue + getValueAtMonthYear(linkedLiability, weakestMonth, year),
        0
      );

      monthlyValueOfThisAsset = monthlyValueOfThisAsset - valueOfLiabilityAtThisMonth;
      return prevValue + monthlyValueOfThisAsset;
    }, 0);

    const totalAssetsValueAtThisMonthAfterCrash = assetsToConsider.reduce((prevValue, asset) => {
      const yearlyValueOfThisAsset = asset.yearlyValues.find(y => y.year === year);
      let monthlyValueOfThisAsset = yearlyValueOfThisAsset ? yearlyValueOfThisAsset.monthlyValues[weakestMonth] : 0;
      monthlyValueOfThisAsset = monthlyValueOfThisAsset - asset.crashPercentage * 0.01 * monthlyValueOfThisAsset;

      const linkedLiabilities = findLinkedLiabilities(liabilities, asset);

      const valueOfLiabilityAtThisMonth = linkedLiabilities.reduce(
        (prevValue, linkedLiability) =>
          (linkedLiability.yearlyValues.find(y => y.year === year)?.monthlyValues[weakestMonth] || 0) + prevValue,
        0
      );

      monthlyValueOfThisAsset = monthlyValueOfThisAsset - valueOfLiabilityAtThisMonth;
      return prevValue + monthlyValueOfThisAsset;
    }, 0);

    totalValuesAtEachYear.push({
      before: totalAssetsValueAtThisMonthBeforeCrash,
      after: totalAssetsValueAtThisMonthAfterCrash
    });
  }

  return totalValuesAtEachYear;
};

export const getStrategyYears = strategy =>
  getNextNYear(strategy.initialYear, strategy.initialMonth ? strategy.totalYears + 1 : strategy.totalYears);

export const getNextNYear = (initialYear, numOfYears = 10) =>
  Array.from({ length: numOfYears }, (_, i) => initialYear + i);

export const findHighestCashflowOfStrategy = (strategy, cashflowCategory, month, year) => {
  let highestCashflow;

  strategy.cashflows
    .filter(
      ({ type, category, affectsRiskManagement = true }) =>
        affectsRiskManagement && type === 'inflow' && category === cashflowCategory
    )
    .forEach(cashflow => {
      const valueOfCashflowAtThisMonth = getValueAtMonthYear(cashflow, month, year);

      if (highestCashflow) {
        if (highestCashflow.valueOfCashflowAtThisMonth < valueOfCashflowAtThisMonth) {
          highestCashflow = { cashflow, valueOfCashflowAtThisMonth };
        }
      } else {
        highestCashflow = { cashflow, valueOfCashflowAtThisMonth };
      }
    });

  return highestCashflow
    ? { name: highestCashflow.cashflow.name, value: highestCashflow.valueOfCashflowAtThisMonth }
    : { name: '-', value: 0 };
};

export const calculateCashflowCushion = (strategy, weakestMonthYear) => {
  const cashAtMonthYear = strategy.assets
    .filter(a => a.category === 'Cash')
    .reduce(
      (prevValue, asset) => prevValue + getValueAtMonthYear(asset, weakestMonthYear.month, weakestMonthYear.year),
      0
    );

  const level1AssetValueAtMonthYear = strategy.assets
    .filter(a => a.category !== 'Cash' && a.liquidationScore === 1)
    .reduce((prevValue, asset) => {
      const valueAtThisMonth = getValueAtMonthYear(asset, weakestMonthYear.month, weakestMonthYear.year);

      //also deduct linked liability value
      const linkedLiabilitiesValue = findLinkedLiabilities(strategy.liabilities, asset).reduce(
        (prevValue, liability) =>
          prevValue + getValueAtMonthYear(liability, weakestMonthYear.month, weakestMonthYear.year),
        0
      );

      return prevValue + valueAtThisMonth - linkedLiabilitiesValue;
    }, 0);

  const level1AssetDepressedValueAtMonthYear = strategy.assets
    .filter(a => a.category !== 'Cash' && a.liquidationScore === 1)
    .reduce((prevValue, asset) => {
      let valueAtThisMonth = getValueAtMonthYear(asset, weakestMonthYear.month, weakestMonthYear.year);
      //depressed value
      valueAtThisMonth = valueAtThisMonth - asset.crashPercentage * 0.01 * valueAtThisMonth;

      //also deduct linked liability value
      const linkedLiabilitiesValue = findLinkedLiabilities(strategy.liabilities, asset).reduce(
        (prevValue, liability) =>
          prevValue + getValueAtMonthYear(liability, weakestMonthYear.month, weakestMonthYear.year),
        0
      );

      return prevValue + valueAtThisMonth - linkedLiabilitiesValue;
    }, 0);

  return [cashAtMonthYear + level1AssetValueAtMonthYear, cashAtMonthYear + level1AssetDepressedValueAtMonthYear];
};

export const calculateMarginCallForStocks = (strategy, stocksWithWeakestMonthYear, percentageDecreaseToConsider) => {
  const marginCallValues = stocksWithWeakestMonthYear.map(stocksWithWeakestMonthYear => {
    const linkedLiability = stocksWithWeakestMonthYear.linkedLiability;
    const monthYear = stocksWithWeakestMonthYear.weakestMonthYear;
    const stock = stocksWithWeakestMonthYear.stock;

    //find value, loan and loanToEquity at that monthYear
    const stockValueAtThisYear = stock.yearlyValues.find(y => y.year === monthYear.year);
    const stockValue = stockValueAtThisYear ? stockValueAtThisYear.monthlyValues[monthYear.month] : 0;

    const loanValueAtThisYear = linkedLiability.yearlyValues.find(y => y.year === monthYear.year);
    const loanValue = loanValueAtThisYear ? loanValueAtThisYear.monthlyValues[monthYear.month] : 0;

    const loanToEquity = stockValue === 0 ? 0 : (loanValue / stockValue) * 100;

    const assetsDecreaseValues = percentageDecreaseToConsider.map(p => {
      const equityValue = stock.stockType === 'IB' ? stockValue - p * 0.01 * stockValue - loanValue : undefined;
      const portfolioValue = stockValue - p * 0.01 * stockValue;
      return {
        percentage: p,
        portfolioValue,
        equityValue,
        equityToPortfolio:
          stock.stockType === 'IB' ? (portfolioValue === 0 ? 0 : (equityValue / portfolioValue) * 100) : undefined
      };
    });

    const maximalDrawdown =
      stockValue == 0
        ? 0
        : (stock.stockType === 'IB' ? 1 - loanValue / 0.7 / stockValue : (stockValue - loanValue) / stockValue) * 100;

    const cushionValues = calculateCashflowCushion(strategy, monthYear);
    return {
      name: stock.name,
      stockType: stock.stockType,
      stockValue,
      loanValue,
      loanToEquity,
      assetsDecreaseValues,
      maximalDrawdown,
      cushionValues
    };
  });

  return marginCallValues;
};

export const calculateSellingTax = ({
  strategyInitialYear,
  assetValue,
  assetBuyYear,
  assetSellYear,
  assetBuyMonth,
  assetSellMonth,
  assetAppreciation,
  onlyApartment,
  assetPurchaseCost = 0,
  assetRealtorCost = 0,
  assetMakeReadyCost = 0,
  assetSalesCost = 0,
  sellingTaxBrackets = [],
  sellingTaxBracketsForOnlyApartment = [],
  totalBuyingTax,
  isApartmentAbroad,
  prevSellingTaxPercentage = 0,
  manualTax = 0,
  autoMode = false
}) => {
  if (!assetSellYear) {
    return {
      appreciatedValue: 0,
      pnl: 0,
      taxes: [],
      total: autoMode ? 0 : manualTax,
      totalPercentage: prevSellingTaxPercentage
    };
  }

  const buyYear = assetBuyYear || strategyInitialYear;
  const buyMonth = assetBuyMonth || 0;
  const sellMonth = assetSellMonth || 0;

  const diffInMonth = findDiffInNumberOfMonths(buyMonth, buyYear, sellMonth, assetSellYear);

  const appreciatedValue = compoundInterestMonthly(assetValue, diffInMonth, assetAppreciation || 0);
  const pnl =
    appreciatedValue -
    //costs involved
    (assetValue + assetMakeReadyCost + totalBuyingTax + assetPurchaseCost + assetRealtorCost + assetSalesCost);

  const sellingTax = {
    appreciatedValue,
    pnl
  };

  sellingTax.taxes =
    pnl > 0 && !isApartmentAbroad
      ? onlyApartment
        ? calculateSellingTaxForOnlyAppartment(appreciatedValue, pnl, sellingTaxBracketsForOnlyApartment)
        : calculateTaxFromBrackets(pnl, onlyApartment ? sellingTaxBracketsForOnlyApartment : sellingTaxBrackets)
      : [];

  if (autoMode) {
    if (!isApartmentAbroad) {
      sellingTax.total = sellingTax.taxes.reduce((total, t) => total + t.actualTax, 0);
    } else {
      sellingTax.total = sellingTax.pnl > 0 ? prevSellingTaxPercentage * 0.01 * sellingTax.pnl : 0;
    }
  } else {
    sellingTax.total = manualTax;
  }

  sellingTax.totalPercentage = pnl > 0 ? (sellingTax.total * 100) / pnl : 0;

  return sellingTax;
};

export const calculateTaxFromBrackets = (amount, brackets = []) => {
  return brackets
    .filter(b => amount >= (b.from || 0))
    .map(b => {
      const upperAmount = b.to && b.to <= amount ? b.to : amount;
      return { ...b, to: upperAmount, actualTax: ((upperAmount - (b.from || 0)) * b.percentage) / 100 };
    });
};

export const calculateSellingTaxForOnlyAppartment = (amount, profit, brackets = []) => {
  return brackets
    .filter(b => amount >= (b.from || 0))
    .map(b => {
      const upperAmount = b.to && b.to <= amount ? b.to : amount;
      const taxOnProfit = (b.percentage * profit) / 100;

      const actualTax = ((upperAmount - (b.from || 0)) / upperAmount) * taxOnProfit;
      return { ...b, to: upperAmount, actualTax };
    });
};

export const getNetValue = (strategy, asset) => {
  const assetValue = asset.value;
  const linkedLiabilityValue = findLinkedLiabilities(strategy.liabilities, asset)[0]?.value || 0;

  return assetValue - linkedLiabilityValue;
};

export const findLinkedLiabilities = (liabilities, asset, includeToBeMerged = false) => {
  return liabilities.filter(
    l =>
      !l.isInvalid &&
      (includeToBeMerged || !l.toBeMerged) &&
      (l.relatedAssets?.includes(asset.name) || l.debtFor === asset.name)
  );
};

export const findDiffInNumberOfMonths = (startMonth, startYear, endMonth, endYear) => {
  const date1 = new Date(startYear, startMonth, 1);
  const date2 = new Date(endYear, endMonth, 1);

  const date1Year = date1.getFullYear();
  const date2Year = date2.getFullYear();
  const fullYearsInBetween = date2Year - date1Year - 1;
  if (fullYearsInBetween == -1) return date2.getMonth() - date1.getMonth();
  return 12 - date1.getMonth() + 12 * fullYearsInBetween + date2.getMonth();
};

export const addMonthAndYear = (buyingMonth, buyingYear, monthsToAdd = 0, yearsToAdd = 0) => {
  const dateNow = new Date(buyingYear, buyingMonth, 1);
  dateNow.setMonth(dateNow.getMonth() + (yearsToAdd * 12 + monthsToAdd));
  return { month: dateNow.getMonth(), year: dateNow.getFullYear() };
};

//updates the end year to be one of the asset end year/maturity end year
// whichever is the earliest
export const updateEndYearOfLiability = (linkedAsset, isFixedIncome, yearsWithIncompleteMonths) => {
  const isAutoDateCalculateChecked = document.getElementById('endDateCalculatedAutomatically')?.checked;
  if (!isAutoDateCalculateChecked) return {};

  let maturityValue = document.getElementById('timeToMaturity').value;
  maturityValue = maturityValue ? Number(maturityValue) : 0;

  const loanStartYear = Number(document.getElementById(isFixedIncome ? 'buyingYear' : 'startYear').value);
  const loanStartMonth = Number(document.getElementById(isFixedIncome ? 'buyingMonth' : 'startMonth').value);

  const loanMaturityEndDate = new Date(loanStartYear, loanStartMonth, 1, 0, 0, 0, 0);
  loanMaturityEndDate.setMonth(loanMaturityEndDate.getMonth() + maturityValue);

  const assetSaleDate =
    !isFixedIncome && linkedAsset?.sellingYear
      ? new Date(linkedAsset?.sellingYear, linkedAsset?.sellingMonth, 1, 0, 0, 0, 0)
      : null;

  const endYearElem = document.getElementById(isFixedIncome ? 'sellingYear' : 'endYear');
  const endMonthElem = document.getElementById(isFixedIncome ? 'sellingMonth' : 'endMonth');

  if (!assetSaleDate || assetSaleDate.getTime() > loanMaturityEndDate.getTime()) {
    let endYear = loanMaturityEndDate.getFullYear();
    const endMonth = loanMaturityEndDate.getMonth();

    const incompleteYear = yearsWithIncompleteMonths?.[endYear];
    if (incompleteYear && (endMonth < incompleteYear.start || endMonth > incompleteYear.end - 1)) {
      endYear++;
    }

    endYearElem.value = endYear;
    endMonthElem.value = endMonth;

    return { year: endYear, month: endMonth };
  } else {
    endYearElem.value = assetSaleDate.getFullYear();
    endMonthElem.value = assetSaleDate.getMonth();
    return { year: assetSaleDate.getFullYear(), month: assetSaleDate.getMonth() };
  }
};

export const getEndYearOfLiability = (linkedAsset, isFixedIncome, yearsWithIncompleteMonths, timeToMaturity) => {
  const isAutoDateCalculateChecked = document.getElementById('endDateCalculatedAutomatically')?.checked;
  if (!isAutoDateCalculateChecked) return {};

  let maturityValue = timeToMaturity || document.getElementById('timeToMaturity').value;
  maturityValue = maturityValue ? Number(maturityValue) : 0;

  const loanStartYear = Number(document.getElementById(isFixedIncome ? 'buyingYear' : 'startYear').value);
  const loanStartMonth = Number(document.getElementById(isFixedIncome ? 'buyingMonth' : 'startMonth').value);

  const loanMaturityEndDate = new Date(loanStartYear, loanStartMonth, 1, 0, 0, 0, 0);
  loanMaturityEndDate.setMonth(loanMaturityEndDate.getMonth() + maturityValue);

  const assetSaleDate =
    !isFixedIncome && linkedAsset?.sellingYear
      ? new Date(linkedAsset?.sellingYear, linkedAsset?.sellingMonth, 1, 0, 0, 0, 0)
      : null;

  if (!assetSaleDate || assetSaleDate.getTime() > loanMaturityEndDate.getTime()) {
    let endYear = loanMaturityEndDate.getFullYear();
    const endMonth = loanMaturityEndDate.getMonth();

    const incompleteYear = yearsWithIncompleteMonths?.[endYear];
    if (incompleteYear && (endMonth < incompleteYear.start || endMonth > incompleteYear.end - 1)) {
      endYear++;
    }

    return { year: endYear, month: endMonth };
  } else {
    return { year: assetSaleDate.getFullYear(), month: assetSaleDate.getMonth() };
  }
};

/**
 * Takes edited asset, unedited asset and updates its linkedLiabilities date, such that liability stays within the asset's range
 * @param {*} asset [Linked Asset of the liability]
 * @returns new variables of the liability according to the asset
 */

export const getUpdatedLinkedVariablesOfLiability = (strategy, asset, oldAsset, linkedLiability) => {
  if (!asset) return {};

  const {
    buyingYear: assetBuyingYear,
    sellingYear: assetSellingYear,
    buyingMonth: assetBuyingMonth,
    sellingMonth: assetSellingMonth,
    linkedBankAccount,
    name: assetName
  } = asset;

  const updatedVariables = {
    linkedBankAccount,
    relatedAssets: [assetName]
  };

  if (!linkedLiability.endDateCalculatedAutomatically) return updatedVariables;

  const {
    startYear: liabilityPreviousStartYear,
    startMonth: liabilityPreviousStartMonth,
    timeToMaturity
  } = linkedLiability;

  const assetNewStartDate = new Date(assetBuyingYear, assetBuyingMonth, 1, 0, 0, 0, 0);
  const assetNewEndDate = assetSellingYear ? new Date(assetSellingYear, assetSellingMonth, 1, 0, 0, 0, 0) : null;

  let differenceInMonthsPreviously = 0;

  //START DATE
  //maintain same gap in start date as with older dates
  //so if previously loan and asset were 2 years apart, same should continue now
  if (oldAsset) {
    const { buyingYear: assetPreviousBuyingYear, buyingMonth: assetPreviousBuyingMonth } = oldAsset;

    differenceInMonthsPreviously = findDiffInNumberOfMonths(
      assetPreviousBuyingMonth,
      assetPreviousBuyingYear,
      liabilityPreviousStartMonth,
      liabilityPreviousStartYear
    );
  }

  const liabilityNewStartDate = new Date(assetNewStartDate);
  liabilityNewStartDate.setMonth(liabilityNewStartDate.getMonth() + differenceInMonthsPreviously);

  if (liabilityNewStartDate.getFullYear() < strategy.initialYear) {
    liabilityNewStartDate.setFullYear(strategy.initialYear);
  }

  updatedVariables.startMonth = liabilityNewStartDate.getMonth();
  updatedVariables.startYear = liabilityNewStartDate.getFullYear();

  //END DATE, either maturity or asset end date, whichever is earlier
  let liabilityMaturityDate = new Date(liabilityNewStartDate);
  liabilityMaturityDate.setMonth(liabilityMaturityDate.getMonth() + timeToMaturity);

  if (assetNewEndDate && assetNewEndDate.getTime() < liabilityMaturityDate.getTime()) {
    updatedVariables.endMonth = assetNewEndDate.getMonth();
    updatedVariables.endYear = assetNewEndDate.getFullYear();
  } else {
    const isMaturityBeyondStrategy = liabilityMaturityDate.getFullYear() > getFinalYearOfStrategy(strategy);
    updatedVariables.endMonth = isMaturityBeyondStrategy ? null : liabilityMaturityDate.getMonth();
    updatedVariables.endYear = isMaturityBeyondStrategy ? null : liabilityMaturityDate.getFullYear();
  }

  return updatedVariables;
};

// if initial month is Jan, it ends exactly after total years
// otherwise we need one more year to cover the additional months
export const getFinalYearOfStrategy = strategy =>
  strategy.initialYear + strategy.totalYears - (strategy.initialMonth ? 0 : 1);

export const getChartColor = strategyIndex => {
  // so that if the strategy index is more than the colors available
  // we start from the beginning of the array
  return chartColors[strategyIndex % chartColors.length];
};

const isLastMonthOfStrategy = (strategyStartYear, totalNumberOfYearsInStrategy = 10, currentMonth, currentYear) => {
  if (currentMonth !== 11) return false;

  return currentYear === strategyStartYear + totalNumberOfYearsInStrategy - 1;
};

export const isRSUAsset = asset => asset?.category === 'RSU';

export const isPaperApartment = asset =>
  asset && asset.category === 'Real Estate' && asset.apartmentType === 'Paper Apartment';

export const isPinuiBinuiApartment = asset =>
  asset && asset.category === 'Real Estate' && asset.apartmentType === 'Pinui Binui Apartment';

export const doesApartmentHasContructionPhase = (asset, considerPinuiBinui = true) =>
  isPaperApartment(asset) || (considerPinuiBinui && isPinuiBinuiApartment(asset));

export const isAbroadApartment = asset =>
  asset && asset.category === 'Real Estate' && asset.apartmentType === 'Abroad Apartment';

const calculateAppreciationDuringConstruction = asset => {
  const { valueOfApartmentToday, returnAppreciation, value, estimatedTimeForFinishingContruction } = asset;

  const valueAtEndOfConstruction = compoundInterest(
    valueOfApartmentToday,
    estimatedTimeForFinishingContruction,
    getMonthlyRate(returnAppreciation * 0.01)
  );

  return findAnnualRateOfCompounding(valueAtEndOfConstruction, value, estimatedTimeForFinishingContruction);
};

export const findAllLoansOfPaperApartment = (asset, liability) => {
  const {
    value: askedPrice,
    buyingMonth,
    buyingYear,
    sellingMonth,
    sellingYear,
    estimatedTimeForFinishingContruction,
    downPayment = 0,
    remainingPayment,
    paperApartmentPayments = []
  } = asset;

  paperApartmentPayments.sort((p1, p2) => {
    const date1 = new Date(p1.year, p1.month, 1, 0, 0, 0, 0);
    const date2 = new Date(p2.year, p2.month, 1, 0, 0, 0, 0);

    return date1.getTime() - date2.getTime();
  });

  const { value: mortgageValue, _id: loanId = '', name: loanName = '' } = liability;

  const paperLiability = (value, startMonth, startYear, isToClearInstallment) => {
    return {
      ...liability,
      value,
      startMonth,
      startYear,
      parentId: loanId,
      parentName: loanName,
      isPaperApartmentLoan: true,
      toBeMerged: true,
      isToClearInstallment
    };
  };

  const liabilities = [];

  let maxEquityThatCanBeUsed = askedPrice - mortgageValue;

  let downPaymentAmount = downPayment * 0.01 * askedPrice; //Adding down payment.

  let cashReducedFromEquity = maxEquityThatCanBeUsed >= downPaymentAmount ? downPaymentAmount : maxEquityThatCanBeUsed;
  maxEquityThatCanBeUsed -= cashReducedFromEquity;

  let remainingDownPayment = downPaymentAmount - cashReducedFromEquity;
  if (remainingDownPayment > 0) {
    //mortgage taken
    liabilities.push(paperLiability(remainingDownPayment, buyingMonth, buyingYear));
  }

  paperApartmentPayments.forEach(p => {
    const paymentAmount = p.amount;

    let paymentCashReducedFromEquity = maxEquityThatCanBeUsed >= paymentAmount ? paymentAmount : maxEquityThatCanBeUsed;
    maxEquityThatCanBeUsed -= paymentCashReducedFromEquity;

    let remainingPayment = paymentAmount - paymentCashReducedFromEquity;
    if (remainingPayment > 0) {
      //mortgage taken
      liabilities.push(paperLiability(remainingPayment, p.month, p.year));
    }
  });

  let remainingAmountToPay =
    askedPrice - downPaymentAmount - paperApartmentPayments.reduce((amount, p) => p.amount + amount, 0);

  //when there are paper payments, gradual payments would start after the final Payment
  const farthestPayment = paperApartmentPayments.reduce((acc, p) => {
    const diffInMonthForThisPayment = findDiffInNumberOfMonths(buyingMonth, buyingYear, p.month, p.year);
    if (!acc || diffInMonthForThisPayment > acc.diffInMonth) return { ...p, diffInMonth: diffInMonthForThisPayment };
    return acc;
  }, null);

  const installmentTimePeriodInMonths = estimatedTimeForFinishingContruction - (farthestPayment?.diffInMonth || 0);
  const numberOfInstallments = remainingPayment === 'oneFinalPayment' ? 1 : installmentTimePeriodInMonths / 12;

  const baseInstallment = remainingAmountToPay / numberOfInstallments;

  const numberOfYearsInEachLoop = installmentTimePeriodInMonths / 12 / numberOfInstallments;
  const doesFinalInstallmentEndBeforeYearEnd = numberOfInstallments % 1 !== 0;

  for (let i = 0; i < numberOfInstallments; i++) {
    const isInstallmentNotAtTheEndOfYear =
      doesFinalInstallmentEndBeforeYearEnd && i === Math.floor(numberOfInstallments);

    const remainingMonthsIfNotAtEndOfYear = installmentTimePeriodInMonths % 12;
    const installmentToConsider = isInstallmentNotAtTheEndOfYear
      ? (numberOfInstallments % 1) * baseInstallment
      : baseInstallment;

    const amountPaidFromEquity =
      maxEquityThatCanBeUsed >= installmentToConsider ? installmentToConsider : maxEquityThatCanBeUsed;
    maxEquityThatCanBeUsed -= amountPaidFromEquity;
    let remainingInstallment = installmentToConsider - amountPaidFromEquity;
    if (remainingInstallment > 0) {
      const yearsPassed =
        (farthestPayment?.diffInMonth || 0) / 12 +
        numberOfYearsInEachLoop * (i + (isInstallmentNotAtTheEndOfYear ? 0 : 1));
      const monthsPassed = isInstallmentNotAtTheEndOfYear ? remainingMonthsIfNotAtEndOfYear : 0;
      const { month: loanStartMonth, year: loanStartYear } = addMonthAndYear(
        buyingMonth,
        buyingYear,
        monthsPassed,
        yearsPassed
      );
      //do not add mortgage, if it starts after asset is sold
      if (
        !sellingYear ||
        loanStartYear < sellingYear ||
        (loanStartYear === sellingYear && loanStartMonth <= sellingMonth)
      ) {
        liabilities.push(paperLiability(remainingInstallment, loanStartMonth, loanStartYear, true));
      }
    }
  }

  return liabilities.map((l, index) => ({ ...l, _id: `${loanId}-${index}`, name: `${loanName}-${index + 1}` }));
};

const createPaperApartmentDebtLoan = (asset, multiplier = 0.4) => {
  const {
    value: askedPrice,
    buyingMonth,
    buyingYear,
    sellingMonth,
    sellingYear,
    estimatedTimeForFinishingContruction,
    downPayment = 0,
    name,
    yearlyMaterialCostIndexRise = 0,
    linkedBankAccount,
    remainingPayment,
    paperApartmentPayments = []
  } = asset;

  let downPaymentAmount = downPayment * 0.01 * askedPrice; //Adding down payment.
  let loanValue = askedPrice - downPaymentAmount;

  const { month: constructionEndMonth, year: constructionEndYear } = addMonthAndYear(
    buyingMonth,
    buyingYear,
    estimatedTimeForFinishingContruction,
    0
  );

  //when there are paper payments, gradual payments would start after the final Payment
  const farthestPayment = paperApartmentPayments.reduce((acc, p) => {
    const diffInMonthForThisPayment = findDiffInNumberOfMonths(buyingMonth, buyingYear, p.month, p.year);
    if (!acc || diffInMonthForThisPayment > acc.diffInMonth) return { ...p, diffInMonth: diffInMonthForThisPayment };
    return acc;
  }, null);

  const numberOfInstallments =
    remainingPayment === 'oneFinalPayment'
      ? 1
      : (estimatedTimeForFinishingContruction - (farthestPayment?.diffInMonth || 0)) / 12;

  const installmentStartDate = remainingPayment !== 'oneFinalPayment' && farthestPayment;
  const isSellingDateEarlier =
    sellingYear && isMonthYearEarlier(sellingMonth, sellingYear, constructionEndMonth, constructionEndYear);

  let remainingAmountToPay =
    askedPrice - downPaymentAmount - paperApartmentPayments.reduce((amount, p) => p.amount + amount, 0);

  const baseInstallment = remainingAmountToPay / numberOfInstallments;

  return {
    type: 'Balloon',
    name: `Debt (${name})`,
    value: loanValue,
    interest: yearlyMaterialCostIndexRise * multiplier,
    editMode: asset.editMode,
    typeOfInterest: 'Fixed',
    timeToMaturity: findDiffInNumberOfMonths(buyingMonth, buyingYear, constructionEndMonth, constructionEndYear),
    startYear: buyingYear,
    startMonth: buyingMonth,
    endYear: isSellingDateEarlier ? sellingYear : constructionEndYear,
    endMonth: isSellingDateEarlier ? sellingMonth : constructionEndMonth,
    loanProvider: '',
    monthlyPayment: 0,
    relatedAssets: [],
    debtFor: name,
    gracePeriod: 0,
    endWithAsset: false,
    endWithMaturity: false,
    linkedBankAccount: linkedBankAccount,
    numberOfInstallments,
    installmentStartDate,
    baseInstallment,
    id: uniqueId()
  };
};

export const findMarginalValueOfLoan = (currentValueOfAsset, margin) => {
  return (margin * 0.01 * currentValueOfAsset) / (1 - margin * 0.01);
};

export const isPrimaryBankAccount = asset => asset.name === primaryBankAccountName;

export const getLoanNamePrefill = (asset, isMarginLoan, translate) => {
  if (!asset) return translate('loan_against');

  const preFix = asset.category === 'Real Estate' ? 'mortgage' : isMarginLoan ? 'margin_loan_against' : 'loan_against';
  return `${translate(preFix)} ${asset.name}`;
};

//group edit related features

/**
 * 1. DO not allow strategy which already has asset/loan with same name
 * 2. On edit, Show warning for strategy which do not have asset/loan with same name, because a new one will be created
 * 3. Do not allow strategy which does not have linked bank account of same name
 */
//works for assets, loans, cashflow because they all have name and linked bank account
export const findCommonGroupEditProblems = ({
  selectedStrategy,
  strategiesToCheck = [],
  instrumentKey,
  instrumentName,
  instrument,
  toBeEditedInstrument
}) => {
  const problemStrategies = [];
  const warningStrategies = [];
  let changedFields = {},
    allFields = {};

  //for automated cashflow, we check if underlying instrument is available
  if (instrumentName === 'cashflow' && instrument.isAutomated) {
    const { strategyKey, type, cashflowChanges } = instrument;
    problemStrategies.push(
      ...strategiesToCheck
        .filter(s => !s[strategyKey].map(i => i.name).includes(instrument.name))
        .map(s => ({
          _id: s._id,
          strategyName: s.name,
          problem: `This strategy does not have an ${type === 'inflow' ? 'asset' : 'liability'} named ${
            instrument.name
          }`
        }))
    );

    changedFields = { cashflowChanges };
    allFields = { cashflowChanges };
    return { problemStrategies, warningStrategies, changedFields };
  }

  //1.
  if (!toBeEditedInstrument) {
    problemStrategies.push(
      ...strategiesToCheck
        .filter(s => s[instrumentKey].map(i => i.name).includes(instrument.name))
        .map(s => ({
          _id: s._id,
          strategyName: s.name,
          problem: `This strategy already has an ${instrumentName} named ${instrument.name}`
        }))
    );
  }

  //2.
  if (toBeEditedInstrument) {
    const getChangedFields = compareObject => {
      let changedFields = findDifferenceInObjects(
        instrument,
        compareObject,
        [],
        [
          'Mode',
          'purchaseCostPercentage',
          'realtorCostPercentage',
          'makeReadyPercentage',
          'returnCashflowPercentage',
          'salesCostPercentage',
          'showMonthlyPayment',
          'monthlyPayment',
          'assetName',
          'valueInPercentage',
          'updateGroup',
          'yearlyValues',
          'groupId',
          '_id',
          'id',
          '%'
        ]
      );

      if (changedFields.linkedBankAccount) {
        changedFields.linkedBankAccountName = selectedStrategy.assets.find(
          a => a._id === changedFields.linkedBankAccount
        )?.name;
      }

      if (changedFields.linkedApartment) {
        changedFields.linkedApartmentName = selectedStrategy.assets.find(
          a => a._id === changedFields.linkedApartment
        )?.name;
      }

      return changedFields;
    };

    changedFields = getChangedFields(toBeEditedInstrument);
    allFields = getChangedFields({});

    warningStrategies.push(
      ...strategiesToCheck
        .filter(s => !s[instrumentKey].map(i => i.name).includes(toBeEditedInstrument.name))
        .map(s => ({
          _id: s._id,
          strategyName: s.name,
          warning: 'Create New'
        }))
    );
  }

  //3.
  if (instrument.linkedBankAccount !== 'not_linked') {
    const linkedBankAccountName = selectedStrategy.assets.find(a => a._id === instrument.linkedBankAccount)?.name;

    problemStrategies.push(
      ...strategiesToCheck
        .filter(s => !s['assets'].map(i => i.name).includes(linkedBankAccountName))
        .map(s => ({
          _id: s._id,
          strategyName: s.name,
          problem: `This strategy does not have an asset with name ${linkedBankAccountName} to link as bank account!`
        }))
    );
  }

  return { problemStrategies, warningStrategies, changedFields, allFields };
};

//1. on edit, do not allow strategy which do not have reinvestment with same groupId
//2. Do not allow strategy which do not have asset/liability

export const findReinvestmentGroupEditProblems = ({
  selectedStrategy,
  strategiesToCheck = [],
  newReinvestment,
  toBeEditedReinvestment
}) => {
  const problemStrategies = [];
  const warningStrategies = [];
  let changedFields = {},
    allFields = {};

  //1.
  if (toBeEditedReinvestment) {
    const getChangedFields = compareObject => {
      let changedFields = findDifferenceInObjects(
        newReinvestment,
        compareObject,
        [],
        ['updateGroup', '_id', 'fromType', 'toType', 'groupId']
      );

      if (changedFields.toId) {
        changedFields.toInstrumentName = selectedStrategy[
          newReinvestment.toType === 'asset' ? 'assets' : 'liabilities'
        ].find(a => a._id === changedFields.toId)?.name;
      }

      if (changedFields.fromId) {
        changedFields.fromInstrumentName = selectedStrategy[
          newReinvestment.fromType === 'asset' ? 'assets' : 'liabilities'
        ].find(a => a._id === changedFields.fromId)?.name;
      }

      return changedFields;
    };

    const { fromType, fromId = '', toType, toId = '' } = toBeEditedReinvestment;

    changedFields = getChangedFields({
      ...toBeEditedReinvestment,
      fromId: fromId.split(':')[1],
      toId: toId.split(':')[1]
    });

    allFields = getChangedFields({});

    warningStrategies.push(
      ...strategiesToCheck
        .filter(
          s =>
            !toBeEditedReinvestment.groupId ||
            !s['investments'].map(i => i.groupId).includes(toBeEditedReinvestment.groupId)
        )
        .map(s => ({
          _id: s._id,
          strategyName: s.name,
          warning: 'Create New'
        }))
    );
  }

  //2.
  const { fromType, fromId, toType, toId } = newReinvestment;

  const fromInstrumentKey = fromType === 'asset' ? 'assets' : 'liabilities';
  const fromInstrument =
    fromType && fromId ? selectedStrategy[fromInstrumentKey].find(a => a._id === fromId)?.name : undefined;

  const toInstrumentKey = toType === 'asset' ? 'assets' : 'liabilities';
  const toInstrument = toType && toId ? selectedStrategy[toInstrumentKey].find(a => a._id === toId)?.name : undefined;

  strategiesToCheck.forEach(s => {
    const assets = s.assets.map(a => a.name);
    const liabilities = s.liabilities.map(a => a.name);
    let notFoundAssetsLiability = [];

    if (fromInstrument && !(fromType === 'asset' ? assets : liabilities).includes(fromInstrument)) {
      notFoundAssetsLiability.push(`${fromInstrument} (${fromType})`);
    }

    if (toInstrument && !(toType === 'asset' ? assets : liabilities).includes(toInstrument)) {
      notFoundAssetsLiability.push(`${toInstrument} (${toType})`);
    }

    if (notFoundAssetsLiability.length > 0) {
      problemStrategies.push({
        _id: s._id,
        strategyName: s.name,
        problem: `This strategy does not have the following: ${notFoundAssetsLiability.join(', ')}`
      });
    }
  });

  return { problemStrategies, warningStrategies, changedFields, allFields };
};

/**
 * DO not allow strategy which does not have linked asset of this loan
 */
export const findLiabilityEditProblems = ({ strategiesToCheck = [], liability, linkedAsset, toBeEditedLiability }) => {
  const problemStrategies = [];
  const warningStrategies = [];

  //add with asset
  if (linkedAsset) {
    //new asset
    if (!linkedAsset._id) {
      problemStrategies.push(
        ...strategiesToCheck
          .filter(s => s['assets'].map(a => a.name).includes(linkedAsset.name))
          .map(s => ({
            _id: s._id,
            strategyName: s.name,
            problem: `This strategy already has an asset named ${linkedAsset.name}`
          }))
      );

      warningStrategies.push(
        ...strategiesToCheck
          .filter(s => !s['assets'].map(a => a.name).includes(linkedAsset.name))
          .map(s => ({
            _id: s._id,
            strategyName: s.name,
            warning: 'Create New Asset'
          }))
      );
    }
    //old asset
    else {
      problemStrategies.push(
        ...strategiesToCheck
          .filter(s => !s['assets'].map(a => a.name).includes(linkedAsset.name))
          .map(s => ({
            _id: s._id,
            strategyName: s.name,
            problem: `This strategy does not have an asset named ${linkedAsset.name}`
          }))
      );
    }
  }

  return { problemStrategies, warningStrategies };
};

/**
 * 1. DO not allow strategy which do not have asset/loan with same name
 */
export const findCommonGroupDeleteProblems = ({
  selectedStrategy,
  strategiesToCheck = [],
  instrumentKey,
  instrumentName,
  instrument,
  linkedLiabilities = [] //only in case of asset
}) => {
  const problemStrategies = [];

  problemStrategies.push(
    ...strategiesToCheck
      .filter(s => !s[instrumentKey].map(i => i.name).includes(instrument.name))
      .map(s => ({
        _id: s._id,
        strategyName: s.name,
        problem: `This strategy does not have an ${instrumentName} named ${instrument.name}`
      }))
  );

  if (instrumentKey === 'assets' && linkedLiabilities.length > 0) {
    const liabilityNames = linkedLiabilities.map(l => l.name);
    strategiesToCheck.forEach(s => {
      const thisLiabilityNames = s['liabilities'].map(i => i.name);
      const notExistingLiabilities = liabilityNames.filter(n => !thisLiabilityNames.includes(n));

      if (notExistingLiabilities.length > 0) {
        problemStrategies.push({
          _id: s._id,
          strategyName: s.name,
          problem: `This strategy does not have the following liabilities: ${notExistingLiabilities.join(', ')}`
        });
      }
    });
  }

  return problemStrategies;
};

/**
 * 1. DO not allow strategy which do not have reinvestment with same groupId
 */
export const findReinvestmentDeleteProblems = ({ selectedStrategy, strategiesToCheck = [], reinvestment }) => {
  const problemStrategies = [];

  problemStrategies.push(
    ...strategiesToCheck
      .filter(s => !reinvestment.groupId || !s.investments.map(i => i.groupId).includes(reinvestment.groupId))
      .map(s => ({
        _id: s._id,
        strategyName: s.name,
        problem: `This strategy does not have a linked reinvestment`
      }))
  );

  return problemStrategies;
};

export const updateLoanValueFromMarginFloor = ({ linkedAsset, floorPercentage }) => {
  const startYear = Number(document.getElementById('startYear').value);
  const startMonth = Number(document.getElementById('startMonth').value);

  const valueOfAssetAtThisMonthYear = doesApartmentHasContructionPhase(linkedAsset)
    ? linkedAsset.value
    : getValueAtMonthYear(linkedAsset, startMonth, startYear);

  const valueOfMarginLoan = findMarginalValueOfLoan(valueOfAssetAtThisMonthYear, floorPercentage);
  const loanPercentage = (valueOfMarginLoan * 100) / valueOfAssetAtThisMonthYear;
  updateLoanValueAndPercentage(valueOfMarginLoan, loanPercentage);
};

export const isMonthYearEarlier = (month1, year1, month2, year2, inclusive) => {
  if (year1 < year2) {
    return true;
  }

  if (year1 > year2) {
    return false;
  }

  // At this point, the years are equal, so we just compare the months.
  return inclusive ? month1 <= month2 : month1 < month2;
};

//sort so that, assets bought first show up at top
//in case of same start month and year, sort by ending the latest
//to show up at the top
export const sortInstruments = (instruments = []) => {
  // Step 1: Filter out instruments with toBeMerged flag
  const filteredInstruments = instruments.filter(instrument => !instrument.toBeMerged);

  // Step 2: Sort the filtered instruments by start and end dates
  const sortedInstruments = filteredInstruments.sort((i1, i2) => {
    if (isPrimaryBankAccount(i1)) return -1;
    if (isPrimaryBankAccount(i2)) return 1;

    const startYear1 = i1['startYear'] ?? i1['buyingYear'];
    const startMonth1 = i1['startMonth'] ?? i1['buyingMonth'];

    const startYear2 = i2['startYear'] ?? i2['buyingYear'];
    const startMonth2 = i2['startMonth'] ?? i2['buyingMonth'];

    const endYear1 = (i1['endYear'] ?? i1['sellingYear']) || 3000;
    const endMonth1 = (i1['endMonth'] ?? i1['sellingMonth']) || 0;

    const endYear2 = (i2['endYear'] ?? i2['sellingYear']) || 3000;
    const endMonth2 = (i2['endMonth'] ?? i2['sellingMonth']) || 0;

    const startMonths1 = startYear1 * 12 + startMonth1;
    const startMonths2 = startYear2 * 12 + startMonth2;

    const diffInStart = startMonths1 - startMonths2;
    if (diffInStart !== 0) return diffInStart;

    const endMonths1 = endYear1 * 12 + endMonth1;
    const endMonths2 = endYear2 * 12 + endMonth2;

    return endMonths2 - endMonths1;
  });

  // Step 3: Move child instruments below their respective parents
  const result = [];

  sortedInstruments.forEach(instrument => {
    result.push(instrument);

    const children = instruments.filter(i => {
      if (!i.toBeMerged) return false;

      const findByName = i.hasOwnProperty('parentName');
      if (findByName) return i.parentName === instrument.name;

      return i.parentId === (instrument._id || instrument.id);
    });
    result.push(...children);
  });

  return result;
};

export const findNewNameInstrument = (namesToIgnore = [], oldName) => {
  const endingWithNumberRegex = new RegExp(/-\d+$/);

  //trying to find new name
  const endingDashNumber = oldName.match(endingWithNumberRegex);
  let newNumber;
  if (endingDashNumber) {
    newNumber = Number(endingDashNumber[0].replace('-', '')) + 1;
  } else {
    newNumber = 2;
  }

  let newName = oldName.replace(endingWithNumberRegex, '');

  while (namesToIgnore.includes(newName + '-' + newNumber)) {
    newNumber++;
  }

  return newName + '-' + newNumber;
};

const isSetupModeValid = instrumentToCheck => {
  return !instrumentToCheck.editMode;
};

export const getIncompleteYearsOfStrategy = strategy => {
  //if strategy starts at first month there are no incomplete years
  if (!strategy.initialMonth) return {};

  const initialYear = strategy.initialYear;
  const initialMonth = strategy.initialMonth || 0;
  const finalYear = getFinalYearOfStrategy(strategy);

  return {
    [initialYear]: {
      start: initialMonth,
      end: 12
    },
    [finalYear]: {
      start: 0,
      end: initialMonth
    }
  };
};

//This function removes any fields which are not part of calculation
//if removeUnnecessaryFields is true
//this significantly improves the overall speed of the algo
export const cloneStrategy = (str, removeUnnecessaryFields = true) => {
  const strToClone = { ...str };

  if (removeUnnecessaryFields) {
    const unnecessaryFields = ['stressTestProblems', 'stressTestIgnoredLiabilities', 'stressTestIgnoredAssets'];

    unnecessaryFields.forEach(field => {
      delete strToClone[field];
    });

    ['assets', 'liabilities', 'fixedIncomeAssets'].forEach(k => {
      if (strToClone[k]) {
        strToClone[k] = strToClone[k].map(i => ({ ...i, transactions: undefined }));
      }
    });
  }
  const clonedStr = cloneDeep(strToClone);
  return clonedStr;
};

const getAnonaPaymentName = asset => {
  return `${asset.name} - Payment`;
};

export function findMonthYearAtWhichAssetBecomesZero(values = [], startMonth, startYear, endMonth, endYear) {
  // Sort the array in ascending order of year
  values.sort((a, b) => a.year - b.year);

  for (const yearlyValue of values) {
    if (yearlyValue.year >= startYear && (!endYear || yearlyValue.year <= endYear)) {
      let monthToStart = yearlyValue.year === startYear ? startMonth : 0;
      let monthToEnd = yearlyValue.year === endYear ? endMonth : 11;

      for (let j = monthToStart; j <= monthToEnd; j++) {
        if (yearlyValue.monthlyValues[j] === 0) {
          return {
            year: yearlyValue.year,
            month: j
          };
        }
      }
    }
  }

  // If zero not found, return the ending month and year
  return {
    year: endYear || null,
    month: endMonth
  };
}

export const calculatorApartmentModalFields = (strategy, appartment, apartmentType) => {
  const getNumberValue = key => {
    if (!appartment?.[key] || isNaN(appartment?.[key])) return 0;

    return appartment[key];
  };

  const startMonth = Number(document.getElementById('buyingMonth').value);
  const startYear = Number(document.getElementById('buyingYear').value);

  const assetValue = getNumberValue('inputPrice');
  const monthlyRent = getNumberValue('monthlyRent');
  const purchaseCost = getNumberValue('relatedCostsLawyerAppraiserEtc');

  const assetFieldsToFill = [
    { key: 'value', value: assetValue },
    { key: 'returnAppreciation', value: getNumberValue('expectedAppreciation') * 100, isPercentage: true },
    { key: 'returnCashflow', value: getNumberValue('monthlyRent') },
    { key: 'returnCashflowPercentage', value: (monthlyRent * 100 * 12) / assetValue, isPercentage: true },
    { key: 'cashflowAppreciation', value: getNumberValue('rentIncrease') * 100, isPercentage: true },
    { key: 'taxOnCashflow', value: getNumberValue('taxOnRent') * 100, isPercentage: true },
    { key: 'purchaseCost', value: purchaseCost },
    { key: 'purchaseCostPercentage', value: (purchaseCost * 100) / assetValue, isPercentage: true },
    { key: 'realtorCost', value: getNumberValue('realtorCost') },
    {
      key: 'realtorCostPercentage',
      value: getNumberValue('realtorCostsInPercentageTermsPreVat') * 100,
      isPercentage: true
    },
    { key: 'makeReadyCost', value: getNumberValue('additionalCostsForMakingApartmentReadyForRent') },
    {
      key: 'makeReadyPercentage',
      value: (getNumberValue('additionalCostsForMakingApartmentReadyForRent') * 100) / assetValue,
      isPercentage: true
    },
    { key: 'salesCost', value: 0 },
    { key: 'salesCostPercentage', value: 0, isPercentage: true },
    {
      key: 'yearlyMaterialCostIndexRise',
      value: getNumberValue('yearlyMaterialCostIndexRise') * 100,
      isPercentage: true
    },
    { key: 'estimatedTimeForFinishingContruction', value: getNumberValue('estimatedTimeForFinishingContruction') },
    { key: 'valueOfApartmentToday', value: getNumberValue('valueOfApartmentToday') },
    { key: 'downPayment', value: getNumberValue('downPayment') * 100, isPercentage: true },
    { key: 'remainingPayment', value: appartment.remainingPayment || 'oneFinalPayment' }
  ];

  //this is in months,even though the key says in years
  const realEstateHoldingPeriodInMonths = getNumberValue('realEstateHoldingPeriodInYears');
  //update selling year if real state is hold for less than the total strategy length
  if (realEstateHoldingPeriodInMonths <= (strategy.totalYears || 10) * 12) {
    const { month, year } = addMonthAndYear(startMonth, startYear, realEstateHoldingPeriodInMonths, 0);
    assetFieldsToFill.push(
      ...[
        { key: 'sellingYear', value: year.toString() },
        { key: 'sellingMonth', value: month.toString() }
      ]
    );
  }

  //paper apartment payments
  if (apartmentType === 'Paper Apartment' && appartment['paperApartmentPayments']?.length > 0) {
    assetFieldsToFill.push({
      key: 'paperApartmentPayments',
      value: appartment['paperApartmentPayments'].map(p => {
        const amount = Number(p.value || 0);
        const { month, year } = addMonthAndYear(startMonth, startYear, Number(p.month || 0));
        const payment = {
          id: uniqueId(),
          amount,
          '%': (amount * 100) / assetValue,
          month: month,
          year: year
        };
        return payment;
      })
    });
  }

  const loanValue = getNumberValue('mortgageToBeTakenDependingOnSeveralOptions');
  const loanFieldsToFill = [
    { key: 'value', value: loanValue },
    { key: 'valueInPercentage', value: (loanValue * 100) / assetValue, isPercentage: true },
    { key: 'monthlyPayment', value: getNumberValue('mortgageMonthlyPayment') },
    { key: 'interest', value: getNumberValue('mortgageInterest') * 100, isPercentage: true },
    { key: 'timeToMaturity', value: getNumberValue('mortgageTimeToMaturityInMonths') }
  ];

  return { assetFieldsToFill, loanFieldsToFill };
};
