import { snakeCase } from "lodash";
import React, { useContext } from "react";
import { LocalizeContext } from 'react-locale-language';

const Title = ({ field: { label, required }, editMode, className, style }) => {
  const { translate, } = useContext(LocalizeContext);
  return (
    <p style={style} className={`mb-1 ${className}`}>
      <span className="text-dark mid">{translate(snakeCase(label)) || label}</span>
      {required && editMode && <span className="text-danger">* </span>}
    </p>
  );
};

export default Title;
