import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import ReactPlayer from 'react-player';

function convertToEmbedLink(googleDriveLink, autoplay = false) {
  const fileIdMatch = googleDriveLink.match(/\/file\/d\/(.*?)\//);

  if (fileIdMatch && fileIdMatch[1]) {
    const fileId = fileIdMatch[1];
    let embedLink = `https://drive.google.com/file/d/${fileId}/preview`;
    if (autoplay) {
      embedLink += '?autoplay=1'; // Attempt to autoplay the video
    }
    return embedLink;
  } else {
    return googleDriveLink;
  }
}

const VideoPlayer = ({ videoLinks = [], height = 380, autoplay = true }) => {
  const [activeVideoTab, setActiveVideoTab] = useState(null);

  useEffect(() => {
    setActiveVideoTab(videoLinks[0]);
  }, [videoLinks]);

  const isGoogleDriveLink = link => {
    return link.includes('drive.google.com');
  };

  return (
    <>
      {videoLinks.length > 1 &&
        videoLinks.map((videoLink, index) => (
          <Button
            onClick={() => setActiveVideoTab(videoLink)}
            key={videoLink}
            size="sm"
            className="mx-1"
            variant={activeVideoTab === videoLink ? 'primary' : 'secondary'}
          >
            Video {index + 1}
          </Button>
        ))}

      {activeVideoTab &&
        (isGoogleDriveLink(activeVideoTab) ? (
          <iframe
            src={convertToEmbedLink(activeVideoTab)}
            width="100%"
            height={height}
            frameBorder="0"
            allow="autoplay"
            style={{ margin: '5px auto' }}
          ></iframe>
        ) : (
          <ReactPlayer
            playing={autoplay}
            url={activeVideoTab}
            controls
            height={height}
            width="100%"
            style={{ margin: '5px auto' }}
          />
        ))}
    </>
  );
};

export default VideoPlayer;
