import React, { useMemo, useContext } from 'react';
import CircularProgressBar from '../../../../../common/circular-progress';
import { DraggableTable } from '../../../../../common/draggable-table/DraggableTable';
import SmallButton from '../../../../../common/SmallButton';
import { getNetValue } from '../helper';
import { LocalizeContext } from 'react-locale-language';
import { primaryBankAccountName } from '../../../../../../helpers/constants';

const getLiqColumns = translate => [
  { label: translate('asset'), key: 'name' },
  { label: translate('liquidation_score'), key: 'liquidationScore' },
  { label: translate('net_value'), key: 'netValue', isMonetaryField: true }
];

const LiquidationTable = ({ strategy, onAssetResort, updating, editable }) => {
  const { translate, langCode } = useContext(LocalizeContext);

  const sortedAssets = useMemo(
    () =>
      strategy.assets
        .filter(a => a.category !== 'Fixed Income')
        .map(a => ({ ...a, netValue: getNetValue(strategy, a) })),
    [strategy]
  );

  const liqColumns = useMemo(() => getLiqColumns(translate), [langCode]);

  const onResortToDefaultClick = () => {
    let tempList = [...sortedAssets];
    tempList.sort((a, b) => {
      const liqSort = a.liquidationScore - b.liquidationScore;
      if (liqSort !== 0) return liqSort;

      return b.netValue - a.netValue;
    });

    // have bank account at top
    tempList = [
      ...tempList.filter(a => a.name === primaryBankAccountName),
      ...tempList.filter(a => a.name !== primaryBankAccountName)
    ];
    onAssetResort(tempList);
  };

  return (
    <>
      <h6 className="d-inline">
        <b>{translate('liquidation_table')}</b>
      </h6>
      {editable && (
        <>
          <SmallButton disabled={updating} className="me-3 ms-2 text-white" size="sm" onClick={onResortToDefaultClick}>
            {translate('resort_to_default')}
          </SmallButton>
          {updating && <CircularProgressBar />}
        </>
      )}

      <DraggableTable
        list={sortedAssets}
        columns={liqColumns}
        onListSort={onAssetResort}
        draggable={editable && !updating}
      />
    </>
  );
};

export default LiquidationTable;
