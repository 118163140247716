import { computeTaxesForCalculators } from './helpers';

const Finance = require('tvm-financejs');
const { FV, PMT } = new Finance();
const { irr: IRR } = require('node-irr');

export const combinedIRRFormula = (
  inputPrice,
  {
    areaInSquareMeters,
    renovationCost,
    valueAddedByRenovation,
    appraisedValue,
    maximalMortgageAvailable,
    onlyApartmentWhileBuying,
    onlyApartmentWhileSelling,
    mortgagePercentage,
    expectedAppreciation,
    //this is in months
    realEstateHoldingPeriodInYears: realEstateHoldingPeriodInMonths,
    timeToDeliveryAfterBuyingInMonths,
    monthlyRent,
    rentIncrease,
    //this includes vat, although the symbol says pre vat
    realtorCostsInPercentageTermsPreVat,
    vat,
    relatedCostsLawyerAppraiserEtc,
    mortgageInterest,
    mortgageTimeToMaturityInMonths,
    taxOnRent,
    compTable,
    useNewIRR = false
  },
  returnIRR
) => {
  //calculate average monthly rent
  const averageMonthlyRent = compute_averageMonthlyRent(monthlyRent, rentIncrease, realEstateHoldingPeriodInMonths);

  const valueAfterRenovation = compute_valueAfterRenovation(renovationCost, inputPrice, valueAddedByRenovation);
  const mortgageToBeTakenDependingOnSeveralOptions = compute_mortgageToBeTakenDependingOnSeveralOptions(
    mortgagePercentage,
    inputPrice,
    appraisedValue,
    maximalMortgageAvailable
  );

  //Use rent increase %
  //this is 1 month of rent, do we use average monthly rate?
  const costOfVacancyAndAnnualRepairs = compute_costOfVacancyAndAnnualRepairs(monthlyRent);
  const mortgageMonthlyPayment = compute_mortgageMonthlyPayment(
    mortgageInterest,
    mortgageTimeToMaturityInMonths,
    mortgageToBeTakenDependingOnSeveralOptions
  );
  const realtorCost = compute_realtorCost(inputPrice, realtorCostsInPercentageTermsPreVat);
  const differenceBetweenAppraisedValueAndAttractivePrice = compute_differenceBetweenAppraisedValueAndAttractivePrice(
    inputPrice,
    appraisedValue
  );

  //Use rent increase %
  //we loose all the rent until the apartment is delivered, this is cost of time to delivery
  const costOfTimeToDeliveryAfterBuying = compute_costOfTimeToDeliveryAfterBuying(
    timeToDeliveryAfterBuyingInMonths,
    monthlyRent,
    rentIncrease
  );
  const equityForTakingMortgage = compute_equityForTakingMortgage(
    inputPrice,
    mortgageToBeTakenDependingOnSeveralOptions
  );

  const sellingPrice = compute_sellingPrice(
    valueAfterRenovation,
    expectedAppreciation,
    realEstateHoldingPeriodInMonths
  );
  const { buyingTax, sellingTax } = computeTaxesForCalculators(
    sellingPrice,
    valueAfterRenovation,
    onlyApartmentWhileBuying,
    onlyApartmentWhileSelling,
    compTable
  );
  const costOfRealtorBuyerAndAllRelatedCosts = compute_costOfRealtorBuyerAndAllRelatedCosts(
    realtorCost,
    relatedCostsLawyerAppraiserEtc,
    buyingTax
  );

  const mortgageLeftAtTheEndPeriodOfHolding = compute_mortgageLeftAtTheEndPeriodOfHolding(
    mortgageInterest,
    realEstateHoldingPeriodInMonths,
    mortgageMonthlyPayment,
    mortgageToBeTakenDependingOnSeveralOptions
  );

  const totalLoanDecrease = compute_totalLoanDecrease(
    mortgageToBeTakenDependingOnSeveralOptions,
    mortgageLeftAtTheEndPeriodOfHolding
  );

  //Use rent increase %
  //total income from rent for realEstateHoldingPeriodInMonths minus all the costs (do we not consider costOfTimeToDeliveryAfterBuying?)
  const totalIncomeFromRent = compute_totalIncomeFromRent(
    averageMonthlyRent,
    costOfVacancyAndAnnualRepairs,
    realEstateHoldingPeriodInMonths,
    taxOnRent
  );

  //Use rent increase %
  //need to find average yearly rent taking in mind rent increase yearly throughout the holding period
  const yearlyReturnFromRent = compute_yearlyReturnFromRent(averageMonthlyRent, inputPrice);

  //Use rent increase %
  //same as yearlyReturnFromRent
  const netYearlyReturnOnRent = compute_netYearlyReturnOnRent(
    averageMonthlyRent,
    costOfVacancyAndAnnualRepairs,
    taxOnRent,
    inputPrice
  );
  const totalMonthlyPayments = compute_totalMonthlyPayments(mortgageMonthlyPayment, realEstateHoldingPeriodInMonths);
  const totalEquityToPutInTheDeal = compute_totalEquityToPutInTheDeal(
    useNewIRR ? 0 : costOfTimeToDeliveryAfterBuying,
    renovationCost,
    equityForTakingMortgage,
    costOfRealtorBuyerAndAllRelatedCosts
  );
  const totalDealCost = compute_totalDealCost(totalEquityToPutInTheDeal, mortgageToBeTakenDependingOnSeveralOptions);
  const profitFromSellingBeforeTax = compute_profitFromSellingBeforeTax(
    sellingPrice,
    inputPrice,
    renovationCost,
    costOfRealtorBuyerAndAllRelatedCosts
  );
  const profitFromSellingAfterTax = compute_profitFromSellingAfterTax(profitFromSellingBeforeTax, sellingTax);
  const totalIncomeFromRentMinusTotalMonthlyPayments = compute_totalIncomeFromRentMinusTotalMonthlyPayments(
    totalIncomeFromRent,
    totalMonthlyPayments
  );
  const totalNetProfit = compute_totalNetProfit(
    profitFromSellingAfterTax,
    totalIncomeFromRentMinusTotalMonthlyPayments
  );
  const totalRoi = compute_totalRoi(totalNetProfit, totalEquityToPutInTheDeal);
  const pricePerSquareMeter = compute_pricePerSquareMeter(inputPrice, areaInSquareMeters);

  const irr_old = compute_iir(totalRoi, realEstateHoldingPeriodInMonths);

  //Add all cashflows here for calculating irr
  //1. initial cashflow
  const allCashflows = [-totalEquityToPutInTheDeal];
  const cashflowBreakdown = {
    Begin: [
      getBreakdownObject('renovation_cost', -renovationCost),
      getBreakdownObject('equity_for_taking_mortgage', -equityForTakingMortgage),
      getBreakdownObject('cost_of_retailer_lawyer', -costOfRealtorBuyerAndAllRelatedCosts)
    ]
  };

  //2. cashflow for each month
  for (let month = 1; month <= realEstateHoldingPeriodInMonths; month++) {
    cashflowBreakdown[month] = [];

    const yearlyRent = calculateYearlyRent(monthlyRent, rentIncrease, Math.ceil(month / 12));
    const incrementedMonthlyRent = yearlyRent / 12;

    //rent only excluding tax
    const finalRent = month <= timeToDeliveryAfterBuyingInMonths ? 0 : incrementedMonthlyRent * (1 - taxOnRent);
    const incomingCashflows = [finalRent];

    //mortgage, costOfVacancyAndAnnualRepairs, realtor cost,
    const vacancyCost = month % 12 === 0 ? incrementedMonthlyRent : 0; //vacancy cost is estimated to 1 month of rent

    const outgoingCashflows = [mortgageMonthlyPayment, vacancyCost];

    let netCashflow =
      incomingCashflows.reduce((acc, c) => acc + c, 0) - outgoingCashflows.reduce((acc, c) => acc + c, 0);

    //add to breakdowns:
    cashflowBreakdown[month].push(
      ...[
        { key: 'rent', value: finalRent },
        { key: 'total_monthly_payments', value: -mortgageMonthlyPayment },
        { key: 'cost_of_vacancy_and_annual_repairs', value: -vacancyCost }
      ]
        .filter(a => a.value !== 0)
        .map(({ key, value }) => getBreakdownObject(key, value))
    );

    //3. if last month, add remaining cash after selling
    if (month === realEstateHoldingPeriodInMonths) {
      netCashflow += sellingPrice - sellingTax - mortgageLeftAtTheEndPeriodOfHolding;

      //add to breakdowns:
      cashflowBreakdown[month].push(
        ...[
          { key: 'selling_price', value: sellingPrice },
          { key: 'selling_tax', value: -sellingTax },
          { key: 'mortgage_left_at_the_end', value: -mortgageLeftAtTheEndPeriodOfHolding }
        ].map(({ key, value }) => getBreakdownObject(key, value))
      );
    }

    allCashflows.push(netCashflow);
  }

  const irr = useNewIRR ? getNewIRR(allCashflows) : irr_old;
  if (returnIRR) {
    return irr;
  }

  return {
    valueAfterRenovation,
    mortgageToBeTakenDependingOnSeveralOptions,
    costOfVacancyAndAnnualRepairs,
    yearlyReturnFromRent,
    mortgageMonthlyPayment,
    realtorCost,
    differenceBetweenAppraisedValueAndAttractivePrice,
    costOfTimeToDeliveryAfterBuying,
    equityForTakingMortgage,
    costOfRealtorBuyerAndAllRelatedCosts,
    buyingTax,
    sellingPrice,
    mortgageLeftAtTheEndPeriodOfHolding,
    totalLoanDecrease,
    totalIncomeFromRent,
    netYearlyReturnOnRent,
    totalMonthlyPayments,
    totalEquityToPutInTheDeal,
    totalDealCost,
    profitFromSellingBeforeTax,
    profitFromSellingAfterTax,
    totalIncomeFromRentMinusTotalMonthlyPayments,
    totalNetProfit,
    totalRoi,
    pricePerSquareMeter,
    irr,
    averageMonthlyRent,
    irrBreakdown: useNewIRR ? cashflowBreakdown : null
  };
};

const getNewIRR = cashflows => {
  const monthlyIRR = IRR(cashflows);
  return convertMonthlyIrrToAnnual(monthlyIRR);
};

const convertMonthlyIrrToAnnual = monthlyIrr => {
  return (1 + monthlyIrr) ** 12 - 1;
};

const getBreakdownObject = (label, cashflow) => ({ description: label, cashflow });

function calculateYearlyRent(monthlyRent, yearlyIncrease, years) {
  const initialYearlyRent = monthlyRent * 12;
  const finalYearlyRent = initialYearlyRent * Math.pow(1 + yearlyIncrease, years - 1);
  return finalYearlyRent;
}

const compute_averageMonthlyRent = (monthlyRent, rentIncreaseYearly, realEstateHoldingPeriodInMonths) => {
  if (!realEstateHoldingPeriodInMonths) return 0;

  let totalRent = 0;
  let currentRent = monthlyRent;

  for (let month = 0; month < realEstateHoldingPeriodInMonths; month++) {
    totalRent += currentRent;

    // Check if a year has passed to apply the rent increase
    if ((month + 1) % 12 === 0) {
      currentRent *= 1 + rentIncreaseYearly;
    }
  }

  const averageMonthlyRent = totalRent / realEstateHoldingPeriodInMonths;
  return averageMonthlyRent;
};

const compute_totalAccumulatedRent = (monthlyRent, rentIncreaseYearly, holdingPeriodInMonths) => {
  let totalRent = 0;
  let currentRent = monthlyRent;
  let monthsRemaining = holdingPeriodInMonths;

  while (monthsRemaining > 0) {
    // Calculate the rent for the remaining months in the current year
    const monthsInCurrentYear = Math.min(12, monthsRemaining);
    totalRent += currentRent * monthsInCurrentYear;
    monthsRemaining -= monthsInCurrentYear;

    // Increase the rent for the next year
    if (monthsRemaining > 0) {
      currentRent *= 1 + rentIncreaseYearly;
    }
  }

  return totalRent;
};

const compute_pricePerSquareMeter = (targetPrice, areaInSquareMeters) => targetPrice / areaInSquareMeters;

const compute_iir = (totalRoi, realEstateHoldingPeriodInMonths) =>
  (1 + totalRoi) ** (12 / realEstateHoldingPeriodInMonths) - 1;

const compute_totalRoi = (totalNetProfit, totalEquityToPutInTheDeal) => totalNetProfit / totalEquityToPutInTheDeal;

const compute_totalMonthlyPayments = (mortgageMonthlyPayment, realEstateHoldingPeriodInMonths) =>
  mortgageMonthlyPayment * realEstateHoldingPeriodInMonths;

const compute_costOfVacancyAndAnnualRepairs = monthlyRent => monthlyRent;

const compute_totalIncomeFromRent = (
  monthlyRent,
  costOfVacancyAndAnnualRepairs,
  realEstateHoldingPeriodInMonths,
  taxOnRent
) => {
  const years = realEstateHoldingPeriodInMonths / 12;
  const totalVacancyCost = Math.ceil(years) * costOfVacancyAndAnnualRepairs;
  return (monthlyRent * realEstateHoldingPeriodInMonths - totalVacancyCost) * (1 - taxOnRent);
};

const compute_yearlyReturnFromRent = (monthlyRent, targetPrice) => (monthlyRent * 12) / targetPrice;

const compute_netYearlyReturnOnRent = (monthlyRent, costOfVacancyAndAnnualRepairs, taxOnRent, targetPrice) =>
  ((monthlyRent * 12 - costOfVacancyAndAnnualRepairs) * (1 - taxOnRent)) / targetPrice;

const compute_totalIncomeFromRentMinusTotalMonthlyPayments = (totalIncomeFromRent, totalMonthlyPayments) =>
  totalIncomeFromRent - totalMonthlyPayments;

const compute_totalNetProfit = (profitFromSellingAfterTax, totalIncomeFromRentMinusTotalMonthlyPayments) =>
  profitFromSellingAfterTax + totalIncomeFromRentMinusTotalMonthlyPayments;

const compute_mortgageMonthlyPayment = (
  mortgageInterest,
  mortgageTimeToMaturityInMonths,
  mortgageToBeTakenDependingOnSeveralOptions
) => -1 * PMT(mortgageInterest / 12, mortgageTimeToMaturityInMonths, mortgageToBeTakenDependingOnSeveralOptions, 0);

const compute_mortgageLeftAtTheEndPeriodOfHolding = (
  mortgageInterest,
  realEstateHoldingPeriodInMonths,
  mortgageMonthlyPayment,
  mortgageToBeTakenDependingOnSeveralOptions
) =>
  -1 *
  FV(
    mortgageInterest / 12,
    realEstateHoldingPeriodInMonths,
    -1 * mortgageMonthlyPayment,
    mortgageToBeTakenDependingOnSeveralOptions,
    0
  );

const compute_totalLoanDecrease = (mortgageToBeTakenDependingOnSeveralOptions, mortgageLeftAtTheEndPeriodOfHolding) =>
  mortgageToBeTakenDependingOnSeveralOptions - mortgageLeftAtTheEndPeriodOfHolding;

const compute_profitFromSellingBeforeTax = (
  sellingPrice,
  inputPrice,
  renovationCost,
  costOfRealtorBuyerAndAllRelatedCosts
) => sellingPrice - inputPrice - renovationCost - costOfRealtorBuyerAndAllRelatedCosts;

const compute_profitFromSellingAfterTax = (profitFromSellingBeforeTax, sellingTax) =>
  profitFromSellingBeforeTax - sellingTax;

const compute_sellingPrice = (valueAfterRenovation, expectedAppreciation, realEstateHoldingPeriodInMonths) => {
  const monthlyAppreciation = (1 + expectedAppreciation) ** (1 / 12) - 1;
  return valueAfterRenovation * (1 + monthlyAppreciation) ** realEstateHoldingPeriodInMonths;
};

const compute_valueAfterRenovation = (renovationCost, targetPrice, valueAddedByRenovation) =>
  renovationCost + targetPrice + valueAddedByRenovation;

const compute_totalEquityToPutInTheDeal = (
  costOfTimeToDeliveryAfterBuying,
  renovationCost,
  equityForTakingMortgage,
  costOfRealtorBuyerAndAllRelatedCosts
) => costOfTimeToDeliveryAfterBuying + renovationCost + equityForTakingMortgage + costOfRealtorBuyerAndAllRelatedCosts;

const compute_totalDealCost = (totalEquityToPutInTheDeal, mortgageToBeTakenDependingOnSeveralOptions) =>
  totalEquityToPutInTheDeal + mortgageToBeTakenDependingOnSeveralOptions;

const compute_costOfRealtorBuyerAndAllRelatedCosts = (realtorCost, relatedCostsLawyerAppraiserEtc, buyingTax) =>
  realtorCost + relatedCostsLawyerAppraiserEtc + buyingTax;

const compute_costOfTimeToDeliveryAfterBuying = (timeToDeliveryAfterBuyingInMonths, monthlyRent, rentIncreaseYearly) =>
  compute_totalAccumulatedRent(monthlyRent, rentIncreaseYearly, timeToDeliveryAfterBuyingInMonths);

const compute_equityForTakingMortgage = (targetPrice, mortgageToBeTakenDependingOnSeveralOptions) =>
  targetPrice - mortgageToBeTakenDependingOnSeveralOptions;

const compute_mortgageToBeTakenDependingOnSeveralOptions = (
  mortgagePercentage,
  inputPrice,
  appraisedValue,
  maximalMortgageAvailable
) => {
  let askedMortgage = inputPrice * mortgagePercentage;
  let mortgageByAppraisedValue = appraisedValue * mortgagePercentage;
  let lowerOfTheTwo = Math.min(askedMortgage, mortgageByAppraisedValue);
  if (lowerOfTheTwo > maximalMortgageAvailable) return maximalMortgageAvailable;
  return lowerOfTheTwo;
};

const compute_realtorCost = (targetPrice, realtorCostsInPercentageTermsIncludingVat) =>
  targetPrice * realtorCostsInPercentageTermsIncludingVat;

const compute_differenceBetweenAppraisedValueAndAttractivePrice = (targetPrice, appraisedValue) =>
  targetPrice - appraisedValue;

const findTargetPriceInRange = (lower, higher, targetIIR, otherParams, depthOfSlicing) => {
  const increments = (higher - lower) / depthOfSlicing;
  let testPrices = [];
  let priceAtInterval = lower;
  while (true) {
    testPrices.push(priceAtInterval);
    priceAtInterval += increments;
    if (priceAtInterval > higher) break;
  }
  let iirAtThesePrices = testPrices.map(price => combinedIRRFormula(price, otherParams, true));
  for (let i = 0; i < iirAtThesePrices.length - 1; i++) {
    let current = iirAtThesePrices[i];
    let next = iirAtThesePrices[i + 1];
    if (Math.abs(targetIIR - current) < 0.001) return testPrices[i];
    if (Math.abs(targetIIR - next) < 0.001) return testPrices[i + 1];
    if (numberBetweenRange(current, next, targetIIR))
      return findTargetPriceInRange(testPrices[i], testPrices[i + 1], targetIIR, otherParams, depthOfSlicing);
  }
  return Number.NaN;
};

const numberBetweenRange = (extreme1, extreme2, value) => {
  const lower = extreme1 > extreme2 ? extreme2 : extreme1;
  const higher = extreme1 > extreme2 ? extreme1 : extreme2;
  const returnValue = value >= lower && value <= higher;
  return returnValue;
};

export const computeTargetPrice = (startPrice, targetIIR, otherParams) => {
  const depthOfSlicing = 200;
  const multiplierStep = 0.0001;
  const canGuessAsLowAsNTimes = 10; // For a value of 100, a guess as low as 25 will converge, others will fail.
  let currentMultiplier = 1,
    previousIRR = combinedIRRFormula(startPrice, otherParams, true);
  while (true) {
    let nextTestPrice = startPrice * (currentMultiplier - multiplierStep);
    let nextIRR = combinedIRRFormula(nextTestPrice, otherParams, true);

    if (numberBetweenRange(previousIRR, nextIRR, targetIIR)) {
      return findTargetPriceInRange(
        nextTestPrice,
        startPrice * currentMultiplier,
        targetIIR,
        otherParams,
        depthOfSlicing
      );
    }
    previousIRR = nextIRR;
    currentMultiplier -= multiplierStep;
    if (currentMultiplier <= 0) break;
  }
  currentMultiplier = 1;
  previousIRR = combinedIRRFormula(startPrice, otherParams, true);
  while (true) {
    let nextTestPrice = startPrice * (currentMultiplier + multiplierStep);
    let nextIRR = combinedIRRFormula(nextTestPrice, otherParams, true);

    if (numberBetweenRange(previousIRR, nextIRR, targetIIR)) {
      return findTargetPriceInRange(
        startPrice * currentMultiplier,
        nextTestPrice,
        targetIIR,
        otherParams,
        depthOfSlicing
      );
    }
    currentMultiplier += multiplierStep;
    previousIRR = nextIRR;
    if (currentMultiplier > canGuessAsLowAsNTimes) break;
  }
  return 0;
};
