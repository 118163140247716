import React, { useContext, useEffect, useState } from 'react';
import { LocalizeContext } from 'react-locale-language';
import { monthSmall } from '../../../../../../helpers/constants';
import LineChart from '../../../../../common/line-chart/LineChart';
import { getValueAtMonthYear } from '../helper';
import ChartContainer from './ChartContainer';

const createChartData = (strategy, strategyYears, translate) => {
  const labels = [];
  const incomeData = [];
  const expenseData = [];
  const equityData = [];

  const getMonthlyValue = (type, monthNum, year) => {
    return strategy['cashflows']
      .filter(c => c.type === type)
      .reduce((prevValue, a) => prevValue + getValueAtMonthYear(a, monthNum, year), 0);
  };

  const initialMonth = strategy.initialMonth;

  strategyYears.forEach((year, yearIndex) => {
    monthSmall.forEach((month, monthNum) => {
      if (yearIndex === 0 && monthNum < initialMonth) return;
      if (initialMonth > 0 && yearIndex === strategyYears.length - 1 && monthNum >= initialMonth) return;

      labels.push(`${month} ${year}`);

      const incomeValueAtThisMonth = getMonthlyValue('inflow', monthNum, year);
      const expenseValueAtThisMonth = getMonthlyValue('outflow', monthNum, year);

      incomeData.push(incomeValueAtThisMonth.toFixed(0));
      expenseData.push(expenseValueAtThisMonth.toFixed(0));
    });
  });

  return {
    labels,
    datasets: [
      {
        label: translate('total_income'),
        data: incomeData,
        borderColor: 'rgba(30, 181, 0, 1)',
        backgroundColor: 'rgba(75, 192, 192, 1)'
      },
      {
        label: translate('total_expenditure'),
        data: expenseData,
        borderColor: 'rgba(255, 99, 132, 1)',
        backgroundColor: 'rgba(255, 99, 132, 1)'
      }
    ]
  };
};

const CashflowLineChart = ({ strategy = {}, strategyYears }) => {
  const [chartData, setChartData] = useState();
  const { translate } = useContext(LocalizeContext);

  useEffect(() => {
    setChartData(createChartData(strategy, strategyYears, translate));
  }, [strategy]);

  return chartData ? (
    <ChartContainer title={translate('cashflow')} maxWidth={'80%'}>
      <LineChart chartData={chartData} shouldFillBetweenLines />
    </ChartContainer>
  ) : (
    ''
  );
};

export default CashflowLineChart;
