import React, { useContext } from 'react';
import { Modal } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';

const ViewAppointmentModal = ({ show, onHide, customer }) => {
  const { translate } = useContext(LocalizeContext);

  return (
    <Modal fullscreen show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header className="pb-1 pt-0" closeButton>
        <Modal.Title>
          <h6 className="mb-0 d-inline-block">Appointment ({customer?.name || customer?.email})</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-0">
        <iframe
          src={`${process.env.REACT_APP_CRM_APP_LINK}/calendar-embed?email=${customer?.email}`}
          title="Prospero Dashboard"
          width="100%"
          height="100%" // Adjust the height as needed
          style={{ border: 'none' }} // Add any additional styling here
        >
          {/* Fallback content in case iframes are not supported */}
          <p>Your browser does not support iframes.</p>
        </iframe>
      </Modal.Body>
    </Modal>
  );
};

export default ViewAppointmentModal;
