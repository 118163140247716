import React, { useState, useRef, useEffect, useMemo } from 'react';

const Dropdown = ({ trigger, children }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const dropdownRef = useRef(null);
  const triggerRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !triggerRef.current.contains(event.target)
      ) {
        setIsOpen(false);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [dropdownRef]);

  const handleTriggerClick = () => {
    if (triggerRef.current && dropdownRef.current) {
      const triggerRect = triggerRef.current.getBoundingClientRect();
      const dropdownWidth = dropdownRef.current.offsetWidth;
      const viewportWidth = window.innerWidth;

      let calculatedXPosition = triggerRect.right - dropdownWidth;

      // If the dropdown goes out of the viewport to the right, adjust its position
      if (calculatedXPosition + dropdownWidth > viewportWidth) {
        calculatedXPosition = viewportWidth - dropdownWidth - 30;
      }

      setPosition(prevPosition => ({
        ...prevPosition,
        x: calculatedXPosition,
        y: triggerRect.bottom
      }));
    }
    setIsOpen(prevIsOpen => !prevIsOpen);
  };

  return (
    <div style={{ position: 'relative' }}>
      <div ref={triggerRef} onClick={handleTriggerClick}>
        {trigger}
      </div>

      <div
        className="rounded"
        ref={dropdownRef}
        onClick={() => setIsOpen(false)}
        style={{
          visibility: isOpen ? 'visible' : 'hidden',
          position: 'fixed',
          top: position.y,
          left: position.x,
          zIndex: 1000,
          backgroundColor: 'white'
        }}
      >
        {children}
      </div>
    </div>
  );
};

export default Dropdown;
