import React, { useMemo } from 'react';
import InterestRatesImpact from './InterestRateImpacts';
import LiquidationTable from './LiquidationTable';
import MarginCall from './MarginCall';
import useAuth from '../../../../../../hooks/useAuth';
import { findIfUserIsInterestedInCourseAndOfSpecifiedLevel } from '../../../../../../helpers/global';

const RiskManagement = ({
  strategy,
  armageddon,
  stressTestApplied,
  calculatedStrategy,
  onAssetResort,
  disabled,
  adminCompTable,
  customerCompTable,
  editable
}) => {
  const { user } = useAuth();
  const showLiquidationTableToClient = useMemo(() => adminCompTable?.appConfig?.showLiquidationTableToClient, [
    adminCompTable
  ]);

  
  return (
    <>
      {!findIfUserIsInterestedInCourseAndOfSpecifiedLevel(user, [0, 1]) && showLiquidationTableToClient && (
        <LiquidationTable strategy={strategy} onAssetResort={onAssetResort} updating={disabled} editable={editable} />
      )}
      <InterestRatesImpact
        strategy={calculatedStrategy}
        originalStrategy={strategy}
        armageddon={armageddon}
        stressTestApplied={stressTestApplied}
        adminCompTable={adminCompTable}
        customerCompTable={customerCompTable}
      />
      <MarginCall  adminCompTable={adminCompTable} strategy={calculatedStrategy} />
    </>
  );
};

export default RiskManagement;
