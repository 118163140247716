import React, { useMemo } from 'react';
import { Dropdown } from 'react-bootstrap';
import SmallDropdownToggle from './SmallDropdownToggle';

const DropdownSelector = ({ options: optionsProvided = [], value: providedValue, onValueChange, className = '' }) => {
  const options = useMemo(() => optionsProvided.map(o => (typeof o === 'string' ? { label: o, value: o } : o)), [
    optionsProvided
  ]);

  const valueToShow = useMemo(() => options.find(o => o.value === providedValue)?.label || '', [
    options,
    providedValue
  ]);

  return (
    <Dropdown className={`d-inline-block ${className}`}>
      <SmallDropdownToggle variant="primary">{valueToShow}</SmallDropdownToggle>
      <Dropdown.Menu>
        {options.map(({ label, value }) => (
          <Dropdown.Item active={value === providedValue} key={value} onClick={() => onValueChange(value)}>
            {label}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownSelector;
