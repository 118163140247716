import React, { useContext } from 'react';
import { Alert, Button, FormControl, Modal } from 'react-bootstrap';
import { ExclamationTriangle, XCircle } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import { ReactMultiEmail } from 'react-multi-email';
import 'react-multi-email/dist/style.css';
import HorizontalProgress from '../../../common/HorizontalProgress';

const InviteCustomerModal = ({
  show,
  password,
  receiverEmails = [],
  onPasswordChange,
  onReceiverEmailsChange,
  onSubmit,
  showProgress,
  hasAlreadyBeenInvited,
  onHide
}) => {
  const { translate } = useContext(LocalizeContext);

  return (
    <Modal show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton={!showProgress}>
        <h6 className="mb-0">{translate('Send_Password_Reset_Link')}</h6>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        {/* {hasAlreadyBeenInvited && (
          <Alert className="mid p-2 mb-2" bg="warning">
            <ExclamationTriangle size={13} className="mx-1" />
            {translate('user_already_invited')}
          </Alert>
        )} */}
        {[

          {
            value: receiverEmails,
            onChange: onReceiverEmailsChange,
            label: 'recipient_emails'
          }
        ].map(({ value, onChange, label }) => (
          <div key={label} className="py-2">
            <h6 className="mid">{translate(label)}</h6>
            {label !== 'recipient_emails' ? (
              <FormControl onChange={e => onChange(e.target.value)} value={value} className="mid" size="sm" />
            ) : (
              <ReactMultiEmail
                placeholder={translate(label)}
                emails={receiverEmails}
                onChange={onReceiverEmailsChange}
                getLabel={(email, index, removeEmail) => {
                  return (
                    <div data-tag key={index}>
                      <div data-tag-item>{email}</div>
                      <span className="px-2 text-danger" data-tag-handle onClick={() => removeEmail(index)}>
                        <XCircle />
                      </span>
                    </div>
                  );
                }}
              />
            )}
          </div>
        ))}
        {showProgress && <HorizontalProgress text={translate('sending')} />}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={showProgress} variant="success" size="sm" className="me-2 text-white" onClick={onSubmit}>
          {translate('send')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InviteCustomerModal;
