import { cloneDeep } from 'lodash';
import * as math from 'mathjs';
import { ADMIN_ROLE, ALL_ROLES, MANAGER_ROLE, STATUS_INTEREST_IN_MY_COURSE } from './constants';

const firebaseErrors = {
  'auth/email-already-exists': 'The provided email is already in use by an existing user.',
  'auth/invalid-email': 'The provided value for the email is invalid.',
  'auth/invalid-password': 'The provided value for the password is invalid.',
  'auth/invalid-phone-number': 'The provided value for the phone number is invalid.',
  'auth/phone-number-already-exists': 'The provided phone number is already in use by an existing user.',
  'auth/user-not-found': 'There is no existing user corresponding to the provided email.',
  'auth/wrong-password': 'Provided email/password do not match',
  'auth/email-already-in-use': 'The provided email is already in use by an existing user.',
  'auth/weak-password': 'Password should be at least 6 characters'
};

export const getErrorMessageFromFirebase = e => {
  return firebaseErrors[e.code] || e.message || 'Oops, Something went wrong!';
};

export const isAdmin = role => [ADMIN_ROLE, MANAGER_ROLE].includes(role);

export const isSystemUser = role => ALL_ROLES.includes(role);
export const isCustomer = role => 'Customer' === role;

export const camelToSentenceCase = text => {
  const result = text.replace(/([A-Z])/g, ' $1');
  return (result.charAt(0).toUpperCase() + result.slice(1)).trim();
};

export const getNameFromEmail = email => {
  const name = email.split('@')[0];
  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const getToggleFields = appChoices => [
  /* { field: 'Status', options: appChoices['Status'] || [], variant: 'dark' }, */
  { field: 'Assigned To', options: appChoices['Assigned To'] || [], variant: 'warning' }
];

export const diffInDays = (date1, date2) => Math.floor((date2.getTime() - date1.getTime()) / (1000 * 60 * 60 * 24));

export const getUserFirstName = name => {
  if (name) return name.split(' ')[0] || '';

  return '';
};

export const areDateEqual = (d1, d2) => {
  return d1.getDate() === d2.getDate() && d1.getMonth() === d2.getMonth() && d1.getYear() === d2.getYear();
};

export const areDateAndTimeEqual = (d1, d2) => {
  return (
    d1.getDate() === d2.getDate() &&
    d1.getMonth() === d2.getMonth() &&
    d1.getYear() === d2.getYear() &&
    d1.getHours() === d2.getHours() &&
    d1.getMinutes() === d2.getMinutes()
  );
};

export const isDateBeforeToday = d => {
  return isDateBefore(d, new Date());
};

export const isDateBefore = (d1, d2) => {
  return new Date(d1.toDateString()) < new Date(d2.toDateString());
};

export const downloadFile = ({ data = '', type, fileName }) => {
  const a = document.createElement('a');
  a.href = URL.createObjectURL(new Blob([data], { type }));
  a.setAttribute('download', fileName);
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export function fileToDataURL(file) {
  var reader = new FileReader();
  return new Promise(function(resolve, reject) {
    reader.onload = function(e) {
      const data = e.target.result.split(',');
      const obj = {
        fileName: file.name,
        mimeType: data[0].match(/:(\w.+);/)[1],
        data: data[1]
      };
      resolve(obj);
    };
    reader.readAsDataURL(file);
  });
}

export function fileToString(file) {
  var reader = new FileReader();
  return new Promise(function(resolve, reject) {
    reader.onload = function(e) {
      resolve(e.target.result);
    };
    reader.readAsText(file);
  });
}

export const evalFormula = (formula = '', variables = []) => {
  try {
    const variableRegEx = /([^[]+(?=]))/g;
    const matchedSymbols = formula.match(variableRegEx);

    let formulaWithReplacedVariables = formula;
    if (matchedSymbols) {
      matchedSymbols.forEach(symbol => {
        const variableValue = variables.find(v => v.symbol === symbol)?.value;
        formulaWithReplacedVariables = formulaWithReplacedVariables.replace(`[${symbol}]`, variableValue || 0);
      });
    }

    const num = math.evaluate(formulaWithReplacedVariables);
    return isNaN(num) ? undefined : num;
  } catch (e) {
    console.log(e);
  }
};

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param si True to use metric (SI) units, aka powers of 1000. False to use
 *           binary (IEC), aka powers of 1024.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export const humanFileSize = (bytes, si = false, dp = 1) => {
  const thresh = si ? 1000 : 1024;

  if (Math.abs(bytes) < thresh) {
    return bytes + ' B';
  }

  const units = si
    ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= thresh;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= thresh && u < units.length - 1);

  return bytes.toFixed(dp) + ' ' + units[u];
};

export function extractFolderId(driveLink) {
  const regex = /[a-zA-Z0-9_-]{25,35}/;
  const match = driveLink.match(regex);
  if (match) {
    return match[0];
  } else {
    return null;
  }
}

export const downloadFileFromBase64 = ({ data, fileName, mimeType }) => {
  const downloadLink = document.createElement('a');
  downloadLink.href = mimeType ? `data:${mimeType};base64,${data}` : data;
  downloadLink.target = '_self';
  downloadLink.download = fileName;

  document.body.appendChild(downloadLink);
  downloadLink.click();
  document.body.removeChild(downloadLink);
};

export const getValidUrl = link => {
  if (!link.startsWith('https://') && !link.startsWith('http://')) {
    link = 'https://' + link;
  }

  return link;
};

export const updateItemsInArray = (originalData = [], updatedData, key = '_id') => {
  const returnData = [...originalData];

  if (!Array.isArray(updatedData)) {
    updatedData = [updatedData];
  }

  updatedData.forEach(newData => {
    const existingIndex = returnData.findIndex(d => d?.[key] === newData?.[key]);
    if (existingIndex !== -1) {
      returnData[existingIndex] = newData;
    } else {
      returnData.unshift(newData);
    }
  });

  return returnData;
};

export const apartmentTypes = [
  { label: 'Real Estate IRR', value: 'irr' },
  { label: 'Pinui Binui', value: 'pinui-binui' },
  { label: 'Paper Apartment', value: 'paper-apartment' }
];

// Returns true if the graph contains a
// cycle, else false.
// This function is a variation of DFS() in
// https://www.geeksforgeeks.org/archives/18212
export const findCyclicPath = (nodes = [], adjacents = []) => {
  const numOfNodes = nodes.length;
  if (numOfNodes < 2) return false;

  // Mark all the vertices as not visited and
  // not part of recursion stack
  let visited = new Array(numOfNodes);
  let recStack = new Array(numOfNodes);
  for (let i = 0; i < numOfNodes; i++) {
    visited[i] = false;
    recStack[i] = false;
  }

  // Call the recursive helper function to
  // detect cycle in different DFS trees
  for (let i = 0; i < numOfNodes; i++) if (isCyclicUtil(i, visited, recStack, nodes, adjacents)) return true;

  return false;
};

function isCyclicUtil(i, visited, recStack, nodes, adj) {
  // Mark the current node as visited and
  // part of recursion stack
  if (recStack[i]) return true;

  if (visited[i]) return false;

  visited[i] = true;

  recStack[i] = true;
  let children = adj[i];

  for (let c = 0; c < children.length; c++) {
    if (isCyclicUtil(nodes.indexOf(children[c]), visited, recStack, nodes, adj)) return true;
  }
  recStack[i] = false;

  return false;
}

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const findDifferenceInObjects = (o1 = {}, o2 = {}, ignoreFields = [], doNotIncludeFields = []) => {
  if (!o1) return;
  const changedObject = cloneDeep(o1);

  Object.keys(changedObject).forEach(k => {
    if (ignoreFields.includes(k)) return;

    if (doNotIncludeFields.includes(k)) {
      delete changedObject[k];
      return;
    }

    if (!o2) return;

    if (!o2.hasOwnProperty(k)) return;

    if (Array.isArray(changedObject[k]) && Array.isArray(o2[k])) {
      if (
        changedObject[k].length === o2[k].length &&
        changedObject[k].every((e, index) => {
          if (typeof e === 'object') {
            const changeInChildKeys = findDifferenceInObjects(e, o2[k][index], ignoreFields, doNotIncludeFields);
            return Object.keys(changeInChildKeys).length === 0;
          }

          return e === o2[k][index];
        })
      ) {
        delete changedObject[k];
      }
      return;
    }

    if (typeof changedObject[k] === 'object') {
      const changeInChildKeys = findDifferenceInObjects(changedObject[k], o2[k], ignoreFields, doNotIncludeFields);
      if (!changeInChildKeys || Object.keys(changeInChildKeys).length === 0) {
        delete changedObject[k];
      }
      return;
    }

    //form returns undefined when field is left blank for number fields
    //in that case if the comparing field is a number, we consider undefined/blank as 0
    const valueToCompare = o2[k];
    if (typeof valueToCompare === 'number' && !changedObject[k]) {
      changedObject[k] = 0;
    }

    if (valueToCompare === changedObject[k]) delete changedObject[k];
  });

  return changedObject;
};

export const removeKeys = (o1 = {}, keysToRemove = []) => {
  if (!o1) return;
  const changedObject = cloneDeep(o1);

  Object.keys(changedObject).forEach(k => {
    if (keysToRemove.includes(k)) {
      delete changedObject[k];
      return;
    }

    if (Array.isArray(changedObject[k])) {
      changedObject[k].forEach(obj => removeKeys(obj, keysToRemove));
      return;
    }

    if (typeof changedObject[k] === 'object') {
      removeKeys(changedObject[k], keysToRemove);
      return;
    }
  });

  return changedObject;
};

export const generateAlphanumericString = (length, prefix = '') => {
  var result = '';
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return prefix + result;
};

export const scrollToEnd = element => {
  if (element) {
    // Check the directionality
    var direction = window.getComputedStyle(element).direction;

    // Determine scroll position based on directionality
    var scrollPosition = direction === 'rtl' ? -1 * element.scrollWidth : element.scrollWidth;

    element.scrollTo({
      left: scrollPosition,
      behavior: 'smooth'
    });
  }
};

export const checkIfUserCanEdit = user => {
  const { edit } = user?.specialRoles;
  return edit === 'Assigned' || edit === 'All';
};

export const findIfUserIsInterestedInCourseAndOfSpecifiedLevel = (user, level = []) => {
  return user?.status === STATUS_INTEREST_IN_MY_COURSE && level.includes(user.level);
};

export const isStrategyCreatedByUser = strategy => strategy?.isCreatedByUser;

export const isCustomerNew = strategies => {
  if (strategies?.length === 0) return true;

  if (strategies?.length !== 1) return false;

  let strategy = strategies[0];

  return (
    strategy?.assets?.length === 1 &&
    strategy?.liabilities?.length === 0 &&
    strategy?.investments?.length === 0 &&
    strategy?.customCashflows?.length === 0 &&
    strategy?.oneOffChanges?.length === 0 &&
    strategy?.fixedIncomeAssets?.length === 0
  );
};

export const isNullOrUndefined = v => v === null || v === undefined;
