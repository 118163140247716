import React from 'react';
import AppModal from '../../../common/AppModal';
import CustomerProfileForm from '../../../../forms/CustomerProfileForm';

const AddEditCustomerProfileModal = ({
  show,
  onHide,
  editMode,
  showProgress,
  initialValues,
  onSubmit,
  onDeleteClick
}) => {
  return (
    <AppModal
      size={'lg'}
      title={editMode ? 'Update customer profile' : 'Add customer profile'}
      show={show}
      onHide={onHide}
      modalBodyClassName="px-0 py-1"
    >
      <CustomerProfileForm
        editMode={editMode}
        showProgress={showProgress}
        values={initialValues}
        onSubmit={onSubmit}
        onDeleteClick={onDeleteClick}
      />
    </AppModal>
  );
};

export default AddEditCustomerProfileModal;
