import React from 'react';
import { Table } from 'react-bootstrap';
import './AppTable.scss';

const AppTable = ({ id, children, responsive = true, className = '' }) => {
  return (
    <Table id={id} bordered responsive={responsive} hover className={`smallFont mt-2 ${className}`}>
      {children}
    </Table>
  );
};

export default AppTable;
