import React, { useContext, useEffect, useState } from 'react';
import { Alert, Card, Col, Image, Row } from 'react-bootstrap';
import { Link, useParams, useHistory,Redirect } from 'react-router-dom';
import { LocalizeContext } from 'react-locale-language';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import Loader from '../../components/common/Loader';
import ConfirmPasswordForm from './ConfirmPasswordForm';
import { toast } from 'react-toastify';
import { UserContext } from '../../context/UserContext';

const CreatePassword = () => {
  const { translate } = useContext(LocalizeContext);
  const { isUserLoggedIn } = useContext(UserContext);
  const { key } = useParams();
  const [findingUser, setFindingUser] = useState(true);
  const [resetingPassword, setResetingPassword] = useState(false);
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState(null);
  const [user, setUser] = useState(null);

  if (isUserLoggedIn) {
    return <Redirect from="/create-password" to="/" />;
  }

  const findUser = async () => {
    try {
      setFindingUser(true);
      const { response, error } = await makeApiRequests({
        endpoint: ENDPOINTS.USERS_DETAILS(key),
        method: 'GET'
      });
      if (response) {
        setUser(response);
      }
      if (error) {
        setErrorMessage({
          type: 'user',
          message: error
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setFindingUser(false);
    }
  };

  useEffect(() => {
    if (key) {
      findUser();
    }
  }, [key]);

  const createPassword = async ({ password }) => {
    try {
      setResetingPassword(true);
      const { response, error } = await makeApiRequests({
        endpoint: ENDPOINTS.RESET_PASSWORD,
        requestBody: {
          email: user?.email,
          temporaryKey: key,
          newPassword: password
        },
        method: 'POST'
      });
      if (response) {
        toast.success('Password updated successfully. You can login using your new password')
        history.push('/login');
      }
      if (error) {
        setErrorMessage({
          type: 'createPassword',
          message: error
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setResetingPassword(false);
    }
  };

  return findingUser || errorMessage?.type === 'user' ? (
    <div className="h-100 d-flex justify-content-center align-items-center">
      {findingUser ? (
        <Loader />
      ) : (
        <Alert className="p-2 mt-3 mid" variant="danger">
          {errorMessage?.message}
        </Alert>
      )}
    </div>
  ) : (
    <Row className="justify-content-center h-100 m-0">
      <Col xs={10} md={6} className="align-self-center">
        <Card className="">
          <Card.Header className="p-3 bg-dark">
            <div className="">
              <Image src={`${process.env.PUBLIC_URL}/logo-new.png`} alt="logo" height={60} />
            </div>
          </Card.Header>
          <Card.Body>
            <h6 className="text-muted mx-2 my-1">
              {translate('hello')} <strong>{user?.name}, </strong>
              {translate('welcome_message')}
            </h6>
            <h6 className="text-muted mx-2 my-1">
              <b className="text-dark">{translate('please_reset_your_password')}</b>
            </h6>
            <hr />
            <ConfirmPasswordForm
              loading={resetingPassword}
              errorMessage={errorMessage?.type === 'createPassword' ? errorMessage?.message : null}
              onSubmit={createPassword}
            />
            <Link className="text-dark" to={'/auth/login'}>
              {translate('already_have_an_account')}
            </Link>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default CreatePassword;
