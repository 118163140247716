import { snakeCase } from 'lodash';
import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';

const GrouppedCheckBox = ({ options = [], values = [], onChange, className }) => {
  const { translate, isRTL } = useContext(LocalizeContext);
  return (
    <div className={`d-flex justify-content-between flex-wrap ${className}`}>
      {options?.map((option,index) => {
        return (
          <Form.Check
            className={isRTL ? 'ms-2' : 'me-2'}
            checked={values?.some(v => v === option?.value)}
            key={option?.value}
            style={{ fontSize: '14px' }}
            label={<h6 className='mb-0 smallFont'>{translate(snakeCase(option?.label)) || option?.label}</h6>}
            onChange={e => onChange(e.target.checked ? [...values, option?.value] : values?.filter(v => v !== option?.value))}
            name={`checkbox-${option?.value}-${index}`}
            id={`checkbox-${option?.value}-${index}`}
          />
        );
      })}
    </div>
  );
};

export default GrouppedCheckBox;
