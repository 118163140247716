import { API_URL, ENDPOINTS } from './constants';
import { setIsUserLoggedInToLocal } from './session';
export const ABORT_ERROR = 'REQUEST_ABORTED';

export const makeApiRequests = async ({
  endpoint,
  contentType = 'application/json',
  requestBody,
  method = 'POST',
  stringify = true,
  signal,
  doNotSendUpdatingAs
}) => {
  const token = JSON.parse(localStorage.getItem('user'))?.token;

  let updatingAs =
    [ENDPOINTS.GET_ME].includes(endpoint) || doNotSendUpdatingAs
      ? null
      : JSON.parse(sessionStorage.getItem('updating-user'));
  const headers = {
    Authorization: token ? `Bearer ${token}` : undefined,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
  };
  if (contentType === 'multipart/form-data') {
    // Remove Content-Type header for multipart/form-data to let the browser set it
    delete headers['Content-Type'];
  } else {
    headers['Content-Type'] = contentType;
  }
  if (updatingAs?._id) {
    headers['Updating-As'] = updatingAs?._id;
  }
  try {
    const response = await fetch(process.env.REACT_APP_API_URL + endpoint, {
      method: method,
      body: requestBody ? (stringify ? JSON.stringify(requestBody) : requestBody) : undefined,
      headers,
      signal,
      credentials: 'include'
    });

    const responseBody = await response.json();
    if (response.status < 200 || response.status >= 300) {
      if (response.status === 401) {
        setTimeout(() => {
          setIsUserLoggedInToLocal(false);
          window.location.reload();
        }, 1500);
        return {
          error: 'Your session has expired, please try logging in again!'
        };
      }
      return { error: responseBody['message'] || 'Oops something went wrong!' };
    } else {
      return { response: responseBody };
    }
  } catch (e) {
    if (e.name === 'AbortError') {
      return { error: ABORT_ERROR };
    }

    return { error: 'Oops something went wrong!' };
  }
};

export const readFile = async ({ fileName, mimeType }) => {
  try {
    const response = await fetch(API_URL + ENDPOINTS.FILE_STREAM_WITH_ID(fileName), {
      method: 'GET',
      credentials: 'include'
    });

    if (response.status < 200 || response.status >= 300) {
      if (response.status === 401) {
        setTimeout(() => {
          setIsUserLoggedInToLocal(false);
          window.location.reload();
        }, 1500);
        return { error: 'Your session has expired, please try logging in again!' };
      }
      return { error: 'Could not load file!' };
    } else {
      const blobFromResponse = await response.blob();
      const blobWithFileMimeType = new Blob([blobFromResponse], { type: mimeType });
      const returnValue = URL.createObjectURL(blobWithFileMimeType);
      return { response: returnValue };
    }
  } catch (e) {
    console.log(e);
    return { error: 'Oops something went wrong!' };
  }
};

export const deleteFileFromServer = file => {
  //we do not care for response
  makeApiRequests({
    endpoint: ENDPOINTS.FILE_WITH_ID(file.filename),
    method: 'DELETE'
  });
};
