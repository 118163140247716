import React from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { Redirect, useLocation } from 'react-router-dom';
import NotFound from '../common/NotFound';
import CompTable from './comp-table';
import ManageUsers from './manage-users';
import CachedResponse from '../common/CachedResponse';
import { FINANCIAL_STRATEGIST_ROLE, REAL_ESTATE_ANALYST_ROLE, SALES_ROLE } from '../../helpers/constants';
import { isAdmin } from '../../helpers/global';
import useAuth from '../../hooks/useAuth';

const Admin = () => {
  const location = useLocation();
  const { user } = useAuth();
  if (location.pathname === '/admin') {
    return <Redirect from="/admin" to="/admin/manage-users" />;
  }

  return (
    <Switch>
      <Route path="/admin/manage-users/">
        {isAdmin(user?.role) ? (
          <CachedResponse
            listEndpoint={'users'}
            requestBody={{ filter: { role: [SALES_ROLE, FINANCIAL_STRATEGIST_ROLE, REAL_ESTATE_ANALYST_ROLE] } }}
            responseModifier={response => response}
            render={assigneUsers => <ManageUsers assigneUsers={assigneUsers} />}
          />
        ) : (
          <ManageUsers assigneUsers={[]} />
        )}
      </Route>
      <Route path="/admin/comp-table/">
        <CompTable />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default withRouter(Admin);
