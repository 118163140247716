import React, { useEffect, useState } from 'react';
import { FormControl } from 'react-bootstrap';

const formatValue = num => {
  if (typeof num !== 'number') return '';
  return num.toLocaleString('en-US', {
    minimumFractionDigits: 0,
    maximumFractionDigits: 2
  });
};

const DecimalInput = ({ value, onChange, className = '' }) => {
  const [inputValue, setInputValue] = useState(formatValue(value));

  const handleInputChange = e => {
    const rawValue = e.target.value;
    // Remove all non-numeric characters except the first occurrence of "-" and "."
    const sanitizedValue = rawValue.replace(/[^0-9.-]/g, '').trim();
    if (sanitizedValue === '-') {
      setInputValue(sanitizedValue);
      onChange(0);
      return;
    }

    const numberValue = parseFloat(sanitizedValue);

    // Call onChange if the value is a valid number
    if (!isNaN(numberValue)) {
      let formatedValue = formatValue(numberValue);
      if (sanitizedValue.endsWith('.')) {
        formatedValue += '.';
      }
      setInputValue(formatedValue);
      onChange(numberValue);
    } else {
      setInputValue('');

      onChange(0);
    }
  };

  useEffect(() => {
    setInputValue(formatValue(value));
  }, [value]);

  return <FormControl className={className} size="sm" type="text" value={inputValue} onChange={handleInputChange} />;
};

export default DecimalInput;
