import React, { useEffect, useState } from 'react';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import CurrencyInput from '../CurrencyInput';
import { formatCurrency } from '../../admin/manage-users/customer/strategies/helper';
import { XCircle, XCircleFill } from 'react-bootstrap-icons';

const NumberRangeFilter = ({ value = { from: '', to: '' }, onChange }) => {
  const [show, setShow] = useState(false);
  const [range, setRange] = useState(value);

  const handleInputChange = (key, value) => {
    setRange(prevRange => ({
      ...prevRange,
      [key]: value
    }));
  };

  const handleFilter = () => {
    onChange(range);
    setShow(false);
  };

  const overlay = (
    <Tooltip className="m-0 p-0" style={{ zIndex: 3 }}>
      <div className="d-flex gap-1 align-items-center justify-content-between">
        <CurrencyInput placeholder="from" value={value?.from} onChange={v => handleInputChange('from', v)} />
        <h6 className="mb-0 smallFont">-</h6>
        <CurrencyInput placeholder="to" value={value?.to} onChange={v => handleInputChange('to', v)} />
      </div>{' '}
      <Button variant="success" size="sm" className="px-1 w-100 mt-2 text-white" onClick={handleFilter}>
        Filter
      </Button>
    </Tooltip>
  );

  return (
    <OverlayTrigger overlay={overlay} flip placement="bottom" show={show}>
      <div
        style={{ borderRadius: 3, height: 28, cursor: 'pointer' }}
        className="border d-flex align-items-center"
        onClick={() => setShow(!show)}
      >
        {typeof value?.from === 'number' || typeof value?.to === 'number' ? (
          <div className="d-flex align-items-center w-100 px-2">
            <div className="flex-grow-1">
              <h6 className="smallFont mb-0">
                {value?.from && !value?.to
                  ? `>= ${formatCurrency(value?.from, true)}`
                  : !value?.from && value?.to
                  ? `<= ${formatCurrency(value?.to, true)}`
                  : `${formatCurrency(value?.from, true)} - ${formatCurrency(value?.to, true)}`}
              </h6>
            </div>
            <div>
              <XCircleFill
                className="text-dark hover mx-1"
                onClick={e => {
                  e.stopPropagation();
                  onChange(null);
                  setRange({ from: '', to: '' });
                }}
              />
            </div>
          </div>
        ) : (
          ' '
        )}
      </div>
    </OverlayTrigger>
  );
};

export default NumberRangeFilter;
