import moment from 'moment';
import React from 'react';
import { Col, Row } from 'react-bootstrap';

const OverViewColumns = ({
  data = {},
  numRows = [],
  emailRows = [],
  priceFields = [],
  dateFields = [],
  dateTimeFields = [],
  fieldsToShow = [],
  md = 4,
  mt = 2,
  className = ''
}) => {
  const getValue = row => {
    if (emailRows.includes(row)) {
      return (
        <a onClick={e => e.stopPropagation()} className="text-secondary" href={`mailto:${data[row]}`}>
          {data[row]}
        </a>
      );
    }

    if (numRows.includes(row)) {
      return (
        <a onClick={e => e.stopPropagation()} className="text-secondary" href={`tel:${data[row]}`}>
          {data[row]}
        </a>
      );
    }
    if (dateFields.includes(row)) {
      return moment(data[row]).format('MMMM Do, YYYY');
    }
    if (dateTimeFields.includes(row)) {
      return moment(data[row]).format('MMMM Do, YYYY hh:mm a');
    }

    if (priceFields.includes(row)) {
      return `$${data[row]}`;
    }
    return Array.isArray(data[row]) ? data[row].join(', ') : data[row];
  };

  return (
    <Row className={className}>
      {fieldsToShow.map(row => {
        const key = typeof row !== 'string' ? row.key : row;
        const label = typeof row !== 'string' ? row.label : row;

        return (
          <Col xs={12} md={md} className={`mt-${mt} px-2`}>
            <p className="mb-1 text-dark ">
              <span className="text-muted mid">{label}: </span>
            </p>
            <p className="mb-0  text-dark px-2 py-1 large bg-primary-light rounded">
              {data[key] ? getValue(key) : 'N/A'}
            </p>
          </Col>
        );
      })}
    </Row>
  );
};

export default OverViewColumns;
