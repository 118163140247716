import { snakeCase } from 'lodash';
import React, { useEffect, useContext } from 'react';
import { FormSelect } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';

const DropDownInput = ({
  id,
  options = [],
  optionValues,
  disabledValues = [],
  className = '',
  onChange = '',
  value: defaultValue = '',
  preValue,
  onFormChange,
  disabled = false,
  onChangeFunction,
  size,
  dependentElems = [],
  showDependentOn = '',
  showDependentOnArray,
  required
}) => {
  const { translate } = useContext(LocalizeContext);

  if (preValue !== null || preValue !== undefined) defaultValue = preValue;
  //called after render only once
  useEffect(() => {
    internalOnChange(null, false);
  }, []);

  const showHideDependentElement = (element, valueToConsider) => {
    const elementContainer = document.getElementById(`${element.id}-col-container`);
    if (document.getElementById(id).value === valueToConsider) {
      elementContainer.classList.remove('d-none');
      if (element.type !== 'checkbox') element.required = true;
    } else {
      elementContainer.classList.add('d-none');
      element.required = null;
    }
  };

  const internalOnChange = (e, checkForm = true) => {
    if (checkForm && onFormChange) onFormChange(e);

    //run internal functions here
    if (dependentElems.length > 0) {
      dependentElems
        .flatMap((dependentElem, elemIndex) => {
          if (!Array.isArray(dependentElem)) {
            return [{ elem: document.getElementById(dependentElem), elemIndex }];
          }
          return dependentElem.map(elem => ({ elem: document.getElementById(elem), elemIndex }));
        })
        .forEach(dependentElement => {
          dependentElement.elem &&
            showHideDependentElement(
              dependentElement.elem,
              showDependentOnArray ? showDependentOnArray[dependentElement.elemIndex] : showDependentOn
            );
        });
    }

    if (onChange && window[onChange]) {
      window[onChange](e);
    }

    if (onChangeFunction) onChangeFunction(e);
  };

  return (
    <FormSelect
      disabled={disabled}
      defaultValue={defaultValue}
      size={size}
      className={` ${className}`}
      id={id}
      onChange={internalOnChange}
      required={required}
    >
      {options.map((option, index) => {
        const value = optionValues ? optionValues[index] : option;

        const isDisabled = disabledValues.includes(value);
        return (
          <option key={option} value={value} disabled={isDisabled}>
            {translate(snakeCase(option)) || option}
          </option>
        );
      })}
    </FormSelect>
  );
};

export default DropDownInput;
