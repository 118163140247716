import React, { useContext, useEffect } from 'react';
import { normalizeId } from '../helpers/utility';
import { LocalizeContext } from 'react-locale-language';
import { snakeCase } from 'lodash';

const CheckBoxInput = ({
  id,
  title,
  groupName = '',
  defaultChecked,
  onChange,
  onChangeFunction,
  value,
  showLabel = false,
  className = '',
  label = '',
  required = false,
  preValue,
  onFormChange,
  disabled = false,
  boldLabel = false,
  size = 'lg',
  checked,
  dependentElems = [],
  showDependentOn = true
}) => {
  const { translate } = useContext(LocalizeContext);

  useEffect(() => {
    internalOnChange(null, false);
  }, []);

  const showHideDependentElement = element => {
    const elementContainer = document.getElementById(`${element.id}-col-container`);
    if (document.getElementById(id).checked === showDependentOn) {
      elementContainer.classList.remove('d-none');
      if (element.type !== 'checkbox') element.required = true;
    } else {
      elementContainer.classList.add('d-none');
      element.required = null;
    }
  };

  const internalOnChange = (e, checkForm = true) => {
    //run internal functions here
    if (checkForm && onFormChange) onFormChange();

    //run internal functions here
    if (dependentElems.length > 0) {
      dependentElems
        .map(dependentElem => document.getElementById(dependentElem))
        .forEach(dependentElement => {
          dependentElement && showHideDependentElement(dependentElement);
        });
    }

    if (onChange && window[onChange]) {
      window[onChange](e);
    }
    if (onChangeFunction) {
      onChangeFunction(e);
    }
  };

  const inputId = id || normalizeId(groupName + '-' + (label || title));
  return (
    <div className={'form-check ' + className}>
      <input
        autoComplete="off"
        className="form-check-input"
        id={inputId}
        type="checkbox"
        name={groupName}
        style={size === 'sm' ? styles.checkboxsm : styles.checkbox}
        defaultChecked={
          checked === undefined ? (preValue === null || preValue === undefined ? defaultChecked : preValue) : undefined
        }
        checked={checked !== undefined ? checked : undefined}
        onChange={internalOnChange}
        value={value || label}
        required={required}
        disabled={disabled}
      />
      {showLabel && (
        <label
          style={size === 'sm' ? { fontSize: 13 } : undefined}
          htmlFor={inputId}
          className="ml-1 form-check-label my-1"
        >
          {boldLabel ? (
            <b>{translate(snakeCase(label)) || translate(snakeCase(title)) || label || title}</b>
          ) : (
            translate(snakeCase(label)) || translate(snakeCase(title)) || label || title
          )}
        </label>
      )}
    </div>
  );
};

const styles = {
  checkbox: {
    height: 22,
    width: 22
  },
  checkboxsm: {
    height: 17,
    width: 17
  }
};

export default CheckBoxInput;
