import React, { useMemo } from 'react';
import Switch from 'react-switch';

const SwitchInput = ({ value, onChange, falseValue = false, trueValue = true }) => {
  const internalValue = useMemo(() => {
    return value === trueValue;
  }, [value, trueValue]);

  return (
    <Switch
      checked={internalValue}
      onChange={checked => onChange(checked ? trueValue : falseValue)}
      height={17}
      width={50}
      offHandleColor="#d1e6cc"
      onHandleColor="#d1e6cc"
    />
  );
};

export default SwitchInput;
