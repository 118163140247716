import React, { useContext, useEffect, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import { assetCategoriesOptions, monthSmall } from '../../../../../../helpers/constants';
import SmallDropdownToggle from '../../../../../common/SmallDropdownToggle';
import PieChart from '../../../../../common/pie-chart/PieChart';
import { getValueAtMonthYear } from '../helper';
import ChartContainer from './ChartContainer';
import { snakeCase } from 'lodash';

export const createChartData = (assets = [], year = 2022, month = 0, translate) => {
  const getMonthlyValueOfAssetCategory = (category, monthNum, year) => {
    return assets
      .filter(a => !a.isInvalid && !a.toBeMerged && a.category === category)
      .reduce((prevValue, a) => prevValue + getValueAtMonthYear(a, monthNum, year), 0)
      .toFixed(0);
  };

  const tooltipWithPercentage = {
    callbacks: {
      label: function(context) {
        let label = context.label;
        let formattedValue = context.formattedValue;
        let value = context.parsed;

        if (!label) label = 'Unknown';

        let sum = context.chart.data.datasets[0].data.reduce((prev, data) => prev + Number(data), 0);
        let percentage = ((value * 100) / sum).toFixed(2) + '%';
        return `${label} : ${formattedValue} - ${percentage}`;
      }
    }
  };

  const categoryValues = assetCategoriesOptions.map(category => getMonthlyValueOfAssetCategory(category, month, year));

  return {
    labels: assetCategoriesOptions.map(c => translate(snakeCase(c))),
    datasets: [
      {
        label: 'Value Of Asset Classes',
        data: categoryValues,
        tooltip: tooltipWithPercentage,
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)',
          'rgba(75, 192, 192, 0.2)',
          'rgba(153, 102, 255, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(153, 102, 255, 1)'
        ],
        borderWidth: 1
      }
    ]
  };
};

const MonthYearPicker = ({ strategyYears, month, year, onMonthChange, onYearChange }) => {
  return (
    <>
      <Dropdown className="d-inline-block ms-1">
        <SmallDropdownToggle variant="primary">{monthSmall[month]}</SmallDropdownToggle>

        <Dropdown.Menu>
          {monthSmall.map((m, index) => (
            <Dropdown.Item onClick={() => onMonthChange(index)}>{m}</Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
      <Dropdown className="d-inline-block">
        <SmallDropdownToggle variant="primary">{year}</SmallDropdownToggle>
        <Dropdown.Menu>
          {strategyYears.map(y => (
            <Dropdown.Item onClick={() => onYearChange(y)}>{y}</Dropdown.Item>
          ))}
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

const AssetDistributionPieChart = ({ strategy, assets = [], strategyYears = [] }) => {
  const [chartData, setChartData] = useState();
  const [monthNum, setMonthNum] = useState(strategy.initialMonth || 0);
  const [year, setYear] = useState(strategyYears[0]);
  const { translate } = useContext(LocalizeContext);

  useEffect(() => {
    setChartData(createChartData(assets, year, monthNum, translate));
  }, [assets, monthNum, year]);

  return chartData ? (
    <ChartContainer
      title={translate('asset_class_distribution')}
      renderAdditionalComponent={() => (
        <MonthYearPicker
          strategyYears={strategyYears}
          month={monthNum}
          year={year}
          onMonthChange={setMonthNum}
          onYearChange={setYear}
        />
      )}
    >
      <PieChart chartData={chartData} />
    </ChartContainer>
  ) : (
    ''
  );
};

export default AssetDistributionPieChart;
