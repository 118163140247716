import React from 'react';
import { Route, Switch, withRouter } from 'react-router';
import CachedResponse from '../common/CachedResponse';
import NotFound from '../common/NotFound';
import CustomerPortal from './CustomerPortal';

const Customer = () => {
  return (
    <Switch>
      <Route exact path="/customer">
        <CachedResponse
          listEndpoint={'comps'}
          requestBody={{ filter: { customer: false } }}
          responseModifier={response => response[0]}
          render={compTable => <CustomerPortal adminCompTable={compTable} />}
        />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default withRouter(Customer);
