import React, { useContext, useEffect, useState } from 'react';
import { Button, Row, Col } from 'react-bootstrap';
import { TextInput } from '../../../form-generator/components';
import SlidingSideBar from '../SlidingSideBar/SlidingSideBar';
import { LocalizeContext } from 'react-locale-language';

const FilterGroup = ({ title, onAllCheck, options = [], values = [], searchable, onChange, optionColumnWidth = 6 }) => {
  const [query, setQuery] = useState('');
  const [filteredOptions, setFilteredOptions] = useState([]);

  useEffect(() => {
    if (!query) {
      return setFilteredOptions([]);
    }

    setFilteredOptions(
      options.filter(o => o.option.toLowerCase().includes(query.trim().toLowerCase())).map(o => o['option'])
    );
  }, [query]);

  return (
    <>
      <div className={'form-check'}>
        <input
          autoComplete="off"
          className="form-check-input"
          type="checkbox"
          style={styles.checkboxsm}
          checked={options.length === values.length}
          onChange={onAllCheck}
        />

        <label style={{ fontSize: 14 }} className="ml-1 form-check-label my-1">
          <b>{title}</b>
        </label>
      </div>
      <hr className="mt-1 mb-2" />

      {searchable && (
        <Row className="mb-2">
          <Col xs={12} className="px-5">
            <TextInput value={query} size={'sm'} hint="Search options here" onChange={e => setQuery(e.target.value)} />
          </Col>
          <Col className="text-right text-muted small px-5">
            <i>{values.length === options.length ? 'All' : values.length} options selected</i>
          </Col>
          {filteredOptions.length === 0 && (
            <Col xs={12}>
              <h6 className="text-center text-muted p-4 small">
                {!query ? 'Nothing to show. Type in the search box to view options.' : 'No matching options found!'}
              </h6>
            </Col>
          )}
        </Row>
      )}
      <Row className={'mb-4'}>
        {options.map(option => {
          const { option: label, value } = option;
          return (
            <Col key={label} xs={12} md={optionColumnWidth} hidden={searchable && !filteredOptions.includes(label)}>
              <div className={'form-check ml-3'}>
                <input
                  autoComplete="off"
                  className="form-check-input"
                  type="checkbox"
                  style={styles.checkboxsm}
                  checked={values.includes(value)}
                  value={value}
                  onChange={e => onChange(title, value, e.target.checked)}
                />

                <label style={{ fontSize: 14 }} className="ml-1 form-check-label my-1">
                  <b>{label}</b>
                </label>
              </div>
            </Col>
          );
        })}
      </Row>
    </>
  );
};

const FilterSideBar = ({ show, onSideBarClose, filterOptions = [], onFilterApply, initialFilterValues }) => {
  const [filterValues, setFilterValues] = useState(initialFilterValues);
  const { translate } = useContext(LocalizeContext);

  const onAllCheckChange = (title, options, checked, skipMapEnabled) => {
    filterValues[title] = checked ? [...(skipMapEnabled ? options.map(o => o.value) : options)] : [];
    setFilterValues({ ...filterValues });
  };

  const onFilterOptionChange = (title, value, checked) => {
    const currentValues = [...filterValues[title]];
    if (checked) {
      currentValues.push(value);
    } else {
      currentValues.splice(currentValues.indexOf(value), 1);
    }

    filterValues[title] = currentValues;

    setFilterValues({ ...filterValues });
  };

  return (
    <SlidingSideBar visible={show} onClose={onSideBarClose} title={translate('filters')}>
      {filterOptions.map(option => (
        <FilterGroup
          key={option.title}
          title={option.title}
          options={option.skipMapOptions ? option.options : option.options.map(o => ({ option: o, value: o }))}
          values={filterValues[option.title]}
          onAllCheck={e => e && onAllCheckChange(option.title, option.options, e.target.checked, option.skipMapOptions)}
          searchable={option.searchable}
          onChange={onFilterOptionChange}
        />
      ))}

      <div className="text-right">
        <Button onClick={() => onFilterApply(filterValues)}>{translate('apply')}</Button>
      </div>
    </SlidingSideBar>
  );
};

const styles = {
  checkboxsm: {
    height: 17,
    width: 17
  }
};

export default FilterSideBar;
