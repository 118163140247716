import React, { useCallback, useEffect, useState } from 'react';
import { Button, FormControl, FormLabel } from 'react-bootstrap';
import useLocalization from '../../../hooks/useLocalization';
import HorizontalProgress from '../HorizontalProgress';
import AppModal from '../AppModal';

const CreateNewFolderModal = ({ show, onHide, showProgress, onSubmit }) => {
  const { translate } = useLocalization();
  const [newFolderName, setNewFolderName] = useState('');
  const [error, setError] = useState('');
  const footerContent = useCallback(() => {
    return (
      <div>
        <Button
          size="sm"
          className=""
          variant="success text-white"
          onClick={() => {
            if (!newFolderName) return setError('Folder name is required');
            onSubmit(newFolderName);
          }}
        >
          Submit
        </Button>
      </div>
    );
  }, [show, onSubmit, newFolderName]);

  useEffect(() => {
    if (newFolderName) {
      setError('');
    }
  }, [newFolderName]);
  return (
    <AppModal
      modalBodyClassName="pt-0"
      show={show}
      onHide={onHide}
      title={'Create new folder'}
      footerContent={footerContent}
    >
      <div>
        <FormLabel className="mid">Folder Name</FormLabel>
        <FormControl size="sm" value={newFolderName} onChange={e => setNewFolderName(e.target.value)} />
        {error && <h6 className="my-1 smallFont text-danger">{error}</h6>}
        {showProgress && <HorizontalProgress text={translate('please_wait')} />}
      </div>
    </AppModal>
  );
};

export default CreateNewFolderModal;
