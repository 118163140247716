import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button, ListGroup, ListGroupItem } from 'react-bootstrap';
import { ArrowLeft, ArrowRight, CaretDownFill } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import { isRtl } from '../../helpers/localization';
import Dropdown from './Dropdown';
import useAuth from '../../hooks/useAuth';

const TabTitle = ({ title, isTabActive }) => {
  return <>{title}</>;
};

const Tabs = ({
  tabs = [],
  tabVariantColors = [],
  keys = [],
  activeTab,
  selectedTab,
  onTabClick,
  onEditClick,
  onDeleteClick,
  onCloneClick,
  onKillClick,
  onSelectClick,
  disabled,
  scrollable,
  readOnlyMode
}) => {
  const { translate, langCode } = useContext(LocalizeContext);
  const tabsContainerRef = useRef(null);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(false);
  const {user} =useAuth()
  const isRTL = useMemo(() => isRtl(langCode), [langCode]);

  const tabsContainerStyle = useMemo(
    () =>
      scrollable
        ? {
            overflowX: 'auto',
            whiteSpace: 'nowrap'
          }
        : {},
    [scrollable]
  );

  useEffect(() => {
    const THRESHOLD = 10;

    const container = tabsContainerRef.current;

    function updateScrollArrows() {
      const scrollLeftMax = container.scrollWidth - container.clientWidth;

      if (isRTL) {
        //for rtl scrollLeft is negative
        setCanScrollRight(-container.scrollLeft > THRESHOLD);
        setCanScrollLeft(-container.scrollLeft < scrollLeftMax - THRESHOLD);
      } else {
        setCanScrollLeft(container.scrollLeft > THRESHOLD);
        setCanScrollRight(container.scrollLeft < scrollLeftMax - THRESHOLD);
      }
    }

    updateScrollArrows();
    container.addEventListener('scroll', updateScrollArrows);

    return () => container.removeEventListener('scroll', updateScrollArrows);
  }, []);

  const handleScrollClick = direction => {
    const container = tabsContainerRef.current;
    if (isRTL) {
      if (direction === 'left') {
        container.scroll({ left: container.scrollLeft - container.clientWidth / 2, behavior: 'smooth' });
      } else {
        container.scroll({ left: container.scrollLeft + container.clientWidth / 2, behavior: 'smooth' });
      }
    } else {
      if (direction === 'left') {
        container.scroll({ left: container.scrollLeft - container.clientWidth / 2, behavior: 'smooth' });
      } else {
        container.scroll({ left: container.scrollLeft + container.clientWidth / 2, behavior: 'smooth' });
      }
    }
  };

  return (
    <div style={{ position: 'relative' }}>
      {scrollable && canScrollLeft && (
        <Button
          size="sm"
          variant="light"
          className="rounded-start h-100  border"
          style={{
            position: 'absolute',
            top: '50%',
            left: -2,
            transform: 'translateY(-50%)',
            zIndex: 1
          }}
          onClick={() => handleScrollClick('left')}
        >
          <ArrowLeft />
        </Button>
      )}
      <div ref={tabsContainerRef} style={tabsContainerStyle}>
        {tabs.map((t, index) => (
          <div key={keys[index]} className="d-inline-block me-1 mt-1">
            <>
              {(onEditClick || onDeleteClick || onCloneClick || onKillClick || onSelectClick) && activeTab === t && !readOnlyMode ? (
                <Dropdown
                  trigger={
                    <Button
                      style={{ ...buttonStyle, ...(selectedTab === t ? { border: '3px solid #e60707' } : {}) }}
                      onClick={() => onTabClick && onTabClick(t)}
                      size={'sm'}
                      disabled={disabled}
                      variant={activeTab === t ? 'primary' : tabVariantColors[index] || 'dark'}
                    >
                      <TabTitle title={t} isTabActive={activeTab === t} />
                      <CaretDownFill size={10} className="mx-1" />
                    </Button>
                  }
                  className="d-inline-block mx-1"
                >
                  <ListGroup>
                    {[
                      { onClick: onEditClick, text: 'edit' },
                      { onClick: onDeleteClick, text: 'delete' },
                      { onClick: onCloneClick, text: 'clone' },
                      { onClick: onKillClick, text: 'archive' },
                      { onClick: onSelectClick, text: selectedTab === t ? 'unselect' : 'select' }
                    ]
                      .filter(a => a.onClick)
                      .map(({ onClick, text }) => (
                        <ListGroupItem className="px-3 py-2 large" action onClick={() => onClick(t)}>
                          {translate(text)}
                        </ListGroupItem>
                      ))}
                  </ListGroup>
                </Dropdown>
              ) : (
                <Button
                  style={{ ...buttonStyle, ...(selectedTab === t ? { border: '3px solid #e60707' } : {}) }}
                  onClick={() => onTabClick && onTabClick(t)}
                  size={'sm'}
                  disabled={disabled}
                  variant={activeTab === t ? 'primary' : tabVariantColors[index] || 'dark'}
                >
                  <TabTitle title={t} /* isSelected={selectedTab === t} */ isTabActive={activeTab === t} />
                </Button>
              )}
            </>
          </div>
        ))}
      </div>
      {scrollable && canScrollRight && (
        <Button
          size="sm"
          variant="light"
          className="rounded-end h-100  border"
          style={{
            position: 'absolute',
            top: '50%',
            right: -2,
            transform: 'translateY(-50%)',
            zIndex: 1
          }}
          onClick={() => handleScrollClick('right')}
        >
          <ArrowRight />
        </Button>
      )}
    </div>
  );
};

const buttonStyle = { fontSize: 12, borderWidth: '3px' };

export default Tabs;
