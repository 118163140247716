import React, { useContext, useMemo } from 'react';
import { Button, Card, Col, Row } from 'react-bootstrap';
import { Pen, PlusCircle, Trash } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import { getTaxBracketFields } from '../../../helpers/constants';
import EntityTable from '../../common/EntityTable';

const TaxBrackets = ({
  buyingTaxBrackets = [],
  sellingTaxBrackets = [],
  buyingTaxBracketsForOnlyApartment = [],
  sellingTaxBracketsForOnlyApartment = [],
  onTaxBracketAddClick,
  onTaxBracketEditClick,
  onTaxBracketDeleteClick,
  readOnlyMode
}) => {
  const { translate, langCode } = useContext(LocalizeContext);
  const taxBracketHeaders = useMemo(() => getTaxBracketFields(translate), [langCode]);

  return (
    <Card className="mt-3">
      <Card.Header>{translate('tax_brackets')}</Card.Header>
      <Card.Body>
        <Row>
          {[
            { label: 'buying_tax_brackets', key: 'buyingTaxBrackets', data: buyingTaxBrackets },
            { label: 'selling_tax_brackets', key: 'sellingTaxBrackets', data: sellingTaxBrackets },
            {
              label: 'buying_tax_brackets_only_apartment',
              key: 'buyingTaxBracketsForOnlyApartment',
              data: buyingTaxBracketsForOnlyApartment
            },
            {
              label: 'selling_tax_brackets_only_apartment',
              key: 'sellingTaxBracketsForOnlyApartment',
              data: sellingTaxBracketsForOnlyApartment
            }
          ].map(({ label, key, data }) => (
            <Col key={key} xs={12} md={6}>
              <EntityTable
                quickSearch={false}
                rowKeyField={'_id'}
                data={data}
                headers={taxBracketHeaders}
                searchQueryKeys={['from', 'to', 'percentage']}
                additionalComponents={() => (
                  <>
                    <b className="mx-3 mid">{translate(label)}:</b>
                    {!readOnlyMode && (
                      <Button
                        size="sm"
                        style={{ fontSize: 12 }}
                        className="text-white px-2 py-1"
                        variant="success"
                        onClick={() => onTaxBracketAddClick(key)}
                      >
                        <PlusCircle className="mx-1" size={10} /> {translate('new_tax_bracket')}
                      </Button>
                    )}
                  </>
                )}
                actionCell={item =>
                  !readOnlyMode && (
                    <div>
                      <Button
                        variant="outline-primary"
                        className="px-1 py-0 mr-1"
                        onClick={() => onTaxBracketEditClick(key, item)}
                      >
                        <Pen size={10} />
                      </Button>
                      <Button
                        variant="outline-danger"
                        className="px-1 py-0 "
                        onClick={() => onTaxBracketDeleteClick(key, item)}
                      >
                        <Trash size={10} />
                      </Button>
                    </div>
                  )
                }
              />
            </Col>
          ))}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default TaxBrackets;
