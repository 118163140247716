import React, { useContext } from 'react';
import { Button, ButtonGroup } from 'react-bootstrap';
import { Arrow90degLeft, Arrow90degRight } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';

const UndoRedoButton = ({ className = '', onUndoClick, onRedoClick, undoDisabled, redoDisabled, disabled }) => {
  const { translate } = useContext(LocalizeContext);
  return (
    <ButtonGroup className={className} dir="ltr" size="sm">
      <Button
        className=" px-2 py-0"
        variant="outline-primary"
        onClick={onUndoClick}
        style={{ fontSize: 13 }}
        disabled={disabled || undoDisabled}
      >
        {/* <Arrow90degLeft size={10} /> */}
        {translate('undo')}
      </Button>
      <Button
        className=" px-2 py-0"
        variant="outline-primary"
        onClick={onRedoClick}
        style={{ fontSize: 13 }}
        disabled={disabled || redoDisabled}
      >
        {/* <Arrow90degRight size={10} /> */}
        {translate('redo')}
      </Button>
    </ButtonGroup>
  );
};

export default UndoRedoButton;
