import moment from 'moment';
import React, { useEffect, useState, useContext } from 'react';
import { Badge, FormControl } from 'react-bootstrap';
import { evalFormula } from '../../helpers/global';
import AppTable from '../common/app-table/AppTable';
import { LocalizeContext } from 'react-locale-language';
import { formatCurrency } from '../admin/manage-users/customer/strategies/helper';
import { months } from '../../helpers/constants';

const getValue = (dataObj, header, showEvaluatedValue, evaluationVariables = []) => {
  let value = dataObj[header.key];

  if (header.isMonth) {
    value = months[value] || value;
  }

  if (header.type !== 'boolean' && !value) return '-';

  if (Array.isArray(value)) {
    value = value.join(', ');
  }

  let returnElement = typeof value === 'object' ? value[header.objectKey] : value;

  if (showEvaluatedValue) {
    returnElement = (
      <>
        {returnElement} <br /> <Badge>{(evalFormula(returnElement, evaluationVariables) || 0).toFixed(2)}</Badge>
      </>
    );
  }

  switch (header.type) {
    case 'email':
      returnElement = (
        <a onClick={e => e.stopPropagation()} href={`mailto:${value}`}>
          {value}
        </a>
      );

      break;
    case 'phone':
      returnElement = (
        <a onClick={e => e.stopPropagation()} href={`tel:${value}`}>
          {value}
        </a>
      );
      break;
    case 'date':
      returnElement = moment(value).format('MMM DD YYYY');
      break;
    case 'boolean':
      returnElement = returnElement ? 'Yes' : 'No';
      break;
    case 'number':
      returnElement = header.isCurrency ? formatCurrency(returnElement) : returnElement.toFixed(2);
  }

  if (header.showAsBadge) {
    returnElement = (
      <Badge className="p-1 px-2" style={{ fontSize: 12 }} variant={header.badgeVariant || 'primary'}>
        {returnElement}
      </Badge>
    );
  }

  if (header.linkToKey) {
    let url = dataObj[header.linkToKey] || '';
    if (!url.startsWith('https://') && !url.startsWith('http://')) {
      url = 'https://' + url;
    }

    returnElement = (
      <a target="_blank" href={url}>
        {header.linkPlaceHolder || returnElement}
      </a>
    );
  }

  return returnElement;
};

const EntityTable = ({
  headers,
  quickSearch = true,
  data = [],
  rowKeyField,
  searchQueryKeys = [],
  actionCell,
  additionalComponents,
  showEvaluatedValues,
  evaluationVariables = [],
  fieldsWithFormulas = []
}) => {
  const [filteredData, setFilteredData] = useState([]);
  const [query, setQuery] = useState('');
  const { translate } = useContext(LocalizeContext);

  useEffect(() => {
    const q = query.trim().toLowerCase();
    if (q.length === 0) return setFilteredData(data);

    setFilteredData(
      data.filter(d =>
        searchQueryKeys.some(
          k =>
            d[k] &&
            d[k]
              .trim()
              .toLowerCase()
              .includes(q)
        )
      )
    );
  }, [data, query]);

  return (
    <>
      <div className="d-flex mb-1">
        {quickSearch && (
          <div className="flex-grow-1">
            <FormControl
              autoComplete="off"
              size={'sm'}
              placeholder={`${translate('quick_search')}...`}
              value={query}
              onChange={e => setQuery(e.target.value)}
            />
          </div>
        )}
        {additionalComponents && <div className="ml-2">{additionalComponents()}</div>}
      </div>
      <AppTable>
        <thead>
          <tr className="text-white bg-dark">
            {headers.map(({ name }) => (
              <th style={{ minWidth: 100 }} key={name}>
                {name}
              </th>
            ))}
            {actionCell && <th style={{ minWidth: 70 }}>{translate('actions')}</th>}
          </tr>
        </thead>
        <tbody>
          {filteredData.length > 0 ? (
            <>
              {filteredData.map(dataObj => (
                <tr key={dataObj[rowKeyField]}>
                  {headers.map(header => (
                    <td className="bg-white" key={header.name}>
                      {getValue(
                        dataObj,
                        header,
                        showEvaluatedValues && fieldsWithFormulas.includes(header.key),
                        evaluationVariables
                      )}
                    </td>
                  ))}
                  {actionCell && <td>{actionCell(dataObj)}</td>}
                </tr>
              ))}
            </>
          ) : (
            <tr>
              <td colSpan={actionCell ? headers.length + 1 : headers.length}>
                <h6 className="text-muted text-center mb-0">{translate('nothing_to_show')}</h6>
              </td>
            </tr>
          )}
        </tbody>
      </AppTable>
    </>
  );
};

export default EntityTable;
