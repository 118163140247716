import React from 'react';
import { Button } from 'react-bootstrap';
import { BoxArrowUp, Pencil, Trash, CaretLeftFill } from 'react-bootstrap-icons';

const CemeteryButton = ({ strategy, onCloseClick, onStrategyClick, onEditClick, onDeleteClick, onRetreiveClick }) => (
  <div className={`h-100 ${onCloseClick ? 'd-inline-flex' : 'd-flex'} bg-dark rounded align-items-center`} dir="ltr">
    {onCloseClick && (
      <div>
        <CaretLeftFill className="hover text-white mx-2" size={13} onClick={onCloseClick} />
      </div>
    )}
    <div className="flex-grow-1">
      <Button
        size="sm"
        variant="dark"
        className="w-100 text-start px-4"
        style={{ fontSize: 12 }}
        onClick={() => onStrategyClick(strategy)}
      >
        <span className="align-middle mx-2">{strategy.name}</span>
      </Button>
    </div>

    <div>
      {[
        { Icon: Pencil, onClick: onEditClick && (() => onEditClick(strategy)) },
        { Icon: Trash, onClick: onDeleteClick && (() => onDeleteClick(strategy)) },
        { Icon: BoxArrowUp, onClick: () => onRetreiveClick(strategy) }
      ]
        .filter(({ onClick }) => onClick)
        .map(({ Icon, onClick }, index) => (
          <Icon key={index} className="hover text-white mx-2" size={13} onClick={onClick} />
        ))}
    </div>
  </div>
);

export default CemeteryButton;
