import React, { useMemo } from 'react';
import { FormControl, OverlayTrigger, Tooltip } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import useLocalization from '../../../hooks/useLocalization';
import CustomMultiSelect from '../CustomMultiSelect';
import NumberRangeFilter from './NumberRangeFilter';

const ColumnFilterCell = ({
  column: {
    key,
    type,
    searchOptions,
    maxItemCustomMessage = length => `+ ${length} selected`,
    isGroupedDropdown,
    isMultiSelect = false
  },
  filterValues = [],
  onColumnFilterChange
}) => {
  const { translate } = useLocalization();
  const filterValue = useMemo(() => filterValues.find(sv => sv.key === key), [filterValues, key]);

  const handleDateChange = dates => {
    const [start, end] = dates;
    onColumnFilterChange(key, { start, end }, type);
  };

  if (type === 'date') {
    return (
      <DatePicker
        portalId="root"
        selectsRange
        startDate={filterValue?.value?.start}
        endDate={filterValue?.value?.end}
        onChange={handleDateChange}
        isClearable={true}
        dateFormat="MMM dd"
        wrapperClassName="d-block"
        className="form-control form-control-sm w-100 smallFont py-0"
      />
    );
  }
  if (type === 'number') {
    return <NumberRangeFilter value={filterValue?.value} onChange={range => onColumnFilterChange(key, range, type)} />;
  }

  if (searchOptions) {
    if (isGroupedDropdown) {
      return (
        <CustomMultiSelect
          items={searchOptions}
          onChange={values => onColumnFilterChange(key, values)}
          selectedItems={filterValue?.value}
          isMulti={false}
          isGroupped
          placeholder=""
          fieldColors={searchOptions.flatMap(o => o?.options)?.find(option => option?.value === filterValue?.value)}
          closeMenuOnSelect
          height="28px"
          isClearable
        />
      );
    }
    return (
      <CustomMultiSelect
        items={searchOptions}
        onChange={values => onColumnFilterChange(key, values)}
        selectedItems={filterValue?.value ? filterValue?.value : []}
        maxToShow={0}
        isMulti={isMultiSelect}
        closeMenuOnSelect={!Boolean(isMultiSelect)}
        placeholder=""
        maxItemCustomMessage={maxItemCustomMessage}
        showMessageOnlyOnOverflow
        isClearable
        height="28px"
      />
    );
  }
  return (
    <FormControl
      className={`mb-0 text-dark px-2 py-0`}
      as={'input'}
      style={{
        fontSize: '12px'
      }}
      value={filterValue?.value || ''}
      onChange={e => onColumnFilterChange(key, e.target.value)}
      size="sm"
    />
  );
};

export default ColumnFilterCell;
