const getIsUserLoggedInFromLocal = () => {
  return Boolean(localStorage.getItem('user-is-loggedin'));
};

const setIsUserLoggedInToLocal = isLoggedIn => {
  if (isLoggedIn) localStorage.setItem('user-is-loggedin', isLoggedIn);
  else localStorage.removeItem('user-is-loggedin');
};

export { getIsUserLoggedInFromLocal, setIsUserLoggedInToLocal };
