import React, { useEffect, useState } from 'react';
import { Row, Col } from 'react-bootstrap';

const BlockSelectInput = ({
  id,
  options,
  onChange = '',
  value: defaultValue = '',
  preValue,
  onFormChange,
  disabled = false,
  onChangeFunction,
  multiple,
  required,
  blockWidth = 2,
  onValueChange
}) => {
  const [selectedBlocks, setSelectedBlocks] = useState(
    preValue || defaultValue ? (multiple ? [...(preValue || defaultValue)] : [preValue || defaultValue]) : []
  );

  //called after render only once
  useEffect(() => {
    internalOnChange(null, false);
  }, []);

  const internalOnChange = (e, checkForm = true) => {
    if (checkForm && onFormChange) onFormChange(e);

    if (onChange && window[onChange]) {
      window[onChange](e);
    }

    if (onChangeFunction) onChangeFunction(e);
  };

  const onBlockClick = (e, option) => {
    if (disabled) return;

    let newBlocks = [...selectedBlocks];
    if (newBlocks.includes(option)) {
      newBlocks.splice(
        newBlocks.findIndex(b => b === option),
        1
      );
      onValueChange && onValueChange(null);
    } else {
      if (multiple) {
        newBlocks.push(option);
        onValueChange && onValueChange(newBlocks);
      } else {
        newBlocks = [option];
        onValueChange && onValueChange(option);
      }
    }
    setSelectedBlocks([...newBlocks]);

    setTimeout(() => internalOnChange(e), 200);
  };

  return (
    <Row
      className="fg-blocks gap-1 m-0"
      disabled={disabled}
      id={`${id}-blocks`}
      data-required={required ? 'true' : 'false'}
    >
      {options.map((option, index) => {
        return (
          <Col
            className="block text-center no-float p-0"
            xs={6}
            sm={6}
            md={blockWidth}
            key={index}
            data-value={option}
            data-selected={selectedBlocks.includes(option) ? 'true' : 'false'}
          >
            <div
              onClick={e => onBlockClick(e, option)}
              className={` p-1 h-100 mid rounded ${!disabled ? 'hover-light' : ''} border border-primary ${
                selectedBlocks.includes(option) ? 'bg-primary' : disabled ? 'bg-secondary' : ''
              }`}
            >
              <p className={`mb-0 ${selectedBlocks.includes(option) ? 'text-white' : 'text-dark'}`}>{option}</p>
            </div>
          </Col>
        );
      })}
    </Row>
  );
};

export default BlockSelectInput;
