import { uniqueId } from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Upload } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import useFileUpload from 'react-use-file-upload';
import { makeApiRequests } from '../../helpers/api';
import { ENDPOINTS } from '../../helpers/constants';
import CircularProgressBar from './circular-progress';

// uploadedFile = {status: ['WAITING_FOR_UPLOAD','UPLOADING', 'UPLOADED', 'ERROR']}

const FileUploadButton = ({
  forceShowProgress = false,
  onUploadFileChange,
  showText,
  render: Render = Button,
  className = '',
  disabled
}) => {
  const { files, setFiles } = useFileUpload();
  const inputRef = useRef();
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const { translate } = useContext(LocalizeContext);

  const showProgress = useMemo(
    () => forceShowProgress || uploadedFiles.some(f => ['WAITING_FOR_UPLOAD', 'UPLOADING'].includes(f.status)),
    [uploadedFiles]
  );

  useEffect(() => {
    //when new file is uploaded, set status to upload the file to server and push to uploaded files
    const newFiles = [];
    for (const f of files) {
      const fileToBeUploaded = { file: f, status: 'WAITING_FOR_UPLOAD', id: uniqueId() };
      newFiles.push(fileToBeUploaded);
    }

    setUploadedFiles(newFiles);
  }, [files]);

  useEffect(() => {
    uploadedFiles.filter(f => f.status === 'WAITING_FOR_UPLOAD').forEach(uploadFile);
    onUploadFileChange(uploadedFiles);
  }, [uploadedFiles]);

  const uploadFile = async fileToBeUploaded => {
    updateUploadedFiles(fileToBeUploaded, 'UPLOADING');

    const formData = new FormData();
    formData.append('file', fileToBeUploaded.file);

    const { error, response } = await makeApiRequests({
      requestBody: formData,
      endpoint: ENDPOINTS.FILE, // `${ENDPOINTS.FILE}${getMetadataParams(metaData)}`,
      stringify: false
    });

    if (error) {
      updateUploadedFiles(fileToBeUploaded, 'ERROR');
    } else {
      updateUploadedFiles(fileToBeUploaded, 'UPLOADED', response);
    }
  };

  const updateUploadedFiles = (fileObject, status, response = {}) => {
    setUploadedFiles(uploadedFiles =>
      uploadedFiles.map(f => (f.id === fileObject.id ? { ...f, ...response, status } : f))
    );
  };

  return (
    <>
      <Render
        disabled={showProgress || disabled}
        variant="outline-primary"
        size="sm"
        className={className || 'px-1 py-0 mx-1'}
        onClick={() => inputRef.current.click()}
      >
        {showProgress ? <CircularProgressBar size={1} /> : <Upload size={12} />}
        {showText && <span className="align-middle mx-2">{translate('upload_files')}</span>}
      </Render>
      <input
        autoComplete="off"
        ref={inputRef}
        type="file"
        multiple
        style={{ display: 'none' }}
        onChange={e => {
          setFiles(e);
          inputRef.current.value = null;
        }}
      />
    </>
  );
};

export default FileUploadButton;
