import React, { useMemo } from 'react';
import { useContext } from 'react';
import { Table } from 'react-bootstrap';
import { monthSmall } from '../../../../../helpers/constants';
import { formatCurrency, isPrimaryBankAccount } from './helper';
import { ActionCell } from './StrategyBalanceSheet';
import { LocalizeContext } from 'react-locale-language';

const getOneOffEventHeaders = translate => [
  { key: 'description', label: translate('description') },
  { key: 'value', label: translate('value'), isCurrency: true },
  { key: 'type', label: translate('type') },
  { key: 'action', label: translate('actions') },
  { key: 'name', label: translate('name') },
  { key: 'year', label: translate('year') },
  { key: 'month', label: translate('month') }
];

const getNameOfInstrument = (event, assetsAndLiabilities, translate) => {
  const instrument = assetsAndLiabilities.find(el => el._id === event.refId);
  if (!instrument) return '-';

  return isPrimaryBankAccount(instrument) ? translate('primary_bank_account') : instrument.name;
};

const OneOffEventSheet = ({ strategy, onEditEventClick, onOneOffEventDeleteClick, editable }) => {
  const { translate, langCode } = useContext(LocalizeContext);

  const oneOffEventHeaders = useMemo(() => getOneOffEventHeaders(translate), [langCode]);

  const oneOffEvents = useMemo(() => {
    const oneOffEvents = strategy.oneOffChanges;
    const assetsAndLiabilities = [...strategy.assets, ...strategy.liabilities];
    return oneOffEvents.map(event => ({
      ...event,
      name: getNameOfInstrument(event, assetsAndLiabilities, translate),
      attribute: assetsAndLiabilities.find(el => el._id === event.refId)
    }));
  });

  return (
    <div className="py-3">
      <h6>
        <b>{translate('one_off_event_summary')}</b>
      </h6>
      {oneOffEvents?.length > 0 ? (
        <>
          <Table responsive bordered className="smallFont mb-2">
            <thead>
              <tr className="bg-dark text-white">
                {oneOffEventHeaders.map(h => (
                  <th style={{ minWidth: 100 }}>{h.label}</th>
                ))}
                {editable && <th style={{ minWidth: 100 }}>{translate('actions')}</th>}
              </tr>
            </thead>
            <tbody>
              {oneOffEvents.map(l => (
                <tr  className="bg-white" key={l._id}>
                  {oneOffEventHeaders.map(h =>
                    h.key === 'month' ? (
                      <td style={{ minWidth: 100 }}>{monthSmall[l['month']]}</td>
                    ) : (
                      <td style={{ minWidth: 100 }}>
                        {Array.isArray(l[h.key])
                          ? l[h.key].join(', ')
                          : h.isCurrency
                          ? formatCurrency(l[h.key])
                          : l[h.key]}
                      </td>
                    )
                  )}
                  {editable && (
                    <td>
                      <ActionCell
                        hideAddEvent={true}
                        onEditClick={() => {
                          onEditEventClick(l);
                        }}
                        onDeleteClick={() => onOneOffEventDeleteClick(l)}
                      />
                    </td>
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      ) : (
        <h6 className="p-5 text-center">{translate('nothing_to_show')}</h6>
      )}
    </div>
  );
};

export default OneOffEventSheet;
