import React, { useState, useEffect, useContext } from 'react';
import UnderlineButton from './UnderlineButton';
import { LocalizeContext } from 'react-locale-language';
import { QuestionCircle } from 'react-bootstrap-icons';

function ResendOTPButton({ otpWaitTime = 60, onSendOTP, otpSendInProgress }) {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [timer, setTimer] = useState(otpWaitTime);
  const { translate } = useContext(LocalizeContext);

  // Function to send the OTP
  const handleSendOTPClick = async () => {
    // if a logic is provided and the logic returns true
    // disable/enable button according to that
    if (onSendOTP) {
      const isOTPSent = await onSendOTP();
      if (isOTPSent) {
        // Disable the button and start the timer
        setIsButtonDisabled(true);
        setTimer(otpWaitTime);
      }
      return;
    }

    // else directly Disable the button and start the timer
    setIsButtonDisabled(true);
    setTimer(otpWaitTime);
  };

  useEffect(() => {
    // Exit early when the button is already enabled
    if (!isButtonDisabled) return;

    // Countdown timer
    const intervalId = setInterval(() => {
      setTimer(prevTimer => {
        if (prevTimer === 1) {
          clearInterval(intervalId);
          setIsButtonDisabled(false);
          return 0;
        } else {
          return prevTimer - 1;
        }
      });
    }, 1000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, [isButtonDisabled]);

  return (
    <div className="px-2">
      <UnderlineButton
        Icon={QuestionCircle}
        fontSize="large"
        text={
          otpSendInProgress
            ? translate('please_wait')
            : isButtonDisabled
            ? translate('please_wait_n_secs', { timer })
            : translate('did_not_receive_send_otp_again')
        }
        onClick={handleSendOTPClick}
        disabled={isButtonDisabled || otpSendInProgress}
      />
    </div>
  );
}

export default ResendOTPButton;
