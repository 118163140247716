import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';

const TimeIntervalInWeek = ({ className, disabled, initialDate, onChange, style }) => {
  const { translate } = useContext(LocalizeContext);

  function getDateWithInterval(date, interval) {
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
    const oneWeek = 7 * oneDay; // milliseconds in a week
    const oneMonth = 30 * oneDay; // assuming a month is 30 days

    // Convert inputDate to a JavaScript Date object if it's not already
    const currentDate = new Date(date);

    // Calculate the new date based on the interval
    switch (interval) {
      case '1Day':
        return new Date(currentDate.getTime() + oneDay);
      case '2Weeks':
        return new Date(currentDate.getTime() + 2 * oneWeek);
      case '3Months':
        const dateToReturn = new Date(currentDate);
        dateToReturn.setMonth(dateToReturn.getMonth() + 3);
        return dateToReturn;
      default:
        return new Date();
    }
  }

  const onIntervalChange = interval => {
    if (disabled) return;
    const newDate = getDateWithInterval(initialDate, interval);
    onChange(newDate);
  };

  return (
    <div className={`d-flex align-items-center  hover ${className}`} style={style}>
      <Button
        variant="dark"
        size="sm"
        className="mx-1 py-0 d-flex align-items-center"
        onClick={() => onIntervalChange('1Day')}
      >
        <Plus size={15} /> <span className="tiny mb-0">{translate('one_week')} </span>
      </Button>
      <Button
        variant="dark"
        size="sm"
        className="mx-1 py-0 d-flex align-items-center"
        onClick={() => onIntervalChange('2Weeks')}
      >
        <Plus size={15} /> <span className="tiny mb-0">{translate('three_week')} </span>
      </Button>
      <Button
        variant="dark"
        size="sm"
        className="mx-1 py-0 d-flex align-items-center"
        onClick={() => onIntervalChange('3Months')}
      >
        <Plus size={15} /> <span className="tiny mb-0">{translate('three_month')} </span>
      </Button>
    </div>
  );
};

export default TimeIntervalInWeek;
