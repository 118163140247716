import { useState, useEffect } from 'react';

export function useScreenWidth() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    // Add event listener to update screenWidth on window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []); // Empty dependency array ensures that effect runs only once on mount

  const screenSize =
    screenWidth < 576
      ? 'xs'
      : screenWidth >= 576 && screenWidth < 768
      ? 'sm'
      : screenWidth >= 768 && screenWidth < 992
      ? 'md'
      : screenWidth >= 992 && screenWidth < 1200
      ? 'lg'
      : screenWidth >= 1200 && screenWidth < 1400
      ? 'xl'
      : 'xxl';

  return {
    screenWidth,
    screenSize
  };
}
