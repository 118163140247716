import React, { useMemo, useState } from 'react';
import { Button, ButtonGroup, InputGroup, ToggleButton } from 'react-bootstrap';
import useLocalization from '../../hooks/useLocalization';
import DecimalInput from './DecimalInput';

const monthsToYearsRounded = months => {
  const year = Math.floor(months / 12);
  const remainingMonths = Math.round(months % 12);
  return parseFloat((year + remainingMonths / 12).toFixed(2));
};

const yearsToMonthsRounded = years => Math.round(years * 12);

const MonthYearInput = ({ isRtlLanguage, value, onChange }) => {
  const [mode, setMode] = useState('month');
  const yearValue = useMemo(() => monthsToYearsRounded(value), [value]);
  const { translate } = useLocalization();

  return (
    <div className="d-flex align-items-center w-100 gap-1">
      <InputGroup size="sm" className={`d-flex ${isRtlLanguage ? 'flex-row-reverse' : ''}`}>
        <DecimalInput
          value={mode === 'month' ? Number(value) || 0 : yearValue}
          onChange={mode === 'month' ? onChange : y => onChange(yearsToMonthsRounded(y))}
        />
        <div
          style={{ margin: '0px 1px' }}
          onClick={() => setMode('month')}
          className={`d-flex align-items-center bg-white hover justify-content-center  border border-2 py-1 px-1 ${
            mode === 'month' ? 'border-dark text-dark' : 'text-dark'
          }`}
        >
          <h6 className={`mb-0 smallFont ${mode === 'month' && 'fw-bold'}`}>{translate('months')}</h6>
        </div>{' '}
        <div
          onClick={() => setMode('year')}
          className={`d-flex align-items-center bg-white justify-content-center border border-2 hover py-1 px-1 ${
            mode === 'year' ? 'border-dark text-dark' : 'text-dark'
          }`}
        >
          <h6 className={`mb-0 smallFont ${mode === 'year' && 'fw-bold'}`}>{translate('years')}</h6>
        </div>
      </InputGroup>
    </div>
  );
};

export default MonthYearInput;
