import React, { useContext, useMemo } from 'react';
import { Button, Dropdown, Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { ArrowLeft, PersonCircle } from 'react-bootstrap-icons';
import { LocalizeContext } from 'react-locale-language';
import { useHistory, useLocation } from 'react-router';
import { LANDING_URL } from '../../helpers/constants';
import { getUserFirstName, isCustomer, isSystemUser } from '../../helpers/global';
import { isRtl } from '../../helpers/localization';
import useAuth from '../../hooks/useAuth';
import LanguagePicker from './LanguagePicker';
import Logo from './Logo';
import { Link } from 'react-router-dom';

const adminRoutes = {
  initialPath: '/admin',
  paths: {
    // manage_users: { initialPath: '/manage-users', routes: manageUsersRoute },
    customers: { route: '/manage-users/customer' },
    comp_table: { route: '/comp-table' }
  }
};
const otherUserRoutes = {
  initialPath: '/admin',
  paths: {
    customers: { route: '/manage-users/customer' },
    comp_table: { route: '/comp-table' }
  }
};

//ROUTES FOR OTHER ROLES COME HERE
const customerRoutes = {
  initialPath: '/customer',
  paths: {
    Dashboard: { route: '/' }
  }
};

const roleWiseRoutes = {
  Admin: adminRoutes,
  Manager: adminRoutes,
  Customer: customerRoutes,
  Salesperson: otherUserRoutes,
  'Financial Strategist': otherUserRoutes,
  'Real Estate Analyst': otherUserRoutes
};

const commonRoutes = user => [
  ...(isSystemUser(user?.role) || user?.level > 1
    ? [
        {
          initialPath: '/calculators',
          paths: {
            calculators: {
              initialPath: '',
              routes: [
                { path: '/realestate-irr', name: 'real_estate_irr' },
                ...(!isCustomer(user?.role)
                  ? [
                      { path: '/pinui-binui', name: 'pinui_binui' },
                      { path: '/paper-apartment', name: 'paper_apartment' },
                      { path: '/rsu-options', name: 'rsu_options' }
                    ]
                  : [])
              ]
            }
          }
        }
      ]
    : []),
  ...(isSystemUser(user?.role)
    ? [
        {
          initialPath: '/apartments',
          paths: {
            apartments: { route: '/' }
          }
        }
      ]
    : [])
];

const LinkItem = ({ dropdown = false, title, path, location, translate }) => {
  return dropdown ? (
    <NavDropdown.Item
      style={{ color: `${location.pathname}` === path ? 'white' : 'black' }}
      to={path}
      as={Link}
      active={`${location.pathname}` === path || `${location.pathname}/` === path}
    >
      {translate(title)}
    </NavDropdown.Item>
  ) : (
    <Nav.Link to={path} as={Link} active={`${location.pathname}` === path || `${location.pathname}/` === path}>
      {translate(title)}
    </Nav.Link>
  );
};

const NavRoutes = ({ user, role, location, translate }) => {
  const allRoutes = [roleWiseRoutes[role], ...commonRoutes(user)].filter(Boolean);

  return allRoutes.map(route => {
    const roleWisePaths = route.paths;
    const roleWiseInitialPath = route.initialPath;

    return Object.keys(roleWisePaths).map(headerName =>
      roleWisePaths[headerName].route ? (
        <LinkItem
          translate={translate}
          location={location}
          path={`${roleWiseInitialPath}${roleWisePaths[headerName].route}`}
          title={headerName}
        />
      ) : (
        <NavDropdown
          title={translate(headerName)}
          active={location.pathname.startsWith(`${roleWiseInitialPath}${roleWisePaths[headerName].initialPath}`)}
        >
          {roleWisePaths[headerName].routes.map((r, index) => (
            <LinkItem
              translate={translate}
              location={location}
              path={`${roleWiseInitialPath}${roleWisePaths[headerName].initialPath}${r.path}`}
              title={r.name}
              dropdown
            />
          ))}
        </NavDropdown>
      )
    );
  });
};

const Header = () => {
  const history = useHistory();
  const location = useLocation();
  const { translate, langCode } = useContext(LocalizeContext);

  const { user, viewAsUserMode, parentUser, logout } = useAuth();

  const role = useMemo(() => user?.role, [user]);

  const userName = useMemo(() => getUserFirstName(parentUser?.name), [parentUser]);

  const logoutLocal = history => {
    logout();
    history.push('/login');
  };

  return (
    <>
      <Navbar className="px-3" bg="white" expand="md">
        <Navbar.Brand href="/">
          <Logo noText small dark />
        </Navbar.Brand>
        <LanguagePicker />

        <>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            {role && (
              <Nav className="ms-3 me-auto" style={{ fontSize: 14 }}>
                <NavRoutes user={user} role={role} location={location} translate={translate} />
              </Nav>
            )}

            <div>
              {role && (
                <>
                  <Button size="sm" className="mx-1" variant="primary-new" href={LANDING_URL}>
                    <ArrowLeft className="mx-1" />
                    <span className="smallFont">{translate('back_to_site')}</span>
                  </Button>
                  {viewAsUserMode && (
                    <Button className="mx-1" variant="outline-dark" size="sm">
                      <span className="smallFont mb-0">Viewing as {user?.name || 'N/A'}</span>
                    </Button>
                  )}{' '}
                  {!isCustomer(role) && (
                    <a className="mx-1" size="sm" href={'https://crm.ethica.finance/'} target="_blank">
                      <img
                        alt="App"
                        style={{ borderRadius: 5 }}
                        height={30}
                        width={30}
                        src={`${process.env.PUBLIC_URL}/CRMLogo.png`}
                      />
                    </a>
                  )}
                  <Dropdown
                    dir="rtl"
                    className={`d-none d-md-inline-block ${isRtl(langCode) ? 'me-1' : 'ms-1'}`}
                    style={{ zIndex: 8 }}
                    drop={isRtl(langCode) ? 'end' : 'start'}
                  >
                    <Dropdown.Toggle size="sm" variant="outline-dark">
                      <PersonCircle size={18} className="ms-2 align-text-top" />
                      <span className="mb-0 smallFont">{userName}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      <Dropdown.Item className="smallFont" onClick={() => history.push('/profile')}>
                        {translate('view_profile')}
                      </Dropdown.Item>
                      <Dropdown.Item className="smallFont" onClick={() => logoutLocal(history)}>
                        {translate('logout')}
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Dropdown className="d-inline-block d-md-none ms-3 mt-1" style={{ zIndex: 8 }}>
                    <Dropdown.Toggle size="sm" variant="outline-dark">
                      <PersonCircle size={18} className="ms-2 align-text-top" />
                      <span className="mb-0 smallFont">{userName}</span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {!isCustomer(role) && (
                        <Dropdown.Item
                          className="border-bottom smallFont"
                          onClick={() => window.open('https://crm.ethica.finance/', '_target_blank')}
                        >
                          Ethica CRM
                        </Dropdown.Item>
                      )}
                      <Dropdown.Item onClick={() => history.push('/profile')}>
                        {translate('view_profile')}
                      </Dropdown.Item>
                      <Dropdown.Item onClick={() => logoutLocal(history)}>{translate('logout')}</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </>
              )}
            </div>
          </Navbar.Collapse>
        </>
      </Navbar>
      <div
        style={{
          height: 0.5
        }}
        className="bg-dark"
      ></div>
    </>
  );
};

export default Header;
