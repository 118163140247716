import React from 'react';
import { Button, Col, FormSelect, Modal, Row } from 'react-bootstrap';
import { ArrowClockwise } from 'react-bootstrap-icons';
import { monthSmall } from '../../../../../helpers/constants';

const ArmageddonMultipleStrategyModal = ({
  strategyYears,
  startYear,
  onStartYearChange,
  startMonth,
  onStartMonthChange,
  strategyAssetDepreciations = [],
  onStrategyAssetDepreciationsChange,
  show,
  onHide,
  onWeakestTimeClick,
  onApplyArmageddon,
  translate
}) => {
  return (
    <Modal size="lg" show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton>
        <Modal.Title>
          <h6 className="mb-0 pt-2">{translate('armageddon_scenario')}</h6>
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className="overflow-auto">
        <Row style={{ padding: '5px' }}>
          <Col>
            <label className="mid">{translate('start_year')}</label>
            <FormSelect size="sm" value={startYear} onChange={e => onStartYearChange(Number(e.target.value))}>
              {strategyYears.map((year, index) => (
                <option value={year} key={index}>
                  {year}
                </option>
              ))}
            </FormSelect>
          </Col>
          <Col>
            <div className="d-flex align-items-end">
              <div className="flex-grow-1">
                <label className="mid" htmlFor="startMonth">
                  {translate('start_month')}
                </label>
                <FormSelect size="sm" value={startMonth} onChange={e => onStartMonthChange(Number(e.target.value))}>
                  {monthSmall.map((month, index) => (
                    <option value={index} key={index}>
                      {month}
                    </option>
                  ))}
                </FormSelect>
              </div>
              <div className="me-2">
                <Button size="sm" onClick={onWeakestTimeClick}>
                  <ArrowClockwise className="ms-1" />
                  {translate('weakest')}
                </Button>
              </div>
            </div>
          </Col>
        </Row>
        <h6 className="mt-2 mb-0">{translate('depreciations')}</h6>
        <hr className="my-1" />
        {strategyAssetDepreciations.map(({ strategyName, assetDepreciations = [] }) => (
          <>
            <h6 className="mt-3 mb-0 text-muted large">
              <b>{strategyName}</b>
            </h6>
            <Row style={{ padding: '5px' }}>
              {assetDepreciations.map(({ _id, name, depreciation }) => (
                <Col key={_id} xs={6} md={4}>
                  <label className="mid">{name}</label>
                  <input
                    autoComplete="off"
                    type="number"
                    className="form-control form-control-sm"
                    name="value"
                    onChange={e => onStrategyAssetDepreciationsChange(strategyName, _id, e.target.value)}
                    value={depreciation}
                  />
                </Col>
              ))}
            </Row>
            <hr />
          </>
        ))}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="success" size="sm" className="me-2 text-white" onClick={onApplyArmageddon}>
          {translate('update')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ArmageddonMultipleStrategyModal;
