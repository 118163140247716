import { RichUtils } from 'draft-js';
import React from 'react';
import {
  AlignCenter,
  AlignEnd,
  AlignStart,
  ChevronDown,
  ChevronUp,
  Code,
  ListOl,
  ListUl,
  PaintBucket,
  Quote,
  TypeBold,
  TypeItalic,
  TypeStrikethrough,
  TypeUnderline
} from 'react-bootstrap-icons';

const Toolbar = ({ editorState, setEditorState }) => {
  const tools = [
    {
      label: 'bold',
      style: 'BOLD',
      icon: TypeBold,
      method: 'inline'
    },
    {
      label: 'italic',
      style: 'ITALIC',
      icon: TypeItalic,
      method: 'inline'
    },
    {
      label: 'underline',
      style: 'UNDERLINE',
      icon: TypeUnderline,
      method: 'inline'
    },
    {
      label: 'highlight',
      style: 'HIGHLIGHT',
      icon: PaintBucket,
      method: 'inline'
    },
    {
      label: 'strike-through',
      style: 'STRIKETHROUGH',
      icon: TypeStrikethrough,
      method: 'inline'
    },
    /*     {
      label: 'Superscript',
      style: 'SUPERSCRIPT',
      icon: <FontAwesomeIcon icon={faSuperscript} />,
      method: 'inline'
    },
    {
      label: 'Subscript',
      style: 'SUBSCRIPT',
      icon: <FontAwesomeIcon icon={faSubscript} />,
      method: 'inline'
    }, */
    {
      label: 'Monospace',
      style: 'CODE',
      icon: Code,
      method: 'inline'
    },
    {
      label: 'Blockquote',
      style: 'blockQuote',
      icon: Quote,
      method: 'block'
    },
    {
      label: 'Unordered-List',
      style: 'unordered-list-item',
      method: 'block',
      icon: ListUl
    },
    {
      label: 'Ordered-List',
      style: 'ordered-list-item',
      method: 'block',
      icon: ListOl
    },
    /*  {
      label: 'Code Block',
      style: 'CODEBLOCK',
      icon: Code,
      method: 'inline'
    }, */
    /*     {
      label: 'Uppercase',
      style: 'UPPERCASE',
      icon: ChevronUp,
      method: 'inline'
    },
    {
      label: 'lowercase',
      style: 'LOWERCASE',
      icon: ChevronDown,
      method: 'inline'
    }, */
    /*     {
      label: 'Left',
      style: 'leftAlign',
      icon: AlignStart,
      method: 'block'
    },
    {
      label: 'Center',
      style: 'centerAlign',
      icon: AlignCenter,
      method: 'block'
    },
    {
      label: 'Right',
      style: 'rightAlign',
      icon: AlignEnd,
      method: 'block'
    }, */
    { label: 'H1', style: 'header-one', method: 'block' },
    { label: 'H2', style: 'header-two', method: 'block' },
    { label: 'H3', style: 'header-three', method: 'block' },
    { label: 'H4', style: 'header-four', method: 'block' },
    { label: 'H5', style: 'header-five', method: 'block' },
    { label: 'H6', style: 'header-six', method: 'block' }
  ];

  const applyStyle = (e, style, method) => {
    e.preventDefault();
    method === 'block'
      ? setEditorState(RichUtils.toggleBlockType(editorState, style))
      : setEditorState(RichUtils.toggleInlineStyle(editorState, style));
  };

  const isActive = (style, method) => {
    if (method === 'block') {
      const selection = editorState.getSelection();
      const blockType = editorState
        .getCurrentContent()
        .getBlockForKey(selection.getStartKey())
        .getType();
      return blockType === style;
    } else {
      const currentStyle = editorState.getCurrentInlineStyle();
      return currentStyle.has(style);
    }
  };

  return (
    <div className="">
      {tools.map((item, idx) => (
        <button
          className={isActive(item.style, item.method) ? 'text-primary' : 'text-dark'}
          key={`${item.label}-${idx}`}
          title={item.label}
          onClick={e => applyStyle(e, item.style, item.method)}
          onMouseDown={e => e.preventDefault()}
        >
          {item.icon ? <item.icon size={18} /> : item.label}
        </button>
      ))}
    </div>
  );
};

export default Toolbar;
