import React, { useContext, useMemo } from 'react';
import { Table, Button } from 'react-bootstrap';
import { formatCurrency } from './helper';
import { LocalizeContext } from 'react-locale-language';
import LTRNumberCell from '../../../../common/LTRNumberCell';
import UnderlineButton from '../../../../common/UnderlineButton';
import { PlusCircle } from 'react-bootstrap-icons';

const getLiabilityHeaders = translate => [
  { key: 'name', label: translate('name') },
  { key: 'value', label: translate('value'), isNumber: true },
  { key: 'type', label: translate('loan_type') },
  { key: 'loanProvider', label: translate('loan_provider') },
  { key: 'relatedAssets', label: translate('related_assets') },
  { key: 'timeToMaturity', label: translate('time_to_maturity') },
  { key: 'interest', label: translate('loan_interest') },
  { key: 'typeOfInterest', label: translate('interest_type') }
];

const LiabilitySheet = ({ editable, liabilities = [], onAddLiabilityClick, disabled }) => {
  const { translate, langCode } = useContext(LocalizeContext);

  const liabilityHeaders = useMemo(() => getLiabilityHeaders(translate), [langCode]);
  return (
    <div className="py-3">
      <h6>
        <b>{translate('loan_summary')}</b>
        {editable && (
          <UnderlineButton
            text={translate('add_liability')}
            Icon={PlusCircle}
            fontSize="mid"
            variant="danger"
            className="mx-1"
            onClick={onAddLiabilityClick}
            disabled={disabled}
          />
        )}
      </h6>
      {liabilities.filter(l => !l.toBeMerged).length > 0 ? (
        <>
          <Table responsive bordered className="smallFont mb-2">
            <thead>
              <tr className="bg-dark text-white">
                {liabilityHeaders.map(h => (
                  <th style={{ minWidth: 100 }}>{h.label}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {liabilities
                .filter(l => !l.toBeMerged)
                .map(l => (
                  <tr className={`bg-${l.isInvalid ? 'danger-light' : 'white'}`} key={l._id}>
                    {liabilityHeaders.map(h => (
                      <td style={{ minWidth: 100 }}>
                        {Array.isArray(l[h.key]) ? (
                          l[h.key].join(', ')
                        ) : h.isNumber ? (
                          <LTRNumberCell value={l[h.key]} />
                        ) : (
                          l[h.key] || '-'
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
            </tbody>
          </Table>
        </>
      ) : (
        <h6 className="p-5 text-center">{translate('nothing_to_show')}</h6>
      )}
    </div>
  );
};

export default LiabilitySheet;
