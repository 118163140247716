import React, { useContext, useMemo } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Pen, PlusCircle, Trash } from 'react-bootstrap-icons';
import { getVariableTableHeaders } from '../../../helpers/constants';
import EntityTable from '../../common/EntityTable';
import { LocalizeContext } from 'react-locale-language';

const Variables = ({
  variables = [],
  onVariableAddClick,
  onVariableEditClick,
  onVariableDeleteClick,
  readOnlyMode
}) => {
  const { translate, langCode } = useContext(LocalizeContext);
  const variableTableHeaders = useMemo(() => getVariableTableHeaders(translate), [langCode]);

  return (
    <Card>
      <Card.Header>{translate('variables')}</Card.Header>
      <Card.Body>
        <EntityTable
          rowKeyField={'_id'}
          data={variables}
          headers={variableTableHeaders}
          searchQueryKeys={['name']}
          additionalComponents={() =>
            !readOnlyMode && (
              <Button size="sm" className="text-white" variant="success" onClick={onVariableAddClick}>
                <PlusCircle className="mr-1" /> {translate('new_variables')}
              </Button>
            )
          }
          actionCell={item =>
            !readOnlyMode && (
              <div>
                <Button variant="outline-primary" className="px-1 py-0 mr-1" onClick={() => onVariableEditClick(item)}>
                  <Pen size={10} />
                </Button>
                <Button variant="outline-danger" className="px-1 py-0 " onClick={() => onVariableDeleteClick(item)}>
                  <Trash size={10} />
                </Button>
              </div>
            )
          }
        />
      </Card.Body>
    </Card>
  );
};

export default Variables;
