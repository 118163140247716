import React, { useContext, useMemo } from 'react';
import { formatCurrency } from '../admin/manage-users/customer/strategies/helper';
import { LocalizeContext } from 'react-locale-language';
import { isRtl } from '../../helpers/localization';

// A hacky component to render minus at front of number even if language
// is right to left
// formatType = currency | percentage
const LTRNumberCell = ({ value = 0, formatType = 'currency', decimalNumber = 2 }) => {
  const { langCode } = useContext(LocalizeContext);

  const isRTL = useMemo(() => isRtl(langCode), [langCode]);

  return (
    <>
      {value < 0 && !isRTL && <span>-</span>}

      <span>
        {formatType === 'currency' ? formatCurrency(Math.abs(value)) : Math.abs(value).toFixed(decimalNumber)}
        {formatType !== 'currency' && '%'}
      </span>
      {value < 0 && isRTL && <span>-</span>}
    </>
  );
};

export default LTRNumberCell;
