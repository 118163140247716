import React, { useEffect, useMemo, useState } from 'react';
import { Button, Col, FormControl, Row } from 'react-bootstrap';
import CurrencyInput from '../components/common/CurrencyInput';
import CustomMultiSelect from '../components/common/CustomMultiSelect';
import HorizontalProgress from '../components/common/HorizontalProgress';
import BlockSelectInput from '../components/common/BlockSelectInput';
import { ApartmentProfileFields } from '../helpers/constants';
import { apartmentTypes } from '../helpers/global';
import useLocalization from '../hooks/useLocalization';
import RangeInput from '../components/common/RangeInput';
import SwitchInput from '../components/common/SwitchInput';

const CustomerProfileForm = ({ editMode, values, onSubmit, onDeleteClick, showProgress }) => {
  const { translate } = useLocalization();
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const fieldsAccordingToApartmentType = useMemo(() => {
    return ApartmentProfileFields[data?.type];
  }, [data]);

  const handleChange = ({ fieldType, key, objectKey, value }) => {
    if (fieldType === 'minMax' || objectKey) {
      setData({
        ...data,
        [key]: {
          ...(data[key] || {}),
          [objectKey]: value
        }
      });
      return;
    }
    setData({
      ...data,
      [key]: value
    });
  };

  const handleValidation = () => {
    const newErrors = {};
    fieldsAccordingToApartmentType?.forEach(field => {
      if (field.type === 'minMax') {
        const min = data[field.key]?.min;
        const max = data[field.key]?.max;
        if (min > max) {
          newErrors[field.key] = 'Min value cannot be greater than max value';
        }
      }
    });
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = () => {
    if (handleValidation()) {
      onSubmit(data);
    }
  };

  useEffect(() => {
    if (values) {
      setData({ ...values });
    }
  }, [values]);

  return (
    <div className="p-2">
      <h6 className="smallFont">{translate('apartment_type')}:</h6>
      <div style={{ width: 200 }}>
        <CustomMultiSelect
          selectedItems={data?.type || ''}
          items={apartmentTypes.slice(1)}
          onChange={a => setData({ _id: data?._id, type: a })}
          isMulti={false}
          closeMenuOnSelect
          height="30px"
        />
      </div>
      <h6 className="smallFont mt-2 my-1">{translate('parameters')}:</h6>
      <div className="border rounded p-1 mt-2">
        <Row className="m-0">
          {fieldsAccordingToApartmentType?.map(field => (
            <Col
              md={field.sizeMD || 6}
              className={`py-1 ${
                field?.type === 'switch' ? 'd-flex align-items-center justify-content-between gap-2 my-1' : ''
              }`}
              key={field.key}
            >
              <h6 className={`smallFont ${field?.type === 'switch' ? 'mb-0' : 'mb-2'}`}>
                {translate(field?.label) || field?.label}
              </h6>
              {field?.type === 'minMax' ? (
                <div className="d-flex align-items-center gap-2">
                  <CurrencyInput
                    size="sm"
                    value={data?.[field.key]?.min || ''}
                    onChange={(value, inputValue) =>
                      handleChange({
                        fieldType: field?.type,
                        key: field.key,
                        objectKey: 'min',
                        value: inputValue === '' ? undefined : value
                      })
                    }
                    placeholder="Min"
                  />
                  <h6 className="smallFont mb-0">-</h6>
                  <CurrencyInput
                    size="sm"
                    value={data?.[field.key]?.max || ''}
                    onChange={(value, inputValue) =>
                      handleChange({
                        fieldType: field?.type,
                        key: field.key,
                        objectKey: 'max',
                        value: inputValue === '' ? undefined : value
                      })
                    }
                    placeholder="Max"
                  />
                </div>
              ) : field?.type === 'multi-select' ? (
                <CustomMultiSelect
                  selectedItems={field?.objectKey ? data?.[field.key]?.[field?.objectKey] : data?.[field?.key]}
                  items={field?.options}
                  onChange={value => {
                    handleChange({
                      fieldType: field?.type,
                      key: field.key,
                      objectKey: field?.objectKey,
                      value
                    });
                  }}
                  height="30px"
                  maxToShow={50}
                />
              ) : field?.type === 'range' ? (
                <RangeInput
                  min={field?.min}
                  max={field?.max}
                  step={1}
                  value={field?.objectKey ? data?.[field.key]?.[field?.objectKey] : data?.[field?.key]}
                  onChange={value => {
                    handleChange({
                      fieldType: field?.type,
                      key: field.key,
                      objectKey: field?.objectKey,
                      value
                    });
                  }}
                />
              ) : field?.type === 'switch' ? (
                <SwitchInput
                  falseValue={field?.falseValue}
                  trueValue={field?.trueValue}
                  value={field?.objectKey ? data?.[field.key]?.[field?.objectKey] : data?.[field?.key]}
                  onChange={value => {
                    handleChange({
                      fieldType: field?.type,
                      key: field.key,
                      objectKey: field?.objectKey,
                      value
                    });
                  }}
                />
              ) : (
                <FormControl
                  size="sm"
                  value={data?.[field.key] || ''}
                  onChange={e => handleChange(field?.type, field.key, null, e.target.value)}
                  placeholder="Max"
                />
              )}
              {errors[field.key] && <div className="text-danger smallFont">{errors[field.key]}</div>}
            </Col>
          ))}
        </Row>
      </div>
      {showProgress && <HorizontalProgress text={translate('please_wait')} />}
      <div className="border-top mt-2 d-flex justify-content-end align-items-center pt-2 px-1">
        {editMode && (
          <Button size="sm" variant="danger text-white" onClick={() => onDeleteClick(data)}>
            <h6 className="mb-0 smallFont">{translate('delete')}</h6>
          </Button>
        )}
        <Button size="sm" variant="success text-white" onClick={handleSubmit}>
          <h6 className="mb-0 smallFont">{translate('save')}</h6>
        </Button>
      </div>
    </div>
  );
};

export default CustomerProfileForm;
