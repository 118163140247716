import * as Yup from 'yup';
import { cashflowTypeOptions, monthSmall } from '../helpers/constants';

const cashflowSchema = Yup.object().shape({
  type: Yup.string()
    .oneOf(cashflowTypeOptions)
    .required('type is required'),
  notes: Yup.string().trim(),
  linkedBankAccount: Yup.string().required('Linked Bank account is required'),
  name: Yup.string()
    .trim()
    .max(128, `Name must be less than ${128} characters`)
    .required('Please enter the name'),
  monthlyValue: Yup.number('Value must be a number')
    .min(0)
    .required('Value is required'),
  startYear: Yup.number().required('Please select a year'),
  startMonth: Yup.number()
    .min(0)
    .max(11)
    .required('Start month is required'),
  endYear: Yup.number().nullable(true),
  endMonth: Yup.number()
    .min(0)
    .max(11)
    .nullable(true),
  yearlyGrowthRate: Yup.number(),
  affectsRiskManagement: Yup.boolean(),
  isRent: Yup.boolean(),
  linkedApartment: Yup.string(),
  monthlyValueCurrencyConversionInfo: Yup.object()
});

export default cashflowSchema;
