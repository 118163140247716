import { snakeCase, uniqueId } from 'lodash';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { PlusCircle, Trash } from 'react-bootstrap-icons';
import UnderlineButton from '../../components/common/UnderlineButton';
import AppTable from '../../components/common/app-table/AppTable';
import useLocalization from '../../hooks/useLocalization';
import { mapFieldToElement } from '../helpers/TypeToElemMapper';
import { normalizeId } from '../helpers/utility';

const getInitialRowCount = (required, initialRowCount, preValue) => {
  if (preValue) return preValue.length;

  return required && initialRowCount < 1 ? 1 : initialRowCount;
};

const getInitialRowIds = numOfRows => Array.from({ length: numOfRows }, () => uniqueId());

const TableInput = ({
  id,
  title,
  tableFields: fields = [],
  initialRowCount = 0,
  className = '',
  preValue,
  onFormChange,
  size,
  disabled = false,
  required = false
}) => {
  const { translate } = useLocalization();
  const [rowIds, setRowIds] = useState(getInitialRowIds(getInitialRowCount(required, initialRowCount, preValue)));

  const tableId = id;

  const removeRow = rowId => {
    setRowIds(rowIds.filter(r => r !== rowId));
  };

  const addRow = () => {
    setRowIds([...rowIds, uniqueId()]);
  };

  const roundedHeader = index => {
    let className = '';
    if (index === 0) {
      className += ' rounded-left';
    }

    if (index === fields.length) {
      className += ' rounded-right';
    }
    return className;
  };

  const createTableRows = rowIds => {
    return rowIds.map((id, index) => createSingleRow(index, id));
  };

  const createSingleRow = (rowIndex, rowId) => {
    return (
      <tr className={`${id}-row-${rowIndex}`} key={rowId}>
        {fields.map((field, index) => (
          <td key={index} className={`${roundedHeader(index)} bg-white p-0 text-center`}>
            {mapFieldToElement({
              ...field,
              id: normalizeId(tableId + '-' + field.title + '-' + rowIndex),
              preValue: preValue && preValue[rowIndex] ? preValue[rowIndex][index] : null,
              onFormChange,
              size,
              disabled,
              isControlled: false
            })}
          </td>
        ))}
        <td className="p-0">
          <div className="text-center">
            <Button variant="outline-danger" className="px-1 py-0 " onClick={() => removeRow(rowId)}>
              <Trash size={9} />
            </Button>
          </div>
        </td>
      </tr>
    );
  };

  return (
    <>
      <AppTable id={tableId} bordered className={'rounded mb-0' + className} responsive>
        {/* mapping header */}
        <thead>
          <tr className={`bg-dark text-white`}>
            {fields.map((field, index) => (
              <th key={index} className={`${roundedHeader(index)} text-center`}>
                {translate(field.titleKey) ||
                  translate(snakeCase(field.id)) ||
                  translate(snakeCase(field.title)) ||
                  field.title}
              </th>
            ))}
            <th style={{ minWidth: 70 }}>{translate('actions')}</th>
          </tr>
        </thead>
        {/* mapping inputs */}
        {rowIds.length > 0 && <tbody>{createTableRows(rowIds)}</tbody>}
        {rowIds.length === 0 && (
          <tfoot>
            <tr>
              <td className={`${roundedHeader(0)} mid text-center p-0`} colSpan={fields.length}>
                Nothing to show
              </td>
            </tr>
          </tfoot>
        )}
      </AppTable>
      {!disabled && (
        <>
          <div className="text-end">
            <UnderlineButton
              fontSize="smallFont"
              variant="success"
              Icon={PlusCircle}
              text={translate('new')}
              onClick={addRow}
            />
          </div>
        </>
      )}
    </>
  );
};

export default TableInput;
