import React, { useContext, useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ENDPOINTS } from '../../../../../helpers/constants';
import { makeApiRequests } from '../../../../../helpers/api';
import Logs from '../../../../common/logs/Logs';
import CircularProgressBar from '../../../../common/circular-progress';
import { LocalizeContext } from 'react-locale-language';
import { toast } from 'react-toastify';

const UserLogsModal = ({ show, user, onHide, disabled, onUndoRedoClick }) => {
  const [loading, setLoading] = useState(true);
  const [logs, setLogs] = useState([]);
  const { translate } = useContext(LocalizeContext);

  const getLogs = async () => {
    if (!show || !user?._id) {
      setLogs([]);
      setLoading(false);
      return;
    }

    setLoading(true);

    const { response, error } = await makeApiRequests({
      endpoint: ENDPOINTS.USERS_LOGS(user._id),
    });

    setLoading(false);
    if (error) {
      toast.error(error);
      return;
    }

    setLogs(response);
  };

  useEffect(() => {
    getLogs();
  }, [show, user]);

  return (
    <Modal size="lg" show={show} onHide={onHide} centered>
      <Modal.Header closeButton>
        <Modal.Title>
          <h5 className="mb-0">{translate('logs')}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="py-1">
        {loading ? (
          <div className="p-5 text-center">
            <CircularProgressBar size={5} />
          </div>
        ) : (
          <Logs user={user} logs={logs} disabled={disabled} onUndoRedoClick={onUndoRedoClick} />
        )}
      </Modal.Body>
    </Modal>
  );
};

export default UserLogsModal;
