import React, { useContext, useMemo } from 'react';
import { Modal, Row, Col, Button } from 'react-bootstrap';
import { ExclamationTriangle } from 'react-bootstrap-icons';
import HorizontalProgress from './HorizontalProgress';
import { LocalizeContext } from 'react-locale-language';

const AlertModal = ({
  show,
  onHide,
  onContinueClick,
  onContinue2Click,
  onContinue3Click,
  onContinue4Click,
  onDismissClick,
  alertText,
  dismissButtonText,
  dismissButtonVariant = 'dark',
  continueButtonText,
  continueButtonVariant = 'danger',
  continue2ButtonText,
  continue2ButtonVariant = 'danger',
  continue3ButtonText,
  continue3ButtonVariant = 'danger',
  continue4ButtonText,
  continue4ButtonVariant = 'danger',
  progressText,
  showProgress,
  size
}) => {
  const { translate } = useContext(LocalizeContext);

  const additionalActions = useMemo(
    () =>
      [
        { onClick: onContinue2Click, text: continue2ButtonText, variant: continue2ButtonVariant },
        { onClick: onContinue3Click, text: continue3ButtonText, variant: continue3ButtonVariant },
        { onClick: onContinue4Click, text: continue4ButtonText, variant: continue4ButtonVariant }
      ].filter(({ onClick }) => onClick),
    [
      onContinue2Click,
      continue2ButtonText,
      continue2ButtonVariant,
      onContinue3Click,
      continue3ButtonText,
      continue3ButtonVariant,
      onContinue4Click,
      continue4ButtonText,
      continue4ButtonVariant
    ]
  );

  return (
    <Modal size={size} show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton={!showProgress}>
        <Modal.Title>
          <h6 className="mb-0">{translate('heads_up')}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        <Row>
          <Col className="text-center">
            <ExclamationTriangle className="text-danger" size={30} />
            <h6 className="mt-3"> {alertText} </h6>
          </Col>
        </Row>
        {showProgress && <HorizontalProgress text={progressText} />}
      </Modal.Body>
      <Modal.Footer>
        <Button
          style={{ fontSize: 12 }}
          size="sm"
          className="ms-2 px-2"
          variant={continueButtonVariant}
          onClick={onContinueClick}
          disabled={showProgress}
        >
          {continueButtonText || translate('yes')}
        </Button>

        {additionalActions.map(({ onClick, text, variant }, index) => (
          <Button
            style={{ fontSize: 12 }}
            key={index}
            size="sm"
            className="ms-1 px-1"
            variant={variant}
            onClick={onClick}
            disabled={showProgress}
          >
            {text || translate('yes')}
          </Button>
        ))}
      </Modal.Footer>
    </Modal>
  );
};

export default AlertModal;
