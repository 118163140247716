import React from 'react';
import { Route, Switch, withRouter } from 'react-router';
import { Redirect, useLocation } from 'react-router-dom';
import CachedResponse from '../../common/CachedResponse';
import NotFound from '../../common/NotFound';
import Customer from './customer/Customer';

const ManageUsers = ({assigneUsers=[]}) => {
  const location = useLocation();

  if (location.pathname === '/admin/manage-users') {
    return <Redirect from="/admin/manage-users" to="/admin/manage-users/customer" />;
  }

  return (
    <Switch>
      <Route path="/admin/manage-users/customer">
        <CachedResponse
          listEndpoint={'comps'}
          requestBody={{ filter: { customer: false } }}
          responseModifier={response => response[0]}
          render={compTable => <Customer assigneUsers={assigneUsers} compTable={compTable} />}
        />
      </Route>
      <Route path="*">
        <NotFound />
      </Route>
    </Switch>
  );
};

export default withRouter(ManageUsers);
