import React, { useContext, useMemo } from 'react';
import { Table } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import LTRNumberCell from '../../../../../common/LTRNumberCell';

const CushionTable = ({ cushionValues }) => {
  const { translate, langCode } = useContext(LocalizeContext);

  const normalStateLabel = useMemo(() => translate('normal_state'), [langCode]);
  const depressedStateLabel = useMemo(() => translate('depressed_state'), [langCode]);

  return (
    <>
      <h6>
        <b>{translate('cushions')}</b>
      </h6>

      <Table responsive bordered className="smallFont mb-2">
        <thead>
          <tr className="bg-dark text-white">
            <th></th>
            <th>{translate('value')}</th>
          </tr>
        </thead>
        <tbody>
          {cushionValues.map((cussion, index) => {
            const title = index === 0 ? normalStateLabel : depressedStateLabel;
            return (
              <tr key={title}>
                <td>{title}</td>
                <td>
                  <LTRNumberCell value={cussion} />
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </>
  );
};

export default CushionTable;
