import React, { useContext, useMemo } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { LocalizeContext } from 'react-locale-language';
import HorizontalProgress from '../../../common/HorizontalProgress';

const ReapplyCompModal = ({
  show,
  presetFields = [],
  selectedFields = [],
  strategies = [],
  selectedStrategies = [],
  onApplyClick,
  onStrategyCheckChange,
  onFieldCheckChange,
  onStrategyAllCheck,
  onFieldsAllCheck,
  showProgress,
  onHide
}) => {
  const { translate } = useContext(LocalizeContext);

  const presetFieldsOptions = useMemo(() => presetFields.map(o => ({ label: o.name, value: o.key })), [presetFields]);

  const strategyOptions = useMemo(() => strategies.map(o => ({ label: o.name, value: o._id })), [strategies]);

  return (
    <Modal show={show} onHide={onHide} centered backdrop="static">
      <Modal.Header closeButton={!showProgress}>
        <h6 className="mb-0">{translate('reapply_comp')}</h6>
      </Modal.Header>
      <Modal.Body className="overflow-auto">
        {[
          {
            options: strategyOptions,
            selections: selectedStrategies,
            onCheckChange: onStrategyCheckChange,
            label: 'strategies',
            onAllCheck: onStrategyAllCheck
          },
          {
            options: presetFieldsOptions,
            selections: selectedFields,
            onCheckChange: onFieldCheckChange,
            label: 'presets',
            onAllCheck: onFieldsAllCheck
          }
        ].map(({ options, selections, onCheckChange, label, onAllCheck }) => (
          <div key={label} className="py-2">
            <Form.Check
              onChange={onAllCheck}
              checked={options.every(o => selections.includes(o.value))}
              className="large fw-bold"
              size="sm"
              label={translate(label)}
            />
            <hr className="mt-0 mb-2" />
            <Row>
              {options.map(({ label, value }) => (
                <Col xs={6}>
                  <Form.Check
                    onChange={() => onCheckChange(value)}
                    checked={selections.includes(value)}
                    className="mid"
                    size="sm"
                    label={label}
                  />
                </Col>
              ))}
            </Row>
          </div>
        ))}
        {showProgress && <HorizontalProgress text={'Reapplying comp...'} />}
      </Modal.Body>
      <Modal.Footer>
        <Button disabled={showProgress} variant="success" size="sm" className="me-2 text-white" onClick={onApplyClick}>
          {translate('apply')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ReapplyCompModal;
