import React, { useContext, useMemo } from 'react';
import { Button, Card } from 'react-bootstrap';
import { Pen, PlusCircle, Trash } from 'react-bootstrap-icons';
import { assetCategoriesOptions, fieldsWithFormulas, getPresetTableHeaders } from '../../../helpers/constants';
import EntityTable from '../../common/EntityTable';
import { LocalizeContext } from 'react-locale-language';

const Presets = ({
  presets = [],
  variables = [],
  onPresetAddClick,
  onPresetEditClick,
  onPresetDeleteClick,
  readOnlyMode
}) => {
  const { translate, langCode } = useContext(LocalizeContext);
  const presetTableHeaders = useMemo(() => getPresetTableHeaders(translate), [langCode]);

  //have presets for real estate and all others
  return (
    <Card className="mt-3">
      <Card.Header>{translate('presets')}</Card.Header>
      <Card.Body>
        {[
          { title: 'Real Estate', filterCategories: ['Real Estate'] },
          { title: 'Others', filterCategories: assetCategoriesOptions.filter(c => c !== 'Real Estate') }
        ].map(({ title, filterCategories }) => (
          <>
            <h6>
              <b>{title}</b>
            </h6>
            <EntityTable
              rowKeyField={'_id'}
              data={presets
                .filter(p => filterCategories.includes(p.assetType))
                .map(p => ({
                  ...p,
                  remainingPayment:
                    p['apartmentType'] === 'Paper Apartment' && p['remainingPayment']
                      ? p['remainingPayment'] === 'gradualPayment'
                        ? 'Gradual Payment'
                        : 'One Final Payment'
                      : ''
                }))}
              evaluationVariables={variables}
              fieldsWithFormulas={fieldsWithFormulas}
              showEvaluatedValues
              headers={presetTableHeaders.filter(
                h => !h.forAssetTypes || filterCategories.some(c => h.forAssetTypes.includes(c))
              )}
              searchQueryKeys={['name']}
              additionalComponents={() =>
                !readOnlyMode && (
                  <Button
                    size="sm"
                    className="text-white"
                    variant="success"
                    onClick={() => onPresetAddClick(filterCategories)}
                  >
                    <PlusCircle className="mr-1" /> {translate('new_presets')} ({title})
                  </Button>
                )
              }
              actionCell={item =>
                !readOnlyMode && (
                  <div>
                    <Button
                      variant="outline-primary"
                      className="px-1 py-0 mr-1"
                      onClick={() => onPresetEditClick(item, filterCategories)}
                    >
                      <Pen size={10} />
                    </Button>
                    <Button variant="outline-danger" className="px-1 py-0 " onClick={() => onPresetDeleteClick(item)}>
                      <Trash size={10} />
                    </Button>
                  </div>
                )
              }
            />
          </>
        ))}
      </Card.Body>
    </Card>
  );
};

export default Presets;
